// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function emptyElem_getBoundingClientRect() {
  return {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
}

var emptyElem = {
  getBoundingClientRect: emptyElem_getBoundingClientRect,
  scrollHeight: 0,
  scrollWidth: 0,
  clientHeight: 0,
  clientWidth: 0
};

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

var addEventListener = ((event, handler) => window.addEventListener(event, handler));

var removeEventListener = ((event, handler) => window.removeEventListener(event, handler));

exports.emptyElem = emptyElem;
exports.$$Window = $$Window;
exports.addEventListener = addEventListener;
exports.removeEventListener = removeEventListener;
/* No side effect */
