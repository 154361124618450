// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin = require("../../../__generated__/ChangeEmailSubmitNew_ChangeEmailMutation_graphql.bs.js");

var convertVariables = ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ChangeEmailMutation_changeEmailErr_decode = ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_decode;

var ChangeEmailMutation_changeEmailErr_fromString = ChangeEmailSubmitNew_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_fromString;

var ChangeEmailMutation = {
  changeEmailErr_decode: ChangeEmailMutation_changeEmailErr_decode,
  changeEmailErr_fromString: ChangeEmailMutation_changeEmailErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSuccess = match$2[1];
  var changeEmail = function (input) {
    setError(function (param) {
          
        });
    mutate({
          input: input
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.changeEmail;
              if (match !== undefined) {
                var err$1 = match.changeEmailErr;
                if (err$1 !== undefined) {
                  var error;
                  if (err$1 === "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" || err$1 === "EMAIL_ALREADY_IN_USE" || err$1 === "NOT_SIGNED_ON_STATUS") {
                    switch (err$1) {
                      case "EMAIL_ALREADY_IN_USE" :
                          error = Txt$Thin.$$Error.emailInUse.value;
                          break;
                      case "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" :
                          error = Txt$Thin.$$Error.emailCantBeChanged.value;
                          break;
                      case "NOT_SIGNED_ON_STATUS" :
                          error = Txt$Thin.Account.emailModalError.value;
                          break;
                      
                    }
                  } else {
                    error = Txt$Thin.Account.emailModalError.value;
                  }
                  setError(function (param) {
                        return error;
                      });
                  return ;
                }
                
              }
              refresh();
              setSuccess(function (param) {
                    return true;
                  });
              return ;
            }
            setError(function (param) {
                  return Txt$Thin.$$Error.genericFormSubmit.value;
                });
          }), (function (param) {
            setError(function (param) {
                  return Txt$Thin.$$Error.genericFormSubmit.value;
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError,
          success: match$2[0],
          isMutating: match[1],
          changeEmail: changeEmail
        };
}

exports.ChangeEmailMutation = ChangeEmailMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
