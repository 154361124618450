// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Common$Thin = require("../Common.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");

var newPassword = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.newPassword;
        }),
      write: (function (m, newPassword) {
          return {
                  newPassword: newPassword,
                  confirmNewPassword: m.confirmNewPassword
                };
        })
    }, Common$Thin.validatePassword, (function (a) {
        return a;
      }), "", undefined);

var confirmNewPassword = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.confirmNewPassword;
        }),
      write: (function (m, confirmNewPassword) {
          return {
                  newPassword: m.newPassword,
                  confirmNewPassword: confirmNewPassword
                };
        })
    }, Common$Thin.validatePassword, (function (a) {
        return a;
      }), "", (function (model, res) {
        return Belt_Result.flatMap(res, (function (good) {
                      if (model.newPassword.value === good) {
                        return {
                                TAG: "Ok",
                                _0: good
                              };
                      } else {
                        return {
                                TAG: "Error",
                                _0: "The passwords you entered do not match."
                              };
                      }
                    }));
      }));

var blankModel_newPassword = newPassword.blankStore;

var blankModel_confirmNewPassword = confirmNewPassword.blankStore;

var blankModel = {
  newPassword: blankModel_newPassword,
  confirmNewPassword: blankModel_confirmNewPassword
};

exports.newPassword = newPassword;
exports.confirmNewPassword = confirmNewPassword;
exports.blankModel = blankModel;
/* newPassword Not a pure module */
