// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var vars_shouldNotRedirectAfterLogin = [
  "login",
  "reset-password",
  "transfer",
  "verify",
  "claim"
];

var vars = {
  loginLink: "/login",
  loginRedirectParam: "to",
  draftParam: "d",
  signUpLink: "/signup",
  bookingsLink: "/bookings",
  accountLink: "/account",
  connectedAccountsLink: "/connected-accounts",
  expiryKey: "expiry",
  tokenKey: "token",
  dateFormat: "MM/dd/yyyy",
  timeFormat: "h:mm b",
  mailtoEmail: "booking@jococruise.com",
  changeRequestSubject: "Update to personal information",
  placementUpdateRequestSubject: "Update to cabin placement preferences",
  currency: "USD",
  locale: "en-US",
  longDateFormat: "MMMM d, yyyy",
  shouldNotRedirectAfterLogin: vars_shouldNotRedirectAfterLogin
};

function getBookedSessionKey(b) {
  return "booked-session-" + CS_NonemptyStrings$Util.BookingId.toString(b);
}

exports.vars = vars;
exports.getBookedSessionKey = getBookedSessionKey;
/* CS_NonemptyStrings-Util Not a pure module */
