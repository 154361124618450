// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var ConfirmActionDialog$Thin = require("./ConfirmActionDialog.bs.js");
var Logout = require("@mui/icons-material/Logout").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var AccountCircle = require("@mui/icons-material/AccountCircle").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

function Sidebar(props) {
  var match = CurrentUser$Thin.Context.use();
  var logout = match.logout;
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoseBookingChangesModalOpen = match$1[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = React.useState(function () {
        return "BOOKING";
      });
  var setNavDestination = match$2[1];
  var navDestination = match$2[0];
  var diff = BookingDiff$Thin.parse(url.search);
  var onCancel = function () {
    setLoseBookingChangesModalOpen(function (param) {
          return false;
        });
  };
  var onSubmit = function () {
    setLoseBookingChangesModalOpen(function (param) {
          return false;
        });
    switch (navDestination) {
      case "BOOKING" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
      case "CONNECTED" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.connectedAccountsLink);
      case "ACCOUNT" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
      case "LOGOUT" :
          return logout();
      
    }
  };
  var onAttemptToNavigateAway = function (link) {
    var match = diff.hasDiff;
    if (match) {
      return setLoseBookingChangesModalOpen(function (param) {
                  return true;
                });
    } else if (link !== undefined) {
      return RescriptReactRouter.push(link);
    } else {
      return logout();
    }
  };
  var match$3 = url.path;
  var tmp;
  if (match.hasConnectedAccounts) {
    var match$4 = url.path;
    tmp = JsxRuntime.jsx(ListItem, {
          children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                    children: [
                      JsxRuntime.jsx(ListItemDecorator, {
                            children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {}))
                          }),
                      JsxRuntime.jsx(ListItemContent, {
                            children: Caml_option.some(Txt$Thin.Nav.connectedAccounts.value)
                          })
                    ],
                    selected: match$4 && !match$4.tl ? Js_string.substr(1, SysCtx$Thin.vars.connectedAccountsLink) === match$4.hd : false,
                    onClick: (function (param) {
                        setNavDestination(function (param) {
                              return "CONNECTED";
                            });
                        onAttemptToNavigateAway(SysCtx$Thin.vars.connectedAccountsLink);
                      }),
                    sx: {
                      borderRadius: "sm"
                    }
                  }))
        });
  } else {
    tmp = null;
  }
  var match$5 = url.path;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              justifyContent: "flex-start",
              sx: {
                display: {
                  xs: "none",
                  md: "flex"
                },
                width: "13rem",
                flexShrink: 0,
                overflowY: "auto",
                borderRight: 1,
                borderColor: "neutral.200",
                backgroundColor: "background.surface",
                p: 1
              },
              children: [
                JsxRuntime.jsx(List, {
                      children: Caml_option.some(JsxRuntime.jsxs(List, {
                                children: [
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DirectionsBoat, {}))
                                                        }),
                                                    JsxRuntime.jsx(ListItemContent, {
                                                          children: Caml_option.some(Txt$Thin.Nav.bookings.value)
                                                        })
                                                  ],
                                                  selected: match$3 && !match$3.tl ? Js_string.substr(1, SysCtx$Thin.vars.bookingsLink) === match$3.hd : false,
                                                  onClick: (function (param) {
                                                      setNavDestination(function (param) {
                                                            return "BOOKING";
                                                          });
                                                      onAttemptToNavigateAway(SysCtx$Thin.vars.bookingsLink);
                                                    }),
                                                  sx: {
                                                    borderRadius: "sm"
                                                  }
                                                }))
                                      }),
                                  tmp,
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(AccountCircle, {}))
                                                        }),
                                                    JsxRuntime.jsx(ListItemContent, {
                                                          children: Caml_option.some(Txt$Thin.Nav.account.value)
                                                        })
                                                  ],
                                                  selected: match$5 && !match$5.tl ? Js_string.substr(1, SysCtx$Thin.vars.accountLink) === match$5.hd : false,
                                                  onClick: (function (param) {
                                                      setNavDestination(function (param) {
                                                            return "ACCOUNT";
                                                          });
                                                      onAttemptToNavigateAway(SysCtx$Thin.vars.accountLink);
                                                    }),
                                                  sx: {
                                                    borderRadius: "sm"
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Logout, {}))
                                                        }),
                                                    JsxRuntime.jsx(ListItemContent, {
                                                          children: Caml_option.some(Txt$Thin.Nav.logout.value)
                                                        })
                                                  ],
                                                  onClick: (function (param) {
                                                      setNavDestination(function (param) {
                                                            return "LOGOUT";
                                                          });
                                                      onAttemptToNavigateAway(undefined);
                                                    }),
                                                  sx: {
                                                    borderRadius: "sm"
                                                  }
                                                })),
                                        sx: {
                                          flexShrink: 0
                                        }
                                      })
                                ],
                                size: "sm",
                                sx: {
                                  gap: 1,
                                  flexGrow: 1
                                }
                              })),
                      size: "sm",
                      sx: {
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        overflowY: "visible"
                      }
                    }),
                JsxRuntime.jsx(ConfirmActionDialog$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setLoseBookingChangesModalOpen(function (param) {
                                return false;
                              });
                        }),
                      action: Txt$Thin.BookingDetails.draftConfirmLoseChangesTitle.value,
                      actionSlug: "cancel-booking-changes",
                      description: Txt$Thin.BookingDetails.draftConfirmLoseChangesText.value,
                      onConfirm: (function (param) {
                          onSubmit();
                        }),
                      confirmText: Txt$Thin.BookingDetails.draftConfirmLoseChangesButtonText.value,
                      onCancel: onCancel,
                      cancelText: Txt$Thin.BookingDetails.draftCancelLoseChangesButtonText.value,
                      loading: false
                    })
              ]
            });
}

var make = Sidebar;

exports.make = make;
/* react Not a pure module */
