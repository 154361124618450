// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppBanner$Thin = require("../../common/atoms/AppBanner.bs.js");
var Switch = require("@mui/joy/Switch").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var BillPayData$Thin = require("../drafts/BillPayData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDBillPayTable$Thin = require("./BDBillPayTable.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;

function BDInvoiceDetails$TableLine(props) {
  var line = props.line;
  switch (line.TAG) {
    case "Billing" :
        var bl = line._0;
        return JsxRuntime.jsxs("tr", {
                    children: [
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                  value: bl.billingLineDescription,
                                  fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal"
                                })
                          }),
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsx(Stack, {
                                  direction: "row",
                                  justifyContent: "flex-end",
                                  sx: {
                                    width: "100%"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                            value: Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)),
                                            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal"
                                          }))
                                })
                          })
                    ]
                  });
    case "Payment" :
        var pl = line._0;
        var dateRender = pl.shouldRenderStatus ? pl.lifecycleStatus : pl.date;
        var url = pl.paymentMethodImage;
        return JsxRuntime.jsxs("tr", {
                    children: [
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                  value: dateRender,
                                  fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                })
                          }),
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                  value: pl.payer,
                                  fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                })
                          }),
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  alignItems: "center",
                                  spacing: 1,
                                  children: [
                                    url !== undefined ? JsxRuntime.jsx(Stack, {
                                            direction: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            sx: {
                                              width: "1.5rem",
                                              height: "1.5rem"
                                            },
                                            children: Caml_option.some(JsxRuntime.jsx("img", {
                                                      style: {
                                                        width: "100%"
                                                      },
                                                      alt: "payment method",
                                                      src: url
                                                    }))
                                          }) : null,
                                    JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                                  value: pl.paymentMethod,
                                                  fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                                }),
                                            CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount) > 0.00 ? JsxRuntime.jsx(Typography, {
                                                    level: "body-xs",
                                                    noWrap: true,
                                                    textColor: "neutral.500",
                                                    fontWeight: "inherit",
                                                    children: "Fee incurred"
                                                  }) : null
                                          ]
                                        })
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsx(Stack, {
                                  direction: "row",
                                  justifyContent: "flex-end",
                                  sx: {
                                    width: "100%"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                            direction: "column",
                                            alignItems: "flex-end",
                                            children: [
                                              JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                                    value: Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.amount)),
                                                    fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                                  }),
                                              CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount) > 0.00 ? JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                                      value: Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount)),
                                                      startDecorator: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                children: JsxRuntime.jsx(IconButton, {
                                                                      sx: {
                                                                        minHeight: "0",
                                                                        minWidth: "0",
                                                                        "&:hover": {
                                                                          background: "none"
                                                                        }
                                                                      },
                                                                      children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                                                                                fontSize: "xs"
                                                                              }))
                                                                    }),
                                                                sx: {
                                                                  maxWidth: "250px"
                                                                },
                                                                title: "Total does not include this additional payment processor fee."
                                                              }))
                                                    }) : null
                                            ]
                                          }))
                                })
                          })
                    ]
                  });
    case "Changelog" :
        var cl = line._0;
        var match = cl.change;
        var tmp;
        tmp = match !== undefined && typeof match !== "object" && match === "Delete" ? "danger" : "inherit";
        var match$1 = cl.change;
        var tmp$1;
        var exit = 0;
        if (match$1 !== undefined && typeof match$1 === "object") {
          tmp$1 = CS_Decimal$Util.Decimal10_2.toFloat(match$1._0) <= 0.0 ? "italic" : "normal";
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp$1 = CS_Decimal$Util.Decimal10_2.toFloat(cl.amt) <= 0.0 ? "italic" : "normal";
        }
        var match$2 = cl.change;
        var tmp$2;
        tmp$2 = match$2 !== undefined && typeof match$2 !== "object" && match$2 === "Delete" ? "line-through" : "none";
        var match$3 = cl.change;
        var tmp$3;
        tmp$3 = match$3 !== undefined && typeof match$3 !== "object" && match$3 !== "Delete" ? JsxRuntime.jsx(AppChip$Thin.make, {
                children: "NEW",
                color: "Success",
                noPad: true
              }) : null;
        var match$4 = cl.change;
        var tmp$4;
        tmp$4 = match$4 !== undefined && typeof match$4 !== "object" && match$4 === "Delete" ? "danger" : "inherit";
        var match$5 = cl.change;
        var tmp$5;
        tmp$5 = match$5 !== undefined && typeof match$5 === "object" ? "neutral.400" : "";
        var match$6 = cl.change;
        var tmp$6;
        tmp$6 = match$6 !== undefined && !(typeof match$6 !== "object" && match$6 !== "Delete") ? "line-through" : "none";
        var match$7 = cl.change;
        var tmp$7;
        if (match$7 !== undefined && typeof match$7 === "object") {
          var val = match$7._0;
          tmp$7 = JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                value: Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(val)),
                fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(val) <= 0.0 ? "italic" : "normal"
              });
        } else {
          tmp$7 = null;
        }
        return JsxRuntime.jsxs("tr", {
                    children: [
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  spacing: 1,
                                  children: [
                                    JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                          value: cl.desc,
                                          color: tmp,
                                          fontStyle: tmp$1,
                                          textDecoration: tmp$2
                                        }),
                                    tmp$3
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("td", {
                            children: JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  alignItems: "flex-end",
                                  sx: {
                                    width: "100%",
                                    py: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                          value: Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(cl.amt)),
                                          color: tmp$4,
                                          fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(cl.amt) <= 0.0 ? "italic" : "normal",
                                          nonVariantColor: tmp$5,
                                          textDecoration: tmp$6
                                        }),
                                    tmp$7
                                  ]
                                })
                          })
                    ]
                  });
    
  }
}

var TableLine = {
  make: BDInvoiceDetails$TableLine
};

function BDInvoiceDetails$InvoiceDetails(props) {
  var showPaymentDetails = props.showPaymentDetails;
  var changelog = props.changelog;
  var isActive = props.isActive;
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setShowAdjustments = match[1];
  var showAdjustments = match[0];
  var billingColumns = [
    {
      heading: Txt$Thin.BookingDetails.descriptionTableHeading.value,
      width: "8rem"
    },
    {
      heading: Txt$Thin.BookingDetails.amountTableHeading.value,
      width: "2rem"
    }
  ];
  var paymentColumns = [
    {
      heading: Txt$Thin.BookingDetails.dateTableHeading.value,
      width: "3rem"
    },
    {
      heading: Txt$Thin.BookingDetails.payerTableHeading.value,
      width: "3rem"
    },
    {
      heading: Txt$Thin.BookingDetails.paymentMethodTableHeading.value,
      width: "4rem"
    },
    {
      heading: Txt$Thin.BookingDetails.amountTableHeading.value,
      width: "3rem"
    }
  ];
  var match$1;
  var exit = 0;
  if (changelog !== undefined && showAdjustments) {
    match$1 = [
      changelog.total.value,
      changelog.total.newValue,
      changelog.arePending,
      changelog.totalInclPending.value,
      changelog.totalInclPending.newValue
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$1 = [
      content.totalAmount,
      undefined,
      content.arePendingPayments,
      content.totalAmountIncludingPending,
      undefined
    ];
  }
  var totalAmountIncludingPendingNew = match$1[4];
  var totalAmountIncludingPendingCurrent = match$1[3];
  var totalNew = match$1[1];
  var totalCurrent = match$1[0];
  var paymentBanner = BillPayData$Thin.PaymentsStatus.getPaymentStatusBanner(content.paymentPlan, props.draftMode);
  var tmp;
  var exit$1 = 0;
  if (changelog !== undefined && showAdjustments) {
    var newVal = changelog.bill.blSubtotal.newValue;
    tmp = JsxRuntime.jsx(Stack, {
          sx: {
            "overflow-x": "auto"
          },
          children: Caml_option.some(JsxRuntime.jsx(BDBillPayTable$Thin.make, {
                    columns: billingColumns,
                    totalDescription: Txt$Thin.BookingDetails.totalBilledTableFooter.value,
                    total: newVal !== undefined ? ({
                          TAG: "Compare",
                          _0: changelog.bill.blSubtotal.value,
                          _1: Caml_option.valFromOption(newVal)
                        }) : ({
                          TAG: "Equals",
                          _0: changelog.bill.blSubtotal.value
                        }),
                    pendingDescription: Txt$Thin.BookingDetails.pendingBilledTableFooter.value,
                    pendingTotal: {
                      TAG: "Equals",
                      _0: CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00")
                    },
                    arePending: false,
                    isActive: isActive,
                    children: Belt_Array.mapWithIndex(changelog.bill.bLines, (function (i, line) {
                            return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                        line: {
                                          TAG: "Changelog",
                                          _0: line
                                        }
                                      }, String(i));
                          }))
                  }))
        });
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp = content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(Stack, {
            sx: {
              "overflow-x": "auto"
            },
            children: Caml_option.some(JsxRuntime.jsx(BDBillPayTable$Thin.make, {
                      columns: billingColumns,
                      totalDescription: Txt$Thin.BookingDetails.totalBilledTableFooter.value,
                      total: {
                        TAG: "Equals",
                        _0: content.billing.billingLineSubtotal
                      },
                      pendingDescription: Txt$Thin.BookingDetails.pendingBilledTableFooter.value,
                      pendingTotal: {
                        TAG: "Equals",
                        _0: CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00")
                      },
                      arePending: false,
                      isActive: isActive,
                      children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                              return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                          line: {
                                            TAG: "Billing",
                                            _0: line
                                          }
                                        }, String(i));
                            }))
                    }))
          }) : JsxRuntime.jsx(Stack, {
            direction: "column",
            justifyContent: "center",
            alignItems: "center",
            sx: {
              height: "7rem"
            },
            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                      level: "title-lg",
                      component: "p",
                      textColor: "neutral.500",
                      children: Caml_option.some(isActive ? Txt$Thin.BookingDetails.noBillingLines.value : Txt$Thin.BookingDetails.noBillingLinesInactive.value)
                    }))
          });
  }
  var tmp$1;
  var exit$2 = 0;
  if (showPaymentDetails !== undefined && !showPaymentDetails) {
    tmp$1 = null;
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Stack, {
                  direction: "row",
                  justifyContent: "space-between",
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            level: "h4",
                            component: "h4",
                            children: Caml_option.some(Txt$Thin.BookingDetails.paymentsHeading.value)
                          }))
                }),
            content.payment.paymentLines.length !== 0 ? JsxRuntime.jsx(Stack, {
                    sx: {
                      "overflow-x": "auto"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(BDBillPayTable$Thin.make, {
                              columns: paymentColumns,
                              totalDescription: Txt$Thin.BookingDetails.totalPaidTableFooter.value,
                              total: {
                                TAG: "Equals",
                                _0: content.payment.completedPaymentSubtotal
                              },
                              pendingDescription: Txt$Thin.BookingDetails.pendingPaidTableFooter.value,
                              pendingTotal: {
                                TAG: "Equals",
                                _0: content.payment.pendingPaymentSubtotal
                              },
                              arePending: content.arePendingPayments,
                              isActive: isActive,
                              children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                      return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                  line: {
                                                    TAG: "Payment",
                                                    _0: line
                                                  }
                                                }, String(i));
                                    }))
                            }))
                  }) : JsxRuntime.jsx(Stack, {
                    direction: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    sx: {
                      height: "7rem"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              component: "p",
                              textColor: "neutral.500",
                              children: Caml_option.some(isActive ? Txt$Thin.BookingDetails.noPaymentLines.value : Txt$Thin.BookingDetails.noPaymentLinesInactive.value)
                            }))
                  })
          ]
        });
  }
  var match$2 = CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmount) > 0.0;
  var tmp$2;
  var exit$3 = 0;
  if (totalNew !== undefined && CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(totalNew)) > 0.0) {
    tmp$2 = "inherit";
  } else {
    exit$3 = 1;
  }
  if (exit$3 === 1) {
    tmp$2 = CS_Decimal$Util.Decimal10_2.toFloat(totalCurrent) > 0.0 ? "inherit" : "#037E3A";
  }
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: Caml_option.some(Txt$Thin.BookingDetails.bookingChargesHeading.value)
                                    }),
                                Belt_Option.isSome(changelog) ? JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                textColor: "text.tertiary",
                                                children: "Show adjustments"
                                              }),
                                          JsxRuntime.jsx(Switch, {
                                                checked: showAdjustments,
                                                onChange: (function (param) {
                                                    setShowAdjustments(function (prev) {
                                                          return !prev;
                                                        });
                                                  })
                                              })
                                        ]
                                      }) : null
                              ]
                            }),
                        tmp
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        tmp$1,
                        JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              spacing: 1,
                              sx: {
                                my: 2,
                                width: "100%"
                              },
                              children: [
                                JsxRuntime.jsx(AppBanner$Thin.make, {
                                      children: JsxRuntime.jsxs(Stack, {
                                            direction: "column",
                                            spacing: 1,
                                            sx: {
                                              width: "100%"
                                            },
                                            children: [
                                              JsxRuntime.jsxs(Stack, {
                                                    direction: "row",
                                                    justifyContent: "space-between",
                                                    sx: {
                                                      width: "100%"
                                                    },
                                                    children: [
                                                      JsxRuntime.jsx(Typography, {
                                                            startDecorator: Caml_option.some(match$2 ? JsxRuntime.jsx(InfoOutlined, {
                                                                        fontSize: "md"
                                                                      }) : JsxRuntime.jsx(CheckCircleOutline, {
                                                                        fontSize: "md",
                                                                        sx: {
                                                                          color: "#037E3A",
                                                                          pr: 1
                                                                        }
                                                                      })),
                                                            level: "title-sm",
                                                            textColor: tmp$2,
                                                            children: Caml_option.some(Txt$Thin.BookingDetails.remainingBalance.value)
                                                          }),
                                                      JsxRuntime.jsxs(Stack, {
                                                            direction: "column",
                                                            justifyContent: "flex-end",
                                                            children: [
                                                              totalNew !== undefined ? JsxRuntime.jsx(Typography, {
                                                                      level: "body-sm",
                                                                      sx: {
                                                                        textDecoration: "line-through"
                                                                      },
                                                                      textColor: "neutral.400",
                                                                      children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(totalCurrent)))
                                                                    }) : null,
                                                              JsxRuntime.jsx(Typography, {
                                                                    level: "title-sm",
                                                                    textColor: "text.secondary",
                                                                    children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(totalNew !== undefined ? Caml_option.valFromOption(totalNew) : totalCurrent)))
                                                                  })
                                                            ]
                                                          })
                                                    ]
                                                  }),
                                              match$1[2] ? JsxRuntime.jsxs(Stack, {
                                                      direction: "row",
                                                      justifyContent: "space-between",
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-xs",
                                                              sx: {
                                                                fontStyle: "italic"
                                                              },
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.balanceAfterPending.value)
                                                            }),
                                                        JsxRuntime.jsxs(Stack, {
                                                              direction: "column",
                                                              justifyContent: "flex-end",
                                                              children: [
                                                                totalAmountIncludingPendingNew !== undefined ? JsxRuntime.jsx(Typography, {
                                                                        level: "body-xs",
                                                                        sx: {
                                                                          textDecoration: "line-through"
                                                                        },
                                                                        textColor: "neutral.400",
                                                                        children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(totalAmountIncludingPendingCurrent)))
                                                                      }) : null,
                                                                JsxRuntime.jsx(Typography, {
                                                                      level: "body-xs",
                                                                      textColor: "text.secondary",
                                                                      children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(totalAmountIncludingPendingNew !== undefined ? Caml_option.valFromOption(totalAmountIncludingPendingNew) : totalAmountIncludingPendingCurrent)))
                                                                    })
                                                              ]
                                                            })
                                                      ]
                                                    }) : null
                                            ]
                                          }),
                                      color: paymentBanner !== undefined ? "Clear" : "Neutral"
                                    }),
                                paymentBanner !== undefined ? Caml_option.valFromOption(paymentBanner) : null
                              ]
                            })
                      ]
                    })
              ]
            });
}

var InvoiceDetails = {
  make: BDInvoiceDetails$InvoiceDetails
};

function BDInvoiceDetails(props) {
  return JsxRuntime.jsx(BDInvoiceDetails$InvoiceDetails, {
              content: props.invoiceContent,
              isActive: props.isActive,
              changelog: props.changelog,
              showPaymentDetails: props.showPaymentDetails,
              draftMode: props.draftMode
            });
}

var make = BDInvoiceDetails;

exports.TableLine = TableLine;
exports.InvoiceDetails = InvoiceDetails;
exports.make = make;
/* react Not a pure module */
