// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PaymentSuggestions$Thin = require("../../../../utils/sales-flow/PaymentSuggestions.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var ConfirmationMiniCard$Thin = require("../ConfirmationMiniCard.bs.js");
var PlaidConfirmationModal$Thin = require("./PlaidConfirmationModal.bs.js");

function PlaidFlow$WithLoaded(props) {
  var needConfirm = props.needConfirm;
  var paymentReturn = props.paymentReturn;
  var isSelected = props.isSelected;
  var setPaymentAction = props.setPaymentAction;
  var bookingId = props.bookingId;
  var payment = props.payment;
  var match = props.loaded;
  var openPlaidForAccountSelection = match.openPlaidForAccountSelection;
  var accountSelection = match.accountSelection;
  var processPaymentEffect = match.processPaymentEffect;
  var isSessionPayment;
  isSessionPayment = payment.TAG === "Manage" ? false : true;
  var match$1 = React.useState(function () {
        return false;
      });
  var setWarningModalOpen = match$1[1];
  var onClick = function (param) {
    if (needConfirm) {
      return setWarningModalOpen(function (param) {
                  return true;
                });
    } else {
      return openPlaidForAccountSelection();
    }
  };
  React.useEffect((function () {
          var match = PaymentSuggestions$Thin.getPaymentCents(payment);
          var exit = 0;
          if (processPaymentEffect !== undefined) {
            if (isSessionPayment) {
              if (match !== undefined && !(typeof accountSelection !== "object" || !(accountSelection.TAG === "Success" && isSelected))) {
                setPaymentAction(function (param) {
                      return (function () {
                                processPaymentEffect(paymentReturn, {
                                      TAG: "AgainstSession",
                                      netAmountInPositiveCents: match
                                    });
                              });
                    });
              } else {
                exit = 1;
              }
            } else if (bookingId !== undefined && match !== undefined && !(typeof accountSelection !== "object" || !(accountSelection.TAG === "Success" && isSelected))) {
              var bookingId$1 = Caml_option.valFromOption(bookingId);
              setPaymentAction(function (param) {
                    return (function () {
                              processPaymentEffect(paymentReturn, {
                                    TAG: "AgainstBooking",
                                    bookingId: bookingId$1,
                                    netAmountInPositiveCents: match
                                  });
                            });
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            setPaymentAction(function (param) {
                  
                });
          }
          
        }), [
        accountSelection,
        isSelected
      ]);
  var tmp;
  tmp = typeof accountSelection !== "object" || accountSelection.TAG !== "Success" ? null : JsxRuntime.jsx(ConfirmationMiniCard$Thin.make, {
          name: accountSelection._0.accountNiceName
        });
  var tmp$1;
  tmp$1 = typeof accountSelection !== "object" || accountSelection.TAG !== "Success" ? "Select account" : "Select a different account";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 2,
                      sx: {
                        py: 1
                      },
                      children: [
                        tmp,
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              sx: {
                                width: "fit-content"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        onClick: onClick,
                                        disabled: isSelected === false,
                                        children: Caml_option.some(tmp$1)
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PlaidConfirmationModal$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setWarningModalOpen(function (param) {
                                return false;
                              });
                        }),
                      onConfirm: (function () {
                          setWarningModalOpen(function (param) {
                                return false;
                              });
                          ((document.body.style.overflow = null));
                          openPlaidForAccountSelection();
                        })
                    })
              ]
            });
}

var WithLoaded = {
  make: PlaidFlow$WithLoaded
};

function PlaidFlow(props) {
  var setCalculatedFee = props.setCalculatedFee;
  var paymentReturn = props.paymentReturn;
  var isSelected = props.isSelected;
  var payment = props.payment;
  var match = props.usePlaidDebit();
  var getFee = match.getFee;
  var loaded = match.loaded;
  React.useEffect((function () {
          var match = PaymentSuggestions$Thin.getPaymentCents(payment);
          var exit = 0;
          if (isSelected && match !== undefined) {
            if (match === 0) {
              setCalculatedFee(function (param) {
                    
                  });
            } else {
              setCalculatedFee(function (param) {
                    return getFee(match);
                  });
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            setCalculatedFee(function (param) {
                  
                });
          }
          
        }), [
        isSelected,
        payment
      ]);
  var tmp;
  var exit = 0;
  if (typeof loaded !== "object") {
    exit = 1;
  } else {
    tmp = loaded.TAG === "Success" ? JsxRuntime.jsx(JsxRuntime.Fragment, {
            children: Caml_option.some(JsxRuntime.jsx(PlaidFlow$WithLoaded, {
                      loaded: loaded._0,
                      getFee: getFee,
                      payment: payment,
                      bookingId: props.bookingId,
                      setPaymentAction: props.setPaymentAction,
                      isSelected: isSelected,
                      paymentReturn: paymentReturn,
                      needConfirm: props.needConfirm
                    }))
          }) : JsxRuntime.jsx(AppAlert$Thin.make, {
            message: "Transfer processor couldn't load",
            type_: "error"
          });
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "center",
          children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                    size: "md",
                    variant: "solid"
                  }))
        });
  }
  var match$1 = ReactState$Util.getter(paymentReturn);
  var tmp$1;
  if (isSelected && !(typeof match$1 !== "object" || match$1.TAG !== "Failure")) {
    var err = match$1._0;
    switch (err.TAG) {
      case "StringError" :
          tmp$1 = JsxRuntime.jsx(AppAlert$Thin.make, {
                message: err._0,
                type_: "error"
              });
          break;
      case "BraintreeHostedFieldsError" :
      case "PlaidError" :
          tmp$1 = null;
          break;
      
    }
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 1,
              children: [
                tmp,
                tmp$1
              ]
            });
}

var make = PlaidFlow;

exports.WithLoaded = WithLoaded;
exports.make = make;
/* react Not a pure module */
