// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var VerifySubmit$Thin = require("../components/verify/mutations/VerifySubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function VerifyEmailPage(props) {
  var withToken = props.withToken;
  var match = React.useState(function () {
        return true;
      });
  var setLoading = match[1];
  var match$1 = VerifySubmit$Thin.useMutation(false);
  var verify = match$1.verify;
  var error = match$1.error;
  React.useEffect((function () {
          verify(CS_UUID$Util.PlainUUID.toString(withToken));
          setLoading(function (param) {
                return false;
              });
        }), []);
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(AuthPanel$Thin.make, {
                    heading: Txt$Thin.Verify.heading.value,
                    children: match[0] ? JsxRuntime.jsx(AppAlert$Thin.make, {
                            message: Txt$Thin.Verify.loading.value
                          }) : (
                        match$1.verified ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                message: Txt$Thin.Verify.successNoRedirect.value,
                                type_: "success"
                              }) : (
                            error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                    message: error
                                  }) : JsxRuntime.jsx(AppAlert$Thin.make, {
                                    message: Txt$Thin.$$Error.genericFormSubmit.value
                                  })
                          )
                      )
                  }),
              auth: true
            });
}

var make = VerifyEmailPage;

exports.make = make;
/* react Not a pure module */
