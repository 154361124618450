// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MyAccountState$Thin = require("../../utils/accounts/MyAccountState.bs.js");
var PassengerForms$Thin = require("../common/molecules/PassengerForms.bs.js");
var ChangeEmailSubmitNew$Thin = require("./mutations/ChangeEmailSubmitNew.bs.js");
var SendOutlined = require("@mui/icons-material/SendOutlined").default;

function ChangeEmailForm(props) {
  var accountState = props.accountState;
  var onClose = props.onClose;
  var match = ChangeEmailSubmitNew$Thin.useMutation(props.refresh);
  var change = match.changeEmail;
  var isMutating = match.isMutating;
  var setError = match.setError;
  var error = match.error;
  var emailError = HopperState$Util.Observable.useComputed(accountState, (function (a) {
          return Belt_Result.isError(MyAccountState$Thin.emailChange.validate(a, undefined));
        }), undefined, undefined);
  var onSubmit = function (e) {
    e.preventDefault();
    var input = MyAccountState$Thin.validateChangeEmail(HopperState$Util.Observable.dangerouslyRead(accountState));
    if (input.TAG === "Ok") {
      return change(input._0);
    }
    var err = input._0;
    setError(function (param) {
          return err;
        });
  };
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.Account.emailModalHeading.value,
              description: Caml_option.some(Txt$Thin.Account.emailModalDescription.value),
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                  HopperState$Util.Observable.notify(accountState, (function (a) {
                          return {
                                  account: a.account,
                                  pw: a.pw,
                                  emailChange: MyAccountState$Thin.emailChange.blankStore
                                };
                        }));
                }),
              titleId: "email-dialog-title",
              titleDescId: "email-dialog-description",
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(match.success ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                  message: props.updatedEmailMessage(),
                                  type_: "warning"
                                }) : JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 2,
                                  sx: {
                                    width: "100%"
                                  },
                                  onSubmit: onSubmit,
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: accountState
                                            },
                                            input: MyAccountState$Thin.emailChange,
                                            children: (function (param) {
                                                var set = param.set;
                                                return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                            label: "New Email Address",
                                                            errorText: param.errorText,
                                                            error: param.error,
                                                            children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                  type_: "text",
                                                                  placeholder: Txt$Thin.Account.emailModalEmailPlaceholder.value,
                                                                  value: param.value,
                                                                  onChange: (function (e) {
                                                                      set(function (param) {
                                                                            return e.target.value;
                                                                          });
                                                                    }),
                                                                  endDecorator: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                            loading: isMutating,
                                                                            type_: "submit",
                                                                            endDecorator: Caml_option.some(JsxRuntime.jsx(SendOutlined, {
                                                                                      fontSize: "sm"
                                                                                    })),
                                                                            disabled: emailError,
                                                                            children: "Send"
                                                                          }))
                                                                }),
                                                            required: true,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          }))
                                }))
                    }),
                error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ChangeEmailForm;

exports.make = make;
/* Joy-Util Not a pure module */
