// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var Stack = require("@mui/joy/Stack").default;
var $$Option = require("@mui/joy/Option").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function OccupancySelector(props) {
  var holdWarningOrProceed = props.holdWarningOrProceed;
  var hasCabinHold = props.hasCabinHold;
  var urlState = props.urlState;
  var occupancy = props.occupancy;
  var url = props.url;
  var flow = props.flow;
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              alignItems: "center",
              spacing: 1,
              children: [
                JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                      children: Caml_option.some(Belt_Array.map(SFState$Thin.validOccupancies, (function (occ) {
                                  return JsxRuntime.jsx($$Option, {
                                              value: String(occ),
                                              children: Caml_option.some(String(occ))
                                            }, String(occ));
                                }))),
                      color: "neutral",
                      onChange: (function (param, value) {
                          if (value === undefined) {
                            return ;
                          }
                          var val = Caml_option.valFromOption(value);
                          if (val === undefined) {
                            return ;
                          }
                          var occ = Belt_Int.fromString(val);
                          if (occ !== undefined) {
                            var select = function (param) {
                              var setOcc;
                              switch (flow) {
                                case "ByCategory" :
                                    setOcc = SFState$Thin.ByCategory.CabinTarget.setOcc;
                                    break;
                                case "AllNoCabin" :
                                    setOcc = SFState$Thin.ByAllNoCabin.CabinTarget.setOcc;
                                    break;
                                case "All" :
                                    setOcc = SFState$Thin.ByAll.CabinTarget.setOcc;
                                    break;
                                
                              }
                              SFState$Thin.navigate(SFState$Thin.URLState.serialize(setOcc(urlState, occ)), url);
                            };
                            return holdWarningOrProceed(hasCabinHold, select);
                          }
                          
                        }),
                      value: String(occupancy),
                      variant: "soft"
                    }),
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      fontWeight: "400",
                      children: Caml_option.some("Guest" + (
                            occupancy > 1 ? "s" : ""
                          ))
                    })
              ]
            });
}

var make = OccupancySelector;

exports.make = make;
/* Joy-Util Not a pure module */
