// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var S_Core$RescriptSchema = require("./S_Core.bs.js");

var Path_toArray = S_Core$RescriptSchema.Path.toArray;

var Path_fromArray = S_Core$RescriptSchema.Path.fromArray;

var Path_fromLocation = S_Core$RescriptSchema.Path.fromLocation;

var Path_concat = S_Core$RescriptSchema.Path.concat;

var Path = {
  empty: "",
  dynamic: "[]",
  toArray: Path_toArray,
  fromArray: Path_fromArray,
  fromLocation: Path_fromLocation,
  concat: Path_concat
};

var Raised = S_Core$RescriptSchema.Raised;

var Error_class = S_Core$RescriptSchema.$$Error.$$class;

var Error_make = S_Core$RescriptSchema.$$Error.make;

var Error_raise = S_Core$RescriptSchema.$$Error.raise;

var Error_message = S_Core$RescriptSchema.$$Error.message;

var Error_reason = S_Core$RescriptSchema.$$Error.reason;

var $$Error = {
  $$class: Error_class,
  make: Error_make,
  raise: Error_raise,
  message: Error_message,
  reason: Error_reason
};

var Literal_parse = S_Core$RescriptSchema.Literal.parse;

var Literal_value = S_Core$RescriptSchema.Literal.value;

var Literal_isJsonable = S_Core$RescriptSchema.Literal.isJsonable;

var Literal_toString = S_Core$RescriptSchema.Literal.toString;

var Literal = {
  parse: Literal_parse,
  value: Literal_value,
  isJsonable: Literal_isJsonable,
  toString: Literal_toString
};

var never = S_Core$RescriptSchema.never;

var unknown = S_Core$RescriptSchema.unknown;

var unit = S_Core$RescriptSchema.unit;

var string = S_Core$RescriptSchema.string;

var bool = S_Core$RescriptSchema.bool;

var $$int = S_Core$RescriptSchema.$$int;

var $$float = S_Core$RescriptSchema.$$float;

var bigint = S_Core$RescriptSchema.bigint;

var json = S_Core$RescriptSchema.json;

var literal = S_Core$RescriptSchema.literal;

var array = S_Core$RescriptSchema.array;

var list = S_Core$RescriptSchema.list;

var dict = S_Core$RescriptSchema.dict;

var option = S_Core$RescriptSchema.option;

var $$null = S_Core$RescriptSchema.$$null;

var nullable = S_Core$RescriptSchema.nullable;

var jsonString = S_Core$RescriptSchema.jsonString;

var union = S_Core$RescriptSchema.union;

var $$enum = S_Core$RescriptSchema.$$enum;

var Catch = S_Core$RescriptSchema.Catch;

var $$catch = S_Core$RescriptSchema.$$catch;

var describe = S_Core$RescriptSchema.describe;

var description = S_Core$RescriptSchema.description;

var deprecate = S_Core$RescriptSchema.deprecate;

var deprecation = S_Core$RescriptSchema.deprecation;

var transform = S_Core$RescriptSchema.transform;

var preprocess = S_Core$RescriptSchema.preprocess;

var custom = S_Core$RescriptSchema.custom;

var refine = S_Core$RescriptSchema.refine;

var variant = S_Core$RescriptSchema.variant;

var to = S_Core$RescriptSchema.to;

var compile = S_Core$RescriptSchema.compile;

var parseWith = S_Core$RescriptSchema.parseWith;

var parseAnyWith = S_Core$RescriptSchema.parseAnyWith;

var parseJsonStringWith = S_Core$RescriptSchema.parseJsonStringWith;

var parseOrRaiseWith = S_Core$RescriptSchema.parseOrRaiseWith;

var parseAnyOrRaiseWith = S_Core$RescriptSchema.parseAnyOrRaiseWith;

var parseAsyncWith = S_Core$RescriptSchema.parseAsyncWith;

var parseAnyAsyncWith = S_Core$RescriptSchema.parseAnyAsyncWith;

var convertAnyWith = S_Core$RescriptSchema.convertAnyWith;

var convertAnyToJsonWith = S_Core$RescriptSchema.convertAnyToJsonWith;

var convertAnyToJsonStringWith = S_Core$RescriptSchema.convertAnyToJsonStringWith;

var convertAnyAsyncWith = S_Core$RescriptSchema.convertAnyAsyncWith;

var reverseConvertWith = S_Core$RescriptSchema.reverseConvertWith;

var reverseConvertToJsonWith = S_Core$RescriptSchema.reverseConvertToJsonWith;

var reverseConvertToJsonStringWith = S_Core$RescriptSchema.reverseConvertToJsonStringWith;

var serializeWith = S_Core$RescriptSchema.serializeWith;

var serializeToUnknownWith = S_Core$RescriptSchema.serializeToUnknownWith;

var serializeToJsonStringWith = S_Core$RescriptSchema.serializeToJsonStringWith;

var serializeOrRaiseWith = S_Core$RescriptSchema.serializeOrRaiseWith;

var serializeToUnknownOrRaiseWith = S_Core$RescriptSchema.serializeToUnknownOrRaiseWith;

var serializeToJsonStringOrRaiseWith = S_Core$RescriptSchema.serializeToJsonStringOrRaiseWith;

var assertOrRaiseWith = S_Core$RescriptSchema.assertOrRaiseWith;

var assertAnyWith = S_Core$RescriptSchema.assertAnyWith;

var assertWith = S_Core$RescriptSchema.assertWith;

var isAsyncParse = S_Core$RescriptSchema.isAsyncParse;

var isAsync = S_Core$RescriptSchema.isAsync;

var recursive = S_Core$RescriptSchema.recursive;

var classify = S_Core$RescriptSchema.classify;

var setName = S_Core$RescriptSchema.setName;

var removeTypeValidation = S_Core$RescriptSchema.removeTypeValidation;

var unwrap = S_Core$RescriptSchema.unwrap;

var Schema = {};

var schema = S_Core$RescriptSchema.schema;

var Object_factory = S_Core$RescriptSchema.$$Object.factory;

var Object_strip = S_Core$RescriptSchema.$$Object.strip;

var Object_strict = S_Core$RescriptSchema.$$Object.strict;

var $$Object = {
  factory: Object_factory,
  strip: Object_strip,
  strict: Object_strict
};

var object = S_Core$RescriptSchema.object;

var Tuple = {};

var tuple = S_Core$RescriptSchema.tuple;

var tuple1 = S_Core$RescriptSchema.tuple1;

var tuple2 = S_Core$RescriptSchema.tuple2;

var tuple3 = S_Core$RescriptSchema.tuple3;

var Option_default = S_Core$RescriptSchema.$$Option.$$default;

var Option_getOr = S_Core$RescriptSchema.$$Option.getOr;

var Option_getOrWith = S_Core$RescriptSchema.$$Option.getOrWith;

var $$Option = {
  $$default: Option_default,
  getOr: Option_getOr,
  getOrWith: Option_getOrWith
};

var String_Refinement = {};

var String_refinements = S_Core$RescriptSchema.$$String.refinements;

var $$String = {
  Refinement: String_Refinement,
  refinements: String_refinements
};

var Int_Refinement = {};

var Int_refinements = S_Core$RescriptSchema.Int.refinements;

var Int = {
  Refinement: Int_Refinement,
  refinements: Int_refinements
};

var Float_Refinement = {};

var Float_refinements = S_Core$RescriptSchema.Float.refinements;

var Float = {
  Refinement: Float_Refinement,
  refinements: Float_refinements
};

var Array_Refinement = {};

var Array_refinements = S_Core$RescriptSchema.$$Array.refinements;

var $$Array = {
  Refinement: Array_Refinement,
  refinements: Array_refinements
};

var Metadata_Id = S_Core$RescriptSchema.Metadata.Id;

var Metadata_get = S_Core$RescriptSchema.Metadata.get;

var Metadata_set = S_Core$RescriptSchema.Metadata.set;

var Metadata = {
  Id: Metadata_Id,
  get: Metadata_get,
  set: Metadata_set
};

var inline = S_Core$RescriptSchema.inline;

var reverse = S_Core$RescriptSchema.reverse;

var intMin = S_Core$RescriptSchema.intMin;

var intMax = S_Core$RescriptSchema.intMax;

var port = S_Core$RescriptSchema.port;

var floatMin = S_Core$RescriptSchema.floatMin;

var floatMax = S_Core$RescriptSchema.floatMax;

var arrayMinLength = S_Core$RescriptSchema.arrayMinLength;

var arrayMaxLength = S_Core$RescriptSchema.arrayMaxLength;

var arrayLength = S_Core$RescriptSchema.arrayLength;

var stringMinLength = S_Core$RescriptSchema.stringMinLength;

var stringMaxLength = S_Core$RescriptSchema.stringMaxLength;

var stringLength = S_Core$RescriptSchema.stringLength;

var email = S_Core$RescriptSchema.email;

var uuid = S_Core$RescriptSchema.uuid;

var cuid = S_Core$RescriptSchema.cuid;

var url = S_Core$RescriptSchema.url;

var pattern = S_Core$RescriptSchema.pattern;

var datetime = S_Core$RescriptSchema.datetime;

var trim = S_Core$RescriptSchema.trim;

var setGlobalConfig = S_Core$RescriptSchema.setGlobalConfig;

exports.Path = Path;
exports.Raised = Raised;
exports.$$Error = $$Error;
exports.Literal = Literal;
exports.never = never;
exports.unknown = unknown;
exports.unit = unit;
exports.string = string;
exports.bool = bool;
exports.$$int = $$int;
exports.$$float = $$float;
exports.bigint = bigint;
exports.json = json;
exports.literal = literal;
exports.array = array;
exports.list = list;
exports.dict = dict;
exports.option = option;
exports.$$null = $$null;
exports.nullable = nullable;
exports.jsonString = jsonString;
exports.union = union;
exports.$$enum = $$enum;
exports.Catch = Catch;
exports.$$catch = $$catch;
exports.describe = describe;
exports.description = description;
exports.deprecate = deprecate;
exports.deprecation = deprecation;
exports.transform = transform;
exports.preprocess = preprocess;
exports.custom = custom;
exports.refine = refine;
exports.variant = variant;
exports.to = to;
exports.compile = compile;
exports.parseWith = parseWith;
exports.parseAnyWith = parseAnyWith;
exports.parseJsonStringWith = parseJsonStringWith;
exports.parseOrRaiseWith = parseOrRaiseWith;
exports.parseAnyOrRaiseWith = parseAnyOrRaiseWith;
exports.parseAsyncWith = parseAsyncWith;
exports.parseAnyAsyncWith = parseAnyAsyncWith;
exports.convertAnyWith = convertAnyWith;
exports.convertAnyToJsonWith = convertAnyToJsonWith;
exports.convertAnyToJsonStringWith = convertAnyToJsonStringWith;
exports.convertAnyAsyncWith = convertAnyAsyncWith;
exports.reverseConvertWith = reverseConvertWith;
exports.reverseConvertToJsonWith = reverseConvertToJsonWith;
exports.reverseConvertToJsonStringWith = reverseConvertToJsonStringWith;
exports.serializeWith = serializeWith;
exports.serializeToUnknownWith = serializeToUnknownWith;
exports.serializeToJsonStringWith = serializeToJsonStringWith;
exports.serializeOrRaiseWith = serializeOrRaiseWith;
exports.serializeToUnknownOrRaiseWith = serializeToUnknownOrRaiseWith;
exports.serializeToJsonStringOrRaiseWith = serializeToJsonStringOrRaiseWith;
exports.assertOrRaiseWith = assertOrRaiseWith;
exports.assertAnyWith = assertAnyWith;
exports.assertWith = assertWith;
exports.isAsyncParse = isAsyncParse;
exports.isAsync = isAsync;
exports.recursive = recursive;
exports.classify = classify;
exports.setName = setName;
exports.removeTypeValidation = removeTypeValidation;
exports.unwrap = unwrap;
exports.Schema = Schema;
exports.schema = schema;
exports.$$Object = $$Object;
exports.object = object;
exports.Tuple = Tuple;
exports.tuple = tuple;
exports.tuple1 = tuple1;
exports.tuple2 = tuple2;
exports.tuple3 = tuple3;
exports.$$Option = $$Option;
exports.$$String = $$String;
exports.Int = Int;
exports.Float = Float;
exports.$$Array = $$Array;
exports.Metadata = Metadata;
exports.inline = inline;
exports.reverse = reverse;
exports.intMin = intMin;
exports.intMax = intMax;
exports.port = port;
exports.floatMin = floatMin;
exports.floatMax = floatMax;
exports.arrayMinLength = arrayMinLength;
exports.arrayMaxLength = arrayMaxLength;
exports.arrayLength = arrayLength;
exports.stringMinLength = stringMinLength;
exports.stringMaxLength = stringMaxLength;
exports.stringLength = stringLength;
exports.email = email;
exports.uuid = uuid;
exports.cuid = cuid;
exports.url = url;
exports.pattern = pattern;
exports.datetime = datetime;
exports.trim = trim;
exports.setGlobalConfig = setGlobalConfig;
/* S_Core-RescriptSchema Not a pure module */
