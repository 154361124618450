// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sidebar$Thin = require("../components/common/molecules/Sidebar.bs.js");
var Stack = require("@mui/joy/Stack").default;
var IsLoggedIn$Thin = require("../routes/gates/IsLoggedIn.bs.js");
var Navigation$Thin = require("../components/common/molecules/Navigation.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FullPageWaveLoader$Thin = require("../components/common/molecules/FullPageWaveLoader.bs.js");

function StandardLayout(props) {
  var children = props.children;
  var __mustBeLoggedIn = props.mustBeLoggedIn;
  var __alwaysHamburger = props.alwaysHamburger;
  var __dash = props.dash;
  var __navigation = props.navigation;
  var navigation = __navigation !== undefined ? __navigation : true;
  var dash = __dash !== undefined ? __dash : true;
  var alwaysHamburger = __alwaysHamburger !== undefined ? __alwaysHamburger : false;
  var mustBeLoggedIn = __mustBeLoggedIn !== undefined ? __mustBeLoggedIn : false;
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        sx: {
                          height: "100%",
                          backgroundColor: "background.body"
                        },
                        children: [
                          navigation ? JsxRuntime.jsx(Navigation$Thin.make, {
                                  alwaysHamburger: alwaysHamburger
                                }) : null,
                          dash ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  sx: {
                                    flex: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Sidebar$Thin.make, {}),
                                    JsxRuntime.jsx(Stack, {
                                          sx: {
                                            flexGrow: 1,
                                            flexShrink: 1,
                                            backgroundColor: Belt_Option.getWithDefault(props.bg, "background.white"),
                                            width: {
                                              xs: "100%",
                                              md: "calc(100% - 250px)"
                                            }
                                          },
                                          children: Caml_option.some(mustBeLoggedIn ? JsxRuntime.jsx(IsLoggedIn$Thin.make, {
                                                      children: children
                                                    }) : children)
                                        })
                                  ]
                                }) : children
                        ]
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(FullPageWaveLoader$Thin.make, {}))
            });
}

var make = StandardLayout;

exports.make = make;
/* react Not a pure module */
