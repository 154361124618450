// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;

function CopyableWithTooltip(props) {
  var value = props.value;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    onClick: (function (param) {
                        Common$Thin.copyToClipboard(value, setCopied);
                      }),
                    sx: {
                      minHeight: "0",
                      minWidth: "0",
                      "&:hover": {
                        background: "none"
                      }
                    },
                    children: Caml_option.some(JsxRuntime.jsx(ContentCopyOutlined, {
                              fontSize: "sm"
                            }))
                  }),
              arrow: true,
              size: "sm",
              sx: {
                cursor: "pointer"
              },
              title: Caml_option.some(match[0] ? "Copied!" : "Copy to clipboard")
            });
}

var make = CopyableWithTooltip;

exports.make = make;
/* react Not a pure module */
