// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var TransferPage$Thin = require("../pages/TransferPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");

function TransferRoute(props) {
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              dash: false,
              children: JsxRuntime.jsx(TransferPage$Thin.make, {})
            });
}

var make = TransferRoute;

exports.make = make;
/* TransferPage-Thin Not a pure module */
