// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../Common.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var PassengerState$Thin = require("../passengers/PassengerState.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SalesByCategoryPage_query_graphql$Thin = require("../../__generated__/SalesByCategoryPage_query_graphql.bs.js");

var ImpossibleBCFormFieldSchema = /* @__PURE__ */Caml_exceptions.create("SFState-Thin.ImpossibleBCFormFieldSchema");

function appendToVeryOptionalArray(arrayThing, a) {
  if (arrayThing !== undefined) {
    return Belt_Array.concat(arrayThing, [Caml_option.some(a)]);
  } else {
    return [Caml_option.some(a)];
  }
}

var pax1 = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.pax1;
        }),
      write: (function (model, pax1) {
          return {
                  paxes: model.paxes,
                  bc: model.bc,
                  pax1: pax1,
                  paymentOption: model.paymentOption,
                  sessionSaveIsMutating: model.sessionSaveIsMutating,
                  signup: model.signup,
                  url: model.url,
                  paymentSuggestions: model.paymentSuggestions,
                  quote: model.quote
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), S$RescriptSchema.transform(S$RescriptSchema.bool, (function (param) {
            return {
                    p: (function (isBc) {
                        if (isBc) {
                          return "Pax1IsBC";
                        } else {
                          return "Pax1IsInMap";
                        }
                      }),
                    s: (function (t) {
                        if (t === "Pax1IsBC") {
                          return true;
                        } else {
                          return false;
                        }
                      })
                  };
          })), "Pax1IsBC", undefined);

var password = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.password;
        }),
      write: (function (signup, password) {
          return {
                  password: password,
                  confirmPassword: signup.confirmPassword
                };
        })
    }, (function (v) {
        if (Common$Thin.isPasswordValid(v)) {
          return {
                  TAG: "Ok",
                  _0: v
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Password must be at least 10 characters"
                };
        }
      }), (function (v) {
        return v;
      }), S$RescriptSchema.string, "", undefined);

var confirmPassword = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.confirmPassword;
        }),
      write: (function (signup, confirmPassword) {
          return {
                  password: signup.password,
                  confirmPassword: confirmPassword
                };
        })
    }, (function (v) {
        if (Common$Thin.isPasswordValid(v)) {
          return {
                  TAG: "Ok",
                  _0: v
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Password must be at least 10 characters"
                };
        }
      }), (function (v) {
        return v;
      }), S$RescriptSchema.string, "", undefined);

function blankModel() {
  return {
          paxes: undefined,
          bc: undefined,
          pax1: pax1.blankStore,
          paymentOption: undefined,
          sessionSaveIsMutating: false,
          signup: undefined,
          url: RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined),
          paymentSuggestions: undefined,
          quote: "Unavailable"
        };
}

var blankSignup_password = password.blankStore;

var blankSignup_confirmPassword = confirmPassword.blankStore;

var blankSignup = {
  password: blankSignup_password,
  confirmPassword: blankSignup_confirmPassword
};

var paymentOptionSchema = S$RescriptSchema.transform(S$RescriptSchema.tuple2(S$RescriptSchema.$$int, CS_Decimal$Util.Decimal10_2.schema), (function (s) {
        return {
                p: (function (param) {
                    var amt = param[1];
                    switch (param[0]) {
                      case 0 :
                          return {
                                  TAG: "PIF",
                                  _0: {
                                    amt: amt
                                  }
                                };
                      case 1 :
                          return {
                                  TAG: "Minimum",
                                  _0: {
                                    amt: amt
                                  }
                                };
                      case 2 :
                          return {
                                  TAG: "Current",
                                  _0: {
                                    amt: amt
                                  }
                                };
                      case 3 :
                          return {
                                  TAG: "Custom",
                                  _0: {
                                    amt: amt
                                  }
                                };
                      default:
                        return s.fail("Impossible pay amount int enum", undefined);
                    }
                  }),
                s: (function (t) {
                    switch (t.TAG) {
                      case "PIF" :
                          return [
                                  0,
                                  t._0.amt
                                ];
                      case "Minimum" :
                          return [
                                  1,
                                  t._0.amt
                                ];
                      case "Current" :
                          return [
                                  2,
                                  t._0.amt
                                ];
                      case "Custom" :
                          return [
                                  3,
                                  t._0.amt
                                ];
                      
                    }
                  })
              };
      }));

var match = HopperState$Util.Observable.FormField.Group.addChildren(HopperState$Util.Observable.FormField.Group.addChildren(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.container(function (blankModel) {
                  return S$RescriptSchema.transform(S$RescriptSchema.$$null(paymentOptionSchema), (function (param) {
                                return {
                                        p: (function (paymentOption) {
                                            return {
                                                    paxes: blankModel.paxes,
                                                    bc: blankModel.bc,
                                                    pax1: blankModel.pax1,
                                                    paymentOption: paymentOption,
                                                    sessionSaveIsMutating: blankModel.sessionSaveIsMutating,
                                                    signup: blankModel.signup,
                                                    url: blankModel.url,
                                                    paymentSuggestions: blankModel.paymentSuggestions,
                                                    quote: blankModel.quote
                                                  };
                                          }),
                                        s: (function (param) {
                                            return param.paymentOption;
                                          })
                                      };
                              }));
                }), pax1), {
          read: (function (m) {
              return Belt_Option.mapWithDefault(m.bc, [], (function (bc) {
                            return [[
                                      undefined,
                                      bc
                                    ]];
                          }));
            }),
          write: (function (m, arr) {
              var len = arr.length;
              if (len !== 1) {
                if (len !== 0) {
                  throw {
                        RE_EXN_ID: ImpossibleBCFormFieldSchema,
                        Error: new Error()
                      };
                }
                return {
                        paxes: m.paxes,
                        bc: undefined,
                        pax1: m.pax1,
                        paymentOption: m.paymentOption,
                        sessionSaveIsMutating: m.sessionSaveIsMutating,
                        signup: m.signup,
                        url: m.url,
                        paymentSuggestions: m.paymentSuggestions,
                        quote: m.quote
                      };
              }
              var match = arr[0];
              return {
                      paxes: m.paxes,
                      bc: match[1],
                      pax1: m.pax1,
                      paymentOption: m.paymentOption,
                      sessionSaveIsMutating: m.sessionSaveIsMutating,
                      signup: m.signup,
                      url: m.url,
                      paymentSuggestions: m.paymentSuggestions,
                      quote: m.quote
                    };
            })
        }, PassengerState$Thin.paxUtils, HopperState$Util.Observable.FormField.jsonUnitSchema, (function () {
            return PassengerState$Thin.blankPax(1);
          })), {
      read: (function (m) {
          return Belt_MapInt.toArray(m.paxes);
        }),
      write: (function (m, arr) {
          return {
                  paxes: Belt_MapInt.fromArray(arr),
                  bc: m.bc,
                  pax1: m.pax1,
                  paymentOption: m.paymentOption,
                  sessionSaveIsMutating: m.sessionSaveIsMutating,
                  signup: m.signup,
                  url: m.url,
                  paymentSuggestions: m.paymentSuggestions,
                  quote: m.quote
                };
        })
    }, PassengerState$Thin.paxUtils, S$RescriptSchema.$$int, PassengerState$Thin.blankPax);

var makeSchema = match.makeSchema;

var modelSchema = makeSchema("v_sep2424", blankModel);

function paxTypeView(pax) {
  return HopperState$Util.Observable.makeView(pax, {
              read: (function (param) {
                  return param.paxType;
                }),
              write: (function (pax, paxType) {
                  var newrecord = Caml_obj.obj_dup(pax);
                  newrecord.paxType = paxType;
                  return newrecord;
                })
            }, undefined);
}

function signupView(model) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (param) {
                  var signup = param.signup;
                  if (signup !== undefined) {
                    return signup;
                  } else {
                    return blankSignup;
                  }
                }),
              write: (function (model, signup) {
                  return {
                          paxes: model.paxes,
                          bc: model.bc,
                          pax1: model.pax1,
                          paymentOption: model.paymentOption,
                          sessionSaveIsMutating: model.sessionSaveIsMutating,
                          signup: Caml_obj.equal(signup, blankSignup) ? undefined : signup,
                          url: model.url,
                          paymentSuggestions: model.paymentSuggestions,
                          quote: model.quote
                        };
                })
            }, undefined);
}

function billingContactView(model) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (model) {
                  return Belt_Option.getWithDefault(model.bc, PassengerState$Thin.blankPax(1));
                }),
              write: (function (model, bc) {
                  return {
                          paxes: model.paxes,
                          bc: bc,
                          pax1: model.pax1,
                          paymentOption: model.paymentOption,
                          sessionSaveIsMutating: model.sessionSaveIsMutating,
                          signup: model.signup,
                          url: model.url,
                          paymentSuggestions: model.paymentSuggestions,
                          quote: model.quote
                        };
                })
            }, undefined);
}

function paxViewByIdx(model, idx) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (model) {
                  var match = model.pax1.value;
                  if (idx === 1 && match === "Pax1IsBC") {
                    return Belt_Option.getWithDefault(model.bc, PassengerState$Thin.blankPax(1));
                  }
                  return Belt_MapInt.getWithDefault(model.paxes, idx, PassengerState$Thin.blankPax(idx));
                }),
              write: (function (model, pax) {
                  var match = model.pax1.value;
                  if (idx === 1 && match === "Pax1IsBC") {
                    return {
                            paxes: model.paxes,
                            bc: pax,
                            pax1: model.pax1,
                            paymentOption: model.paymentOption,
                            sessionSaveIsMutating: model.sessionSaveIsMutating,
                            signup: model.signup,
                            url: model.url,
                            paymentSuggestions: model.paymentSuggestions,
                            quote: model.quote
                          };
                  }
                  return {
                          paxes: Belt_MapInt.set(model.paxes, idx, pax),
                          bc: model.bc,
                          pax1: model.pax1,
                          paymentOption: model.paymentOption,
                          sessionSaveIsMutating: model.sessionSaveIsMutating,
                          signup: model.signup,
                          url: model.url,
                          paymentSuggestions: model.paymentSuggestions,
                          quote: model.quote
                        };
                })
            }, undefined);
}

function sameAsBcToggle(paxView) {
  return HopperState$Util.Observable.makeView(paxView, {
              read: (function (model) {
                  return model.pax1.value === "Pax1IsBC";
                }),
              write: (function (model, isBc) {
                  var bc = model.bc;
                  var tmp;
                  if (bc !== undefined) {
                    var match = bc.paxType;
                    var exit = 0;
                    if (match === "ProvideInfo") {
                      tmp = model.bc;
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      var newrecord = Caml_obj.obj_dup(bc);
                      tmp = (newrecord.paxType = "ProvideInfo", newrecord);
                    }
                    
                  } else {
                    var newrecord$1 = Caml_obj.obj_dup(PassengerState$Thin.blankPax(1));
                    tmp = (newrecord$1.paxType = "ProvideInfo", newrecord$1);
                  }
                  return pax1.set({
                              paxes: model.paxes,
                              bc: tmp,
                              pax1: model.pax1,
                              paymentOption: model.paymentOption,
                              sessionSaveIsMutating: model.sessionSaveIsMutating,
                              signup: model.signup,
                              url: model.url,
                              paymentSuggestions: model.paymentSuggestions,
                              quote: model.quote
                            }, isBc ? "Pax1IsBC" : "Pax1IsInMap", undefined);
                })
            }, undefined);
}

function isAgeGated(paxDob, pax1MustBeBornBy, isPrimary) {
  if (paxDob !== undefined && pax1MustBeBornBy !== undefined && isPrimary) {
    return DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(paxDob)));
  } else {
    return false;
  }
}

function allTravelDocFieldsComplete(pax) {
  var match = PassengerState$Thin.docFirstName.validate(pax, undefined);
  var match$1 = PassengerState$Thin.docLastName.validate(pax, undefined);
  var match$2 = PassengerState$Thin.docGender.validate(pax, undefined);
  var match$3 = PassengerState$Thin.docCitizenship.validate(pax, undefined);
  var match$4 = PassengerState$Thin.docBirthdate.validate(pax, undefined);
  if (match.TAG === "Ok" && match._0 !== undefined && match$1.TAG === "Ok" && match$1._0 !== undefined && match$2.TAG === "Ok" && match$2._0 !== undefined && match$3.TAG === "Ok" && match$3._0 !== undefined && match$4.TAG === "Ok") {
    return match$4._0 !== undefined;
  } else {
    return false;
  }
}

function paxBirthdateShouldError(pax, pax1MustBeBornBy) {
  return Belt_Result.getWithDefault(Belt_Result.map(PassengerState$Thin.docBirthdate.validate(pax, undefined), (function (paxDob) {
                    return isAgeGated(paxDob, pax1MustBeBornBy, pax.idx === 1);
                  })), false);
}

function paxArray(model) {
  var match = model.pax1.value;
  var tmp;
  tmp = match === "Pax1IsBC" ? Belt_Array.concat(Belt_Option.mapWithDefault(model.bc, [], (function (bc) {
                return [bc];
              })), Belt_Array.keep(Belt_MapInt.valuesToArray(model.paxes), (function (param) {
                return param.idx !== 1;
              }))) : Belt_MapInt.valuesToArray(model.paxes);
  return Belt_Array.keep(tmp, (function (param) {
                return param.paxType !== undefined;
              }));
}

function numPaxes(model) {
  var match = model.pax1.value;
  var match$1 = Belt_Array.keep(Belt_MapInt.keysToArray(model.paxes), (function (idx) {
          return idx === 1;
        }));
  if (match === "Pax1IsBC" && match$1.length === 0) {
    return Belt_MapInt.size(model.paxes) + 1 | 0;
  } else {
    return Belt_MapInt.size(model.paxes);
  }
}

function notBookable(pax, voyageSlug) {
  return Belt_Array.some(pax.pendingVoyageSlugs, (function (slug) {
                return Caml_obj.equal(slug, voyageSlug);
              }));
}

function prefillFromCurrentUserProfile(model, prof, voyageSlug) {
  var newrecord = Caml_obj.obj_dup(Belt_Option.getWithDefault(model.bc, PassengerState$Thin.blankPax(1)));
  var bc = PassengerState$Thin.acquisitionSlug.setFromValid(PassengerState$Thin.email.setFromValid(PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.paxType = "ProvideInfo", newrecord.pendingVoyageSlugs = Belt_Array.keepMap(Belt_Option.getWithDefault(prof.pendingVoyageSlugs, []), (function (a) {
                                                                return a;
                                                              })), newrecord.accountId = Caml_option.some(prof.accountId), newrecord.isComplete = prof.travelDocComplete, newrecord), prof.firstName, "InitialValueAsPrefill"), prof.lastName, "InitialValueAsPrefill"), prof.legalDocumentFirstName, "InitialValueAsPrefill"), prof.legalDocumentLastName, "InitialValueAsPrefill"), prof.legalDocumentBirthdate, "InitialValueAsPrefill"), prof.legalDocumentCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prof.legalDocumentGender, SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                  return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                  return CS_Phone$Util.Phone.meta(p).callingCode;
                                })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill"), prof.email, "InitialValueAsPrefill"), prof.custAcquisitionSlug, "InitialValueAsPrefill");
  var notBookable$1 = notBookable(bc, voyageSlug);
  return pax1.set({
              paxes: model.paxes,
              bc: bc,
              pax1: model.pax1,
              paymentOption: model.paymentOption,
              sessionSaveIsMutating: model.sessionSaveIsMutating,
              signup: model.signup,
              url: model.url,
              paymentSuggestions: model.paymentSuggestions,
              quote: model.quote
            }, notBookable$1 ? "Pax1IsInMap" : "Pax1IsBC", "InitialValueAsPrefill");
}

function prefillFromConnectedAccount(pax, prof) {
  var newrecord = Caml_obj.obj_dup(pax);
  return PassengerState$Thin.acquisitionSlug.setFromValid(PassengerState$Thin.email.setFromValid(PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.paxType = "AddConnected", newrecord.pendingVoyageSlugs = Belt_Array.keepMap(Belt_Option.getWithDefault(prof.pendingVoyageSlugs, []), (function (a) {
                                                                      return a;
                                                                    })), newrecord.accountId = Caml_option.some(prof.accountId), newrecord.isComplete = prof.travelDocComplete, newrecord), Caml_option.some(prof.firstName), "InitialValueAsPrefill"), prof.lastName, "InitialValueAsPrefill"), prof.legalDocumentFirstName, "InitialValueAsPrefill"), prof.legalDocumentLastName, "InitialValueAsPrefill"), prof.legalDocumentBirthdate, "InitialValueAsPrefill"), prof.legalDocumentCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prof.legalDocumentGender, SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                        return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                      })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                        return CS_Phone$Util.Phone.meta(p).callingCode;
                                      })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill"), prof.email, "InitialValueAsPrefill"), prof.custAcquisitionSlug, "InitialValueAsPrefill");
}

function createFromConnectedAccount(attach, prof) {
  var newrecord = Caml_obj.obj_dup(PassengerState$Thin.blankPax(attach.idx));
  return PassengerState$Thin.acquisitionSlug.setFromValid(PassengerState$Thin.email.setFromValid(PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.paxType = "AddConnected", newrecord.pendingVoyageSlugs = Belt_Array.keepMap(Belt_Option.getWithDefault(prof.pendingVoyageSlugs, []), (function (a) {
                                                                      return a;
                                                                    })), newrecord.accountId = Caml_option.some(attach.accountId), newrecord.isComplete = prof.travelDocComplete, newrecord), Caml_option.some(prof.firstName), "InitialValueAsPrefill"), prof.lastName, "InitialValueAsPrefill"), prof.legalDocumentFirstName, "InitialValueAsPrefill"), prof.legalDocumentLastName, "InitialValueAsPrefill"), prof.legalDocumentBirthdate, "InitialValueAsPrefill"), prof.legalDocumentCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prof.legalDocumentGender, SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                        return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                      })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                        return CS_Phone$Util.Phone.meta(p).callingCode;
                                      })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill"), prof.email, "InitialValueAsPrefill"), prof.custAcquisitionSlug, "InitialValueAsPrefill");
}

function createLoyaltyFromFromAttachExisting(loyaltyNumber, prof) {
  var newrecord = Caml_obj.obj_dup(PassengerState$Thin.blankPax(prof.idx));
  return PassengerState$Thin.loyalty.setFromValid((newrecord.paxType = "AddByLoyalty", newrecord.accountId = Caml_option.some(prof.accountId), newrecord), Caml_option.some(loyaltyNumber), "InitialValueAsPrefill");
}

function createInviteFromAttachInvited(optPrev, prof) {
  var base = PassengerState$Thin.blankPax(prof.idx);
  var base$1 = Belt_Option.mapWithDefault(optPrev, base, (function (prevPax) {
          var newrecord = Caml_obj.obj_dup(base);
          newrecord.preferredLastName = prevPax.preferredLastName;
          newrecord.preferredFirstName = prevPax.preferredFirstName;
          return newrecord;
        }));
  var newrecord = Caml_obj.obj_dup(base$1);
  return PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid(PassengerState$Thin.email.setFromValid((newrecord.paxType = "Invite", newrecord), Caml_option.some(prof.invitationEmail), "InitialValueAsPrefill"), Caml_option.some(prof.preferredFirstName), "InitialValueAsPrefill"), prof.preferredLastName, "InitialValueAsPrefill");
}

function createProvideInfoFromAttachNewConnected(optPrev, prof) {
  var base = PassengerState$Thin.blankPax(prof.idx);
  var base$1 = Belt_Option.mapWithDefault(optPrev, base, (function (prevPax) {
          var newrecord = Caml_obj.obj_dup(base);
          newrecord.preferredLastName = prevPax.preferredLastName;
          newrecord.preferredFirstName = prevPax.preferredFirstName;
          return newrecord;
        }));
  var newrecord = Caml_obj.obj_dup(base$1);
  return PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.paxType = "ProvideInfo", newrecord), prof.preferredFirstName, "InitialValueAsPrefill"), prof.preferredLastName, "InitialValueAsPrefill"), Caml_option.some(prof.docFirstname), "InitialValueAsPrefill"), Caml_option.some(prof.docLastname), "InitialValueAsPrefill"), Caml_option.some(prof.docBirthdate), "InitialValueAsPrefill"), Caml_option.some(prof.docCitizenship), "InitialValueAsPrefill"), SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode(prof.docGender), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                              })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                return CS_Phone$Util.Phone.meta(p).callingCode;
                              })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill");
}

function mapAttachExisting(paxes, currentUserId, currentUserConnectedInfo, attachExisting) {
  var attaches = Belt_Array.keepMap(Belt_Option.getWithDefault(attachExisting, []), (function (a) {
          return a;
        }));
  return Belt_Array.reduce(attaches, paxes, (function (carry, a) {
                var idx = a.idx;
                var accountId = a.accountId;
                var match = Belt_MapString.get(currentUserConnectedInfo, CS_NonemptyStrings$Util.AccountId.toString(accountId));
                var match$1 = Belt_MapInt.get(paxes, idx);
                if (currentUserId !== undefined && Caml_obj.equal(accountId, Caml_option.valFromOption(currentUserId))) {
                  return carry;
                }
                if (match !== undefined) {
                  return Belt_MapInt.set(carry, idx, createFromConnectedAccount(a, match));
                }
                if (match$1 === undefined) {
                  return carry;
                }
                var match$2 = match$1.paxType;
                if (match$2 === undefined) {
                  return carry;
                }
                if (match$2 !== "AddByLoyalty") {
                  return carry;
                }
                var match$3 = Belt_Option.map(match$1.loyalty.value, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
                if (match$3 !== undefined && match$3.TAG === "Ok") {
                  return Belt_MapInt.set(carry, idx, createLoyaltyFromFromAttachExisting(match$3._0, a));
                } else {
                  return carry;
                }
              }));
}

function mapAttachInvited(paxes, attachInvited) {
  var attaches = Belt_Array.keepMap(Belt_Option.getWithDefault(attachInvited, []), (function (a) {
          return a;
        }));
  return Belt_Array.reduce(attaches, paxes, (function (carry, a) {
                return Belt_MapInt.update(carry, a.idx, (function (o) {
                              return createInviteFromAttachInvited(o, a);
                            }));
              }));
}

function mapAttachNewConnected(paxes, attachNewConnected) {
  var attaches = Belt_Array.keepMap(Belt_Option.getWithDefault(attachNewConnected, []), (function (a) {
          return a;
        }));
  return Belt_Array.reduce(attaches, paxes, (function (carry, a) {
                return Belt_MapInt.update(carry, a.idx, (function (o) {
                              return createProvideInfoFromAttachNewConnected(o, a);
                            }));
              }));
}

function updatePaxesFromCurrentBookingSession(model, frag) {
  var currentUserId = Belt_Option.flatMap(frag.currentUserProfiles, (function (p) {
          return Belt_Option.map(Belt_Array.get(p.nodes, 0), (function (p) {
                        return p.accountId;
                      }));
        }));
  var currentUserConnectedInfo = Belt_MapString.fromArray(Belt_Option.getWithDefault(Belt_Option.map(frag.currentUserConnectedAccounts, (function (p) {
                  return Belt_Array.map(p.nodes, (function (p) {
                                return [
                                        CS_NonemptyStrings$Util.AccountId.toString(p.accountId),
                                        p
                                      ];
                              }));
                })), []));
  var node = Belt_Option.flatMap(frag.cubs, (function (cubs) {
          return Belt_Array.get(cubs.nodes, 0);
        }));
  if (node !== undefined) {
    return HopperState$Util.Observable.notify(model, (function (model) {
                  return {
                          paxes: mapAttachNewConnected(mapAttachInvited(mapAttachExisting(model.paxes, currentUserId, currentUserConnectedInfo, node.attachExistingPax), node.attachInvitedPax), node.attachNewConnectedPax),
                          bc: model.bc,
                          pax1: model.pax1,
                          paymentOption: model.paymentOption,
                          sessionSaveIsMutating: model.sessionSaveIsMutating,
                          signup: model.signup,
                          url: model.url,
                          paymentSuggestions: model.paymentSuggestions,
                          quote: model.quote
                        };
                }));
  }
  
}

function getConnectedSelectOptions(profs, voyageSlug) {
  if (profs !== undefined) {
    return Belt_Array.map(profs, (function (prof) {
                  return {
                          cName: CS_NonemptyStrings$Util.NonEmptyString.toString(prof.firstName) + " " + Belt_Option.mapWithDefault(prof.lastName, "", CS_NonemptyStrings$Util.NonEmptyString.toString),
                          cId: prof.accountId,
                          cBirthdate: prof.legalDocumentBirthdate,
                          bookable: !Belt_Array.some(Belt_Array.keepMap(Belt_Option.getWithDefault(prof.pendingVoyageSlugs, []), (function (a) {
                                      return a;
                                    })), (function (slug) {
                                  return Caml_obj.equal(slug, voyageSlug);
                                })),
                          complete: prof.travelDocComplete,
                          prof: prof
                        };
                }));
  } else {
    return [];
  }
}

function validatePhone(pax) {
  var match = PassengerState$Thin.phone.validate(pax, undefined);
  var match$1 = PassengerState$Thin.phoneCountryCode.validate(pax, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var number = match._0;
  if (number !== undefined && match$1.TAG === "Ok") {
    return Belt_Result.map(CS_Phone$Util.Phone.fromPrimitive(match$1._0 + " " + number), (function (v) {
                  return Caml_option.some(v);
                }));
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function validateOnePax(bcAccountId, pax1Type, pax) {
  var match = Belt_Option.isSome(pax.accountId) && !pax.isComplete;
  var match$1 = pax.paxType;
  var match$2 = pax.idx === 1 ? pax1Type : undefined;
  var exit = 0;
  if (match) {
    if (match$1 !== undefined) {
      var exit$1 = 0;
      var exit$2 = 0;
      switch (match$1) {
        case "ProvideInfo" :
        case "AddConnected" :
            exit$2 = 3;
            break;
        default:
          exit = 1;
      }
      if (exit$2 === 3) {
        if (match$2 !== undefined) {
          if (match$2 === "Pax1IsBC") {
            return Belt_Result.map(validatePaxApplicationAndTravelDocUpdateInput(pax), (function (input) {
                          return {
                                  TAG: "PaxApplicationAndTravelDocUpdate",
                                  _0: input
                                };
                        }));
          }
          exit$1 = 2;
        } else {
          exit$1 = 2;
        }
      }
      if (exit$1 === 2) {
        if (match$1 !== "ProvideInfo") {
          return {
                  TAG: "Error",
                  _0: {
                    TAG: "PassengerInfoError",
                    _0: pax.idx
                  }
                };
        }
        exit = 1;
      }
      
    } else {
      exit = 1;
    }
  } else if (match$1 !== undefined) {
    if (match$1 === "AddConnected") {
      return Belt_Result.map(validatePaxApplication(pax), (function (input) {
                    return {
                            TAG: "PaxApplication",
                            _0: input
                          };
                  }));
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$1 === undefined) {
      return {
              TAG: "Error",
              _0: {
                TAG: "PassengerInfoError",
                _0: pax.idx
              }
            };
    }
    switch (match$1) {
      case "VerifyBC" :
      case "SignupBC" :
          return {
                  TAG: "Error",
                  _0: {
                    TAG: "PassengerInfoError",
                    _0: pax.idx
                  }
                };
      case "ProvideInfo" :
          var exit$3 = 0;
          if (match$2 !== undefined) {
            if (match$2 === "Pax1IsBC") {
              return Belt_Result.map(validatePaxApplication(pax), (function (input) {
                            return {
                                    TAG: "PaxApplication",
                                    _0: input
                                  };
                          }));
            }
            exit$3 = 2;
          } else {
            exit$3 = 2;
          }
          if (exit$3 === 2) {
            return Belt_Result.map(validateNewConnectedPaxInput(bcAccountId, pax), (function (input) {
                          return {
                                  TAG: "NewConnectedPax",
                                  _0: input
                                };
                        }));
          }
          break;
      case "AddByLoyalty" :
          return Belt_Result.map(validatePaxApplicationByLoyaltyInput(pax), (function (input) {
                        return {
                                TAG: "PaxApplicationByLoyalty",
                                _0: input
                              };
                      }));
      case "Invite" :
          if (match$2 !== undefined) {
            return {
                    TAG: "Error",
                    _0: {
                      TAG: "PassengerInfoError",
                      _0: pax.idx
                    }
                  };
          } else {
            return Belt_Result.map(validatePaxInvitationInput(pax), (function (input) {
                          return {
                                  TAG: "PaxInvitation",
                                  _0: input
                                };
                        }));
          }
      case "TBA" :
          if (match$2 !== undefined) {
            return {
                    TAG: "Error",
                    _0: {
                      TAG: "PassengerInfoError",
                      _0: pax.idx
                    }
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: "ValidatedTBA"
                  };
          }
      
    }
  }
  
}

function validatePaxApplication(pax) {
  var accountId = pax.accountId;
  if (accountId !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              idx: pax.idx,
              accountId: Caml_option.valFromOption(accountId)
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
}

function validateNewConnectedPaxInput(custodianAccountId, pax) {
  var match = pax.paxType;
  var match$1 = PassengerState$Thin.docFirstName.validate(pax, undefined);
  var match$2 = PassengerState$Thin.docLastName.validate(pax, undefined);
  var match$3 = PassengerState$Thin.docGender.validate(pax, undefined);
  var match$4 = PassengerState$Thin.docCitizenship.validate(pax, undefined);
  var match$5 = PassengerState$Thin.docBirthdate.validate(pax, undefined);
  var match$6 = PassengerState$Thin.preferredFirstName.validate(pax, undefined);
  var match$7 = PassengerState$Thin.preferredLastName.validate(pax, undefined);
  var match$8 = validatePhone(pax);
  var match$9 = PassengerState$Thin.postalCode.validate(pax, undefined);
  var match$10 = PassengerState$Thin.residenceCountry.validate(pax, undefined);
  var match$11 = pax.halMarinerNumber.value;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match !== "ProvideInfo") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docFirstname = match$1._0;
  if (docFirstname === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docLastname = match$2._0;
  if (docLastname === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docGender = match$3._0;
  if (docGender === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docCitizenship = match$4._0;
  if (docCitizenship === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docBirthdate = match$5._0;
  if (docBirthdate === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$8.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$9.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$10.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var halMarinerNumber;
  if (match$11 !== undefined && match$11 !== "") {
    var v = CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(match$11);
    halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
  } else {
    halMarinerNumber = undefined;
  }
  return {
          TAG: "Ok",
          _0: {
            idx: pax.idx,
            custodianAccountId: custodianAccountId,
            docFirstname: Caml_option.valFromOption(docFirstname),
            docLastname: Caml_option.valFromOption(docLastname),
            docGender: docGender,
            docCitizenship: Caml_option.valFromOption(docCitizenship),
            docBirthdate: Caml_option.valFromOption(docBirthdate),
            preferredFirstName: match$6._0,
            preferredLastName: match$7._0,
            phone: match$8._0,
            postalCode: match$9._0,
            residenceCountry: match$10._0,
            halMarinerNumber: halMarinerNumber
          }
        };
}

function validatePaxApplicationAndTravelDocUpdateInput(pax) {
  var match = pax.accountId;
  var match$1 = pax.isComplete;
  var match$2 = PassengerState$Thin.docFirstName.validate(pax, undefined);
  var match$3 = PassengerState$Thin.docLastName.validate(pax, undefined);
  var match$4 = PassengerState$Thin.docGender.validate(pax, undefined);
  var match$5 = PassengerState$Thin.docCitizenship.validate(pax, undefined);
  var match$6 = PassengerState$Thin.docBirthdate.validate(pax, undefined);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$1) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docFirstname = match$2._0;
  if (docFirstname === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docLastname = match$3._0;
  if (docLastname === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docGender = match$4._0;
  if (docGender === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docCitizenship = match$5._0;
  if (docCitizenship === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var docBirthdate = match$6._0;
  if (docBirthdate !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              idx: pax.idx,
              accountId: Caml_option.some(Caml_option.valFromOption(match)),
              docFirstname: Caml_option.some(Caml_option.valFromOption(docFirstname)),
              docLastname: Caml_option.some(Caml_option.valFromOption(docLastname)),
              docGender: docGender,
              docCitizenship: Caml_option.some(Caml_option.valFromOption(docCitizenship)),
              docBirthdate: Caml_option.some(Caml_option.valFromOption(docBirthdate))
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
}

function validatePaxInvitationInput(pax) {
  var match = pax.paxType;
  var match$1 = PassengerState$Thin.preferredFirstName.validate(pax, undefined);
  var match$2 = PassengerState$Thin.preferredLastName.validate(pax, undefined);
  var match$3 = PassengerState$Thin.email.validate(pax, undefined);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match !== "Invite") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var preferredFirstName = match$1._0;
  if (preferredFirstName === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var invitationEmail = match$3._0;
  if (invitationEmail !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              idx: pax.idx,
              invitationEmail: Caml_option.valFromOption(invitationEmail),
              preferredFirstName: Caml_option.valFromOption(preferredFirstName),
              preferredLastName: match$2._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
}

function validatePaxApplicationByLoyaltyInput(pax) {
  var match = pax.paxType;
  var match$1 = pax.accountId;
  var match$2 = PassengerState$Thin.loyalty.validate(pax, undefined);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match !== "AddByLoyalty") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var loyaltyNumber = match$2._0;
  if (loyaltyNumber !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              idx: pax.idx,
              accountId: Caml_option.valFromOption(match$1),
              loyaltyNumber: Caml_option.valFromOption(loyaltyNumber)
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
}

function validatePaxes(model, occupancy, bcAccountId) {
  return Belt_Array.reduce(Belt_Array.map(Belt_Array.keep(paxArray(model), (function (param) {
                        return param.idx <= occupancy;
                      })), (function (extra) {
                    return validateOnePax(bcAccountId, model.pax1.value, extra);
                  })), {
              TAG: "Ok",
              _0: {
                _attachControlledPax: undefined,
                _attachControlledPaxWithTravelDoc: undefined,
                _attachLoyaltyPax: undefined,
                _attachInvitedPax: undefined,
                _attachNewConnectedPax: undefined
              }
            }, (function (carry, paxResult) {
                if (carry.TAG !== "Ok") {
                  return carry;
                }
                var carry$1 = carry._0;
                if (paxResult.TAG === "Ok") {
                  var a = paxResult._0;
                  if (typeof a === "object") {
                    switch (a.TAG) {
                      case "PaxApplication" :
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    _attachControlledPax: appendToVeryOptionalArray(carry$1._attachControlledPax, a._0),
                                    _attachControlledPaxWithTravelDoc: carry$1._attachControlledPaxWithTravelDoc,
                                    _attachLoyaltyPax: carry$1._attachLoyaltyPax,
                                    _attachInvitedPax: carry$1._attachInvitedPax,
                                    _attachNewConnectedPax: carry$1._attachNewConnectedPax
                                  }
                                };
                      case "PaxApplicationAndTravelDocUpdate" :
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    _attachControlledPax: carry$1._attachControlledPax,
                                    _attachControlledPaxWithTravelDoc: appendToVeryOptionalArray(carry$1._attachControlledPaxWithTravelDoc, a._0),
                                    _attachLoyaltyPax: carry$1._attachLoyaltyPax,
                                    _attachInvitedPax: carry$1._attachInvitedPax,
                                    _attachNewConnectedPax: carry$1._attachNewConnectedPax
                                  }
                                };
                      case "PaxApplicationByLoyalty" :
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    _attachControlledPax: carry$1._attachControlledPax,
                                    _attachControlledPaxWithTravelDoc: carry$1._attachControlledPaxWithTravelDoc,
                                    _attachLoyaltyPax: appendToVeryOptionalArray(carry$1._attachLoyaltyPax, a._0),
                                    _attachInvitedPax: carry$1._attachInvitedPax,
                                    _attachNewConnectedPax: carry$1._attachNewConnectedPax
                                  }
                                };
                      case "PaxInvitation" :
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    _attachControlledPax: carry$1._attachControlledPax,
                                    _attachControlledPaxWithTravelDoc: carry$1._attachControlledPaxWithTravelDoc,
                                    _attachLoyaltyPax: carry$1._attachLoyaltyPax,
                                    _attachInvitedPax: appendToVeryOptionalArray(carry$1._attachInvitedPax, a._0),
                                    _attachNewConnectedPax: carry$1._attachNewConnectedPax
                                  }
                                };
                      case "NewConnectedPax" :
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    _attachControlledPax: carry$1._attachControlledPax,
                                    _attachControlledPaxWithTravelDoc: carry$1._attachControlledPaxWithTravelDoc,
                                    _attachLoyaltyPax: carry$1._attachLoyaltyPax,
                                    _attachInvitedPax: carry$1._attachInvitedPax,
                                    _attachNewConnectedPax: appendToVeryOptionalArray(carry$1._attachNewConnectedPax, a._0)
                                  }
                                };
                      
                    }
                  }
                  
                }
                if (paxResult.TAG === "Ok") {
                  return carry;
                } else {
                  return paxResult;
                }
              }));
}

function validateSignupFormField(model, pax) {
  var match = Belt_Option.map(model.signup, (function (signup) {
          return password.validate(signup, undefined);
        }));
  var match$1 = Belt_Option.map(model.signup, (function (signup) {
          return password.getValue(signup) === confirmPassword.getValue(signup);
        }));
  var match$2 = pax.paxType;
  var match$3 = PassengerState$Thin.preferredFirstName.validate(pax, undefined);
  var match$4 = PassengerState$Thin.preferredLastName.validate(pax, undefined);
  var match$5 = PassengerState$Thin.email.validate(pax, undefined);
  var match$6 = validatePhone(pax);
  var match$7 = PassengerState$Thin.postalCode.validate(pax, undefined);
  var match$8 = PassengerState$Thin.residenceCountry.validate(pax, undefined);
  var match$9 = PassengerState$Thin.acquisitionSlug.validate(pax, undefined);
  var match$10 = pax.halMarinerNumber.value;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (!match$1) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2 === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$2 !== "SignupBC") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var accountFirstName = match$3._0;
  if (accountFirstName === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var accountLastName = match$4._0;
  if (accountLastName === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var accountEmail = match$5._0;
  if (accountEmail === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var phone = match$6._0;
  if (phone === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$8.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var residenceCountry = match$8._0;
  if (residenceCountry === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  if (match$9.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var custAcquisitionSlug = match$9._0;
  if (custAcquisitionSlug === undefined) {
    return {
            TAG: "Error",
            _0: {
              TAG: "PassengerInfoError",
              _0: pax.idx
            }
          };
  }
  var halMarinerNumber;
  if (match$10 !== undefined && match$10 !== "") {
    var v = CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(match$10);
    halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
  } else {
    halMarinerNumber = undefined;
  }
  return {
          TAG: "Ok",
          _0: {
            newPasswd: match._0,
            accountEmail: Caml_option.valFromOption(accountEmail),
            personalFields: {
              accountFirstName: Caml_option.valFromOption(accountFirstName),
              accountLastName: Caml_option.valFromOption(accountLastName),
              phone: Caml_option.valFromOption(phone),
              residenceCountry: Caml_option.valFromOption(residenceCountry),
              postalCode: match$7._0,
              custAcquisitionSlug: Caml_option.valFromOption(custAcquisitionSlug),
              halMarinerNumber: halMarinerNumber
            }
          }
        };
}

function highestContiguousValidPaxIdx(model, occupancy) {
  var match = model.bc;
  if (match === undefined) {
    return 0;
  }
  var bcAccountId = match.accountId;
  if (bcAccountId === undefined) {
    return 0;
  }
  var bcAccountId$1 = Caml_option.valFromOption(bcAccountId);
  return Belt_Array.reduce(Belt_Array.keep(paxArray(model), (function (pax) {
                    return pax.idx <= occupancy;
                  })), 0, (function (carry, pax) {
                var match = validateOnePax(bcAccountId$1, model.pax1.value, pax);
                if (match.TAG === "Ok" && carry === (pax.idx - 1 | 0)) {
                  return carry + 1 | 0;
                } else {
                  return carry;
                }
              }));
}

function validateSaveBookingSessionInput(model, _forOccupancy, _voyageSlug) {
  var match = model.paymentOption;
  var _pifIntention = match !== undefined && match.TAG === "PIF" ? true : false;
  var match$1 = model.bc;
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: "InvalidBillingContact"
          };
  }
  var bcAccountId = match$1.accountId;
  if (bcAccountId !== undefined) {
    return Belt_Result.map(validatePaxes(model, _forOccupancy, Caml_option.valFromOption(bcAccountId)), (function (param) {
                  return {
                          _voyageSlug: _voyageSlug,
                          _forOccupancy: _forOccupancy,
                          _pifIntention: _pifIntention,
                          _attachControlledPax: param._attachControlledPax,
                          _attachControlledPaxWithTravelDoc: param._attachControlledPaxWithTravelDoc,
                          _attachLoyaltyPax: param._attachLoyaltyPax,
                          _attachInvitedPax: param._attachInvitedPax,
                          _attachNewConnectedPax: param._attachNewConnectedPax
                        };
                }));
  } else {
    return {
            TAG: "Error",
            _0: "InvalidBillingContact"
          };
  }
}

function fieldToString(urlField) {
  return urlField;
}

var validOccupancies = [
  1,
  2,
  3,
  4
];

var genderSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("M"),
      S$RescriptSchema.literal("F"),
      S$RescriptSchema.literal("X")
    ]);

var payOptSchema = S$RescriptSchema.union([
      S$RescriptSchema.object(function (o) {
            o.tag("kind", "PIF");
            return {
                    TAG: "PIF",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.tag("kind", "minimum");
            return {
                    TAG: "Minimum",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.tag("kind", "current");
            return {
                    TAG: "Current",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.tag("kind", "custom");
            return {
                    TAG: "Custom",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          })
    ]);

var empty = {
  navTarget: "ClassAndSubclassSelection",
  session: undefined,
  cabinTargetingByCategory: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinTargetingByAllNoCabin: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinTargetingByAll: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinHold: undefined,
  bookingSuccess: undefined
};

var navTargetCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "ClassAndSubclassSelection",
        "CategorySelection",
        "LocationSelection",
        "DeckSelection",
        "CabinSelection",
        "EnterPassengerDetails",
        "Checkout"
      ],
      toString: (function (a) {
          switch (a) {
            case "ClassAndSubclassSelection" :
                return "class";
            case "CategorySelection" :
                return "category";
            case "LocationSelection" :
                return "location";
            case "DeckSelection" :
                return "deck";
            case "CabinSelection" :
                return "cabin";
            case "EnterPassengerDetails" :
                return "pax";
            case "Checkout" :
                return "checkout";
            
          }
        })
    });

var cabinTargetingByCategoryCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple6(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5]
                ];
        })
    });

var longitudeCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "FORE",
        "MID",
        "AFT"
      ],
      toString: (function (a) {
          return a;
        })
    });

var cabinTargetingAllNoCabinCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple8(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), longitudeCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.DeckNumber), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5],
                    param[6],
                    param[7]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5],
                  param[6],
                  param[7]
                ];
        })
    });

var cabinTargetingAllCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple9(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), longitudeCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.DeckNumber), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5],
                    param[6],
                    param[7],
                    param[8]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5],
                  param[6],
                  param[7],
                  param[8]
                ];
        })
    });

function uuid_decode(a) {
  return {
          TAG: "Ok",
          _0: a
        };
}

function uuid_encode(v) {
  return v;
}

var uuid = {
  decode: uuid_decode,
  encode: uuid_encode
};

var salesFlowURLCodec = QueryString$Util.Parser.make([
      QueryString$Util.Param.Singular.make("n", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(navTargetCodec)), false, (function (diff, navTarget) {
              return {
                      navTarget: Belt_Option.getWithDefault(navTarget, "ClassAndSubclassSelection"),
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.navTarget;
            })),
      QueryString$Util.Param.Singular.make("s", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(uuid)), false, (function (diff, session) {
              return {
                      navTarget: diff.navTarget,
                      session: session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.session;
            })),
      QueryString$Util.Param.Singular.make("cc", fieldToString, cabinTargetingByCategoryCodec, false, (function (diff, cabinTargetingByCategory) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: Belt_Option.getWithDefault(cabinTargetingByCategory, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByCategory;
            })),
      QueryString$Util.Param.Singular.make("cn", fieldToString, cabinTargetingAllNoCabinCodec, false, (function (diff, cabinTargetingByAllNoCabin) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: Belt_Option.getWithDefault(cabinTargetingByAllNoCabin, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByAllNoCabin;
            })),
      QueryString$Util.Param.Singular.make("ca", fieldToString, cabinTargetingAllCodec, false, (function (diff, cabinTargetingByAll) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: Belt_Option.getWithDefault(cabinTargetingByAll, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByAll;
            })),
      QueryString$Util.Param.Singular.make("h", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain))), false, (function (diff, cabinHold) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinHold;
            })),
      QueryString$Util.Param.Singular.make("b", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), false, (function (diff, bookingSuccess) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: bookingSuccess
                    };
            }), (function (diff) {
              return diff.bookingSuccess;
            }))
    ], empty);

function parse(queryString) {
  return salesFlowURLCodec.decode(queryString);
}

function serialize(urlData) {
  return salesFlowURLCodec.encode(urlData);
}

var URLState = {
  parse: parse,
  serialize: serialize
};

var cmp = Caml_obj.compare;

var NavTargetCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function setNavTarget(urlState, target) {
  return {
          navTarget: target,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: urlState.bookingSuccess
        };
}

function setSession(urlState, session) {
  return {
          navTarget: urlState.navTarget,
          session: session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: urlState.bookingSuccess
        };
}

function setBookingSuccess(urlState, success) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: success
        };
}

function setOccupancy(flow, urlState, occupancy) {
  switch (flow) {
    case "ByCategory" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinClass(flow, urlState, cabinClass) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinSubclass(flow, urlState, cabinSubclass) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinCategory(flow, urlState, cabinCategory) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setFareClassSlug(flow, urlState, fareClassSlug) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  match$1[5],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  match$2[3],
                  match$2[4],
                  match$2[5],
                  match$2[6],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinLocation(flow, urlState, longitude) {
  switch (flow) {
    case "ByCategory" :
        return urlState;
    case "AllNoCabin" :
        var match = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  longitude,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$1 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  longitude,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinDeck(flow, urlState, deck) {
  switch (flow) {
    case "ByCategory" :
        return urlState;
    case "AllNoCabin" :
        var match = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  Caml_option.some(deck),
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$1 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  Caml_option.some(deck),
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinNumber(flow, urlState, cabin) {
  switch (flow) {
    case "ByCategory" :
    case "AllNoCabin" :
        return urlState;
    case "All" :
        var match = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  match[5],
                  Caml_option.some(cabin),
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setHoldRequestTime(flow, urlState, requestTime) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  Caml_option.some(requestTime)
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  match$1[5],
                  match$1[6],
                  Caml_option.some(requestTime)
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  match$2[3],
                  match$2[4],
                  match$2[5],
                  match$2[6],
                  match$2[7],
                  Caml_option.some(requestTime)
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinHold(urlState, expiration) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: Caml_option.some(expiration),
          bookingSuccess: urlState.bookingSuccess
        };
}

function clearCabinHold(urlState) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: undefined,
          bookingSuccess: urlState.bookingSuccess
        };
}

var Alter = {
  setNavTarget: setNavTarget,
  setSession: setSession,
  setBookingSuccess: setBookingSuccess,
  setOccupancy: setOccupancy,
  setCabinClass: setCabinClass,
  setCabinSubclass: setCabinSubclass,
  setCabinCategory: setCabinCategory,
  setFareClassSlug: setFareClassSlug,
  setCabinLocation: setCabinLocation,
  setCabinDeck: setCabinDeck,
  setCabinNumber: setCabinNumber,
  setHoldRequestTime: setHoldRequestTime,
  setCabinHold: setCabinHold,
  clearCabinHold: clearCabinHold
};

function generateNavUrl(target, validTargets, urlState, url) {
  if (Belt_Set.has(validTargets, target)) {
    var urlData = setNavTarget(urlState, target);
    return url + "?" + salesFlowURLCodec.encode(urlData);
  }
  if (Belt_Set.has(validTargets, "EnterPassengerDetails")) {
    var urlData$1 = setNavTarget(urlState, "EnterPassengerDetails");
    return url + "?" + salesFlowURLCodec.encode(urlData$1);
  }
  if (Belt_Set.has(validTargets, "CabinSelection")) {
    var urlData$2 = setNavTarget(urlState, "CabinSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$2);
  }
  if (Belt_Set.has(validTargets, "DeckSelection")) {
    var urlData$3 = setNavTarget(urlState, "DeckSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$3);
  }
  if (Belt_Set.has(validTargets, "LocationSelection")) {
    var urlData$4 = setNavTarget(urlState, "LocationSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$4);
  }
  if (Belt_Set.has(validTargets, "CategorySelection")) {
    var urlData$5 = setNavTarget(urlState, "CategorySelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$5);
  }
  var urlData$6 = setNavTarget(urlState, "ClassAndSubclassSelection");
  var queryString = salesFlowURLCodec.encode(urlData$6);
  if (queryString === "") {
    return url;
  } else {
    return url + "?" + queryString;
  }
}

var targetingCriteria = ["ClassAndSubclassSelection"];

var targetingSteppers = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  }
];

function stepToSteppers(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers;
  }
}

var allTargets = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "EnterPassengerDetails",
  "Checkout"
];

function nextNavTarget(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavStates(model) {
  var match = salesFlowURLCodec.decode(model.url.search);
  var cabinHold = match.cabinHold;
  var cabinTargetingByCategory = match.cabinTargetingByCategory;
  var initState = ["Initial"];
  var numPaxes = paxArray(model).length;
  var match$1 = model.bc;
  var cabinClass = cabinTargetingByCategory[1];
  var occupancy = cabinTargetingByCategory[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByCategory[4];
  var category = cabinTargetingByCategory[3];
  var subclass = cabinTargetingByCategory[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      if (fareRuleSlug !== undefined) {
        var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
        var category$1 = Caml_option.valFromOption(category);
        var exit = 0;
        if (match$1 !== undefined) {
          var bc = match$1.accountId;
          if (bc !== undefined && cabinHold !== undefined) {
            var expiration = Caml_option.valFromOption(cabinHold);
            if (Belt_Result.isOk(validatePaxes(model, occupancy, Caml_option.valFromOption(bc))) && numPaxes >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
              tmp = [
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanEnterPaxDataByCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: fareRuleSlug$1
                },
                {
                  TAG: "CanCheckoutByCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: fareRuleSlug$1,
                  _5: expiration
                }
              ];
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = Belt_Array.keepMap([
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanEnterPaxDataByCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: fareRuleSlug$1
                }
              ], (function (state) {
                  if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataByCategory" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                    return state;
                  }
                  
                }));
        }
        
      } else {
        tmp = [];
      }
    } else {
      tmp = fareRuleSlug !== undefined ? [] : [{
            TAG: "CanSelectCategory",
            _0: occupancy,
            _1: cabinClass$1,
            _2: subclass$1
          }];
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanEnterPaxDataByCategory" :
        return "EnterPassengerDetails";
    case "CanCheckoutByCategory" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState(state);
                  })), NavTargetCmp);
}

function readyForNavState(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions(model) {
  return validNavTargets(validNavStates(model));
}

function canNavigate(target, model) {
  return Belt_Set.has(navOptions(model), target);
}

function navUrl(target, model, urlData, url) {
  return generateNavUrl(target, navOptions(model), urlData, url);
}

function setOcc(urlState, occupancy) {
  return clearCabinHold(setOccupancy("ByCategory", urlState, occupancy));
}

function setClass(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("ByCategory", urlState, cabinClass));
}

function setSub(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("ByCategory", urlState, cabinSubclass));
}

function setCat(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("ByCategory", urlState, cabinCategory));
}

function setFare(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("ByCategory", urlState, fareClassSlug));
}

function setRequestTime(urlState, requestTime) {
  return setHoldRequestTime("ByCategory", urlState, requestTime);
}

var CabinTarget = {
  setOcc: setOcc,
  setClass: setClass,
  setSub: setSub,
  setCat: setCat,
  setFare: setFare,
  setRequestTime: setRequestTime
};

function stepIsComplete(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataByCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    default:
      return false;
  }
}

function isCurrentStep(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
}

var ByCategory = {
  targetingCriteria: targetingCriteria,
  targetingSteppers: targetingSteppers,
  stepToSteppers: stepToSteppers,
  allTargets: allTargets,
  nextNavTarget: nextNavTarget,
  validNavStates: validNavStates,
  getNavTargetFromState: getNavTargetFromState,
  validNavTargets: validNavTargets,
  readyForNavState: readyForNavState,
  navOptions: navOptions,
  canNavigate: canNavigate,
  navUrl: navUrl,
  CabinTarget: CabinTarget,
  stepIsComplete: stepIsComplete,
  isCurrentStep: isCurrentStep
};

var targetingCriteria$1 = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "LocationSelection",
  "DeckSelection"
];

var targetingSteppers$1 = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  },
  {
    title: "Location",
    nav: "LocationSelection",
    params: ["Location"]
  },
  {
    title: "Deck",
    nav: "DeckSelection",
    params: ["Deck"]
  }
];

function stepToSteppers$1(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers$1;
  }
}

function nextNavTarget$1(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "LocationSelection";
    case "LocationSelection" :
        return "DeckSelection";
    case "DeckSelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    case "CabinSelection" :
    case "Checkout" :
        return "ClassAndSubclassSelection";
    
  }
}

function validNavStates$1(model) {
  var match = salesFlowURLCodec.decode(model.url.search);
  var cabinHold = match.cabinHold;
  var cabinTargetingByAllNoCabin = match.cabinTargetingByAllNoCabin;
  var initState = ["Initial"];
  var numPaxes = paxArray(model).length;
  var match$1 = model.bc;
  var cabinClass = cabinTargetingByAllNoCabin[1];
  var occupancy = cabinTargetingByAllNoCabin[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByAllNoCabin[6];
  var deck = cabinTargetingByAllNoCabin[5];
  var longitude = cabinTargetingByAllNoCabin[4];
  var category = cabinTargetingByAllNoCabin[3];
  var subclass = cabinTargetingByAllNoCabin[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      var category$1 = Caml_option.valFromOption(category);
      if (longitude !== undefined) {
        if (deck !== undefined) {
          if (fareRuleSlug !== undefined) {
            var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
            var deck$1 = Caml_option.valFromOption(deck);
            var exit = 0;
            if (match$1 !== undefined) {
              var bc = match$1.accountId;
              if (bc !== undefined && cabinHold !== undefined) {
                var expiration = Caml_option.valFromOption(cabinHold);
                if (Belt_Result.isOk(validatePaxes(model, occupancy, Caml_option.valFromOption(bc))) && numPaxes >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
                  tmp = [
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    },
                    {
                      TAG: "CanSelectDeck",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude
                    },
                    {
                      TAG: "CanEnterPaxDataAllNoCabin",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: fareRuleSlug$1
                    },
                    {
                      TAG: "CanCheckoutAllNoCabin",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: fareRuleSlug$1,
                      _7: expiration
                    }
                  ];
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              tmp = Belt_Array.keepMap([
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    },
                    {
                      TAG: "CanSelectDeck",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude
                    },
                    {
                      TAG: "CanEnterPaxDataAllNoCabin",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: fareRuleSlug$1
                    }
                  ], (function (state) {
                      if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataAllNoCabin" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                        return state;
                      }
                      
                    }));
            }
            
          } else {
            tmp = [];
          }
        } else {
          tmp = fareRuleSlug !== undefined ? [] : [
              {
                TAG: "CanSelectCategory",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1
              },
              {
                TAG: "CanSelectLocation",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1
              },
              {
                TAG: "CanSelectDeck",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1,
                _4: longitude
              }
            ];
        }
      } else {
        tmp = deck !== undefined ? [] : (
            fareRuleSlug !== undefined ? [] : [
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanSelectLocation",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1
                }
              ]
          );
      }
    } else {
      tmp = [{
          TAG: "CanSelectCategory",
          _0: occupancy,
          _1: cabinClass$1,
          _2: subclass$1
        }];
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState$1(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanSelectLocation" :
        return "LocationSelection";
    case "CanSelectDeck" :
        return "DeckSelection";
    case "CanEnterPaxDataAllNoCabin" :
        return "EnterPassengerDetails";
    case "CanCheckoutAllNoCabin" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets$1(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState$1(state);
                  })), NavTargetCmp);
}

function readyForNavState$1(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions$1(model) {
  return validNavTargets$1(validNavStates$1(model));
}

function canNavigate$1(target, model) {
  return Belt_Set.has(navOptions$1(model), target);
}

function navUrl$1(target, urlState, model, url) {
  return generateNavUrl(target, navOptions$1(model), urlState, url);
}

function setOcc$1(urlState, occupancy) {
  return clearCabinHold(setOccupancy("AllNoCabin", urlState, occupancy));
}

function setClass$1(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("AllNoCabin", urlState, cabinClass));
}

function setSub$1(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("AllNoCabin", urlState, cabinSubclass));
}

function setCat$1(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("AllNoCabin", urlState, cabinCategory));
}

function setLoc(urlState, longitude) {
  return clearCabinHold(setCabinLocation("AllNoCabin", urlState, longitude));
}

function setDeck(urlState, deck) {
  return clearCabinHold(setCabinDeck("AllNoCabin", urlState, deck));
}

function setFare$1(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("AllNoCabin", urlState, fareClassSlug));
}

function setRequestTime$1(urlState, requestTime) {
  return setHoldRequestTime("AllNoCabin", urlState, requestTime);
}

var CabinTarget$1 = {
  setOcc: setOcc$1,
  setClass: setClass$1,
  setSub: setSub$1,
  setCat: setCat$1,
  setLoc: setLoc,
  setDeck: setDeck,
  setFare: setFare$1,
  setRequestTime: setRequestTime$1
};

function stepIsComplete$1(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectLocation") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Location" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectDeck") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Deck" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataAllNoCabin") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Cabin" :
        return false;
    
  }
}

function isCurrentStep$1(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    case "Location" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectLocation")) {
          return true;
        } else {
          return false;
        }
    case "Deck" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectDeck")) {
          return true;
        } else {
          return false;
        }
    case "Cabin" :
        return false;
    
  }
}

var ByAllNoCabin = {
  targetingCriteria: targetingCriteria$1,
  targetingSteppers: targetingSteppers$1,
  stepToSteppers: stepToSteppers$1,
  nextNavTarget: nextNavTarget$1,
  validNavStates: validNavStates$1,
  getNavTargetFromState: getNavTargetFromState$1,
  validNavTargets: validNavTargets$1,
  readyForNavState: readyForNavState$1,
  navOptions: navOptions$1,
  canNavigate: canNavigate$1,
  navUrl: navUrl$1,
  CabinTarget: CabinTarget$1,
  stepIsComplete: stepIsComplete$1,
  isCurrentStep: isCurrentStep$1
};

var targetingCriteria$2 = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "LocationSelection",
  "DeckSelection",
  "CabinSelection"
];

var targetingSteppers$2 = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  },
  {
    title: "Location",
    nav: "LocationSelection",
    params: ["Location"]
  },
  {
    title: "Deck",
    nav: "DeckSelection",
    params: ["Deck"]
  },
  {
    title: "Cabin",
    nav: "CabinSelection",
    params: ["Cabin"]
  }
];

function stepToSteppers$2(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers$2;
  }
}

function nextNavTarget$2(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "LocationSelection";
    case "LocationSelection" :
        return "DeckSelection";
    case "DeckSelection" :
        return "CabinSelection";
    case "CabinSelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    case "Checkout" :
        return "ClassAndSubclassSelection";
    
  }
}

function validNavStates$2(model) {
  var match = salesFlowURLCodec.decode(model.url.search);
  var cabinHold = match.cabinHold;
  var cabinTargetingByAll = match.cabinTargetingByAll;
  var numPaxes = paxArray(model).length;
  var initState = ["Initial"];
  var cabinClass = cabinTargetingByAll[1];
  var occupancy = cabinTargetingByAll[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByAll[7];
  var cabin = cabinTargetingByAll[6];
  var deck = cabinTargetingByAll[5];
  var longitude = cabinTargetingByAll[4];
  var category = cabinTargetingByAll[3];
  var subclass = cabinTargetingByAll[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var match$1 = model.bc;
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      var category$1 = Caml_option.valFromOption(category);
      if (longitude !== undefined) {
        if (deck !== undefined) {
          var deck$1 = Caml_option.valFromOption(deck);
          if (cabin !== undefined) {
            if (fareRuleSlug !== undefined) {
              var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
              var cabin$1 = Caml_option.valFromOption(cabin);
              var exit = 0;
              if (match$1 !== undefined) {
                var bc = match$1.accountId;
                if (bc !== undefined && cabinHold !== undefined) {
                  var expiration = Caml_option.valFromOption(cabinHold);
                  if (Belt_Result.isOk(validatePaxes(model, occupancy, Caml_option.valFromOption(bc))) && numPaxes >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
                    tmp = [
                      {
                        TAG: "CanSelectCategory",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1
                      },
                      {
                        TAG: "CanSelectLocation",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1
                      },
                      {
                        TAG: "CanSelectDeck",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude
                      },
                      {
                        TAG: "CanSelectCabin",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1
                      },
                      {
                        TAG: "CanEnterPaxDataAll",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1,
                        _6: cabin$1,
                        _7: fareRuleSlug$1
                      },
                      {
                        TAG: "CanCheckoutAll",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1,
                        _6: cabin$1,
                        _7: fareRuleSlug$1,
                        _8: expiration
                      }
                    ];
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                tmp = Belt_Array.keepMap([
                      {
                        TAG: "CanSelectCategory",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1
                      },
                      {
                        TAG: "CanSelectLocation",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1
                      },
                      {
                        TAG: "CanSelectDeck",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude
                      },
                      {
                        TAG: "CanSelectCabin",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1
                      },
                      {
                        TAG: "CanEnterPaxDataAll",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1,
                        _6: cabin$1,
                        _7: fareRuleSlug$1
                      }
                    ], (function (state) {
                        if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataAll" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                          return state;
                        }
                        
                      }));
              }
              
            } else {
              tmp = [];
            }
          } else {
            tmp = fareRuleSlug !== undefined ? [] : [
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanSelectLocation",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1
                },
                {
                  TAG: "CanSelectDeck",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: longitude
                },
                {
                  TAG: "CanSelectCabin",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: longitude,
                  _5: deck$1
                }
              ];
          }
        } else {
          tmp = cabin !== undefined ? [] : (
              fareRuleSlug !== undefined ? [] : [
                  {
                    TAG: "CanSelectCategory",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1
                  },
                  {
                    TAG: "CanSelectLocation",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1
                  },
                  {
                    TAG: "CanSelectDeck",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1,
                    _4: longitude
                  }
                ]
            );
        }
      } else {
        tmp = deck !== undefined ? [] : (
            cabin !== undefined ? [] : (
                fareRuleSlug !== undefined ? [] : [
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    }
                  ]
              )
          );
      }
    } else {
      tmp = longitude !== undefined ? [] : (
          deck !== undefined ? [] : (
              cabin !== undefined ? [] : (
                  fareRuleSlug !== undefined ? [] : [{
                        TAG: "CanSelectCategory",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1
                      }]
                )
            )
        );
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState$2(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanSelectLocation" :
        return "LocationSelection";
    case "CanSelectDeck" :
        return "DeckSelection";
    case "CanSelectCabin" :
        return "CabinSelection";
    case "CanEnterPaxDataAll" :
        return "EnterPassengerDetails";
    case "CanCheckoutAll" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets$2(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState$2(state);
                  })), NavTargetCmp);
}

function readyForNavState$2(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions$2(model) {
  return validNavTargets$2(validNavStates$2(model));
}

function canNavigate$2(target, model) {
  return Belt_Set.has(navOptions$2(model), target);
}

function navUrl$2(target, urlState, model, url) {
  return generateNavUrl(target, navOptions$2(model), urlState, url);
}

function setOcc$2(urlState, occupancy) {
  return clearCabinHold(setOccupancy("All", urlState, occupancy));
}

function setClass$2(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("All", urlState, cabinClass));
}

function setSub$2(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("All", urlState, cabinSubclass));
}

function setCat$2(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("All", urlState, cabinCategory));
}

function setLoc$1(urlState, longitude) {
  return clearCabinHold(setCabinLocation("All", urlState, longitude));
}

function setDeck$1(urlState, deck) {
  return clearCabinHold(setCabinDeck("All", urlState, deck));
}

function setCabin(urlState, cabin) {
  return clearCabinHold(setCabinNumber("All", urlState, cabin));
}

function setFare$2(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("All", urlState, fareClassSlug));
}

function setRequestTime$2(urlState, requestTime) {
  return setHoldRequestTime("All", urlState, requestTime);
}

var CabinTarget$2 = {
  setOcc: setOcc$2,
  setClass: setClass$2,
  setSub: setSub$2,
  setCat: setCat$2,
  setLoc: setLoc$1,
  setDeck: setDeck$1,
  setCabin: setCabin,
  setFare: setFare$2,
  setRequestTime: setRequestTime$2
};

function stepIsComplete$2(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectLocation") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Location" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectDeck") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Deck" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCabin") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Cabin" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataAll") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    
  }
}

function isCurrentStep$2(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    case "Location" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectLocation")) {
          return true;
        } else {
          return false;
        }
    case "Deck" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectDeck")) {
          return true;
        } else {
          return false;
        }
    case "Cabin" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCabin")) {
          return true;
        } else {
          return false;
        }
    
  }
}

var ByAll = {
  targetingCriteria: targetingCriteria$2,
  targetingSteppers: targetingSteppers$2,
  stepToSteppers: stepToSteppers$2,
  nextNavTarget: nextNavTarget$2,
  validNavStates: validNavStates$2,
  getNavTargetFromState: getNavTargetFromState$2,
  validNavTargets: validNavTargets$2,
  readyForNavState: readyForNavState$2,
  navOptions: navOptions$2,
  canNavigate: canNavigate$2,
  navUrl: navUrl$2,
  CabinTarget: CabinTarget$2,
  stepIsComplete: stepIsComplete$2,
  isCurrentStep: isCurrentStep$2
};

function urlString(searchStr, url) {
  var match = searchStr.trim().length;
  if (match !== 0) {
    return url + "?" + searchStr;
  } else {
    return url;
  }
}

function navigate(searchStr, url) {
  RescriptReactRouter.push(urlString(searchStr, url));
}

function goTo(urlState, navTarget, url) {
  var urlData = setNavTarget(urlState, navTarget);
  navigate(salesFlowURLCodec.encode(urlData), url);
}

function cabinTypeSelect(urlData, slug, flow) {
  var match;
  switch (flow) {
    case "ByCategory" :
        match = [
          setClass,
          setSub,
          setCat
        ];
        break;
    case "AllNoCabin" :
        match = [
          setClass$1,
          setSub$1,
          setCat$1
        ];
        break;
    case "All" :
        match = [
          setClass$2,
          setSub$2,
          setCat$2
        ];
        break;
    
  }
  switch (slug.TAG) {
    case "Class" :
        return match[0](urlData, slug._0);
    case "Subclass" :
        return match[1](urlData, Caml_option.some(slug._0));
    case "Category" :
        return match[2](urlData, slug._0);
    
  }
}

function paymentOptionToInt(p) {
  return CS_Decimal$Util.Decimal10_2.toInt(p._0.amt);
}

var preferredFirstName = PassengerState$Thin.preferredFirstName;

var preferredLastName = PassengerState$Thin.preferredLastName;

var docFirstName = PassengerState$Thin.docFirstName;

var docLastName = PassengerState$Thin.docLastName;

var docBirthdate = PassengerState$Thin.docBirthdate;

var docCitizenship = PassengerState$Thin.docCitizenship;

var docGender = PassengerState$Thin.docGender;

var phone = PassengerState$Thin.phone;

var phoneCountryCode = PassengerState$Thin.phoneCountryCode;

var postalCode = PassengerState$Thin.postalCode;

var residenceCountry = PassengerState$Thin.residenceCountry;

var email = PassengerState$Thin.email;

var acquisitionSlug = PassengerState$Thin.acquisitionSlug;

var halMarinerNumber = PassengerState$Thin.halMarinerNumber;

var loyalty = PassengerState$Thin.loyalty;

var loyaltyTier = PassengerState$Thin.loyaltyTier;

var paxTypeSchema = PassengerState$Thin.paxTypeSchema;

var genderInputToText = PassengerState$Thin.genderInputToText;

var blankPax = PassengerState$Thin.blankPax;

var paxUtils = PassengerState$Thin.paxUtils;

var validateUpdateAccount = PassengerState$Thin.validateUpdateAccount;

exports.ImpossibleBCFormFieldSchema = ImpossibleBCFormFieldSchema;
exports.preferredFirstName = preferredFirstName;
exports.preferredLastName = preferredLastName;
exports.docFirstName = docFirstName;
exports.docLastName = docLastName;
exports.docBirthdate = docBirthdate;
exports.docCitizenship = docCitizenship;
exports.docGender = docGender;
exports.phone = phone;
exports.phoneCountryCode = phoneCountryCode;
exports.postalCode = postalCode;
exports.residenceCountry = residenceCountry;
exports.email = email;
exports.acquisitionSlug = acquisitionSlug;
exports.halMarinerNumber = halMarinerNumber;
exports.loyalty = loyalty;
exports.loyaltyTier = loyaltyTier;
exports.paxTypeSchema = paxTypeSchema;
exports.genderInputToText = genderInputToText;
exports.blankPax = blankPax;
exports.paxUtils = paxUtils;
exports.validateUpdateAccount = validateUpdateAccount;
exports.appendToVeryOptionalArray = appendToVeryOptionalArray;
exports.pax1 = pax1;
exports.password = password;
exports.confirmPassword = confirmPassword;
exports.blankModel = blankModel;
exports.blankSignup = blankSignup;
exports.paymentOptionSchema = paymentOptionSchema;
exports.makeSchema = makeSchema;
exports.modelSchema = modelSchema;
exports.paxTypeView = paxTypeView;
exports.signupView = signupView;
exports.billingContactView = billingContactView;
exports.paxViewByIdx = paxViewByIdx;
exports.sameAsBcToggle = sameAsBcToggle;
exports.allTravelDocFieldsComplete = allTravelDocFieldsComplete;
exports.isAgeGated = isAgeGated;
exports.paxBirthdateShouldError = paxBirthdateShouldError;
exports.paxArray = paxArray;
exports.numPaxes = numPaxes;
exports.notBookable = notBookable;
exports.prefillFromCurrentUserProfile = prefillFromCurrentUserProfile;
exports.prefillFromConnectedAccount = prefillFromConnectedAccount;
exports.createFromConnectedAccount = createFromConnectedAccount;
exports.createLoyaltyFromFromAttachExisting = createLoyaltyFromFromAttachExisting;
exports.createInviteFromAttachInvited = createInviteFromAttachInvited;
exports.createProvideInfoFromAttachNewConnected = createProvideInfoFromAttachNewConnected;
exports.mapAttachExisting = mapAttachExisting;
exports.mapAttachInvited = mapAttachInvited;
exports.mapAttachNewConnected = mapAttachNewConnected;
exports.updatePaxesFromCurrentBookingSession = updatePaxesFromCurrentBookingSession;
exports.getConnectedSelectOptions = getConnectedSelectOptions;
exports.validatePhone = validatePhone;
exports.validateSignupFormField = validateSignupFormField;
exports.validatePaxApplication = validatePaxApplication;
exports.validatePaxApplicationAndTravelDocUpdateInput = validatePaxApplicationAndTravelDocUpdateInput;
exports.validatePaxApplicationByLoyaltyInput = validatePaxApplicationByLoyaltyInput;
exports.validatePaxInvitationInput = validatePaxInvitationInput;
exports.validateNewConnectedPaxInput = validateNewConnectedPaxInput;
exports.validateOnePax = validateOnePax;
exports.highestContiguousValidPaxIdx = highestContiguousValidPaxIdx;
exports.validatePaxes = validatePaxes;
exports.validateSaveBookingSessionInput = validateSaveBookingSessionInput;
exports.fieldToString = fieldToString;
exports.validOccupancies = validOccupancies;
exports.genderSchema = genderSchema;
exports.payOptSchema = payOptSchema;
exports.empty = empty;
exports.navTargetCodec = navTargetCodec;
exports.cabinTargetingByCategoryCodec = cabinTargetingByCategoryCodec;
exports.longitudeCodec = longitudeCodec;
exports.cabinTargetingAllNoCabinCodec = cabinTargetingAllNoCabinCodec;
exports.cabinTargetingAllCodec = cabinTargetingAllCodec;
exports.uuid = uuid;
exports.salesFlowURLCodec = salesFlowURLCodec;
exports.URLState = URLState;
exports.NavTargetCmp = NavTargetCmp;
exports.Alter = Alter;
exports.generateNavUrl = generateNavUrl;
exports.ByCategory = ByCategory;
exports.ByAllNoCabin = ByAllNoCabin;
exports.ByAll = ByAll;
exports.urlString = urlString;
exports.navigate = navigate;
exports.goTo = goTo;
exports.cabinTypeSelect = cabinTypeSelect;
exports.paymentOptionToInt = paymentOptionToInt;
/* pax1 Not a pure module */
