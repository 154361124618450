// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var SendOutlined = require("@mui/icons-material/SendOutlined").default;

function InviteSelector$SaveButton(props) {
  var __disabled = props.disabled;
  var setLocalError = props.setLocalError;
  var handler = props.handler;
  var disabled = __disabled !== undefined ? __disabled : false;
  return JsxRuntime.jsx(AppButton$Thin.make, {
              loading: props.loading,
              onClick: (function (param) {
                  if (handler.TAG === "Ok") {
                    return handler._0();
                  } else if (setLocalError !== undefined) {
                    return setLocalError(function (param) {
                                return "Error saving passenger information";
                              });
                  } else {
                    return ;
                  }
                }),
              disabled: disabled || Belt_Result.isError(handler),
              children: "Save"
            });
}

var SaveButton = {
  make: InviteSelector$SaveButton
};

function InviteSelector(props) {
  var sessionPax = props.sessionPax;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        
      });
  var error = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        return false;
      });
  var setSaveSuccess = match$2[1];
  var sessionData = HopperState$Util.Observable.getRoot(sessionPax, "RetainSkipUpdateFromView");
  var isDirty = HopperState$Util.Observable.FormField.useIsDirty(sessionPax, SFState$Thin.paxUtils);
  var sessionSaveHandler = props.useSessionSaveHandler(sessionData, match[1], (function () {
          setSaveSuccess(function (param) {
                return true;
              });
        }), match$1[1]);
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsxs(Stack, {
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 2,
                            sx: {
                              pt: 1
                            },
                            children: [
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    spacing: 2,
                                    sx: {
                                      p: 2,
                                      border: "1px solid",
                                      borderColor: "neutral.300",
                                      backgroundColor: "neutral.200",
                                      borderRadius: "sm"
                                    },
                                    children: [
                                      JsxRuntime.jsx(Typography, {
                                            startDecorator: Caml_option.some(JsxRuntime.jsx(SendOutlined, {})),
                                            level: "title-md",
                                            component: "h2",
                                            children: "Invite"
                                          }),
                                      JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                            children: [
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                              view: {
                                                                TAG: "Direct",
                                                                _0: sessionPax
                                                              },
                                                              input: SFState$Thin.email,
                                                              map: [
                                                                (function (o) {
                                                                    return Belt_Option.getWithDefault(o, "");
                                                                  }),
                                                                (function (s) {
                                                                    if (s === "") {
                                                                      return ;
                                                                    } else {
                                                                      return s;
                                                                    }
                                                                  })
                                                              ],
                                                              children: (function (param) {
                                                                  var set = param.set;
                                                                  return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                              label: "Invitee email",
                                                                              errorText: param.errorText,
                                                                              error: param.error,
                                                                              children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "email",
                                                                                    placeholder: "Email (required)",
                                                                                    required: true,
                                                                                    variant: "outlined",
                                                                                    value: param.value,
                                                                                    onChange: (function (e) {
                                                                                        set(function (param) {
                                                                                              return e.target.value;
                                                                                            });
                                                                                      })
                                                                                  }),
                                                                              required: true,
                                                                              wasTouched: param.wasTouched
                                                                            });
                                                                })
                                                            })),
                                                    xs: 12
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                              view: {
                                                                TAG: "Direct",
                                                                _0: sessionPax
                                                              },
                                                              input: SFState$Thin.preferredFirstName,
                                                              map: [
                                                                (function (o) {
                                                                    return Belt_Option.getWithDefault(o, "");
                                                                  }),
                                                                (function (s) {
                                                                    if (s === "") {
                                                                      return ;
                                                                    } else {
                                                                      return s;
                                                                    }
                                                                  })
                                                              ],
                                                              children: (function (param) {
                                                                  var set = param.set;
                                                                  return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                              label: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                                              errorText: param.errorText,
                                                                              error: param.error,
                                                                              children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "text",
                                                                                    placeholder: "First name (required)",
                                                                                    required: true,
                                                                                    variant: "outlined",
                                                                                    value: param.value,
                                                                                    onChange: (function (e) {
                                                                                        set(function (param) {
                                                                                              return e.target.value;
                                                                                            });
                                                                                      })
                                                                                  }),
                                                                              required: true,
                                                                              wasTouched: param.wasTouched
                                                                            });
                                                                })
                                                            })),
                                                    xs: 12,
                                                    md: 6
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                              view: {
                                                                TAG: "Direct",
                                                                _0: sessionPax
                                                              },
                                                              input: SFState$Thin.preferredLastName,
                                                              map: [
                                                                (function (o) {
                                                                    return Belt_Option.getWithDefault(o, "");
                                                                  }),
                                                                (function (s) {
                                                                    if (s === "") {
                                                                      return ;
                                                                    } else {
                                                                      return s;
                                                                    }
                                                                  })
                                                              ],
                                                              children: (function (param) {
                                                                  var set = param.set;
                                                                  return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                              label: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                                              errorText: param.errorText,
                                                                              error: param.error,
                                                                              children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "text",
                                                                                    placeholder: "Last name (optional)",
                                                                                    variant: "outlined",
                                                                                    value: param.value,
                                                                                    onChange: (function (e) {
                                                                                        set(function (param) {
                                                                                              return e.target.value;
                                                                                            });
                                                                                      })
                                                                                  }),
                                                                              wasTouched: param.wasTouched
                                                                            });
                                                                })
                                                            })),
                                                    xs: 12,
                                                    md: 6
                                                  })
                                            ],
                                            container: true,
                                            spacing: {
                                              TAG: "Fixed",
                                              _0: 1
                                            },
                                            rowSpacing: 1,
                                            columnSpacing: 1
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(Stack, {
                                    direction: "row",
                                    spacing: 1,
                                    sx: {
                                      width: "fit-content",
                                      display: isDirty ? "flex" : "none",
                                      py: 1
                                    },
                                    children: [
                                      JsxRuntime.jsx(AppButton$Thin.make, {
                                            onClick: (function (param) {
                                                HopperState$Util.Observable.FormField.resetToInitialValue(sessionPax, SFState$Thin.paxUtils);
                                              }),
                                            variant: "outlined",
                                            color: "neutral",
                                            disabled: !isDirty,
                                            children: "Cancel"
                                          }),
                                      JsxRuntime.jsx(InviteSelector$SaveButton, {
                                            loading: match$1[0],
                                            handler: sessionSaveHandler,
                                            disabled: !isDirty
                                          })
                                    ]
                                  })
                            ]
                          }),
                      error !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: error,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              locked: props.locked
            });
}

var make = InviteSelector;

exports.SaveButton = SaveButton;
exports.make = make;
/* react Not a pure module */
