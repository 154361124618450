// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"updateAccountDataInput":{"_updateOtherVoyages":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"_residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"_preferredLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_preferredFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"_phone":{"c":"Util.CustomScalars.Phone.Exn"},"_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"_custAcquisitionSlug":{"c":"Util.CustomScalars.Slug.Exn"},"_accountId":{"c":"Util.CustomScalars.AccountId.Exn"}},"__root":{"input":{"r":"updateAccountDataInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.Slug.Exn": CS_Slugs$Util.Slug.Exn.serialize,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.serialize,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.serialize,
  "Util.CustomScalars.Phone.Exn": CS_Phone$Util.PhoneImpl.Exn.serialize,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAccountDataPayload",
    "kind": "LinkedField",
    "name": "updateAccountData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountData_UpdateAccountDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountData_UpdateAccountDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b8d1c6e30a18e92c9db82dab5b5b4b67",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountData_UpdateAccountDataMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountData_UpdateAccountDataMutation(\n  $input: UpdateAccountDataInput!\n) {\n  updateAccountData(input: $input) {\n    boolean\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
