// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ConfirmPaxCard$Thin = require("../../sales-flow/passengers/ConfirmPaxCard.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Close = require("@mui/icons-material/Close").default;
var Search = require("@mui/icons-material/Search").default;
var SearchByLoyaltyNumber$Thin = require("../../common/queries/SearchByLoyaltyNumber.bs.js");
var ReportOutlined = require("@mui/icons-material/ReportOutlined").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function AddByLoyalty(props) {
  var action = props.action;
  var addOrErr = props.addOrErr;
  var idx = props.idx;
  var __mobile = props.mobile;
  var setSearchErrorType = props.setSearchErrorType;
  var searchErrorType = props.searchErrorType;
  var setLoyaltyNum = props.setLoyaltyNum;
  var loyaltyNum = props.loyaltyNum;
  var setLoyaltyPatch = props.setLoyaltyPatch;
  var loyaltyPatch = props.loyaltyPatch;
  var mobile = __mobile !== undefined ? __mobile : false;
  var match = React.useState(function () {
        return false;
      });
  var setSearching = match[1];
  var match$1 = AppConfig$Thin.Context.use();
  var clearAll = function () {
    setLoyaltyPatch(function (param) {
          
        });
    setLoyaltyNum(function (param) {
          
        });
    setSearchErrorType(function (param) {
          
        });
    setSearching(function (param) {
          return false;
        });
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var onSearchSuccess = function (l) {
    var patch = SearchByLoyaltyNumber$Thin.Utils.toLoyaltyPatch(l);
    if (patch !== undefined) {
      return setLoyaltyPatch(function (param) {
                  return patch;
                });
    } else {
      return setSearchErrorType(function (param) {
                  return "Invalid";
                });
    }
  };
  var connectedIntMapToArray = function (m) {
    return Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(m, undefined)), (function (param) {
                  return param[1];
                }));
  };
  var loyaltyIntMapToArray = function (m) {
    return Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(m, undefined)), (function (param) {
                  return param[1].acctId;
                }));
  };
  var draftAcctIds = Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(connectedIntMapToArray(diff.attachExistingConnectedPax), connectedIntMapToArray(diff.replacePaxExistingConnected)), loyaltyIntMapToArray(diff.attachExistingLoyaltyPax)), loyaltyIntMapToArray(diff.replaceExistingLoyaltyPax));
  var match$2 = SearchByLoyaltyNumber$Thin.useQuery(setSearchErrorType, (function () {
          setSearchErrorType(function (param) {
                
              });
          setLoyaltyPatch(function (param) {
                
              });
        }), setSearching, props.voyageSlug, draftAcctIds);
  var lookupByLoyalty = match$2.lookupByLoyalty;
  var fetchError = match$2.error;
  var onConfirm = function (e) {
    e.preventDefault();
    if (loyaltyPatch !== undefined) {
      var applyDiff;
      applyDiff = action === "Attach" ? BookingDiff$Thin.Alter.attachExistingLoyaltyPax(diff, idx, loyaltyPatch) : BookingDiff$Thin.Alter.replaceWithExistingLoyaltyPax(diff, idx, loyaltyPatch);
      addOrErr(applyDiff);
      return clearAll();
    }
    console.log("No loyalty account selected");
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var match = Belt_Option.map(loyaltyNum, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
    if (match !== undefined && match.TAG === "Ok") {
      return lookupByLoyalty(match._0, onSearchSuccess);
    }
    
  };
  var tmp;
  if (loyaltyPatch !== undefined && CS_NonemptyStrings$Util.LoyaltyNumber.toString(loyaltyPatch.loyalty) === loyaltyNum) {
    var tmp$1;
    if (searchErrorType !== undefined) {
      switch (searchErrorType) {
        case "Invalid" :
        case "IncompletePrimary" :
            tmp$1 = "danger";
            break;
        default:
          tmp$1 = "inherit";
      }
    } else {
      tmp$1 = "inherit";
    }
    tmp = JsxRuntime.jsx(Close, {
          color: tmp$1
        });
  } else {
    tmp = JsxRuntime.jsx(Search, {});
  }
  var endDecorator = JsxRuntime.jsx(IconButton, {
        onClick: (function (e) {
            e.preventDefault();
            var match = Belt_Option.map(loyaltyNum, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
            if (loyaltyPatch !== undefined) {
              return clearAll();
            } else if (match !== undefined && match.TAG === "Ok") {
              return lookupByLoyalty(match._0, onSearchSuccess);
            } else {
              return ;
            }
          }),
        type: Belt_Option.isNone(loyaltyPatch) ? "submit" : "button",
        "aria-label": "search",
        loading: match[0],
        children: Caml_option.some(tmp)
      });
  var tmp$2;
  if (searchErrorType !== undefined) {
    switch (searchErrorType) {
      case "Invalid" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Invalid loyalty number"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "We couldn’t find a passenger with that loyalty number that can be added to your booking."
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "IncompletePrimary" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Incomplete account"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "The passenger with that loyalty number has an incomplete travel document record. They must complete the travel document information in their user profile before they can serve as the primary contact in a booking."
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "Incomplete" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "warning",
                                  level: "title-sm",
                                  children: "Incomplete account"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "warning",
                                  level: "body-xs",
                                  children: "This account is incomplete. It can be completed after booking."
                                })
                          ]
                        })),
                color: "warning",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                          sx: {
                            color: "warning.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "warningSecondary.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "AlreadyBooked" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Already booked"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "The passenger with that loyalty number is already booked on this voyage!"
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "AlreadyInDraft" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Already booked"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "The passenger with that loyalty number has already been added in this draft!"
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      
    }
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (mobile) {
    tmp$3 = null;
  } else {
    var tmp$4;
    tmp$4 = action === "Attach" ? Txt$Thin.BookingDetails.addPassAddPassenger.value : Txt$Thin.BookingDetails.addPassReplacePassenger.value;
    tmp$3 = JsxRuntime.jsx(Stack, {
          sx: {
            width: "fit-content"
          },
          children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                    fullWidth: false,
                    loading: props.loading,
                    onClick: onConfirm,
                    size: "lg",
                    disabled: Belt_Option.isNone(loyaltyPatch),
                    children: Caml_option.some(tmp$4)
                  }))
        });
  }
  return JsxRuntime.jsxs(Stack, {
              justifyContent: "center",
              spacing: 2,
              sx: {
                py: 1,
                px: mobile ? 2 : 0,
                height: "100%"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      component: "form",
                      spacing: 2,
                      sx: {
                        mt: 1,
                        px: mobile ? 2 : 0,
                        pt: mobile ? 2 : 1,
                        pb: mobile ? 3 : 0,
                        backgroundColor: mobile ? "neutral.100" : "transparent",
                        borderRadius: "sm",
                        width: "auto"
                      },
                      onSubmit: onSubmit,
                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                error: searchErrorType === "Invalid" || searchErrorType === "IncompletePrimary",
                                children: [
                                  JsxRuntime.jsx(FormLabel, {
                                        children: Caml_option.some(match$1.brandFamily.brandFamilyName + " Loyalty Number")
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                        type_: "text",
                                        placeholder: "Loyalty number",
                                        variant: "outlined",
                                        value: Belt_Option.getWithDefault(loyaltyNum, ""),
                                        onChange: (function (e) {
                                            var val = e.currentTarget.value;
                                            setLoyaltyNum(function (param) {
                                                  return val;
                                                });
                                          }),
                                        endDecorator: Caml_option.some(endDecorator)
                                      })
                                ]
                              }))
                    }),
                loyaltyPatch !== undefined ? JsxRuntime.jsx(ConfirmPaxCard$Thin.make, {
                        name: CS_NonemptyStrings$Util.NonEmptyString.toString(loyaltyPatch.protectedName),
                        email: Belt_Option.map(loyaltyPatch.loyEmail, CS_Emails$Util.Email.toString),
                        custodian: undefined,
                        custodianEmail: undefined,
                        isComplete: true
                      }) : null,
                tmp$2,
                fetchError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: fetchError,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                tmp$3
              ]
            });
}

var make = AddByLoyalty;

exports.make = make;
/* react Not a pure module */
