// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function JustAnError(props) {
  return JsxRuntime.jsx(Sheet, {
              variant: "outlined",
              sx: {
                width: 300,
                mx: "auto",
                my: 4,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
                backgroundColor: "background.level1"
              },
              children: Caml_option.some(JsxRuntime.jsx(JsxRuntime.Fragment, {
                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  spacing: 1,
                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                            color: "danger",
                                            level: "body-sm",
                                            children: Caml_option.some(props.text)
                                          }))
                                }))
                      }))
            });
}

var make = JustAnError;

exports.make = make;
/* @mui/joy/Sheet Not a pure module */
