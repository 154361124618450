// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FareClassSelector$Thin = require("./FareClassSelector.bs.js");

function FareClassDrawer(props) {
  var onCabinHoldSuccess = props.onCabinHoldSuccess;
  var quoteFc = props.quoteFc;
  var setQuote = props.setQuote;
  var redirectNoCabinHold = props.redirectNoCabinHold;
  var signOnStatus = props.signOnStatus;
  var requestCabinHoldByCategory = props.requestCabinHoldByCategory;
  var setFare = props.setFare;
  var setCat = props.setCat;
  var url = props.url;
  var urlState = props.urlState;
  var openLoginModal = props.openLoginModal;
  var displayTotal = props.displayTotal;
  var fareClasses = props.fareClasses;
  var category = props.category;
  var onClose = props.onClose;
  React.useState(function () {
        return false;
      });
  var match = React.useState(function () {
        var fc = Belt_Array.get(fareClasses, 0);
        if (fc !== undefined) {
          return fc.fcSlug;
        } else {
          return CS_Slugs$Util.FareClassSlug.Exn.fromPrimitive("retail");
        }
      });
  var setSelectedFareClass = match[1];
  var selectedFareClass = match[0];
  var onSubmit = function (param) {
    var newUrlState = setFare(setCat(urlState, category), selectedFareClass);
    SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState), url);
    if (quoteFc === Caml_option.some(selectedFareClass)) {
      
    } else {
      setQuote("Unavailable");
    }
    if (!(signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON")) {
      return redirectNoCabinHold(newUrlState);
    }
    switch (signOnStatus) {
      case "ANONYMOUS" :
          return openLoginModal();
      case "SIGNED_ON" :
          var cabinHoldSuccess = function (expiry) {
            var func = onCabinHoldSuccess(newUrlState);
            func(expiry);
            onClose();
          };
          return requestCabinHoldByCategory(newUrlState, cabinHoldSuccess);
      default:
        return redirectNoCabinHold(newUrlState);
    }
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(AppDrawer$Thin.make, {
                        open_: props.drawerOpen,
                        title: "Select a fare class",
                        onClose: onClose,
                        children: [
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                          direction: "column",
                                          spacing: 2,
                                          sx: {
                                            p: 2
                                          },
                                          children: Caml_option.some(Belt_Array.mapWithIndex(fareClasses, (function (i, fc) {
                                                      return JsxRuntime.jsx(FareClassSelector$Thin.make, {
                                                                  fc: fc,
                                                                  displayTotal: displayTotal,
                                                                  isSelected: Caml_obj.equal(selectedFareClass, fc.fcSlug),
                                                                  onClick: (function () {
                                                                      setSelectedFareClass(function (param) {
                                                                            return fc.fcSlug;
                                                                          });
                                                                    })
                                                                }, String(i));
                                                    })))
                                        }))
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsx(DialogActions, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: {
                                            xs: "column-reverse",
                                            sm: "row"
                                          },
                                          justifyContent: "space-between",
                                          spacing: 1,
                                          sx: {
                                            width: "100%",
                                            p: 2
                                          },
                                          children: [
                                            JsxRuntime.jsx(Button, {
                                                  onClick: onClose,
                                                  variant: "outlined",
                                                  color: "neutral",
                                                  children: "Cancel"
                                                }),
                                            JsxRuntime.jsx(Button, {
                                                  onClick: onSubmit,
                                                  children: "Confirm"
                                                })
                                          ]
                                        }))
                              })
                        ]
                      }))
            });
}

var make = FareClassDrawer;

exports.make = make;
/* react Not a pure module */
