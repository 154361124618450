// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

function makeRefetchVariables(_bookingId, _loyaltyNumbers, _voyageSlug) {
  return {
          _bookingId: _bookingId,
          _loyaltyNumbers: _loyaltyNumbers,
          _voyageSlug: _voyageSlug
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_loyaltyNumbers":{"ca":"Util.CustomScalars.LoyaltyNumber.Exn"},"_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.LoyaltyNumber.Exn": CS_NonemptyStrings$Util.LoyaltyNumber.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"lookupDetailsByLoyalty_nodes_protectedName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_numPendingVoyages":{"b":""},"lookupDetailsByLoyalty_nodes_numEligibleVoyages":{"b":""},"lookupDetailsByLoyalty_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"lookupDetailsByLoyalty_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_email":{"c":"Util.CustomScalars.Email.Exn"},"lookupDetailsByLoyalty_nodes_eligibleVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"lookupDetailsByLoyalty_nodes_docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"lookupDetailsByLoyalty_nodes_docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"lookupDetailsByLoyalty_nodes_custodianProtectedName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_custodianEmail":{"c":"Util.CustomScalars.Email.Exn"},"lookupDetailsByLoyalty_nodes_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.serialize,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"lookupDetailsByLoyalty_nodes_protectedName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_numPendingVoyages":{"b":""},"lookupDetailsByLoyalty_nodes_numEligibleVoyages":{"b":""},"lookupDetailsByLoyalty_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"lookupDetailsByLoyalty_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_email":{"c":"Util.CustomScalars.Email.Exn"},"lookupDetailsByLoyalty_nodes_eligibleVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"lookupDetailsByLoyalty_nodes_docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"lookupDetailsByLoyalty_nodes_docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"lookupDetailsByLoyalty_nodes_custodianProtectedName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_custodianEmail":{"c":"Util.CustomScalars.Email.Exn"},"lookupDetailsByLoyalty_nodes_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"lookupDetailsByLoyalty_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_bookingId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_loyaltyNumbers"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_voyageSlug"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_bookingId",
        "variableName": "_bookingId"
      },
      {
        "kind": "Variable",
        "name": "_loyaltyNumbers",
        "variableName": "_loyaltyNumbers"
      },
      {
        "kind": "Variable",
        "name": "_voyageSlug",
        "variableName": "_voyageSlug"
      }
    ],
    "concreteType": "LookupDetailsByLoyaltyConnection",
    "kind": "LinkedField",
    "name": "lookupDetailsByLoyalty",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookupDetailsByLoyaltyRecord",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodianEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodianProtectedName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docBirthdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docCitizenship",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docFirstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docLastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docGender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protectedName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "travelDocComplete",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numEligibleVoyages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numPendingVoyages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eligibleVoyageSlugs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "halMarinerNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentTierTitle",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftUtilsFetchLoyaltyUsersQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DraftUtilsFetchLoyaltyUsersQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a054288914716e8a69bd0e164097986e",
    "id": null,
    "metadata": {},
    "name": "DraftUtilsFetchLoyaltyUsersQuery",
    "operationKind": "query",
    "text": "query DraftUtilsFetchLoyaltyUsersQuery(\n  $_loyaltyNumbers: [LoyaltyNumber]!\n  $_voyageSlug: VoyageSlug!\n  $_bookingId: BookingId!\n) {\n  lookupDetailsByLoyalty(_loyaltyNumbers: $_loyaltyNumbers, _voyageSlug: $_voyageSlug, _bookingId: $_bookingId) {\n    nodes {\n      accountId\n      custodianEmail\n      custodianProtectedName\n      docBirthdate\n      docCitizenship\n      docFirstName\n      docLastName\n      docGender\n      email\n      firstName\n      lastName\n      protectedName\n      travelDocComplete\n      numEligibleVoyages\n      numPendingVoyages\n      eligibleVoyageSlugs\n      halMarinerNumber\n      currentTierTitle\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
