// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var Processor_Shared$Thin = require("./Processor_Shared.bs.js");
var Processor_Endpoint$Thin = require("./Processor_Endpoint.bs.js");
var Processor_PlaidDebit$Thin = require("./Processor_PlaidDebit.bs.js");
var Processor_SupportedProcessor$Thin = require("./Processor_SupportedProcessor.bs.js");
var Processor_BraintreeHostedFields$Thin = require("./Processor_BraintreeHostedFields.bs.js");

var emptyState = {
  method: undefined
};

function fieldToString(field) {
  return field;
}

function methodParam(endpoints) {
  var match = Processor_SupportedProcessor$Thin.getList(endpoints);
  var avails = match.avails;
  var listHash = match.listHash;
  var codec = QueryString$Util.Codec.customStringable({
        legalVals: avails,
        toString: (function (v) {
            return listHash + String(avails.findIndex(function (elem) {
                            return Caml_obj.equal(elem, v);
                          }));
          })
      });
  return QueryString$Util.Param.Singular.make("pmt.method", fieldToString, QueryString$Util.Codec.scalar(codec), undefined, (function (dest, method) {
                return {
                        method: Belt_Option.flatMap(method, (function (a) {
                                return a;
                              }))
                      };
              }), (function (dest) {
                return Caml_option.some(dest.method);
              }));
}

function getParserExtended(endpoints) {
  return QueryString$Util.Parser.makeExtended([methodParam(endpoints)], emptyState);
}

function getParser(endpoints) {
  return getParserExtended(endpoints).codec;
}

function getUnvalidatedParams(endpoints) {
  return getParserExtended(endpoints).getUnvalidatedParams;
}

function parse(endpoints, str) {
  return getParserExtended(endpoints).codec.decode(str);
}

function serialize(endpoints, state) {
  return getParserExtended(endpoints).codec.encode(state);
}

function usePaymentUrlState(endpoints, prependToQueryString) {
  var isReal = endpoints.length !== 0;
  var parse$1 = function (s) {
    if (isReal) {
      return parse(endpoints, s);
    } else {
      return emptyState;
    }
  };
  var serializeToQueryString = function (state, myParams) {
    if (isReal) {
      return serialize(endpoints, state);
    } else {
      return myParams;
    }
  };
  return ReactState$Util.useURLState(parse$1, prependToQueryString, getParserExtended(endpoints).getUnvalidatedParams, serializeToQueryString);
}

function derivedMethod(parent) {
  return ReactState$Util.derive(parent, (function (state, method) {
                return {
                        method: method
                      };
              }), (function (state) {
                return state.method;
              }));
}

var QueryString = {
  emptyState: emptyState,
  fieldToString: fieldToString,
  methodParam: methodParam,
  getParserExtended: getParserExtended,
  getParser: getParser,
  getUnvalidatedParams: getUnvalidatedParams,
  parse: parse,
  serialize: serialize,
  usePaymentUrlState: usePaymentUrlState,
  derivedMethod: derivedMethod
};

function usePaymentProcessor(forVoyage, prependToQueryString) {
  var endpointsAsync = React.useState(function () {
        return "NotAsked";
      });
  RemoteData$Util.useLoadStatefulEffect(endpointsAsync, Processor_Endpoint$Thin.getProccessorEndpointsEffect(endpointsAsync, forVoyage));
  var urlState = usePaymentUrlState(RemoteData$Util.getWithDefault(ReactState$Util.getter(endpointsAsync), []), prependToQueryString);
  var selectedPaymentMethod = derivedMethod(urlState);
  var processorSuperstate_tokenizationIntent = Processor_Shared$Thin.useTokenizationIntent();
  var processorSuperstate_paymentIntent = Processor_Shared$Thin.usePaymentIntent();
  var processorSuperstate_plaidLinkToken = Processor_Shared$Thin.usePlaidLinkToken();
  var processorSuperstate_plaidAccountSelection = Processor_Shared$Thin.usePlaidAccountSelection();
  var processorSuperstate_braintreeClientToken = Processor_Shared$Thin.useBraintreeClientToken();
  var processorSuperstate = {
    tokenizationIntent: processorSuperstate_tokenizationIntent,
    paymentIntent: processorSuperstate_paymentIntent,
    plaidReceivedRedirectUri: undefined,
    plaidLinkToken: processorSuperstate_plaidLinkToken,
    plaidAccountSelection: processorSuperstate_plaidAccountSelection,
    braintreeClientToken: processorSuperstate_braintreeClientToken
  };
  return RemoteData$Util.mapSuccess(ReactState$Util.getter(endpointsAsync), (function (endpoints) {
                return {
                        getSubflow: (function (supp) {
                            var endpointSlug = supp.endpointSlug;
                            var method = supp.method;
                            var processor = supp.processor;
                            if (supp.flow === "PlaidDebit") {
                              return {
                                      TAG: "PlaidDebit",
                                      usePlaidDebit: (function () {
                                          return Processor_PlaidDebit$Thin.use(forVoyage, processor, method, "/" + endpointSlug, (function (extra) {
                                                        return Processor_SupportedProcessor$Thin.calculateFee(supp, extra);
                                                      }), processorSuperstate);
                                        })
                                    };
                            } else {
                              return {
                                      TAG: "BraintreeHostedFields",
                                      useHostedFields: (function () {
                                          return Processor_BraintreeHostedFields$Thin.use(forVoyage, processor, method, "/" + endpointSlug, (function (extra) {
                                                        return Processor_SupportedProcessor$Thin.calculateFee(supp, extra);
                                                      }), processorSuperstate);
                                        })
                                    };
                            }
                          }),
                        supportedProcessors: Processor_SupportedProcessor$Thin.getList(endpoints),
                        reloadProcs: (function () {
                            ReactState$Util.setter(endpointsAsync)(function (param) {
                                  return "NotAsked";
                                });
                          }),
                        selectedPaymentMethod: selectedPaymentMethod
                      };
              }));
}

var Supported;

var Endpoint;

var PlaidDebit;

var BraintreeHostedFields;

var PaymentIntent;

var TokenizationIntent;

var Shared;

exports.Supported = Supported;
exports.Endpoint = Endpoint;
exports.PlaidDebit = PlaidDebit;
exports.BraintreeHostedFields = BraintreeHostedFields;
exports.PaymentIntent = PaymentIntent;
exports.TokenizationIntent = TokenizationIntent;
exports.Shared = Shared;
exports.QueryString = QueryString;
exports.usePaymentProcessor = usePaymentProcessor;
/* react Not a pure module */
