// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppAvatar$Thin = require("../components/common/atoms/AppAvatar.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var MyAccountState$Thin = require("../utils/accounts/MyAccountState.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var AccountAccessSection$Thin = require("../components/account/AccountAccessSection.bs.js");
var AccountPage_query_graphql$Thin = require("../__generated__/AccountPage_query_graphql.bs.js");
var PersonalAndTravelInformation$Thin = require("../components/account/PersonalAndTravelInformation.bs.js");
var AccountPageRefetchQuery_graphql$Thin = require("../__generated__/AccountPageRefetchQuery_graphql.bs.js");

var convertFragment = AccountPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountPage_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AccountPage_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = AccountPageRefetchQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = AccountPageRefetchQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AccountPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_accountStatus_decode = AccountPage_query_graphql$Thin.Utils.accountStatus_decode;

var QueryFragment_accountStatus_fromString = AccountPage_query_graphql$Thin.Utils.accountStatus_fromString;

var QueryFragment_docGender_decode = AccountPage_query_graphql$Thin.Utils.docGender_decode;

var QueryFragment_docGender_fromString = AccountPage_query_graphql$Thin.Utils.docGender_fromString;

var QueryFragment_lifecycleStatus_decode = AccountPage_query_graphql$Thin.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = AccountPage_query_graphql$Thin.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  accountStatus_decode: QueryFragment_accountStatus_decode,
  accountStatus_fromString: QueryFragment_accountStatus_fromString,
  docGender_decode: QueryFragment_docGender_decode,
  docGender_fromString: QueryFragment_docGender_fromString,
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function AccountPage(props) {
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(), "store-and-network", undefined);
  };
  var dismissibleAlerts = HopperURL$Thin.Context.useDismissibleAlerts();
  var accountState = HopperState$Util.Observable.useStore(MyAccountState$Thin.blankModel, undefined);
  React.useEffect((function () {
          var match = data.currentUserProfiles;
          if (match !== undefined) {
            var match$1 = match.nodes;
            if (match$1.length === 1) {
              var node = match$1[0];
              HopperState$Util.Observable.notify(accountState, (function (a) {
                      return MyAccountState$Thin.prefillFromCurrentUserProfile(a, node);
                    }));
            }
            
          }
          
        }), [Belt_Option.getWithDefault(Belt_Option.flatMap(data.currentUserProfiles, (function (v) {
                    return JSON.stringify(v);
                  })), "")]);
  var match$1 = data.currentUserProfiles;
  var displayName;
  if (match$1 !== undefined) {
    var profile = Belt_Array.getExn(match$1.nodes, 0);
    var firstName = profile.firstName;
    if (firstName !== undefined) {
      var lastName = profile.lastName;
      var firstName$1 = Caml_option.valFromOption(firstName);
      displayName = lastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1);
    } else {
      var lastName$1 = profile.lastName;
      displayName = lastName$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName$1)) : "Sample Name";
    }
  } else {
    displayName = "Sample Name";
  }
  var initial = Js_string.charAt(0, displayName).toUpperCase();
  var match$2 = data.currentUserProfiles;
  var tmp;
  if (match$2 !== undefined) {
    var profile$1 = Belt_Array.getExn(match$2.nodes, 0);
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(AccountAccessSection$Thin.make, {
                  fragmentRefs: data.fragmentRefs,
                  accountState: accountState
                }),
            JsxRuntime.jsx(Divider, {}),
            JsxRuntime.jsx(PersonalAndTravelInformation$Thin.make, {
                  accountState: accountState,
                  paxRecordFrag: profile$1.passenger_record.fragmentRefs,
                  bookings: {
                    TAG: "Account",
                    _0: profile$1.passenger_record.nodes
                  },
                  countryFrag: data.fragmentRefs,
                  refetch: refetch$1
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx("div", {
          children: Txt$Thin.Account.noProfileFound.value
        });
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(Sheet, {
                    sx: {
                      backgroundColor: "background.surface",
                      borderRadius: "sm",
                      flex: 1,
                      width: "100%",
                      border: 1,
                      borderColor: "neutral.200"
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              sx: {
                                py: 2,
                                px: 3
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "row",
                                              justifyContent: "space-between",
                                              sx: {
                                                mb: 2
                                              },
                                              children: [
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      justifyContent: "center",
                                                      alignItems: "flex-start",
                                                      sx: {
                                                        gap: 1,
                                                        display: {
                                                          xs: "none",
                                                          md: "flex"
                                                        }
                                                      },
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "h3",
                                                              component: "h1",
                                                              children: Caml_option.some(Txt$Thin.Account.heading.value)
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              component: "p",
                                                              textColor: "text.tertiary",
                                                              children: Caml_option.some(Txt$Thin.Account.subheading.value)
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "row",
                                                      justifyContent: "flex=start",
                                                      alignItems: "center",
                                                      spacing: 2,
                                                      children: [
                                                        JsxRuntime.jsx(Stack, {
                                                              direction: "column",
                                                              children: Caml_option.some(JsxRuntime.jsx(AppAvatar$Thin.make, {
                                                                        children: initial,
                                                                        color: "primary",
                                                                        size: "lg"
                                                                      }))
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "h3",
                                                              component: "h1",
                                                              sx: {
                                                                display: {
                                                                  xs: "flex",
                                                                  md: "none"
                                                                }
                                                              },
                                                              children: Caml_option.some(displayName)
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        HopperURL$Thin.renderAlerts(dismissibleAlerts)
                                      ]
                                    }),
                                JsxRuntime.jsx(Divider, {}),
                                tmp
                              ]
                            }))
                  }),
              backgroundColor: "background.level1"
            });
}

var make = AccountPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
