// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DOM$Util = require("util/src/DOM.bs.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var Card = require("@mui/joy/Card").default;
var NukaCarousel = require("nuka-carousel");
var Stack = require("@mui/joy/Stack").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CardContent = require("@mui/joy/CardContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var PeopleOutlined = require("@mui/icons-material/PeopleOutlined").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;
var SquareFootOutlined = require("@mui/icons-material/SquareFootOutlined").default;

function CabinCard(props) {
  var __shadow = props.shadow;
  var onSelect = props.onSelect;
  var selected = props.selected;
  var occupancy = props.occupancy;
  var $$class = props.class;
  var shadow = __shadow !== undefined ? __shadow : true;
  var availability = Belt_MapInt.get($$class.availabilities, occupancy);
  var match = availability !== undefined ? availability : SalesCabin$Thin.emptyAvail;
  var availability$1 = match.availability;
  var scimages = SalesCabin$Thin.useSalesCabinLinkedImagesForVariant($$class.imageSetSlug, "Aspect13to10");
  var match$1 = props.pricingMode === "Total price" ? [
      match.quotePricePerBooking,
      match.referencePricePerBooking
    ] : [
      match.quotePricePerPerson,
      match.referencePricePerPerson
    ];
  var referencePrice = match$1[1];
  var quotePrice = match$1[0];
  var unselectable;
  switch (availability$1) {
    case "Available" :
    case "Limited" :
    case "LimitedAtPrice" :
        unselectable = false;
        break;
    default:
      unselectable = true;
  }
  var availChip = SalesCabin$Thin.getAvailChip(availability$1, occupancy, $$class.name);
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowMore = match$2[1];
  var showMore = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setDisplayToggle = match$3[1];
  var cardTextRef = React.useRef(null);
  React.useEffect((function () {
          var toggleShowMore = function () {
            var cardText = cardTextRef.current;
            return Belt_Option.map((cardText == null) ? undefined : Caml_option.some(cardText), (function (el) {
                          var isOverflown = el.scrollHeight > el.clientHeight;
                          var rect = el.getBoundingClientRect();
                          var twoLinesTall = 0.875 * 1.5 * 16.0 * 2.0;
                          var cardTooTall = rect.bottom - rect.top > twoLinesTall;
                          if (isOverflown || cardTooTall) {
                            return setDisplayToggle(function (param) {
                                        return true;
                                      });
                          } else {
                            return setDisplayToggle(function (param) {
                                        return false;
                                      });
                          }
                        }));
          };
          toggleShowMore();
          DOM$Util.addEventListener("resize", toggleShowMore);
          return (function () {
                    DOM$Util.removeEventListener("resize", toggleShowMore);
                  });
        }), []);
  var component = unselectable ? undefined : "button";
  var maxOccupancy = $$class.maxOccupancy;
  var blurb = $$class.blurb;
  return JsxRuntime.jsx(Stack, {
              sx: {
                display: "flex"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Card, {
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                sx: {
                                  position: "relative"
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          padding: 1,
                                          zIndex: 1
                                        },
                                        children: Caml_option.some(availChip)
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          opacity: unselectable ? "0.6" : "1"
                                        },
                                        onClick: (function (e) {
                                            e.stopPropagation();
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsx(NukaCarousel.Carousel, {
                                                  children: Caml_option.some(Belt_Array.mapWithIndex(scimages, (function (i, param) {
                                                              return JsxRuntime.jsx("img", {
                                                                          style: {
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            objectFit: "cover"
                                                                          },
                                                                          alt: param.alt,
                                                                          src: param.src
                                                                        }, String(i));
                                                            }))),
                                                  showArrows: scimages.length > 1,
                                                  showDots: scimages.length > 1,
                                                  scrollDistance: "slide",
                                                  className: "sales-cabin-card"
                                                }))
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(CardContent, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          justifyContent: "space-between",
                                          spacing: 1,
                                          sx: {
                                            height: "100%"
                                          },
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.Link.make, {
                                                  children: $$class.name,
                                                  level: "h4",
                                                  component: component,
                                                  sx: {
                                                    "&:hover": {
                                                      textDecoration: "none"
                                                    },
                                                    color: "text.secondary"
                                                  }
                                                }),
                                            JsxRuntime.jsxs(Stack, {
                                                  direction: "column",
                                                  spacing: 1,
                                                  children: [
                                                    JsxRuntime.jsxs(Stack, {
                                                          direction: "row",
                                                          alignItems: "flex-end",
                                                          spacing: 1,
                                                          children: [
                                                            quotePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                                                    level: "body-md",
                                                                    children: Caml_option.some("From " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(quotePrice))))
                                                                  }) : (
                                                                referencePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                                                        level: "body-md",
                                                                        children: Caml_option.some("From " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(referencePrice))))
                                                                      }) : JsxRuntime.jsx(Stack, {
                                                                        sx: {
                                                                          height: "calc(1em * var(--joy-lineHeight-md, 1.5))"
                                                                        }
                                                                      })
                                                              ),
                                                            quotePrice !== undefined && referencePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                                                    color: "danger",
                                                                    level: "body-xs",
                                                                    sx: {
                                                                      textDecoration: "line-through"
                                                                    },
                                                                    children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(referencePrice))))
                                                                  }) : null
                                                          ]
                                                        }),
                                                    JsxRuntime.jsxs(Stack, {
                                                          direction: "row",
                                                          flexWrap: "wrap",
                                                          children: [
                                                            JsxRuntime.jsx(AppChip$Thin.make, {
                                                                  children: String($$class.minSqFoot) + "-" + String($$class.maxSqFoot) + " SQ FT",
                                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(SquareFootOutlined, {}))
                                                                }),
                                                            JsxRuntime.jsx(Stack, {
                                                                  sx: {
                                                                    width: "0.5rem"
                                                                  }
                                                                }),
                                                            JsxRuntime.jsx(AppChip$Thin.make, {
                                                                  children: maxOccupancy !== 1 ? "Up to " + String(maxOccupancy) + " guests" : "1 guest",
                                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(PeopleOutlined, {}))
                                                                })
                                                          ]
                                                        }),
                                                    blurb !== undefined ? JsxRuntime.jsxs(Stack, {
                                                            direction: "column",
                                                            sx: {
                                                              position: "relative"
                                                            },
                                                            children: [
                                                              JsxRuntime.jsx(Typography, {
                                                                    ref: cardTextRef,
                                                                    level: "body-sm",
                                                                    sx: {
                                                                      height: "auto",
                                                                      overflow: "hidden",
                                                                      textOverflow: showMore ? "auto" : "ellipsis",
                                                                      display: "-webkit-box",
                                                                      "-webkit-line-clamp": showMore ? "unset" : "2",
                                                                      "-webkit-box-orient": "vertical"
                                                                    },
                                                                    children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(blurb)))
                                                                  }),
                                                              JsxRuntime.jsx(Stack, {
                                                                    sx: {
                                                                      cursor: "pointer",
                                                                      pt: "5px",
                                                                      display: match$3[0] ? "flex" : "none"
                                                                    },
                                                                    onClick: (function (e) {
                                                                        e.stopPropagation();
                                                                        setShowMore(function (prev) {
                                                                              return !prev;
                                                                            });
                                                                      }),
                                                                    children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                              children: showMore ? "Show less" : "Show more",
                                                                              level: "body-sm",
                                                                              component: component,
                                                                              color: "primary",
                                                                              endDecorator: Caml_option.some(JsxRuntime.jsx(KeyboardArrowDown, {
                                                                                        sx: {
                                                                                          transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
                                                                                          "&:hover": {
                                                                                            textDecoration: "none"
                                                                                          }
                                                                                        }
                                                                                      }))
                                                                            }))
                                                                  })
                                                            ]
                                                          }) : null
                                                  ]
                                                })
                                          ]
                                        })),
                                orientation: "vertical"
                              })
                        ],
                        sx: {
                          backgroundColor: "background.surface",
                          border: "1px solid",
                          borderColor: selected ? "primary.500" : "neutral.200",
                          boxShadow: shadow ? "0px 8px 50px 0px #0000000A" : "0",
                          cursor: unselectable ? "not-allowed" : "pointer",
                          borderRadius: "12px",
                          flex: 1,
                          "&:hover": {
                            borderColor: unselectable ? "neutral.200" : (
                                selected ? "primary.500" : "neutral.400"
                              ),
                            boxShadow: unselectable ? "0" : (
                                shadow ? "0px 8px 50px 0px #212A3033" : "0px 8px 50px 0px #0000000A"
                              )
                          }
                        },
                        onClick: unselectable ? (function (param) {
                              
                            }) : (function (param) {
                              onSelect();
                            })
                      }))
            });
}

var make = CabinCard;

exports.make = make;
/* react Not a pure module */
