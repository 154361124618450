// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Stack = require("@mui/joy/Stack").default;
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BookingHistorySection$Thin = require("../components/bookings/BookingHistorySection.bs.js");
var UpcomingBookingsSection$Thin = require("../components/bookings/UpcomingBookingsSection.bs.js");

function BookingsPage(props) {
  var fragmentRefs = props.fragmentRefs;
  var dismissibleAlerts = HopperURL$Thin.Context.useDismissibleAlerts();
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    alignItems: "flex-start",
                    spacing: 2,
                    sx: {
                      width: "100%"
                    },
                    children: [
                      HopperURL$Thin.renderAlerts(dismissibleAlerts),
                      JsxRuntime.jsx(UpcomingBookingsSection$Thin.make, {
                            fragmentRefs: fragmentRefs
                          }),
                      JsxRuntime.jsx(BookingHistorySection$Thin.make, {
                            fragmentRefs: fragmentRefs
                          })
                    ]
                  }),
              backgroundColor: "background.level1"
            });
}

var make = BookingsPage;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
