// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var DateFns = require("date-fns");
var Mustache = require("mustache");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var Drawer = require("@mui/joy/Drawer").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var AddPassMobile$Thin = require("./AddPassMobile.bs.js");
var CheckValidity$Thin = require("../drafts/CheckValidity.bs.js");
var ModalClose = require("@mui/joy/ModalClose").default;
var AddPassDesktop$Thin = require("./AddPassDesktop.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var AddPassDrawer_query_graphql$Thin = require("../../../__generated__/AddPassDrawer_query_graphql.bs.js");

var convertFragment = AddPassDrawer_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AddPassDrawer_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AddPassDrawer_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AddPassDrawer_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function AddPassDrawer(props) {
  var queryFrag = props.queryFrag;
  var __showFillInLater = props.showFillInLater;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var isPrimaryPax = props.isPrimaryPax;
  var voyageSlug = props.voyageSlug;
  var custodianAccountId = props.custodianAccountId;
  var idx = props.idx;
  var action = props.action;
  var __desktopAnchor = props.desktopAnchor;
  var onClose = props.onClose;
  var __title = props.title;
  var open_ = props.open_;
  var title = __title !== undefined ? __title : Txt$Thin.BookingDetails.addPassDrawerTitle.value;
  var desktopAnchor = __desktopAnchor !== undefined ? __desktopAnchor : "right";
  var showFillInLater = __showFillInLater !== undefined ? __showFillInLater : false;
  var match = React.useState(function () {
        return "all";
      });
  var setTab = match[1];
  var tab = match[0];
  var match$1 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$2 = match$1.state;
  var setDocFName = match$2[1];
  var docFName = match$2[0];
  var match$3 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$4 = match$3.state;
  var setDocLName = match$4[1];
  var docLName = match$4[0];
  var match$5 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$6 = match$5.state;
  var setPrefFName = match$6[1];
  var prefFName = match$6[0];
  var match$7 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$8 = match$7.state;
  var setPrefLName = match$8[1];
  var prefLName = match$8[0];
  var match$9 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$10 = match$9.state;
  var setEmail = match$10[1];
  var email = match$10[0];
  var match$11 = ReactState$Util.useDebouncedState((function () {
          
        }), undefined, undefined);
  var match$12 = match$11.state;
  var setGender = match$12[1];
  var gender = match$12[0];
  var match$13 = ReactState$Util.useDebouncedState((function () {
          
        }), undefined, undefined);
  var match$14 = match$13.state;
  var setCountry = match$14[1];
  var country = match$14[0];
  var match$15 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$16 = match$15.state;
  var setDob = match$16[1];
  var dob = match$16[0];
  var match$17 = React.useState(function () {
        
      });
  var setConnectedAcct = match$17[1];
  var connectedAcct = match$17[0];
  var match$18 = React.useState(function () {
        
      });
  var setLoyaltyPatch = match$18[1];
  var loyaltyPatch = match$18[0];
  var match$19 = React.useState(function () {
        
      });
  var setSearchErrorType = match$19[1];
  var searchErrorType = match$19[0];
  var match$20 = React.useState(function () {
        
      });
  var setLoyaltyNum = match$20[1];
  var loyaltyNum = match$20[0];
  var getTitle = function (tab) {
    if (tab === "all") {
      return title;
    } else if (tab === "managed") {
      return Txt$Thin.BookingDetails.selectFromManagedAccounts.value;
    } else if (tab === "invite") {
      return Txt$Thin.BookingDetails.invitePassenger.value;
    } else if (tab === "details") {
      return Txt$Thin.BookingDetails.providePassengerDetails.value;
    } else {
      return Txt$Thin.BookingDetails.addPassengerByLoyalty.value;
    }
  };
  var match$21 = AppConfig$Thin.Context.use();
  var brandFamilyName = match$21.brandFamily.brandFamilyName;
  var getDescription = function (tab) {
    if (tab === "loyalty") {
      return Mustache.render(Txt$Thin.BookingDetails.addPassengerByLoyaltyDesc.value, brandFamilyName);
    } else if (tab === "managed") {
      return Txt$Thin.BookingDetails.selectFromManagedAccountsDesc.value;
    } else if (tab === "invite") {
      return Txt$Thin.BookingDetails.invitePassengerDesc.value;
    } else if (tab === "details") {
      return Txt$Thin.BookingDetails.providePassengerDetailsDesc.value;
    } else {
      return "";
    }
  };
  var clearAll = function () {
    setDocFName(function (param) {
          return "";
        });
    setDocLName(function (param) {
          return "";
        });
    setPrefFName(function (param) {
          return "";
        });
    setPrefLName(function (param) {
          return "";
        });
    setEmail(function (param) {
          return "";
        });
    setGender(function (param) {
          
        });
    setCountry(function (param) {
          
        });
    setDob(function (param) {
          return "";
        });
    setConnectedAcct(function (param) {
          
        });
    setLoyaltyPatch(function (param) {
          
        });
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var match$22 = CheckValidity$Thin.useQuery(props.getPreviewFromDiff, RescriptReactRouter.useUrl(undefined, undefined), onClose);
  var addOrErr = match$22.addOrErr;
  var loading = match$22.loading;
  var onClose$1 = function () {
    clearAll();
    setTab(function (param) {
          return "all";
        });
    if (showFillInLater) {
      addOrErr(BookingDiff$Thin.Alter.clearAutoAdd(diff));
    }
    onClose();
  };
  var currentAndConnectedQuery = use(queryFrag);
  var attachedOrReplaced = Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.attachExistingConnectedPax, undefined)), (function (param) {
                      return param[1];
                    })), Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.replacePaxExistingConnected, undefined)), (function (param) {
                      return param[1];
                    }))), Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.attachExistingLoyaltyPax, undefined)), (function (param) {
                  return param[1].acctId;
                }))), Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.replaceExistingLoyaltyPax, undefined)), (function (param) {
              return param[1].acctId;
            })));
  var match$23 = currentAndConnectedQuery.currentUserConnectedAccounts;
  var connectedOptions = match$23 !== undefined ? Belt_Array.keepMap(match$23.nodes, (function (c) {
            if (Belt_Array.some(attachedOrReplaced, (function (acct) {
                      return Caml_obj.equal(acct, c.accountId);
                    }))) {
              var match = c.legalDocumentBirthdate;
              return {
                      accountId: c.accountId,
                      name: c.fullDisplayName,
                      cantAdd: "InDraft",
                      canBePax1: match !== undefined && pax1MustBeBornBy !== undefined ? !DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(match))) : false
                    };
            }
            var cantAdd = Belt_Array.some(Belt_Option.getWithDefault(c.pendingVoyageSlugs, []), (function (slug) {
                    return Caml_obj.equal(slug, Caml_option.some(voyageSlug));
                  })) ? "Booked" : undefined;
            var match$1 = c.legalDocumentBirthdate;
            return {
                    accountId: c.accountId,
                    name: c.fullDisplayName,
                    cantAdd: cantAdd,
                    canBePax1: match$1 !== undefined && pax1MustBeBornBy !== undefined ? !DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(match$1))) : false
                  };
          })) : [];
  var getFullDisplayName = function (f, l) {
    var match = Belt_Option.map(f, CS_NonemptyStrings$Util.NonEmptyString.toString);
    var match$1 = Belt_Option.map(l, CS_NonemptyStrings$Util.NonEmptyString.toString);
    if (match !== undefined) {
      if (match$1 !== undefined) {
        return match + " " + match$1;
      } else {
        return match;
      }
    } else if (match$1 !== undefined) {
      return match$1;
    } else {
      return "Myself";
    }
  };
  var match$24 = currentAndConnectedQuery.currentUserProfiles;
  var selfOption = match$24 !== undefined ? Belt_Array.keepMap(match$24.nodes, (function (c) {
            if (Belt_Array.some(attachedOrReplaced, (function (acct) {
                      return Caml_obj.equal(acct, c.accountId);
                    }))) {
              var match = c.legalDocumentBirthdate;
              return {
                      accountId: c.accountId,
                      name: getFullDisplayName(c.firstName, c.lastName),
                      cantAdd: "InDraft",
                      canBePax1: match !== undefined && pax1MustBeBornBy !== undefined ? !DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(match))) : false
                    };
            }
            var cantAdd = Belt_Array.some(Belt_Option.getWithDefault(c.pendingVoyageSlugs, []), (function (slug) {
                    return Caml_obj.equal(slug, Caml_option.some(voyageSlug));
                  })) ? "Booked" : undefined;
            var match$1 = c.legalDocumentBirthdate;
            return {
                    accountId: c.accountId,
                    name: getFullDisplayName(c.firstName, c.lastName),
                    cantAdd: cantAdd,
                    canBePax1: match$1 !== undefined && pax1MustBeBornBy !== undefined ? !DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(match$1))) : false
                  };
          })) : [];
  var managedOptions = Belt_Array.concat(selfOption, connectedOptions);
  var shouldDisableManaged = Belt_Array.keep(Belt_Array.keep(managedOptions, (function (o) {
              return Belt_Option.isSome(o.cantAdd);
            })), (function (o) {
          if (isPrimaryPax) {
            return !o.canBePax1;
          } else {
            return true;
          }
        })).length === 0;
  var paxBirthdateShouldError = function (userBirthdate, pax1BirthDate, isPrimaryPax) {
    var match = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(userBirthdate);
    if (isPrimaryPax && match.TAG === "Ok") {
      if (pax1BirthDate !== undefined) {
        return DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1BirthDate)), DateOnly$Util.toDate(match._0));
      } else {
        return false;
      }
    }
    if (match.TAG === "Ok") {
      return false;
    } else {
      return true;
    }
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  JsxRuntime.jsx(DialogTitle, {
                                        children: Caml_option.some(title)
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(AddPassDesktop$Thin.make, {
                                        tab: tab,
                                        setTab: setTab,
                                        getTitle: getTitle,
                                        getDescription: getDescription,
                                        clearAll: clearAll,
                                        onClose: onClose$1,
                                        docFName: docFName,
                                        setDocFName: setDocFName,
                                        docLName: docLName,
                                        setDocLName: setDocLName,
                                        prefFName: prefFName,
                                        setPrefFName: setPrefFName,
                                        prefLName: prefLName,
                                        setPrefLName: setPrefLName,
                                        email: email,
                                        setEmail: setEmail,
                                        dob: dob,
                                        setDob: setDob,
                                        country: country,
                                        setCountry: setCountry,
                                        gender: gender,
                                        setGender: setGender,
                                        connectedAcct: connectedAcct,
                                        setConnectedAcct: setConnectedAcct,
                                        action: action,
                                        idx: idx,
                                        custodianAccountId: custodianAccountId,
                                        loading: loading,
                                        addOrErr: addOrErr,
                                        voyageSlug: voyageSlug,
                                        loyaltyPatch: loyaltyPatch,
                                        setLoyaltyPatch: setLoyaltyPatch,
                                        loyaltyNum: loyaltyNum,
                                        setLoyaltyNum: setLoyaltyNum,
                                        searchErrorType: searchErrorType,
                                        setSearchErrorType: setSearchErrorType,
                                        isPrimaryPax: isPrimaryPax,
                                        pax1MustBeBornBy: pax1MustBeBornBy,
                                        managedOptions: managedOptions,
                                        shouldDisableManaged: shouldDisableManaged,
                                        paxBirthdateShouldError: paxBirthdateShouldError,
                                        queryFrag: queryFrag,
                                        showFillInLater: showFillInLater
                                      })
                                ],
                                open: open_,
                                anchor: desktopAnchor,
                                disablePortal: true,
                                onClose: onClose$1,
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-horizontalSize": "clamp(500px, 45%, 75%)",
                                      borderRadius: "4px",
                                      margin: "8px",
                                      height: "calc(100% - 16px)",
                                      overflow: "auto"
                                    }
                                  }
                                }
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(AddPassMobile$Thin.make, {
                                tab: tab,
                                setTab: setTab,
                                title: getTitle(tab),
                                open_: open_,
                                onClose: onClose$1,
                                clearAll: clearAll,
                                getTitle: getTitle,
                                docFName: docFName,
                                setDocFName: setDocFName,
                                docLName: docLName,
                                setDocLName: setDocLName,
                                prefFName: prefFName,
                                setPrefFName: setPrefFName,
                                prefLName: prefLName,
                                setPrefLName: setPrefLName,
                                email: email,
                                setEmail: setEmail,
                                dob: dob,
                                setDob: setDob,
                                country: country,
                                setCountry: setCountry,
                                gender: gender,
                                setGender: setGender,
                                connectedAcct: connectedAcct,
                                setConnectedAcct: setConnectedAcct,
                                action: action,
                                idx: idx,
                                custodianAccountId: custodianAccountId,
                                loading: loading,
                                loyaltyPatch: loyaltyPatch,
                                setLoyaltyPatch: setLoyaltyPatch,
                                loyaltyNum: loyaltyNum,
                                setLoyaltyNum: setLoyaltyNum,
                                searchErrorType: searchErrorType,
                                setSearchErrorType: setSearchErrorType,
                                voyageSlug: voyageSlug,
                                addOrErr: addOrErr,
                                isPrimaryPax: isPrimaryPax,
                                pax1MustBeBornBy: pax1MustBeBornBy,
                                managedOptions: managedOptions,
                                shouldDisableManaged: shouldDisableManaged,
                                paxBirthdateShouldError: paxBirthdateShouldError,
                                queryFrag: queryFrag,
                                showFillInLater: showFillInLater
                              }))
                    }),
                JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                      error: match$22.error,
                      setError: match$22.setError
                    })
              ]
            });
}

var make = AddPassDrawer;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
