// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var preferredFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredFirstName;
        }),
      write: (function (pax, preferredFirstName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.preferredFirstName = preferredFirstName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var preferredLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredLastName;
        }),
      write: (function (pax, preferredLastName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.preferredLastName = preferredLastName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var docFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docFirstName;
        }),
      write: (function (pax, docFirstName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.docFirstName = docFirstName;
          var tmp;
          if (pax.preferredFirstName.lastTouched === undefined && docFirstName.lastTouched !== undefined) {
            var init = pax.preferredFirstName;
            tmp = {
              initialValue: init.initialValue,
              value: docFirstName.value,
              lastTouched: init.lastTouched
            };
          } else {
            tmp = pax.preferredFirstName;
          }
          newrecord.preferredFirstName = tmp;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docLastName;
        }),
      write: (function (pax, docLastName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.docLastName = docLastName;
          var tmp;
          if (pax.preferredLastName.lastTouched === undefined && docLastName.lastTouched !== undefined) {
            var init = pax.preferredLastName;
            tmp = {
              initialValue: init.initialValue,
              value: docLastName.value,
              lastTouched: init.lastTouched
            };
          } else {
            tmp = pax.preferredLastName;
          }
          newrecord.preferredLastName = tmp;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docBirthdate;
        }),
      write: (function (pax, docBirthdate) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.docBirthdate = docBirthdate;
          return newrecord;
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docCitizenship = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docCitizenship;
        }),
      write: (function (pax, docCitizenship) {
          var newrecord = Caml_obj.obj_dup(pax);
          var tmp;
          if (pax.residenceCountry.value === undefined && pax.residenceCountry.lastTouched === undefined) {
            var init = pax.residenceCountry;
            tmp = {
              initialValue: init.initialValue,
              value: docCitizenship.value,
              lastTouched: init.lastTouched
            };
          } else {
            tmp = pax.residenceCountry;
          }
          newrecord.residenceCountry = tmp;
          newrecord.docCitizenship = docCitizenship;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docGender = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docGender;
        }),
      write: (function (pax, docGender) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.docGender = docGender;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var phone = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.phone;
        }),
      write: (function (pax, phone) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.phone = phone;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var phoneCountryCode = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.phoneCountryCode;
        }),
      write: (function (pax, phoneCountryCode) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.phoneCountryCode = phoneCountryCode;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), "+1", undefined);

var postalCode = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.postalCode;
        }),
      write: (function (pax, postalCode) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.postalCode = postalCode;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.PostalCode.fromPrimitive, CS_NonemptyStrings$Util.PostalCode.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var residenceCountry = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.residenceCountry;
        }),
      write: (function (pax, residenceCountry) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.residenceCountry = residenceCountry;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var email = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.email;
        }),
      write: (function (pax, email) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.email = email;
          return newrecord;
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var acquisitionSlug = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.acquisitionSlug;
        }),
      write: (function (pax, acquisitionSlug) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.acquisitionSlug = acquisitionSlug;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), CS_Slugs$Util.Slug.schema, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var halMarinerNumber = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.halMarinerNumber;
        }),
      write: (function (pax, halMarinerNumber) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.halMarinerNumber = halMarinerNumber;
          return newrecord;
        })
    }, CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive, CS_LoyaltyNumbers$Util.HalMarinerNumber.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var loyalty = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.loyalty;
        }),
      write: (function (pax, loyalty) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.loyalty = loyalty;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive, CS_NonemptyStrings$Util.LoyaltyNumber.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var loyaltyTier = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.loyaltyTier;
        }),
      write: (function (pax, loyaltyTier) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.loyaltyTier = loyaltyTier;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var paxTypeSchema = S$RescriptSchema.transform(S$RescriptSchema.$$int, (function (s) {
        return {
                p: (function (i) {
                    switch (i) {
                      case 0 :
                          return "VerifyBC";
                      case 1 :
                          return "SignupBC";
                      case 2 :
                          return "ProvideInfo";
                      case 3 :
                          return "AddConnected";
                      case 4 :
                          return "AddByLoyalty";
                      case 5 :
                          return "Invite";
                      case 6 :
                          return "TBA";
                      default:
                        return s.fail("Impossible pax type int value", undefined);
                    }
                  }),
                s: (function (t) {
                    switch (t) {
                      case "VerifyBC" :
                          return 0;
                      case "SignupBC" :
                          return 1;
                      case "ProvideInfo" :
                          return 2;
                      case "AddConnected" :
                          return 3;
                      case "AddByLoyalty" :
                          return 4;
                      case "Invite" :
                          return 5;
                      case "TBA" :
                          return 6;
                      
                    }
                  })
              };
      }));

HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.container(function (blankPax) {
                                                                  return S$RescriptSchema.transform(S$RescriptSchema.tuple2(S$RescriptSchema.tuple2(S$RescriptSchema.bool, S$RescriptSchema.$$null(CS_NonemptyStrings$Util.AccountId.schema)), S$RescriptSchema.tuple2(S$RescriptSchema.array(CS_Slugs$Util.VoyageSlug.schema), S$RescriptSchema.$$null(paxTypeSchema))), (function (param) {
                                                                                return {
                                                                                        p: (function (param) {
                                                                                            var match = param[1];
                                                                                            var match$1 = param[0];
                                                                                            var newrecord = Caml_obj.obj_dup(blankPax);
                                                                                            newrecord.paxType = match[1];
                                                                                            newrecord.pendingVoyageSlugs = match[0];
                                                                                            newrecord.accountId = match$1[1];
                                                                                            newrecord.isComplete = match$1[0];
                                                                                            return newrecord;
                                                                                          }),
                                                                                        s: (function (param) {
                                                                                            return [
                                                                                                    [
                                                                                                      param.isComplete,
                                                                                                      param.accountId
                                                                                                    ],
                                                                                                    [
                                                                                                      param.pendingVoyageSlugs,
                                                                                                      param.paxType
                                                                                                    ]
                                                                                                  ];
                                                                                          })
                                                                                      };
                                                                              }));
                                                                }), preferredFirstName), preferredLastName), docFirstName), docLastName), docBirthdate), docCitizenship), docGender), phone), phoneCountryCode), postalCode), residenceCountry), email), acquisitionSlug), halMarinerNumber), loyalty);

function paxTypeView(pax) {
  return HopperState$Util.Observable.makeView(pax, {
              read: (function (param) {
                  return param.paxType;
                }),
              write: (function (pax, paxType) {
                  var newrecord = Caml_obj.obj_dup(pax);
                  newrecord.paxType = paxType;
                  return newrecord;
                })
            }, undefined);
}

function isAgeGated(paxDob, pax1MustBeBornBy, isPrimary) {
  if (paxDob !== undefined && pax1MustBeBornBy !== undefined && isPrimary) {
    return DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(paxDob)));
  } else {
    return false;
  }
}

function allTravelDocFieldsComplete(pax) {
  var match = docFirstName.validate(pax, undefined);
  var match$1 = docLastName.validate(pax, undefined);
  var match$2 = docGender.validate(pax, undefined);
  var match$3 = docCitizenship.validate(pax, undefined);
  var match$4 = docBirthdate.validate(pax, undefined);
  if (match.TAG === "Ok" && match._0 !== undefined && match$1.TAG === "Ok" && match$1._0 !== undefined && match$2.TAG === "Ok" && match$2._0 !== undefined && match$3.TAG === "Ok" && match$3._0 !== undefined && match$4.TAG === "Ok") {
    return match$4._0 !== undefined;
  } else {
    return false;
  }
}

function paxBirthdateShouldError(pax, pax1MustBeBornBy) {
  return Belt_Result.getWithDefault(Belt_Result.map(docBirthdate.validate(pax, undefined), (function (paxDob) {
                    return isAgeGated(paxDob, pax1MustBeBornBy, pax.idx === 1);
                  })), false);
}

function notBookable(pax, voyageSlug) {
  return Belt_Array.some(pax.pendingVoyageSlugs, (function (slug) {
                return Caml_obj.equal(slug, voyageSlug);
              }));
}

function genderInputToText(g) {
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

function blankPax(idx) {
  return {
          idx: idx,
          isComplete: false,
          accountId: undefined,
          pendingVoyageSlugs: [],
          updateOtherVoyages: [],
          paxType: undefined,
          preferredFirstName: preferredFirstName.blankStore,
          preferredLastName: preferredLastName.blankStore,
          docFirstName: docFirstName.blankStore,
          docLastName: docLastName.blankStore,
          docBirthdate: docBirthdate.blankStore,
          docCitizenship: docCitizenship.blankStore,
          docGender: docGender.blankStore,
          phone: phone.blankStore,
          phoneCountryCode: phoneCountryCode.blankStore,
          postalCode: postalCode.blankStore,
          residenceCountry: residenceCountry.blankStore,
          email: email.blankStore,
          acquisitionSlug: acquisitionSlug.blankStore,
          halMarinerNumber: halMarinerNumber.blankStore,
          loyalty: loyalty.blankStore,
          loyaltyTier: loyaltyTier.blankStore,
          eligibleVoyageSlugs: [],
          resendVerifyMutation: "NotAsked"
        };
}

var paxUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.container(function (blankPax) {
                                                                      return S$RescriptSchema.transform(S$RescriptSchema.tuple2(S$RescriptSchema.tuple2(S$RescriptSchema.bool, S$RescriptSchema.$$null(CS_NonemptyStrings$Util.AccountId.schema)), S$RescriptSchema.tuple2(S$RescriptSchema.array(CS_Slugs$Util.VoyageSlug.schema), S$RescriptSchema.$$null(paxTypeSchema))), (function (param) {
                                                                                    return {
                                                                                            p: (function (param) {
                                                                                                var match = param[1];
                                                                                                var match$1 = param[0];
                                                                                                var newrecord = Caml_obj.obj_dup(blankPax);
                                                                                                newrecord.paxType = match[1];
                                                                                                newrecord.pendingVoyageSlugs = match[0];
                                                                                                newrecord.accountId = match$1[1];
                                                                                                newrecord.isComplete = match$1[0];
                                                                                                return newrecord;
                                                                                              }),
                                                                                            s: (function (param) {
                                                                                                return [
                                                                                                        [
                                                                                                          param.isComplete,
                                                                                                          param.accountId
                                                                                                        ],
                                                                                                        [
                                                                                                          param.pendingVoyageSlugs,
                                                                                                          param.paxType
                                                                                                        ]
                                                                                                      ];
                                                                                              })
                                                                                          };
                                                                                  }));
                                                                    }), preferredFirstName), preferredLastName), docFirstName), docLastName), docBirthdate), docCitizenship), docGender), phone), phoneCountryCode), postalCode), residenceCountry), email), acquisitionSlug), halMarinerNumber), loyalty), loyaltyTier);

function validatePhone(pax) {
  var match = phone.validate(pax, undefined);
  var match$1 = phoneCountryCode.validate(pax, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var number = match._0;
  if (number !== undefined && match$1.TAG === "Ok") {
    return Belt_Result.map(CS_Phone$Util.Phone.fromPrimitive(match$1._0 + " " + number), (function (v) {
                  return Caml_option.some(v);
                }));
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function validateUpdateAccount(pax, f) {
  var match = pax.updateOtherVoyages.length;
  var _updateOtherVoyages = match !== 0 ? Belt_Array.map(pax.updateOtherVoyages, (function (v) {
            return Caml_option.some(v);
          })) : undefined;
  var match$1 = pax.accountId;
  var match$2 = docGender.validate(pax, undefined);
  var match$3 = docCitizenship.validate(pax, undefined);
  var match$4 = preferredFirstName.validate(pax, undefined);
  var match$5 = preferredLastName.validate(pax, undefined);
  var match$6 = validatePhone(pax);
  var match$7 = postalCode.validate(pax, undefined);
  var match$8 = residenceCountry.validate(pax, undefined);
  var match$9 = pax.halMarinerNumber.value;
  switch (f) {
    case "Personal" :
        if (match$1 === undefined) {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$4.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$5.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$6.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$7.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$8.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        var _halMarinerNumber;
        if (match$9 !== undefined && match$9 !== "") {
          var v = CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(match$9);
          _halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
        } else {
          _halMarinerNumber = undefined;
        }
        return {
                TAG: "Ok",
                _0: {
                  _accountId: Caml_option.valFromOption(match$1),
                  _updateProfile: true,
                  _updateOtherVoyages: _updateOtherVoyages,
                  _preferredFirstName: match$4._0,
                  _preferredLastName: match$5._0,
                  _phone: match$6._0,
                  _residenceCountry: match$8._0,
                  _postalCode: match$7._0,
                  _halMarinerNumber: _halMarinerNumber
                }
              };
    case "Travel" :
        if (match$1 !== undefined && match$2.TAG === "Ok" && match$3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    _accountId: Caml_option.valFromOption(match$1),
                    _updateProfile: true,
                    _updateOtherVoyages: _updateOtherVoyages,
                    _docGender: match$2._0,
                    _docCitizenship: match$3._0
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
    case "All" :
        if (match$1 === undefined) {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$2.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$3.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$4.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$5.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$6.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$7.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        if (match$8.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: "Please fill out all required fields"
                };
        }
        var _halMarinerNumber$1;
        if (match$9 !== undefined && match$9 !== "") {
          var v$1 = CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(match$9);
          _halMarinerNumber$1 = v$1.TAG === "Ok" ? Caml_option.some(v$1._0) : undefined;
        } else {
          _halMarinerNumber$1 = undefined;
        }
        return {
                TAG: "Ok",
                _0: {
                  _accountId: Caml_option.valFromOption(match$1),
                  _updateProfile: true,
                  _updateOtherVoyages: _updateOtherVoyages,
                  _preferredFirstName: match$4._0,
                  _preferredLastName: match$5._0,
                  _phone: match$6._0,
                  _residenceCountry: match$8._0,
                  _postalCode: match$7._0,
                  _halMarinerNumber: _halMarinerNumber$1,
                  _docGender: match$2._0,
                  _docCitizenship: match$3._0
                }
              };
    
  }
}

exports.preferredFirstName = preferredFirstName;
exports.preferredLastName = preferredLastName;
exports.docFirstName = docFirstName;
exports.docLastName = docLastName;
exports.docBirthdate = docBirthdate;
exports.docCitizenship = docCitizenship;
exports.docGender = docGender;
exports.phone = phone;
exports.phoneCountryCode = phoneCountryCode;
exports.postalCode = postalCode;
exports.residenceCountry = residenceCountry;
exports.email = email;
exports.acquisitionSlug = acquisitionSlug;
exports.halMarinerNumber = halMarinerNumber;
exports.loyalty = loyalty;
exports.loyaltyTier = loyaltyTier;
exports.paxTypeSchema = paxTypeSchema;
exports.paxTypeView = paxTypeView;
exports.allTravelDocFieldsComplete = allTravelDocFieldsComplete;
exports.isAgeGated = isAgeGated;
exports.paxBirthdateShouldError = paxBirthdateShouldError;
exports.notBookable = notBookable;
exports.genderInputToText = genderInputToText;
exports.blankPax = blankPax;
exports.paxUtils = paxUtils;
exports.validatePhone = validatePhone;
exports.validateUpdateAccount = validateUpdateAccount;
/* preferredFirstName Not a pure module */
