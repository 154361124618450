// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../context/AppConfig.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var Divider = require("@mui/joy/Divider").default;
var CurrentUser$Thin = require("../../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var ChangeEmailForm$Thin = require("./ChangeEmailForm.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ChangePasswordForm$Thin = require("./ChangePasswordForm.bs.js");
var CopyableWithTooltip$Thin = require("../common/atoms/CopyableWithTooltip.bs.js");
var KeyOutlined = require("@mui/icons-material/KeyOutlined").default;
var AccountAccessNewQuery_graphql$Thin = require("../../__generated__/AccountAccessNewQuery_graphql.bs.js");
var AccountAccessSection_query_graphql$Thin = require("../../__generated__/AccountAccessSection_query_graphql.bs.js");
var AccountAccessSection_ResendVerifyMutation_graphql$Thin = require("../../__generated__/AccountAccessSection_ResendVerifyMutation_graphql.bs.js");

var convertFragment = AccountAccessSection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountAccessSection_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AccountAccessSection_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = AccountAccessNewQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = AccountAccessNewQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertVariables = AccountAccessSection_ResendVerifyMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = AccountAccessSection_ResendVerifyMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = AccountAccessSection_ResendVerifyMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, AccountAccessSection_ResendVerifyMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables, AccountAccessSection_ResendVerifyMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var Mutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$1
};

function useMutationWithNotify(notify) {
  var actual = RemoteData$Util.Relay.useMutationWithNotify(use$1, (function (response) {
          var match = response.resendEmailVerification;
          if (match === undefined) {
            return {
                    TAG: "Failure",
                    _0: "We couldn't resend your invitation, please write to us if this problem persists"
                  };
          }
          var match$1 = match.boolean;
          if (match$1 !== undefined && match$1) {
            return {
                    TAG: "Success",
                    _0: Date.now()
                  };
          } else {
            return {
                    TAG: "Failure",
                    _0: "We couldn't resend your invitation, please write to us if this problem persists"
                  };
          }
        }), notify);
  return function (param) {
    actual({
          input: {}
        }, undefined, undefined, undefined, undefined);
  };
}

function resendVerifyIsLoading(resendVerifyMutation) {
  if (typeof resendVerifyMutation !== "object") {
    if (resendVerifyMutation === "NotAsked") {
      return false;
    } else {
      return true;
    }
  } else if (resendVerifyMutation.TAG === "Success") {
    return resendVerifyMutation._0 + 30 * 1000 > Date.now();
  } else {
    return false;
  }
}

function verifyResendError(resendVerifyMutation) {
  if (typeof resendVerifyMutation !== "object" || resendVerifyMutation.TAG !== "Failure") {
    return ;
  } else {
    return Caml_option.some(resendVerifyMutation._0);
  }
}

var ResendVerify = {
  Mutation: Mutation,
  useMutationWithNotify: useMutationWithNotify,
  resendVerifyIsLoading: resendVerifyIsLoading,
  verifyResendError: verifyResendError
};

function AccountAccessSection$DetailRow(props) {
  var __copyable = props.copyable;
  var value = props.value;
  var label = props.label;
  var copyable = __copyable !== undefined ? __copyable : false;
  return JsxRuntime.jsxs(Joy$Util.Grid.make, {
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                fontWeight: "700",
                                children: Caml_option.some(label)
                              })),
                      xs: 6,
                      sm: 4,
                      md: 3
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        children: Caml_option.some(value)
                                      }),
                                  copyable ? JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                                          value: value,
                                          label: label
                                        }) : null
                                ]
                              })),
                      xs: 6,
                      sm: 8,
                      md: 9
                    })
              ],
              container: true,
              rowSpacing: 2,
              columnSpacing: 2,
              sx: {
                py: 2
              }
            });
}

var DetailRow = {
  make: AccountAccessSection$DetailRow
};

function AccountAccessSection(props) {
  var accountState = props.accountState;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setEmailFormOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPasswordFormOpen = match$2[1];
  var refresh = function (param) {
    return refetch(undefined, "store-and-network", undefined);
  };
  var match$3 = CurrentUser$Thin.Context.use();
  var userContext = match$3.userContext;
  var match$4 = AppConfig$Thin.Context.use();
  var getVoyageName = match$4.voyages.getVoyageName;
  var brandFamilyName = match$4.brandFamily.brandFamilyName;
  var match$5 = HopperState$Util.Observable.useComputed(accountState, (function (acc) {
          return [
                  acc.account.loyalty,
                  acc.account.loyaltyTier,
                  acc.account.eligibleVoyageSlugs
                ];
        }), undefined, undefined);
  var eligibleVoyages = Belt_Array.map(match$5[2], (function (slug) {
          return Belt_Option.getWithDefault(getVoyageName(slug), CS_Slugs$Util.VoyageSlug.toString(slug));
        }));
  var updatedEmailMessage = function () {
    var data$1 = data.currentUserEmail;
    return Merge$Util.render(Txt$Thin.Account.emailVerify, {
                userEmail: data$1 !== undefined ? data$1 : userContext.userEmail
              });
  };
  var match$6 = React.useState(function () {
        return "NotAsked";
      });
  var setResendMutation = match$6[1];
  var resendMutation = match$6[0];
  var resendVerify = useMutationWithNotify(function (mut) {
        setResendMutation(function (param) {
              return mut;
            });
      });
  var resendVerifyIsLoading$1 = resendVerifyIsLoading(resendMutation);
  var verifyResendError$1 = verifyResendError(resendMutation);
  var match$7 = data.currentUserSignonStatus;
  var tmp;
  tmp = (match$7 === "VERIFY_EMAIL" || match$7 === "INVITED" || match$7 === "PENDING_2FA" || match$7 === "CLEAR_JWT" || match$7 === "ANONYMOUS" || match$7 === "SIGNED_ON") && match$7 === "VERIFY_EMAIL" ? JsxRuntime.jsx(AppAlert$Thin.make, {
          message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  updatedEmailMessage(),
                  "\u00A0",
                  JsxRuntime.jsx(Joy$Util.Link.make, {
                        children: Belt_Option.getWithDefault(verifyResendError$1, resendVerifyIsLoading$1 ? " Resending..." : " Click here to resend your verification email."),
                        level: "body-sm",
                        component: "button",
                        onClick: (function (param) {
                            if (resendVerifyIsLoading$1) {
                              return ;
                            } else {
                              return resendVerify();
                            }
                          }),
                        sx: {
                          textDecoration: "underline",
                          "&:hover": {
                            cursor: "pointer"
                          },
                          color: "text.tertiary"
                        }
                      })
                ]
              }),
          type_: "warning",
          size: "sm"
        }) : null;
  var emailHelperModule = JsxRuntime.jsxs(Stack, {
        direction: "column",
        spacing: 1,
        children: [
          JsxRuntime.jsx(Joy$Util.Link.make, {
                children: Txt$Thin.Account.changeEmail.value,
                level: "body-sm",
                component: "button",
                onClick: (function (param) {
                    setEmailFormOpen(function (param) {
                          return true;
                        });
                  }),
                sx: {
                  textDecoration: "underline",
                  "&:hover": {
                    cursor: "pointer"
                  },
                  color: "text.tertiary"
                }
              }),
          tmp
        ]
      });
  var email = data.currentUserEmail;
  var number = match$5[0].value;
  var tier = match$5[1].value;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: "Account details"
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(email !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                    children: [
                                                      JsxRuntime.jsx(FormLabel, {
                                                            children: Caml_option.some(Txt$Thin.Account.emailLabel.value)
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Input.make, {
                                                            type_: "email",
                                                            fullWidth: true,
                                                            disabled: true,
                                                            value: email
                                                          }),
                                                      JsxRuntime.jsx(FormHelperText, {
                                                            children: Caml_option.some(emailHelperModule)
                                                          })
                                                    ]
                                                  })),
                                          xs: 12,
                                          md: 6
                                        }),
                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                    children: [
                                                      JsxRuntime.jsx(FormLabel, {
                                                            children: Caml_option.some(Txt$Thin.Account.passwordLabel.value)
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Input.make, {
                                                            type_: "password",
                                                            fullWidth: true,
                                                            disabled: true,
                                                            value: "*********"
                                                          }),
                                                      JsxRuntime.jsx(FormHelperText, {
                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                      children: Txt$Thin.Account.changePassword.value,
                                                                      level: "body-sm",
                                                                      component: "button",
                                                                      onClick: (function (param) {
                                                                          setPasswordFormOpen(function (param) {
                                                                                return true;
                                                                              });
                                                                        }),
                                                                      sx: {
                                                                        textDecoration: "underline",
                                                                        "&:hover": {
                                                                          cursor: "pointer"
                                                                        },
                                                                        color: "text.tertiary"
                                                                      }
                                                                    }))
                                                          })
                                                    ]
                                                  })),
                                          xs: 12,
                                          md: 6
                                        })
                                  ]
                                }) : JsxRuntime.jsx("div", {
                                  children: Txt$Thin.Account.noEmail.value
                                })),
                      container: true,
                      spacing: {
                        TAG: "Fixed",
                        _0: 1
                      },
                      sx: {
                        width: "100%"
                      }
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      sx: {
                        pt: 3
                      },
                      children: [
                        JsxRuntime.jsx(Divider, {}),
                        number !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                children: Caml_option.some(JsxRuntime.jsx(AccountAccessSection$DetailRow, {
                                          label: brandFamilyName + " Loyalty Number",
                                          value: number,
                                          copyable: true
                                        }))
                              }) : null,
                        tier !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(AccountAccessSection$DetailRow, {
                                        label: brandFamilyName + " Loyalty Tier",
                                        value: tier
                                      })
                                ]
                              }) : null,
                        eligibleVoyages.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(AccountAccessSection$DetailRow, {
                                        label: brandFamilyName + " Voyage History",
                                        value: eligibleVoyages.join(", ")
                                      })
                                ]
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(ChangeEmailForm$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setEmailFormOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      updatedEmailMessage: updatedEmailMessage,
                      accountState: accountState
                    }),
                JsxRuntime.jsx(ChangePasswordForm$Thin.make, {
                      open_: match$2[0],
                      onClose: (function () {
                          setPasswordFormOpen(function (param) {
                                return false;
                              });
                        }),
                      accountState: accountState
                    })
              ]
            });
}

var make = AccountAccessSection;

exports.QueryFragment = QueryFragment;
exports.ResendVerify = ResendVerify;
exports.DetailRow = DetailRow;
exports.make = make;
/* commitMutation Not a pure module */
