// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var AppAlert$Thin = require("../atoms/AppAlert.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thin = require("../../../utils/booking/DraftUtils.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PasswordInput$Thin = require("../molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var VoyageMiniCard$Thin = require("../molecules/VoyageMiniCard.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PhoneNumberInput$Thin = require("../molecules/PhoneNumberInput.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var Cached = require("@mui/icons-material/Cached").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var ArticleOutlined = require("@mui/icons-material/ArticleOutlined").default;
var UpdateAccountData_query_graphql$Thin = require("../../../__generated__/UpdateAccountData_query_graphql.bs.js");
var UpdateAccountData_passenger_graphql$Thin = require("../../../__generated__/UpdateAccountData_passenger_graphql.bs.js");
var UpdateAccountData_acquisitionslugs_graphql$Thin = require("../../../__generated__/UpdateAccountData_acquisitionslugs_graphql.bs.js");
var UpdateAccountData_UpdateAccountDataMutation_graphql$Thin = require("../../../__generated__/UpdateAccountData_UpdateAccountDataMutation_graphql.bs.js");

var convertVariables = UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var UpdateAccountDataMutation_docGender_decode = UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode;

var UpdateAccountDataMutation_docGender_fromString = UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_fromString;

var UpdateAccountDataMutation = {
  docGender_decode: UpdateAccountDataMutation_docGender_decode,
  docGender_fromString: UpdateAccountDataMutation_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function convertUserAccount(u) {
  if (u.TAG === "User") {
    var data = u._0;
    return {
            accountId: data.accountId,
            accountStatus: data.accountStatus,
            docFirstName: data.legalDocumentFirstName,
            docLastName: data.legalDocumentLastName,
            docBirthdate: data.legalDocumentBirthdate,
            docGender: data.legalDocumentGender,
            docCitizenship: data.legalDocumentCitizenship,
            preferredFirstName: data.firstName,
            preferredLastName: data.lastName,
            postalCode: data.postalCode,
            phone: data.phone,
            residenceCountry: data.residenceCountry,
            acquisitionSlug: data.custAcquisitionSlug,
            loyaltyNumber: data.loyaltyNumber,
            loyaltyTier: data.currentTierTitle,
            numLoyaltyVoyages: Belt_Option.getWithDefault(data.numEligibleVoyages, 0),
            loyaltyVoyages: Belt_Array.concat(Belt_Array.keepMap(data.loyalty_eligible_voyage.nodes, (function (v) {
                        var match = v.voyage;
                        if (match !== undefined) {
                          return {
                                  voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                  isPending: false
                                };
                        }
                        
                      })), Belt_Array.keepMap(data.loyalty_pending_voyage.nodes, (function (v) {
                        var match = v.voyage;
                        if (match !== undefined) {
                          return {
                                  voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                  isPending: true
                                };
                        }
                        
                      })))
          };
  }
  var data$1 = u._0;
  return {
          accountId: data$1.accountId,
          accountStatus: data$1.accountStatus,
          docFirstName: data$1.legalDocumentFirstName,
          docLastName: data$1.legalDocumentLastName,
          docBirthdate: data$1.legalDocumentBirthdate,
          docGender: data$1.legalDocumentGender,
          docCitizenship: data$1.legalDocumentCitizenship,
          preferredFirstName: Caml_option.some(data$1.firstName),
          preferredLastName: data$1.lastName,
          postalCode: data$1.postalCode,
          phone: data$1.phone,
          residenceCountry: data$1.residenceCountry,
          acquisitionSlug: data$1.custAcquisitionSlug,
          loyaltyNumber: data$1.loyaltyNumber,
          loyaltyTier: data$1.currentTierTitle,
          numLoyaltyVoyages: Belt_Option.getWithDefault(data$1.numEligibleVoyages, 0),
          loyaltyVoyages: Belt_Array.concat(Belt_Array.keepMap(data$1.loyalty_eligible_voyage.nodes, (function (v) {
                      var match = v.voyage;
                      if (match !== undefined) {
                        return {
                                voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                isPending: false
                              };
                      }
                      
                    })), Belt_Array.keepMap(data$1.loyalty_pending_voyage.nodes, (function (v) {
                      var match = v.voyage;
                      if (match !== undefined) {
                        return {
                                voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                isPending: true
                              };
                      }
                      
                    })))
        };
}

function areTravelDocInputsEqual(a, b) {
  if (Caml_obj.equal(a.docGender, b.docGender)) {
    return Caml_obj.equal(a.docCitizenship, b.docCitizenship);
  } else {
    return false;
  }
}

function arePersonalInfoInputsEqual(a, b) {
  if (Caml_obj.equal(a.preferredFirstName, b.preferredFirstName) && Caml_obj.equal(a.preferredLastName, b.preferredLastName) && Caml_obj.equal(a.phone, b.phone) && a.phoneCountryCode === b.phoneCountryCode && Caml_obj.equal(a.postalCode, b.postalCode)) {
    return Caml_obj.equal(a.residenceCountry, b.residenceCountry);
  } else {
    return false;
  }
}

function areUserAccountUpdatesEqual(a, b) {
  if (areTravelDocInputsEqual(a, b)) {
    return arePersonalInfoInputsEqual(a, b);
  } else {
    return false;
  }
}

function convertUserAccountToUpdate(userAccount) {
  var p = Belt_Option.map(userAccount.phone, CS_Phone$Util.Phone.meta);
  var phoneCountryCode = p !== undefined ? Belt_Option.getWithDefault(p.callingCode, "+1") : "+1";
  var p$1 = userAccount.phone;
  var phone;
  if (p$1 !== undefined) {
    var match = CS_Phone$Util.Phone.toString(Caml_option.valFromOption(p$1)).split(" ");
    var len = match.length;
    if (len >= 3) {
      phone = undefined;
    } else {
      switch (len) {
        case 0 :
        case 1 :
            phone = undefined;
            break;
        case 2 :
            phone = match[1];
            break;
        
      }
    }
  } else {
    phone = undefined;
  }
  return {
          accountId: userAccount.accountId,
          preferredFirstName: Belt_Option.map(userAccount.preferredFirstName, (function (s) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(s);
                })),
          preferredLastName: Belt_Option.map(userAccount.preferredLastName, (function (s) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(s);
                })),
          phone: phone,
          phoneCountryCode: phoneCountryCode,
          postalCode: Belt_Option.map(userAccount.postalCode, (function (s) {
                  return CS_NonemptyStrings$Util.PostalCode.toPrimitive(s);
                })),
          residenceCountry: userAccount.residenceCountry,
          docGender: userAccount.docGender,
          docCitizenship: userAccount.docCitizenship,
          updateOtherVoyages: []
        };
}

function convertUpdateToMutationInput(a, t) {
  var _docCitizenship = a.docCitizenship;
  var docGender = a.docGender;
  var _residenceCountry = a.residenceCountry;
  var postalCode = a.postalCode;
  var phone = a.phone;
  var preferredLastName = a.preferredLastName;
  var preferredFirstName = a.preferredFirstName;
  var _updateOtherVoyages = Belt_Array.map(a.updateOtherVoyages, (function (v) {
          return Caml_option.some(v);
        }));
  if (Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(Belt_Option.getWithDefault(preferredFirstName, "123"))) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(Belt_Option.getWithDefault(preferredLastName, "123"))) || t === "User" && Belt_Result.isError(CS_Phone$Util.Phone.fromPrimitive(Belt_Option.getWithDefault(phone, "123"))) || Belt_Result.isError(CS_NonemptyStrings$Util.PostalCode.fromPrimitive(Belt_Option.getWithDefault(postalCode, "123"))) || Belt_Option.isNone(_residenceCountry) || Belt_Option.isNone(docGender) || Belt_Option.isNone(_docCitizenship)) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  var _preferredFirstName = Belt_Option.map(preferredFirstName, (function (s) {
          return CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(s);
        }));
  var _preferredLastName = Belt_Option.map(preferredLastName, (function (s) {
          return CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(s);
        }));
  var _phone = Belt_Option.map(phone, (function (s) {
          return CS_Phone$Util.PhoneImpl.Exn.fromPrimitive(s);
        }));
  var _postalCode = Belt_Option.map(postalCode, (function (s) {
          return CS_NonemptyStrings$Util.PostalCode.Exn.fromPrimitive(s);
        }));
  var _docGender = Belt_Option.map(docGender, (function (g) {
          return Belt_Option.getWithDefault(UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode(g), "X");
        }));
  return {
          TAG: "Ok",
          _0: {
            _accountId: a.accountId,
            _updateProfile: true,
            _updateOtherVoyages: _updateOtherVoyages,
            _preferredFirstName: _preferredFirstName,
            _preferredLastName: _preferredLastName,
            _phone: _phone,
            _residenceCountry: _residenceCountry,
            _postalCode: _postalCode,
            _docGender: _docGender,
            _docCitizenship: _docCitizenship
          }
        };
}

var Utils_usCCDefault = {
  country: "United States",
  alpha2: "US",
  alpha3: "USA",
  numeric: "840",
  emoji: "🇺🇸",
  callingCode: "+1"
};

var Utils = {
  usCCDefault: Utils_usCCDefault,
  convertUserAccount: convertUserAccount,
  areTravelDocInputsEqual: areTravelDocInputsEqual,
  arePersonalInfoInputsEqual: arePersonalInfoInputsEqual,
  areUserAccountUpdatesEqual: areUserAccountUpdatesEqual,
  convertUserAccountToUpdate: convertUserAccountToUpdate,
  convertUpdateToMutationInput: convertUpdateToMutationInput
};

function useMutation(onSuccess) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var patchFromBooking = function (paxPatch, currentVoyage) {
    if (Belt_Option.isNone(paxPatch.pDocCitizenship) && Belt_Option.isNone(paxPatch.pDocGender)) {
      return ;
    }
    setError(function (param) {
          
        });
    var updateOtherVoyages = paxPatch.updateOtherVoyages;
    var _docGender = Belt_Option.map(paxPatch.pDocGender, (function (g) {
            return Belt_Option.getWithDefault(UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode(g), "X");
          }));
    var _updateOtherVoyages = updateOtherVoyages.length === 0 ? [Caml_option.some(currentVoyage)] : Belt_Array.concat([Caml_option.some(currentVoyage)], Belt_Array.map(updateOtherVoyages, (function (v) {
                  return Caml_option.some(v);
                })));
    mutate({
          input: {
            _accountId: paxPatch.accountId,
            _updateProfile: paxPatch.updateUserAccount,
            _updateOtherVoyages: _updateOtherVoyages,
            _docGender: _docGender,
            _docCitizenship: paxPatch.pDocCitizenship
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              return ;
            }
            var match = resp.updateAccountData;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                onSuccess();
                return ;
              }
              
            }
            setError(function (param) {
                  return "Failed to update account data";
                });
          }), (function (error) {
            console.log(error);
            setError(function (param) {
                  return "Error updating account data";
                });
          }), undefined);
  };
  var updateUserAccount = function (i) {
    setError(function (param) {
          
        });
    mutate({
          input: i
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              return ;
            }
            var match = resp.updateAccountData;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                onSuccess();
                return ;
              }
              
            }
            setError(function (param) {
                  return "Failed to update account data";
                });
          }), (function (error) {
            console.log(error);
            setError(function (param) {
                  return "Error updating account data";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          patchFromBooking: patchFromBooking,
          updateUserAccount: updateUserAccount
        };
}

var convertFragment = UpdateAccountData_query_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(UpdateAccountData_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpdateAccountData_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(UpdateAccountData_query_graphql$Thin.node, convertFragment, fRef);
}

var CountryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = UpdateAccountData_passenger_graphql$Thin.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(UpdateAccountData_passenger_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpdateAccountData_passenger_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(UpdateAccountData_passenger_graphql$Thin.node, convertFragment$1, fRef);
}

var PassengerFragment_lifecycleStatus_decode = UpdateAccountData_passenger_graphql$Thin.Utils.lifecycleStatus_decode;

var PassengerFragment_lifecycleStatus_fromString = UpdateAccountData_passenger_graphql$Thin.Utils.lifecycleStatus_fromString;

var PassengerFragment = {
  lifecycleStatus_decode: PassengerFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: PassengerFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertFragment$2 = UpdateAccountData_acquisitionslugs_graphql$Thin.Internal.convertFragment;

function use$3(fRef) {
  return RescriptRelay_Fragment.useFragment(UpdateAccountData_acquisitionslugs_graphql$Thin.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpdateAccountData_acquisitionslugs_graphql$Thin.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(UpdateAccountData_acquisitionslugs_graphql$Thin.node, convertFragment$2, fRef);
}

var AcquisitionSlugFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$3,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function UpdateAccountData$Form$FormWrapper(props) {
  var showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var subtitle = props.subtitle;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var tmp;
  tmp = variant === "Neutral" ? "transparent" : "neutral.200";
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 0 : 2;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        mb: showTitleSection ? 2 : 0
                      },
                      children: [
                        showTitleSection ? JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(props.startDecorator),
                                level: props.titleSize === "md" ? "title-md" : "h4",
                                component: "h2",
                                children: Caml_option.some(props.title)
                              }) : null,
                        subtitle !== undefined ? JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  py: 1,
                                  color: "neutral.500"
                                },
                                children: Caml_option.some(subtitle)
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      sx: {
                        backgroundColor: tmp,
                        p: tmp$1,
                        borderRadius: "4px"
                      },
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var FormWrapper = {
  make: UpdateAccountData$Form$FormWrapper
};

function UpdateAccountData$Form$Travel(props) {
  var forceValidate = props.forceValidate;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var __showTitleSection = props.showTitleSection;
  var disabled = props.disabled;
  var __variant = props.variant;
  var userAccount = props.userAccount;
  var __allFieldsEditable = props.allFieldsEditable;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Travel Document";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var allFieldsEditable = __allFieldsEditable !== undefined ? __allFieldsEditable : false;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var countryData = use$1(props.fragmentRefs);
  var match = countryData.countries;
  var countryOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  var tmp;
  tmp = variant === "Neutral" ? 1 : 2;
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsx(UpdateAccountData$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(ArticleOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsxs(Joy$Util.Grid.make, {
                    children: [
                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                      state: [
                                        update,
                                        setUpdate
                                      ],
                                      get: (function (update) {
                                          if (allFieldsEditable) {
                                            if (update.TAG === "Account") {
                                              if (userAccount !== undefined) {
                                                return Belt_Option.getWithDefault(Belt_Option.map(userAccount.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString), "");
                                              } else {
                                                return "";
                                              }
                                            } else {
                                              return Belt_Option.getWithDefault(update._0.docFirstName, "");
                                            }
                                          } else if (userAccount !== undefined) {
                                            return Belt_Option.getWithDefault(Belt_Option.map(userAccount.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString), "");
                                          } else {
                                            return "";
                                          }
                                        }),
                                      set: (function (update, val) {
                                          if (update.TAG === "Account") {
                                            return update;
                                          }
                                          var pax = update._0;
                                          return {
                                                  TAG: "Pax",
                                                  _0: {
                                                    accountId: pax.accountId,
                                                    isComplete: pax.isComplete,
                                                    isBookable: pax.isBookable,
                                                    preferredFirstName: pax.preferredFirstName,
                                                    preferredLastName: pax.preferredLastName,
                                                    docFirstName: val,
                                                    docLastName: pax.docLastName,
                                                    docBirthdate: pax.docBirthdate,
                                                    phone: pax.phone,
                                                    phoneCountryCode: pax.phoneCountryCode,
                                                    postalCode: pax.postalCode,
                                                    residenceCountry: pax.residenceCountry,
                                                    docGender: pax.docGender,
                                                    docCitizenship: pax.docCitizenship,
                                                    email: pax.email,
                                                    loyalty: pax.loyalty,
                                                    acquisitionSlug: pax.acquisitionSlug
                                                  }
                                                };
                                        }),
                                      forceValidate: forceValidate,
                                      isValid: (function (v) {
                                          if (allFieldsEditable) {
                                            return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                          } else {
                                            return true;
                                          }
                                        }),
                                      children: (function (param) {
                                          var tmp;
                                          tmp = variant === "Neutral" ? "outlined" : "plain";
                                          return JsxRuntime.jsxs(FormControl, {
                                                      error: param.error,
                                                      children: [
                                                        JsxRuntime.jsx(FormLabel, {
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsDocFirstName.value)
                                                            }),
                                                        JsxRuntime.jsx(Joy$Util.Input.make, {
                                                              type_: "text",
                                                              placeholder: Txt$Thin.BookingDetails.addPassDetailsDocFirstName.value,
                                                              disabled: !allFieldsEditable || disabled,
                                                              variant: tmp,
                                                              value: param.get,
                                                              onChange: param.onChange.withCurrentTarget
                                                            }),
                                                        allFieldsEditable ? null : JsxRuntime.jsx(FormHelperText, {
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "body-sm",
                                                                          children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                                                                        }))
                                                              })
                                                      ]
                                                    });
                                        })
                                    })),
                            xs: 12,
                            md: 6
                          }),
                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                      state: [
                                        update,
                                        setUpdate
                                      ],
                                      get: (function (update) {
                                          if (allFieldsEditable) {
                                            if (update.TAG === "Account") {
                                              if (userAccount !== undefined) {
                                                return Belt_Option.getWithDefault(Belt_Option.map(userAccount.docLastName, CS_NonemptyStrings$Util.NonEmptyString.toString), "");
                                              } else {
                                                return "";
                                              }
                                            } else {
                                              return Belt_Option.getWithDefault(update._0.docLastName, "");
                                            }
                                          } else if (userAccount !== undefined) {
                                            return Belt_Option.getWithDefault(Belt_Option.map(userAccount.docLastName, CS_NonemptyStrings$Util.NonEmptyString.toString), "");
                                          } else {
                                            return "";
                                          }
                                        }),
                                      set: (function (update, val) {
                                          if (update.TAG === "Account") {
                                            return update;
                                          }
                                          var pax = update._0;
                                          return {
                                                  TAG: "Pax",
                                                  _0: {
                                                    accountId: pax.accountId,
                                                    isComplete: pax.isComplete,
                                                    isBookable: pax.isBookable,
                                                    preferredFirstName: pax.preferredFirstName,
                                                    preferredLastName: pax.preferredLastName,
                                                    docFirstName: pax.docFirstName,
                                                    docLastName: val,
                                                    docBirthdate: pax.docBirthdate,
                                                    phone: pax.phone,
                                                    phoneCountryCode: pax.phoneCountryCode,
                                                    postalCode: pax.postalCode,
                                                    residenceCountry: pax.residenceCountry,
                                                    docGender: pax.docGender,
                                                    docCitizenship: pax.docCitizenship,
                                                    email: pax.email,
                                                    loyalty: pax.loyalty,
                                                    acquisitionSlug: pax.acquisitionSlug
                                                  }
                                                };
                                        }),
                                      forceValidate: forceValidate,
                                      isValid: (function (v) {
                                          if (allFieldsEditable) {
                                            return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                          } else {
                                            return true;
                                          }
                                        }),
                                      children: (function (param) {
                                          var tmp;
                                          tmp = variant === "Neutral" ? "outlined" : "plain";
                                          return JsxRuntime.jsxs(FormControl, {
                                                      error: param.error,
                                                      children: [
                                                        JsxRuntime.jsx(FormLabel, {
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsDocLastName.value)
                                                            }),
                                                        JsxRuntime.jsx(Joy$Util.Input.make, {
                                                              type_: "text",
                                                              placeholder: Txt$Thin.BookingDetails.addPassDetailsDocLastName.value,
                                                              disabled: !allFieldsEditable || disabled,
                                                              variant: tmp,
                                                              value: param.get,
                                                              onChange: param.onChange.withCurrentTarget
                                                            }),
                                                        allFieldsEditable ? null : JsxRuntime.jsx(FormHelperText, {
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "body-sm",
                                                                          children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                                                                        }))
                                                              })
                                                      ]
                                                    });
                                        })
                                    })),
                            xs: 12,
                            md: 6
                          }),
                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                      state: [
                                        update,
                                        setUpdate
                                      ],
                                      get: (function (update) {
                                          if (update.TAG === "Account") {
                                            return update._0.docGender;
                                          } else {
                                            return update._0.docGender;
                                          }
                                        }),
                                      set: (function (update, val) {
                                          if (update.TAG === "Account") {
                                            var account = update._0;
                                            return {
                                                    TAG: "Account",
                                                    _0: {
                                                      accountId: account.accountId,
                                                      preferredFirstName: account.preferredFirstName,
                                                      preferredLastName: account.preferredLastName,
                                                      phone: account.phone,
                                                      phoneCountryCode: account.phoneCountryCode,
                                                      postalCode: account.postalCode,
                                                      residenceCountry: account.residenceCountry,
                                                      docGender: val,
                                                      docCitizenship: account.docCitizenship,
                                                      updateOtherVoyages: account.updateOtherVoyages
                                                    }
                                                  };
                                          }
                                          var pax = update._0;
                                          return {
                                                  TAG: "Pax",
                                                  _0: {
                                                    accountId: pax.accountId,
                                                    isComplete: pax.isComplete,
                                                    isBookable: pax.isBookable,
                                                    preferredFirstName: pax.preferredFirstName,
                                                    preferredLastName: pax.preferredLastName,
                                                    docFirstName: pax.docFirstName,
                                                    docLastName: pax.docLastName,
                                                    docBirthdate: pax.docBirthdate,
                                                    phone: pax.phone,
                                                    phoneCountryCode: pax.phoneCountryCode,
                                                    postalCode: pax.postalCode,
                                                    residenceCountry: pax.residenceCountry,
                                                    docGender: val,
                                                    docCitizenship: pax.docCitizenship,
                                                    email: pax.email,
                                                    loyalty: pax.loyalty,
                                                    acquisitionSlug: pax.acquisitionSlug
                                                  }
                                                };
                                        }),
                                      forceValidate: forceValidate,
                                      isValid: Belt_Option.isSome,
                                      children: (function (param) {
                                          var tmp;
                                          tmp = variant === "Neutral" ? "outlined" : "plain";
                                          return JsxRuntime.jsxs(FormControl, {
                                                      error: param.error,
                                                      children: [
                                                        JsxRuntime.jsx(FormLabel, {
                                                              children: "Gender on travel document"
                                                            }),
                                                        JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                              children: Caml_option.some(Belt_Array.mapWithIndex(DraftUtils$Thin.Pax.genderOptions, (function (i, g) {
                                                                          return JsxRuntime.jsx($$Option, {
                                                                                      value: g,
                                                                                      children: Caml_option.some(DraftUtils$Thin.Pax.genderToText(g))
                                                                                    }, String(i));
                                                                        }))),
                                                              disabled: disabled,
                                                              onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                              placeholder: Txt$Thin.BookingDetails.addPassDetailsDocGender.value,
                                                              slotProps: {
                                                                listbox: {
                                                                  sx: {
                                                                    zIndex: "modal"
                                                                  }
                                                                }
                                                              },
                                                              value: param.get,
                                                              variant: tmp
                                                            })
                                                      ]
                                                    });
                                        })
                                    })),
                            xs: 12,
                            md: 6
                          }),
                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                      state: [
                                        update,
                                        setUpdate
                                      ],
                                      get: (function (update) {
                                          if (update.TAG === "Account") {
                                            return update._0.docCitizenship;
                                          } else {
                                            return update._0.docCitizenship;
                                          }
                                        }),
                                      set: (function (update, val) {
                                          if (update.TAG === "Account") {
                                            var account = update._0;
                                            return {
                                                    TAG: "Account",
                                                    _0: {
                                                      accountId: account.accountId,
                                                      preferredFirstName: account.preferredFirstName,
                                                      preferredLastName: account.preferredLastName,
                                                      phone: account.phone,
                                                      phoneCountryCode: account.phoneCountryCode,
                                                      postalCode: account.postalCode,
                                                      residenceCountry: account.residenceCountry,
                                                      docGender: account.docGender,
                                                      docCitizenship: val,
                                                      updateOtherVoyages: account.updateOtherVoyages
                                                    }
                                                  };
                                          }
                                          var pax = update._0;
                                          return {
                                                  TAG: "Pax",
                                                  _0: {
                                                    accountId: pax.accountId,
                                                    isComplete: pax.isComplete,
                                                    isBookable: pax.isBookable,
                                                    preferredFirstName: pax.preferredFirstName,
                                                    preferredLastName: pax.preferredLastName,
                                                    docFirstName: pax.docFirstName,
                                                    docLastName: pax.docLastName,
                                                    docBirthdate: pax.docBirthdate,
                                                    phone: pax.phone,
                                                    phoneCountryCode: pax.phoneCountryCode,
                                                    postalCode: pax.postalCode,
                                                    residenceCountry: pax.residenceCountry,
                                                    docGender: pax.docGender,
                                                    docCitizenship: val,
                                                    email: pax.email,
                                                    loyalty: pax.loyalty,
                                                    acquisitionSlug: pax.acquisitionSlug
                                                  }
                                                };
                                        }),
                                      forceValidate: forceValidate,
                                      isValid: Belt_Option.isSome,
                                      children: (function (param) {
                                          var tmp;
                                          tmp = variant === "Neutral" ? "outlined" : "plain";
                                          return JsxRuntime.jsxs(FormControl, {
                                                      error: param.error,
                                                      children: [
                                                        JsxRuntime.jsx(FormLabel, {
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsCountry.value)
                                                            }),
                                                        JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                              children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                                          var emoji = option.emoji;
                                                                          return JsxRuntime.jsxs($$Option, {
                                                                                      value: option.alpha3,
                                                                                      children: [
                                                                                        emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                                children: Caml_option.some(emoji)
                                                                                              }) : null,
                                                                                        JsxRuntime.jsxs(ListItemContent, {
                                                                                              children: [
                                                                                                option.country,
                                                                                                JsxRuntime.jsx(Typography, {
                                                                                                      level: "body-xs",
                                                                                                      children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                                    })
                                                                                              ],
                                                                                              sx: {
                                                                                                fontSize: "sm"
                                                                                              }
                                                                                            })
                                                                                      ],
                                                                                      label: option.alpha3,
                                                                                      sx: {
                                                                                        maxWidth: "calc(100vw - 32px)",
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis"
                                                                                      }
                                                                                    }, option.alpha3);
                                                                        }))),
                                                              disabled: disabled,
                                                              onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                              placeholder: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                              slotProps: {
                                                                listbox: {
                                                                  sx: {
                                                                    zIndex: "modal"
                                                                  }
                                                                }
                                                              },
                                                              sx: {
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                              },
                                                              value: param.get,
                                                              variant: tmp
                                                            })
                                                      ]
                                                    });
                                        })
                                    })),
                            xs: 12,
                            md: 6
                          }),
                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                      state: [
                                        update,
                                        setUpdate
                                      ],
                                      get: (function (update) {
                                          if (allFieldsEditable) {
                                            if (update.TAG === "Account") {
                                              return "";
                                            } else {
                                              return Belt_Option.getWithDefault(Belt_Option.map(update._0.docBirthdate, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive), "");
                                            }
                                          } else if (userAccount !== undefined) {
                                            return Belt_Option.getWithDefault(Belt_Option.map(userAccount.docBirthdate, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive), "");
                                          } else {
                                            return "";
                                          }
                                        }),
                                      set: (function (update, val) {
                                          var d = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(val);
                                          if (d.TAG !== "Ok") {
                                            return update;
                                          }
                                          if (update.TAG === "Account") {
                                            return update;
                                          }
                                          var pax = update._0;
                                          return {
                                                  TAG: "Pax",
                                                  _0: {
                                                    accountId: pax.accountId,
                                                    isComplete: pax.isComplete,
                                                    isBookable: pax.isBookable,
                                                    preferredFirstName: pax.preferredFirstName,
                                                    preferredLastName: pax.preferredLastName,
                                                    docFirstName: pax.docFirstName,
                                                    docLastName: pax.docLastName,
                                                    docBirthdate: Caml_option.some(d._0),
                                                    phone: pax.phone,
                                                    phoneCountryCode: pax.phoneCountryCode,
                                                    postalCode: pax.postalCode,
                                                    residenceCountry: pax.residenceCountry,
                                                    docGender: pax.docGender,
                                                    docCitizenship: pax.docCitizenship,
                                                    email: pax.email,
                                                    loyalty: pax.loyalty,
                                                    acquisitionSlug: pax.acquisitionSlug
                                                  }
                                                };
                                        }),
                                      forceValidate: forceValidate,
                                      isValid: (function (v) {
                                          if (!allFieldsEditable) {
                                            return true;
                                          }
                                          var match = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(v);
                                          if (match.TAG === "Ok" && pax1MustBeBornBy !== undefined) {
                                            return !DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(match._0));
                                          } else {
                                            return false;
                                          }
                                        }),
                                      children: (function (param) {
                                          var tmp;
                                          tmp = allFieldsEditable && !(update.TAG === "Account" || !Belt_Option.isNone(update._0.docBirthdate)) ? "text" : "date";
                                          return JsxRuntime.jsxs(FormControl, {
                                                      error: param.error,
                                                      children: [
                                                        JsxRuntime.jsx(FormLabel, {
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsBirthdate.value)
                                                            }),
                                                        JsxRuntime.jsx(Joy$Util.Input.make, {
                                                              type_: tmp,
                                                              placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                              disabled: !allFieldsEditable || disabled,
                                                              variant: "outlined",
                                                              value: param.get,
                                                              onChange: param.onChange.withCurrentTarget
                                                            }),
                                                        allFieldsEditable ? (
                                                            Belt_Option.isSome(pax1MustBeBornBy) ? JsxRuntime.jsx(FormHelperText, {
                                                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                              level: "body-xs",
                                                                              children: "Primary passengers must be at least 21 at time of sailing."
                                                                            }))
                                                                  }) : null
                                                          ) : JsxRuntime.jsx(FormHelperText, {
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          color: "inherit",
                                                                          level: "body-sm",
                                                                          children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                                                                        }))
                                                              })
                                                      ]
                                                    });
                                        })
                                    })),
                            xs: 12,
                            md: 6
                          })
                    ],
                    container: true,
                    spacing: {
                      TAG: "Fixed",
                      _0: 1
                    },
                    rowSpacing: tmp,
                    columnSpacing: tmp$1
                  })
            });
}

var Travel = {
  make: UpdateAccountData$Form$Travel
};

function UpdateAccountData$Form$PersonalFields(props) {
  var forceValidate = props.forceValidate;
  var __noHelperText = props.noHelperText;
  var disabled = props.disabled;
  var variant = props.variant;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var acquisitionOptions = props.acquisitionOptions;
  var countryOptions = props.countryOptions;
  var noHelperText = __noHelperText !== undefined ? __noHelperText : false;
  var onChangeDebounceTimerRef = React.useRef(null);
  React.useEffect((function () {
          Belt_Option.forEach(Caml_option.nullable_to_opt(onChangeDebounceTimerRef.current), (function (prim) {
                  clearTimeout(prim);
                }));
        }), []);
  var tmp;
  tmp = props.accountType === "User" ? JsxRuntime.jsx(Joy$Util.Grid.make, {
          children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                    state: [
                      update,
                      setUpdate
                    ],
                    get: (function (update) {
                        if (update.TAG === "Account") {
                          var match = update._0;
                          return [
                                  match.phoneCountryCode,
                                  match.phone
                                ];
                        }
                        var match$1 = update._0;
                        return [
                                match$1.phoneCountryCode,
                                match$1.phone
                              ];
                      }),
                    set: (function (update, param) {
                        var phone = param[1];
                        var phoneCountryCode = param[0];
                        if (update.TAG === "Account") {
                          var account = update._0;
                          return {
                                  TAG: "Account",
                                  _0: {
                                    accountId: account.accountId,
                                    preferredFirstName: account.preferredFirstName,
                                    preferredLastName: account.preferredLastName,
                                    phone: phone,
                                    phoneCountryCode: phoneCountryCode,
                                    postalCode: account.postalCode,
                                    residenceCountry: account.residenceCountry,
                                    docGender: account.docGender,
                                    docCitizenship: account.docCitizenship,
                                    updateOtherVoyages: account.updateOtherVoyages
                                  }
                                };
                        }
                        var pax = update._0;
                        return {
                                TAG: "Pax",
                                _0: {
                                  accountId: pax.accountId,
                                  isComplete: pax.isComplete,
                                  isBookable: pax.isBookable,
                                  preferredFirstName: pax.preferredFirstName,
                                  preferredLastName: pax.preferredLastName,
                                  docFirstName: pax.docFirstName,
                                  docLastName: pax.docLastName,
                                  docBirthdate: pax.docBirthdate,
                                  phone: phone,
                                  phoneCountryCode: phoneCountryCode,
                                  postalCode: pax.postalCode,
                                  residenceCountry: pax.residenceCountry,
                                  docGender: pax.docGender,
                                  docCitizenship: pax.docCitizenship,
                                  email: pax.email,
                                  loyalty: pax.loyalty,
                                  acquisitionSlug: pax.acquisitionSlug
                                }
                              };
                      }),
                    forceValidate: forceValidate,
                    isValid: (function (param) {
                        return true;
                      }),
                    children: (function (param) {
                        var set = param.set;
                        var match = param.get;
                        return JsxRuntime.jsx(JsxRuntime.Fragment, {
                                    children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Phone Number"
                                                    }),
                                                JsxRuntime.jsx(PhoneNumberInput$Thin.make, {
                                                      countryOptions: Belt_Array.map(countryOptions, (function (v) {
                                                              return v;
                                                            })),
                                                      countryCode: match[0],
                                                      phoneNumber: match[1],
                                                      onUpdateCountryCode: (function (phoneCountryCode) {
                                                          set(function (param) {
                                                                return [
                                                                        phoneCountryCode,
                                                                        param[1]
                                                                      ];
                                                              });
                                                        }),
                                                      onUpdatePhoneNumber: (function (phone) {
                                                          set(function (param) {
                                                                return [
                                                                        param[0],
                                                                        phone
                                                                      ];
                                                              });
                                                        }),
                                                      disabled: disabled
                                                    }),
                                                JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                children: ""
                                                              }))
                                                    })
                                              ]
                                            }))
                                  });
                      })
                  })),
          xs: 12,
          md: 6
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    if (update.TAG === "Account") {
                                      return Belt_Option.getWithDefault(update._0.preferredFirstName, "");
                                    } else {
                                      return Belt_Option.getWithDefault(update._0.preferredFirstName, "");
                                    }
                                  }),
                                set: (function (update, val) {
                                    if (update.TAG === "Account") {
                                      var account = update._0;
                                      return {
                                              TAG: "Account",
                                              _0: {
                                                accountId: account.accountId,
                                                preferredFirstName: val,
                                                preferredLastName: account.preferredLastName,
                                                phone: account.phone,
                                                phoneCountryCode: account.phoneCountryCode,
                                                postalCode: account.postalCode,
                                                residenceCountry: account.residenceCountry,
                                                docGender: account.docGender,
                                                docCitizenship: account.docCitizenship,
                                                updateOtherVoyages: account.updateOtherVoyages
                                              }
                                            };
                                    }
                                    var pax = update._0;
                                    return {
                                            TAG: "Pax",
                                            _0: {
                                              accountId: pax.accountId,
                                              isComplete: pax.isComplete,
                                              isBookable: pax.isBookable,
                                              preferredFirstName: val,
                                              preferredLastName: pax.preferredLastName,
                                              docFirstName: pax.docFirstName,
                                              docLastName: pax.docLastName,
                                              docBirthdate: pax.docBirthdate,
                                              phone: pax.phone,
                                              phoneCountryCode: pax.phoneCountryCode,
                                              postalCode: pax.postalCode,
                                              residenceCountry: pax.residenceCountry,
                                              docGender: pax.docGender,
                                              docCitizenship: pax.docCitizenship,
                                              email: pax.email,
                                              loyalty: pax.loyalty,
                                              acquisitionSlug: pax.acquisitionSlug
                                            }
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Preferred First Name"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      }),
                                                  noHelperText ? null : JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    children: "Will display in the platform"
                                                                  }))
                                                        })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    if (update.TAG === "Account") {
                                      return Belt_Option.getWithDefault(update._0.preferredLastName, "");
                                    } else {
                                      return Belt_Option.getWithDefault(update._0.preferredLastName, "");
                                    }
                                  }),
                                set: (function (update, val) {
                                    if (update.TAG === "Account") {
                                      var account = update._0;
                                      return {
                                              TAG: "Account",
                                              _0: {
                                                accountId: account.accountId,
                                                preferredFirstName: account.preferredFirstName,
                                                preferredLastName: val,
                                                phone: account.phone,
                                                phoneCountryCode: account.phoneCountryCode,
                                                postalCode: account.postalCode,
                                                residenceCountry: account.residenceCountry,
                                                docGender: account.docGender,
                                                docCitizenship: account.docCitizenship,
                                                updateOtherVoyages: account.updateOtherVoyages
                                              }
                                            };
                                    }
                                    var pax = update._0;
                                    return {
                                            TAG: "Pax",
                                            _0: {
                                              accountId: pax.accountId,
                                              isComplete: pax.isComplete,
                                              isBookable: pax.isBookable,
                                              preferredFirstName: pax.preferredFirstName,
                                              preferredLastName: val,
                                              docFirstName: pax.docFirstName,
                                              docLastName: pax.docLastName,
                                              docBirthdate: pax.docBirthdate,
                                              phone: pax.phone,
                                              phoneCountryCode: pax.phoneCountryCode,
                                              postalCode: pax.postalCode,
                                              residenceCountry: pax.residenceCountry,
                                              docGender: pax.docGender,
                                              docCitizenship: pax.docCitizenship,
                                              email: pax.email,
                                              loyalty: pax.loyalty,
                                              acquisitionSlug: pax.acquisitionSlug
                                            }
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Preferred Last Name"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      }),
                                                  noHelperText ? null : JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    children: "Will display in the platform"
                                                                  }))
                                                        })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                tmp,
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    if (update.TAG === "Account") {
                                      return Belt_Option.getWithDefault(update._0.postalCode, "");
                                    } else {
                                      return Belt_Option.getWithDefault(update._0.postalCode, "");
                                    }
                                  }),
                                set: (function (update, val) {
                                    if (update.TAG === "Account") {
                                      var account = update._0;
                                      return {
                                              TAG: "Account",
                                              _0: {
                                                accountId: account.accountId,
                                                preferredFirstName: account.preferredFirstName,
                                                preferredLastName: account.preferredLastName,
                                                phone: account.phone,
                                                phoneCountryCode: account.phoneCountryCode,
                                                postalCode: val,
                                                residenceCountry: account.residenceCountry,
                                                docGender: account.docGender,
                                                docCitizenship: account.docCitizenship,
                                                updateOtherVoyages: account.updateOtherVoyages
                                              }
                                            };
                                    }
                                    var pax = update._0;
                                    return {
                                            TAG: "Pax",
                                            _0: {
                                              accountId: pax.accountId,
                                              isComplete: pax.isComplete,
                                              isBookable: pax.isBookable,
                                              preferredFirstName: pax.preferredFirstName,
                                              preferredLastName: pax.preferredLastName,
                                              docFirstName: pax.docFirstName,
                                              docLastName: pax.docLastName,
                                              docBirthdate: pax.docBirthdate,
                                              phone: pax.phone,
                                              phoneCountryCode: pax.phoneCountryCode,
                                              postalCode: val,
                                              residenceCountry: pax.residenceCountry,
                                              docGender: pax.docGender,
                                              docCitizenship: pax.docCitizenship,
                                              email: pax.email,
                                              loyalty: pax.loyalty,
                                              acquisitionSlug: pax.acquisitionSlug
                                            }
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    return Belt_Result.isOk(CS_NonemptyStrings$Util.PostalCode.fromPrimitive(v));
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Postal Code"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "Postal Code",
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget,
                                                        pattern: "^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$"
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    if (update.TAG === "Account") {
                                      return update._0.residenceCountry;
                                    } else {
                                      return update._0.residenceCountry;
                                    }
                                  }),
                                set: (function (update, val) {
                                    if (update.TAG === "Account") {
                                      var account = update._0;
                                      return {
                                              TAG: "Account",
                                              _0: {
                                                accountId: account.accountId,
                                                preferredFirstName: account.preferredFirstName,
                                                preferredLastName: account.preferredLastName,
                                                phone: account.phone,
                                                phoneCountryCode: account.phoneCountryCode,
                                                postalCode: account.postalCode,
                                                residenceCountry: val,
                                                docGender: account.docGender,
                                                docCitizenship: account.docCitizenship,
                                                updateOtherVoyages: account.updateOtherVoyages
                                              }
                                            };
                                    }
                                    var pax = update._0;
                                    return {
                                            TAG: "Pax",
                                            _0: {
                                              accountId: pax.accountId,
                                              isComplete: pax.isComplete,
                                              isBookable: pax.isBookable,
                                              preferredFirstName: pax.preferredFirstName,
                                              preferredLastName: pax.preferredLastName,
                                              docFirstName: pax.docFirstName,
                                              docLastName: pax.docLastName,
                                              docBirthdate: pax.docBirthdate,
                                              phone: pax.phone,
                                              phoneCountryCode: pax.phoneCountryCode,
                                              postalCode: pax.postalCode,
                                              residenceCountry: val,
                                              docGender: pax.docGender,
                                              docCitizenship: pax.docCitizenship,
                                              email: pax.email,
                                              loyalty: pax.loyalty,
                                              acquisitionSlug: pax.acquisitionSlug
                                            }
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: Belt_Option.isSome,
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Country of Residence"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                        children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                                    var emoji = option.emoji;
                                                                    return JsxRuntime.jsxs($$Option, {
                                                                                value: option.alpha3,
                                                                                children: [
                                                                                  emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                          children: Caml_option.some(emoji)
                                                                                        }) : null,
                                                                                  JsxRuntime.jsxs(ListItemContent, {
                                                                                        children: [
                                                                                          option.country,
                                                                                          JsxRuntime.jsx(Typography, {
                                                                                                level: "body-xs",
                                                                                                children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                              })
                                                                                        ],
                                                                                        sx: {
                                                                                          fontSize: "sm"
                                                                                        }
                                                                                      })
                                                                                ],
                                                                                label: option.alpha3,
                                                                                sx: {
                                                                                  maxWidth: "calc(100vw - 32px)",
                                                                                  overflow: "hidden",
                                                                                  textOverflow: "ellipsis"
                                                                                }
                                                                              }, option.alpha3);
                                                                  }))),
                                                        disabled: disabled,
                                                        onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                        placeholder: "Country of Residence",
                                                        slotProps: {
                                                          listbox: {
                                                            sx: {
                                                              zIndex: "modal"
                                                            }
                                                          }
                                                        },
                                                        value: param.get,
                                                        variant: tmp
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    if (update.TAG === "Account") {
                                      return ;
                                    } else {
                                      return Caml_option.some(update._0.acquisitionSlug);
                                    }
                                  }),
                                set: (function (update, val) {
                                    if (val === undefined) {
                                      return update;
                                    }
                                    if (update.TAG === "Account") {
                                      return update;
                                    }
                                    var pax = update._0;
                                    return {
                                            TAG: "Pax",
                                            _0: {
                                              accountId: pax.accountId,
                                              isComplete: pax.isComplete,
                                              isBookable: pax.isBookable,
                                              preferredFirstName: pax.preferredFirstName,
                                              preferredLastName: pax.preferredLastName,
                                              docFirstName: pax.docFirstName,
                                              docLastName: pax.docLastName,
                                              docBirthdate: pax.docBirthdate,
                                              phone: pax.phone,
                                              phoneCountryCode: pax.phoneCountryCode,
                                              postalCode: pax.postalCode,
                                              residenceCountry: pax.residenceCountry,
                                              docGender: pax.docGender,
                                              docCitizenship: pax.docCitizenship,
                                              email: pax.email,
                                              loyalty: pax.loyalty,
                                              acquisitionSlug: Caml_option.valFromOption(val)
                                            }
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (v !== undefined) {
                                      return Caml_option.valFromOption(v) !== undefined;
                                    } else {
                                      return false;
                                    }
                                  }),
                                children: (function (param) {
                                    var value = param.get;
                                    if (value === undefined) {
                                      return null;
                                    }
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "How did you find out about us?"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                        children: Caml_option.some(Belt_Array.map(acquisitionOptions, (function (param) {
                                                                    var desc = param[1];
                                                                    var slug = param[0];
                                                                    return JsxRuntime.jsx($$Option, {
                                                                                value: Caml_option.some(slug),
                                                                                children: Caml_option.some(JsxRuntime.jsx(ListItemContent, {
                                                                                          children: Caml_option.some(desc),
                                                                                          sx: {
                                                                                            fontSize: "sm"
                                                                                          }
                                                                                        })),
                                                                                label: desc,
                                                                                sx: {
                                                                                  maxWidth: "calc(100vw - 32px)",
                                                                                  overflow: "hidden",
                                                                                  textOverflow: "ellipsis"
                                                                                }
                                                                              }, CS_Slugs$Util.Slug.toString(slug));
                                                                  }))),
                                                        disabled: disabled,
                                                        onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                        placeholder: "Select an option",
                                                        slotProps: {
                                                          listbox: {
                                                            sx: {
                                                              zIndex: "modal"
                                                            }
                                                          }
                                                        },
                                                        value: value,
                                                        variant: tmp
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var PersonalFields = {
  make: UpdateAccountData$Form$PersonalFields
};

function UpdateAccountData$Form$Personal(props) {
  var __noHelperText = props.noHelperText;
  var __variant = props.variant;
  var __accountType = props.accountType;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var fragmentRefs = props.fragmentRefs;
  var title = __title !== undefined ? __title : "User Profile";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var accountType = __accountType !== undefined ? __accountType : "User";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var noHelperText = __noHelperText !== undefined ? __noHelperText : false;
  var countryData = use$1(fragmentRefs);
  var match = countryData.countries;
  var countryOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  var acquisitionData = use$3(fragmentRefs);
  var match$1 = acquisitionData.brandFamilyAcquisitionChannels;
  var acquisitionOptions = match$1 !== undefined ? Belt_Array.map(match$1.nodes, (function (n) {
            return [
                    n.custAcquisitionSlug,
                    CS_NonemptyStrings$Util.NonEmptyString.toString(n.descriptiveName)
                  ];
          })) : [];
  var tmp;
  tmp = variant === "Neutral" ? 1 : 2;
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsx(UpdateAccountData$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsx(Joy$Util.Grid.make, {
                    children: Caml_option.some(JsxRuntime.jsx(UpdateAccountData$Form$PersonalFields, {
                              countryOptions: countryOptions,
                              acquisitionOptions: acquisitionOptions,
                              update: props.update,
                              setUpdate: props.setUpdate,
                              variant: variant,
                              accountType: accountType,
                              disabled: props.disabled,
                              noHelperText: noHelperText,
                              forceValidate: props.forceValidate
                            })),
                    container: true,
                    spacing: {
                      TAG: "Fixed",
                      _0: 1
                    },
                    rowSpacing: tmp,
                    columnSpacing: tmp$1
                  })
            });
}

var Personal = {
  make: UpdateAccountData$Form$Personal
};

function UpdateAccountData$Form$SignUp(props) {
  var forceValidate = props.forceValidate;
  var __blockSignUp = props.blockSignUp;
  var signUpError = props.signUpError;
  var __noHelperText = props.noHelperText;
  var disabled = props.disabled;
  var __variant = props.variant;
  var __accountType = props.accountType;
  var __signedUp = props.signedUp;
  var onSubmit = props.onSubmit;
  var confirmPassword = props.confirmPassword;
  var password = props.password;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var fragmentRefs = props.fragmentRefs;
  var title = __title !== undefined ? __title : "Sign Up";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var signedUp = __signedUp !== undefined ? __signedUp : false;
  var accountType = __accountType !== undefined ? __accountType : "User";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var noHelperText = __noHelperText !== undefined ? __noHelperText : false;
  var blockSignUp = __blockSignUp !== undefined ? __blockSignUp : false;
  var countryData = use$1(fragmentRefs);
  var match = countryData.countries;
  var countryOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  var acquisitionData = use$3(fragmentRefs);
  var match$1 = acquisitionData.brandFamilyAcquisitionChannels;
  var acquisitionOptions = match$1 !== undefined ? Belt_Array.map(match$1.nodes, (function (n) {
            return [
                    n.custAcquisitionSlug,
                    CS_NonemptyStrings$Util.NonEmptyString.toString(n.descriptiveName)
                  ];
          })) : [];
  var onSubmit$1 = function (e) {
    e.preventDefault();
    onSubmit();
  };
  var tmp;
  if (update.TAG === "Account") {
    tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  "To update your email and password, please visit the ",
                  JsxRuntime.jsx(Joy$Util.Link.make, {
                        children: "account page",
                        href: SysCtx$Thin.vars.accountLink
                      }),
                  "."
                ]
              }),
          type_: "warning"
        });
  } else {
    var tmp$1;
    tmp$1 = variant === "Neutral" ? 1 : 2;
    var tmp$2;
    tmp$2 = variant === "Neutral" ? 1 : 2;
    tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                    children: [
                      JsxRuntime.jsx(Typography, {
                            level: "title-md",
                            sx: {
                              pb: 2
                            },
                            children: "Account Access"
                          }),
                      JsxRuntime.jsxs(Joy$Util.Grid.make, {
                            children: [
                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                    children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                              state: [
                                                update,
                                                setUpdate
                                              ],
                                              get: (function (update) {
                                                  if (update.TAG === "Account") {
                                                    return "";
                                                  } else {
                                                    return Belt_Option.getWithDefault(update._0.email, "");
                                                  }
                                                }),
                                              set: (function (update, val) {
                                                  if (update.TAG === "Account") {
                                                    return update;
                                                  }
                                                  var pax = update._0;
                                                  return {
                                                          TAG: "Pax",
                                                          _0: {
                                                            accountId: pax.accountId,
                                                            isComplete: pax.isComplete,
                                                            isBookable: pax.isBookable,
                                                            preferredFirstName: pax.preferredFirstName,
                                                            preferredLastName: pax.preferredLastName,
                                                            docFirstName: pax.docFirstName,
                                                            docLastName: pax.docLastName,
                                                            docBirthdate: pax.docBirthdate,
                                                            phone: pax.phone,
                                                            phoneCountryCode: pax.phoneCountryCode,
                                                            postalCode: pax.postalCode,
                                                            residenceCountry: pax.residenceCountry,
                                                            docGender: pax.docGender,
                                                            docCitizenship: pax.docCitizenship,
                                                            email: val,
                                                            loyalty: pax.loyalty,
                                                            acquisitionSlug: pax.acquisitionSlug
                                                          }
                                                        };
                                                }),
                                              forceValidate: forceValidate,
                                              isValid: (function (v) {
                                                  return Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(v));
                                                }),
                                              children: (function (param) {
                                                  var tmp;
                                                  tmp = variant === "Neutral" ? "outlined" : "plain";
                                                  return JsxRuntime.jsxs(FormControl, {
                                                              error: param.error,
                                                              children: [
                                                                JsxRuntime.jsx(FormLabel, {
                                                                      children: "Email"
                                                                    }),
                                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                      type_: "text",
                                                                      placeholder: "Email",
                                                                      disabled: disabled,
                                                                      variant: tmp,
                                                                      value: param.get,
                                                                      onChange: param.onChange.withCurrentTarget
                                                                    })
                                                              ]
                                                            });
                                                })
                                            })),
                                    xs: 12,
                                    md: 6
                                  }),
                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                    sx: {
                                      display: {
                                        xs: "none",
                                        md: "block"
                                      }
                                    },
                                    md: 6
                                  }),
                              signedUp ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                        state: [
                                                          password,
                                                          props.setPassword
                                                        ],
                                                        get: (function (password) {
                                                            return password;
                                                          }),
                                                        set: (function (param, val) {
                                                            return val;
                                                          }),
                                                        forceValidate: forceValidate,
                                                        isValid: (function (v) {
                                                            return Common$Thin.isPasswordValid(v);
                                                          }),
                                                        children: (function (param) {
                                                            return JsxRuntime.jsxs(FormControl, {
                                                                        error: param.error,
                                                                        children: [
                                                                          JsxRuntime.jsx(FormLabel, {
                                                                                children: "Password"
                                                                              }),
                                                                          JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                placeholder: "Password",
                                                                                disabled: disabled,
                                                                                value: param.get,
                                                                                onChange: param.onChange.withCurrentTarget,
                                                                                autoComplete: "new-password"
                                                                              }),
                                                                          JsxRuntime.jsx(FormHelperText, {
                                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                          level: "body-xs",
                                                                                          children: Caml_option.some("Must be at least " + String(Common$Thin.passwordLength) + " characters")
                                                                                        }))
                                                                              })
                                                                        ]
                                                                      });
                                                          })
                                                      })),
                                              xs: 12,
                                              md: 6
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                        state: [
                                                          confirmPassword,
                                                          props.setConfirmPassword
                                                        ],
                                                        get: (function (confirmPassword) {
                                                            return confirmPassword;
                                                          }),
                                                        set: (function (param, val) {
                                                            return val;
                                                          }),
                                                        forceValidate: forceValidate,
                                                        isValid: (function (v) {
                                                            return Common$Thin.isPasswordValid(v);
                                                          }),
                                                        children: (function (param) {
                                                            return JsxRuntime.jsxs(FormControl, {
                                                                        error: param.error,
                                                                        children: [
                                                                          JsxRuntime.jsx(FormLabel, {
                                                                                children: "Confirm Password"
                                                                              }),
                                                                          JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                placeholder: "Confirm Password",
                                                                                disabled: disabled,
                                                                                value: param.get,
                                                                                onChange: param.onChange.withCurrentTarget,
                                                                                autoComplete: "new-password"
                                                                              })
                                                                        ]
                                                                      });
                                                          })
                                                      })),
                                              xs: 12,
                                              md: 6
                                            })
                                      ]
                                    })
                            ],
                            container: true,
                            spacing: {
                              TAG: "Fixed",
                              _0: 1
                            },
                            rowSpacing: tmp$1,
                            columnSpacing: tmp$2
                          })
                    ]
                  }))
        });
  }
  var tmp$3;
  tmp$3 = variant === "Neutral" ? 1 : 2;
  var tmp$4;
  tmp$4 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsx(UpdateAccountData$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    component: "form",
                    spacing: 3,
                    onSubmit: onSubmit$1,
                    children: [
                      tmp,
                      JsxRuntime.jsxs(Stack, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    sx: {
                                      pb: 2
                                    },
                                    children: "Personal Information"
                                  }),
                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                    children: Caml_option.some(JsxRuntime.jsx(UpdateAccountData$Form$PersonalFields, {
                                              countryOptions: countryOptions,
                                              acquisitionOptions: acquisitionOptions,
                                              update: update,
                                              setUpdate: setUpdate,
                                              variant: variant,
                                              accountType: accountType,
                                              disabled: disabled,
                                              noHelperText: noHelperText,
                                              forceValidate: forceValidate
                                            })),
                                    container: true,
                                    spacing: {
                                      TAG: "Fixed",
                                      _0: 1
                                    },
                                    rowSpacing: tmp$3,
                                    columnSpacing: tmp$4
                                  })
                            ]
                          }),
                      signedUp ? null : JsxRuntime.jsxs(Stack, {
                              spacing: 2,
                              sx: {
                                width: "fit-content"
                              },
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      sx: {
                                        color: "danger.400"
                                      },
                                      children: Caml_option.some(password !== confirmPassword ? "Passwords don't match" : (
                                              Common$Thin.isPasswordValid(password) ? null : "Password must be at least 10 characters"
                                            ))
                                    }),
                                JsxRuntime.jsx(Button, {
                                      type: "submit",
                                      endDecorator: Caml_option.some(JsxRuntime.jsx(Cached, {
                                                sx: {
                                                  transform: "rotate(15deg)"
                                                }
                                              })),
                                      size: "lg",
                                      disabled: disabled || blockSignUp,
                                      children: "Create Account"
                                    })
                              ]
                            }),
                      signUpError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: JsxRuntime.jsx(JsxRuntime.Fragment, {
                                    children: Caml_option.some(signUpError)
                                  }),
                              type_: "error"
                            }) : null
                    ]
                  })
            });
}

var SignUp = {
  make: UpdateAccountData$Form$SignUp
};

function UpdateAccountData$Form$Voyages(props) {
  var setUpdate = props.setUpdate;
  var update = props.update;
  var setShowVoyageOptions = props.setShowVoyageOptions;
  var showVoyageOptions = props.showVoyageOptions;
  var voyagesData = use$2(props.fragmentRefs);
  var voyOpt = Belt_Array.keepMap(voyagesData.nodes, (function (param) {
          var bookings = param.bookings;
          if (bookings === undefined) {
            return ;
          }
          var match = bookings.bookingListitem;
          if (match === undefined) {
            return ;
          }
          var match$1 = match.voyage;
          if (match$1 === undefined) {
            return ;
          }
          var tmp = bookings.lifecycleStatus;
          if ((tmp === "CANCELED" || tmp === "STAGED" || tmp === "DRAFT" || tmp === "ACTIVE" || tmp === "HISTORY") && tmp === "ACTIVE") {
            return {
                    voyageSlug: match$1.voyageSlug,
                    voyageName: match$1.voyageName,
                    voyStartDate: match$1.voyStartDate,
                    voyEndDate: match$1.voyEndDate,
                    imageSetSlug: match$1.imageSetSlug
                  };
          }
          
        }));
  var isVoyageSelected = function (s, u) {
    return Belt_Array.some(u.updateOtherVoyages, (function (voy) {
                  return voy === s;
                }));
  };
  var match = voyOpt.length !== 0;
  if (!props.display) {
    return null;
  }
  if (!match) {
    return null;
  }
  if (update.TAG !== "Account") {
    return null;
  }
  var update$1 = update._0;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      sx: {
                        cursor: "pointer"
                      },
                      onClick: (function (param) {
                          setShowVoyageOptions(function (param) {
                                return !showVoyageOptions;
                              });
                        }),
                      children: [
                        JsxRuntime.jsx(Checkbox, {
                              checked: showVoyageOptions,
                              size: "sm"
                            }),
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              children: "Apply changes to bookings"
                            })
                      ]
                    }),
                showVoyageOptions ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                        children: Caml_option.some(Belt_Array.mapWithIndex(voyOpt, (function (i, v) {
                                    return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(VoyageMiniCard$Thin.make, {
                                                          voyage: v,
                                                          onSelect: (function () {
                                                              var s = v.voyageSlug;
                                                              if (isVoyageSelected(s, update$1)) {
                                                                return setUpdate(function (param) {
                                                                            return {
                                                                                    TAG: "Account",
                                                                                    _0: {
                                                                                      accountId: update$1.accountId,
                                                                                      preferredFirstName: update$1.preferredFirstName,
                                                                                      preferredLastName: update$1.preferredLastName,
                                                                                      phone: update$1.phone,
                                                                                      phoneCountryCode: update$1.phoneCountryCode,
                                                                                      postalCode: update$1.postalCode,
                                                                                      residenceCountry: update$1.residenceCountry,
                                                                                      docGender: update$1.docGender,
                                                                                      docCitizenship: update$1.docCitizenship,
                                                                                      updateOtherVoyages: Belt_Array.keep(update$1.updateOtherVoyages, (function (voy) {
                                                                                              return voy !== s;
                                                                                            }))
                                                                                    }
                                                                                  };
                                                                          });
                                                              } else {
                                                                return setUpdate(function (param) {
                                                                            return {
                                                                                    TAG: "Account",
                                                                                    _0: {
                                                                                      accountId: update$1.accountId,
                                                                                      preferredFirstName: update$1.preferredFirstName,
                                                                                      preferredLastName: update$1.preferredLastName,
                                                                                      phone: update$1.phone,
                                                                                      phoneCountryCode: update$1.phoneCountryCode,
                                                                                      postalCode: update$1.postalCode,
                                                                                      residenceCountry: update$1.residenceCountry,
                                                                                      docGender: update$1.docGender,
                                                                                      docCitizenship: update$1.docCitizenship,
                                                                                      updateOtherVoyages: Belt_Array.concat(update$1.updateOtherVoyages, [s])
                                                                                    }
                                                                                  };
                                                                          });
                                                              }
                                                            }),
                                                          isSelected: isVoyageSelected(v.voyageSlug, update$1)
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }, String(i));
                                  }))),
                        container: true,
                        rowSpacing: 1,
                        columnSpacing: 1
                      }) : null
              ]
            });
}

var Voyages = {
  make: UpdateAccountData$Form$Voyages
};

var Form = {
  CountryFragment: CountryFragment,
  PassengerFragment: PassengerFragment,
  AcquisitionSlugFragment: AcquisitionSlugFragment,
  FormWrapper: FormWrapper,
  Travel: Travel,
  PersonalFields: PersonalFields,
  Personal: Personal,
  SignUp: SignUp,
  Voyages: Voyages
};

var empty = {
  accountId: undefined,
  isComplete: false,
  isBookable: true,
  preferredFirstName: undefined,
  preferredLastName: undefined,
  docFirstName: undefined,
  docLastName: undefined,
  docBirthdate: undefined,
  phone: undefined,
  phoneCountryCode: "+1",
  postalCode: undefined,
  residenceCountry: undefined,
  docGender: undefined,
  docCitizenship: undefined,
  email: undefined,
  loyalty: undefined,
  acquisitionSlug: undefined
};

function convertToPaxUpdate(p, v) {
  if (p.TAG === "SalesUser") {
    var u = p._0;
    var p$1 = u.phone;
    var p$2 = u.phone;
    return {
            accountId: Caml_option.some(u.accountId),
            isComplete: u.travelDocComplete,
            isBookable: !Belt_Array.some(Belt_Option.getWithDefault(u.pendingVoyageSlugs, []), (function (slug) {
                    return slug === Caml_option.some(v);
                  })),
            preferredFirstName: Belt_Option.map(u.firstName, CS_NonemptyStrings$Util.NonEmptyString.toString),
            preferredLastName: Belt_Option.map(u.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
            docFirstName: Belt_Option.map(u.legalDocumentFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString),
            docLastName: Belt_Option.map(u.legalDocumentLastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
            docBirthdate: u.legalDocumentBirthdate,
            phone: p$1 !== undefined ? CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(Caml_option.valFromOption(p$1))) : undefined,
            phoneCountryCode: p$2 !== undefined ? Belt_Option.getWithDefault(CS_Phone$Util.Phone.getCallingCode(CS_Phone$Util.Phone.toPrimitive(Caml_option.valFromOption(p$2))), "+1") : "+1",
            postalCode: Belt_Option.map(u.postalCode, CS_NonemptyStrings$Util.PostalCode.toString),
            residenceCountry: u.residenceCountry,
            docGender: u.legalDocumentGender,
            docCitizenship: u.legalDocumentCitizenship,
            email: Belt_Option.map(u.email, CS_Emails$Util.Email.toString),
            loyalty: undefined,
            acquisitionSlug: u.custAcquisitionSlug
          };
  }
  var u$1 = p._0;
  var p$3 = u$1.phone;
  var p$4 = u$1.phone;
  return {
          accountId: Caml_option.some(u$1.accountId),
          isComplete: u$1.travelDocComplete,
          isBookable: !Belt_Array.some(Belt_Option.getWithDefault(u$1.pendingVoyageSlugs, []), (function (slug) {
                  return slug === Caml_option.some(v);
                })),
          preferredFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(u$1.firstName),
          preferredLastName: Belt_Option.map(u$1.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          docFirstName: Belt_Option.map(u$1.legalDocumentFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          docLastName: Belt_Option.map(u$1.legalDocumentLastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          docBirthdate: u$1.legalDocumentBirthdate,
          phone: p$3 !== undefined ? CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(Caml_option.valFromOption(p$3))) : undefined,
          phoneCountryCode: p$4 !== undefined ? Belt_Option.getWithDefault(CS_Phone$Util.Phone.getCallingCode(CS_Phone$Util.Phone.toPrimitive(Caml_option.valFromOption(p$4))), "+1") : "+1",
          postalCode: Belt_Option.map(u$1.postalCode, CS_NonemptyStrings$Util.PostalCode.toString),
          residenceCountry: u$1.residenceCountry,
          docGender: u$1.legalDocumentGender,
          docCitizenship: u$1.legalDocumentCitizenship,
          email: Belt_Option.map(u$1.email, CS_Emails$Util.Email.toString),
          loyalty: undefined,
          acquisitionSlug: u$1.custAcquisitionSlug
        };
}

function sessionPaxToPaxUpdate(p) {
  if (typeof p !== "object") {
    return empty;
  }
  switch (p.TAG) {
    case "BCIncomplete" :
        var bc = p._0;
        return {
                accountId: Caml_option.some(bc.accountId),
                isComplete: false,
                isBookable: true,
                preferredFirstName: undefined,
                preferredLastName: undefined,
                docFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(bc.iDocFirstName),
                docLastName: CS_NonemptyStrings$Util.NonEmptyString.toString(bc.iDocLastName),
                docBirthdate: Caml_option.some(bc.iDocBirthdate),
                phone: undefined,
                phoneCountryCode: "+1",
                postalCode: undefined,
                residenceCountry: undefined,
                docGender: bc.iDocGender,
                docCitizenship: Caml_option.some(bc.iDocCitizenship),
                email: undefined,
                loyalty: undefined,
                acquisitionSlug: undefined
              };
    case "Invite" :
        var i = p._0;
        return {
                accountId: undefined,
                isComplete: false,
                isBookable: true,
                preferredFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(i.firstName),
                preferredLastName: Belt_Option.map(i.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
                docFirstName: undefined,
                docLastName: undefined,
                docBirthdate: undefined,
                phone: undefined,
                phoneCountryCode: "+1",
                postalCode: undefined,
                residenceCountry: undefined,
                docGender: undefined,
                docCitizenship: undefined,
                email: CS_Emails$Util.Email.toString(i.email),
                loyalty: undefined,
                acquisitionSlug: undefined
              };
    case "BC" :
    case "Connected" :
        return {
                accountId: Caml_option.some(p._0.id),
                isComplete: false,
                isBookable: true,
                preferredFirstName: undefined,
                preferredLastName: undefined,
                docFirstName: undefined,
                docLastName: undefined,
                docBirthdate: undefined,
                phone: undefined,
                phoneCountryCode: "+1",
                postalCode: undefined,
                residenceCountry: undefined,
                docGender: undefined,
                docCitizenship: undefined,
                email: undefined,
                loyalty: undefined,
                acquisitionSlug: undefined
              };
    case "ConnectedIncomplete" :
        var c = p._0;
        return {
                accountId: Caml_option.some(c.accountId),
                isComplete: false,
                isBookable: true,
                preferredFirstName: undefined,
                preferredLastName: undefined,
                docFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(c.iDocFirstName),
                docLastName: CS_NonemptyStrings$Util.NonEmptyString.toString(c.iDocLastName),
                docBirthdate: Caml_option.some(c.iDocBirthdate),
                phone: undefined,
                phoneCountryCode: "+1",
                postalCode: undefined,
                residenceCountry: undefined,
                docGender: c.iDocGender,
                docCitizenship: Caml_option.some(c.iDocCitizenship),
                email: undefined,
                loyalty: undefined,
                acquisitionSlug: undefined
              };
    case "Loyalty" :
        var l = p._0;
        return {
                accountId: Caml_option.some(l.acctId),
                isComplete: false,
                isBookable: true,
                preferredFirstName: undefined,
                preferredLastName: undefined,
                docFirstName: undefined,
                docLastName: undefined,
                docBirthdate: undefined,
                phone: undefined,
                phoneCountryCode: "+1",
                postalCode: undefined,
                residenceCountry: undefined,
                docGender: undefined,
                docCitizenship: undefined,
                email: undefined,
                loyalty: CS_NonemptyStrings$Util.LoyaltyNumber.toString(l.number),
                acquisitionSlug: undefined
              };
    case "Provide" :
        var p$1 = p._0;
        var p$2 = p$1.phone;
        var tmp;
        if (p$2 !== undefined) {
          var match = CS_Phone$Util.Phone.toString(Caml_option.valFromOption(p$2)).split(" ");
          var len = match.length;
          if (len >= 3) {
            tmp = undefined;
          } else {
            switch (len) {
              case 0 :
              case 1 :
                  tmp = undefined;
                  break;
              case 2 :
                  tmp = match[1];
                  break;
              
            }
          }
        } else {
          tmp = undefined;
        }
        var match$1 = Belt_Option.map(p$1.phone, CS_Phone$Util.Phone.meta);
        var tmp$1;
        if (match$1 !== undefined) {
          var callingCode = match$1.callingCode;
          tmp$1 = callingCode !== undefined ? callingCode : "#1";
        } else {
          tmp$1 = "#1";
        }
        return {
                accountId: undefined,
                isComplete: false,
                isBookable: true,
                preferredFirstName: Belt_Option.map(p$1.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString),
                preferredLastName: Belt_Option.map(p$1.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
                docFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(p$1.docFirstName),
                docLastName: CS_NonemptyStrings$Util.NonEmptyString.toString(p$1.docLastName),
                docBirthdate: Caml_option.some(p$1.docBirthdate),
                phone: tmp,
                phoneCountryCode: tmp$1,
                postalCode: Belt_Option.map(p$1.postalCode, CS_NonemptyStrings$Util.PostalCode.toString),
                residenceCountry: p$1.residenceCountry,
                docGender: p$1.docGender,
                docCitizenship: Caml_option.some(p$1.docCitizenship),
                email: undefined,
                loyalty: undefined,
                acquisitionSlug: undefined
              };
    
  }
}

function updateToSignUpInput(u, b, p) {
  if (!Common$Thin.isPasswordValid(p)) {
    return {
            TAG: "Error",
            _0: "Invalid password."
          };
  }
  var phone = u.phoneCountryCode + " " + Belt_Option.getWithDefault(u.phone, "");
  var match = Belt_Option.map(u.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$1 = Belt_Option.map(u.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$2 = Belt_Option.map(u.email, CS_Emails$Util.Email.fromPrimitive);
  var match$3 = CS_Phone$Util.Phone.fromPrimitive(phone);
  var match$4 = Belt_Option.map(u.postalCode, CS_NonemptyStrings$Util.PostalCode.fromPrimitive);
  var match$5 = u.residenceCountry;
  var match$6 = u.acquisitionSlug;
  if (match !== undefined && match.TAG === "Ok" && match$1 !== undefined && match$1.TAG === "Ok" && match$2 !== undefined && match$2.TAG === "Ok" && match$3.TAG === "Ok" && match$4 !== undefined && match$4.TAG === "Ok" && match$5 !== undefined && match$6 !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              newPasswd: p,
              accountEmail: match$2._0,
              personalFields: {
                accountFirstName: match._0,
                accountLastName: match$1._0,
                phone: match$3._0,
                residenceCountry: Caml_option.valFromOption(match$5),
                postalCode: Caml_option.some(match$4._0),
                custAcquisitionSlug: Caml_option.valFromOption(match$6)
              }
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Invalid input."
          };
  }
}

var Passenger = {
  empty: empty,
  convertToPaxUpdate: convertToPaxUpdate,
  sessionPaxToPaxUpdate: sessionPaxToPaxUpdate,
  updateToSignUpInput: updateToSignUpInput
};

exports.UpdateAccountDataMutation = UpdateAccountDataMutation;
exports.Utils = Utils;
exports.useMutation = useMutation;
exports.Form = Form;
exports.Passenger = Passenger;
/* commitMutation Not a pure module */
