// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PassengerForms$Thin = require("../common/molecules/PassengerForms.bs.js");
var PassengerState$Thin = require("../../utils/passengers/PassengerState.bs.js");
var UpdateAccountNew$Thin = require("../common/mutations/UpdateAccountNew.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function CAUpdateAccountDetailsDrawer(props) {
  var refetch = props.refetch;
  var user = props.user;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match[1];
  var onSuccess = function (param) {
    setShowVoyageOptions(function (param) {
          return false;
        });
    onClose();
    refetch();
  };
  var match$1 = UpdateAccountNew$Thin.useMutation(onSuccess);
  var updateUserAccount = match$1.updateUserAccount;
  var setError = match$1.setError;
  var error = match$1.error;
  var onSubmit = function (e) {
    e.preventDefault();
    var input = PassengerState$Thin.validateUpdateAccount(HopperState$Util.Observable.dangerouslyRead(user), "All");
    if (input.TAG === "Ok") {
      return updateUserAccount(input._0);
    }
    var err = input._0;
    setError(function (param) {
          return err;
        });
  };
  var isDirty = HopperState$Util.Observable.FormField.useIsDirty(user, PassengerState$Thin.paxUtils);
  var shouldError = HopperState$Util.Observable.useComputed(user, (function (user) {
          return Belt_Result.isError(PassengerState$Thin.validateUpdateAccount(user, "All"));
        }), undefined, undefined);
  return JsxRuntime.jsxs(AppDrawer$Thin.make, {
              open_: props.open_,
              title: "Edit " + props.fullName + "'s information",
              onClose: (function () {
                  onClose();
                  setShowVoyageOptions(function (param) {
                        return false;
                      });
                }),
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  p: 2
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        sx: {
                                          p: 2,
                                          border: "1px solid",
                                          borderColor: "neutral.300",
                                          borderRadius: "4px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.Personal.make, {
                                                  pax: user,
                                                  variant: "Gray",
                                                  isConnectedAcct: true,
                                                  disabled: false
                                                }))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        sx: {
                                          p: 2,
                                          border: "1px solid",
                                          borderColor: "neutral.300",
                                          borderRadius: "4px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                                                  interface: PassengerForms$Thin.Travel.PassengerState.$$interface(user),
                                                  variant: "Gray",
                                                  disabled: false,
                                                  restrictedEdit: "Always"
                                                }))
                                      }),
                                  JsxRuntime.jsx(PassengerForms$Thin.Voyages.make, {
                                        fragmentRefs: props.voyageFragment,
                                        bookings: {
                                          TAG: "Connected",
                                          _0: props.bookings
                                        },
                                        showVoyageOptions: match[0],
                                        setShowVoyageOptions: setShowVoyageOptions,
                                        pax: user,
                                        display: true
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                          message: error,
                                          type_: "error"
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%",
                                  p: 2
                                },
                                onSubmit: onSubmit,
                                disabled: !isDirty || shouldError,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: (function (param) {
                                            setError(function (param) {
                                                  
                                                });
                                            setShowVoyageOptions(function (param) {
                                                  return false;
                                                });
                                            onClose();
                                          }),
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$1.isMutating,
                                        type: "submit",
                                        disabled: !isDirty || shouldError,
                                        children: "Save Changes"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = CAUpdateAccountDetailsDrawer;

exports.make = make;
/* react Not a pure module */
