// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var $$EvalError = {};

var $$RangeError = {};

var $$ReferenceError = {};

var $$SyntaxError = {};

var $$TypeError = {};

var $$URIError = {};

function panic(msg) {
  throw new Error("Panic! " + msg);
}

exports.$$EvalError = $$EvalError;
exports.$$RangeError = $$RangeError;
exports.$$ReferenceError = $$ReferenceError;
exports.$$SyntaxError = $$SyntaxError;
exports.$$TypeError = $$TypeError;
exports.$$URIError = $$URIError;
exports.panic = panic;
/* No side effect */
