// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var HopperURL$Thin = require("../../context/HopperURL.bs.js");
var CurrentUser$Thin = require("../../context/CurrentUser.bs.js");

function IsLoggedIn(props) {
  var children = props.children;
  var match = CurrentUser$Thin.Context.use();
  var signOnStatus = match.signOnStatus;
  React.useEffect((function () {
          var exit = 0;
          if (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") {
            switch (signOnStatus) {
              case "PENDING_2FA" :
              case "VERIFY_EMAIL" :
              case "SIGNED_ON" :
                  break;
              default:
                exit = 1;
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            HopperURL$Thin.redirect((function (prevPath, prevGlobals) {
                    var match = prevPath.trim();
                    var loginRedirect;
                    switch (match) {
                      case "" :
                      case "/" :
                          loginRedirect = undefined;
                          break;
                      default:
                        loginRedirect = prevPath;
                    }
                    return [
                            SysCtx$Thin.vars.loginLink,
                            {
                              dismissibleAlerts: prevGlobals.dismissibleAlerts,
                              loginRedirect: loginRedirect,
                              token: prevGlobals.token,
                              landing: prevGlobals.landing,
                              expiryMillis: prevGlobals.expiryMillis
                            }
                          ];
                  }), undefined);
          }
          
        }), [signOnStatus]);
  if (!(signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON")) {
    return null;
  }
  switch (signOnStatus) {
    case "PENDING_2FA" :
    case "VERIFY_EMAIL" :
    case "SIGNED_ON" :
        return children;
    default:
      return null;
  }
}

var make = IsLoggedIn;

exports.make = make;
/* react Not a pure module */
