// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var AppLink$Thin = require("../components/common/atoms/AppLink.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../components/common/atoms/AppButton.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var URLSearchParams$Util = require("util/src/URLSearchParams.bs.js");
var RequestTokenSubmit$Thin = require("../components/reset-password/mutations/RequestTokenSubmit.bs.js");
var ResetPasswordSubmit$Thin = require("../components/reset-password/mutations/ResetPasswordSubmit.bs.js");

function ResetPasswordPage(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var token = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.tokenKey);
  var match = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.expiryKey);
  var expired;
  if (match !== undefined && match.TAG === "Number") {
    var now = Date.now();
    var expiry = match._0 * 1000.0;
    expired = now >= expiry ? true : false;
  } else {
    expired = undefined;
  }
  var match$1 = ResetPasswordSubmit$Thin.useMutation();
  var resetError = match$1.resetError;
  var passUpdateSuccess = match$1.passUpdateSuccess;
  var onResetSubmit = match$1.onSubmit;
  var setConfirmPassword = match$1.setConfirmPassword;
  var confirmPassword = match$1.confirmPassword;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  var match$2 = RequestTokenSubmit$Thin.useMutation();
  var tokenError = match$2.tokenError;
  var tokenSent = match$2.tokenSent;
  var setEmail = match$2.setEmail;
  var email = match$2.email;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (token !== undefined && token.TAG === "String") {
    if (expired !== undefined) {
      if (expired) {
        exit$2 = 3;
      } else if (passUpdateSuccess) {
        exit$1 = 2;
      } else if (tokenSent) {
        exit = 1;
      } else {
        var token$1 = token._0;
        tmp = JsxRuntime.jsxs(Stack, {
              direction: "column",
              component: "form",
              spacing: 2,
              sx: {
                width: "100%"
              },
              onSubmit: (function (e) {
                  onResetSubmit(e, token$1);
                }),
              disabled: password === "" || confirmPassword === "" || password !== confirmPassword,
              children: [
                JsxRuntime.jsx(PasswordInput$Thin.make, {
                      fullWidth: true,
                      size: "lg",
                      placeholder: Txt$Thin.ResetPassword.passwordPlaceholder.value,
                      error: password !== "" && !Common$Thin.isPasswordValid(password),
                      value: password,
                      onChange: (function (e) {
                          var val = e.currentTarget.value;
                          setPassword(function (param) {
                                return val;
                              });
                        }),
                      label: Txt$Thin.ResetPassword.passwordPlaceholder.value
                    }),
                JsxRuntime.jsx(PasswordInput$Thin.make, {
                      fullWidth: true,
                      size: "lg",
                      placeholder: Txt$Thin.ResetPassword.confirmPasswordPlaceholder.value,
                      error: confirmPassword !== "" && password !== confirmPassword,
                      value: confirmPassword,
                      onChange: (function (e) {
                          var val = e.currentTarget.value;
                          setConfirmPassword(function (param) {
                                return val;
                              });
                        }),
                      errorText: Txt$Thin.ResetPassword.passwordMismatch.value,
                      label: Txt$Thin.ResetPassword.confirmPasswordPlaceholder.value
                    }),
                JsxRuntime.jsx(AppButton$Thin.make, {
                      fullWidth: true,
                      loading: match$1.isMutating,
                      type_: "submit",
                      size: "lg",
                      disabled: password === "" || confirmPassword === "" || password !== confirmPassword,
                      children: Caml_option.some(Txt$Thin.ResetPassword.reset.value)
                    })
              ]
            });
      }
    } else {
      exit$1 = 2;
    }
  } else {
    exit$2 = 3;
  }
  if (exit$2 === 3) {
    if (expired !== undefined && expired) {
      tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
            message: Txt$Thin.ResetPassword.expired.value,
            type_: "warning"
          });
    } else {
      exit$1 = 2;
    }
  }
  if (exit$1 === 2) {
    if (passUpdateSuccess) {
      tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
            message: Merge$Util.render(Txt$Thin.ResetPassword.passwordUpdated, SysCtx$Thin.vars),
            type_: "success"
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = tokenSent ? JsxRuntime.jsx(AppAlert$Thin.make, {
            message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                  children: [
                    Txt$Thin.ResetPassword.emailSent.value,
                    JsxRuntime.jsx(AppLink$Thin.make, {
                          onClick: match$2.onClick,
                          children: Txt$Thin.ResetPassword.resendEmail.value
                        })
                  ]
                })
          }) : JsxRuntime.jsxs(Stack, {
            direction: "column",
            component: "form",
            spacing: 2,
            sx: {
              width: "100%"
            },
            onSubmit: match$2.onSubmit,
            disabled: email === "" || !Common$Thin.Email.isValid(email),
            children: [
              JsxRuntime.jsxs(FormControl, {
                    error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                    children: [
                      JsxRuntime.jsx(FormLabel, {
                            children: "Email"
                          }),
                      JsxRuntime.jsx(Joy$Util.Input.make, {
                            type_: "email",
                            size: "lg",
                            fullWidth: true,
                            placeholder: Txt$Thin.ResetPassword.emailPlaceholder.value,
                            value: email,
                            onChange: (function (e) {
                                var val = e.currentTarget.value;
                                setEmail(function (param) {
                                      return val;
                                    });
                              })
                          })
                    ]
                  }),
              JsxRuntime.jsx(AppButton$Thin.make, {
                    fullWidth: true,
                    loading: match$2.isMutating,
                    type_: "submit",
                    size: "lg",
                    disabled: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                    children: Caml_option.some(Txt$Thin.ResetPassword.sendResetEmail.value)
                  })
            ]
          });
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(AuthPanel$Thin.make, {
                    heading: Txt$Thin.ResetPassword.heading.value,
                    children: [
                      tmp,
                      tokenError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: tokenError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                      resetError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: resetError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              auth: true
            });
}

var make = ResetPasswordPage;

exports.make = make;
/* Joy-Util Not a pure module */
