// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Send = require("@mui/icons-material/Send").default;

function InvitePass(props) {
  var forceValidate = props.forceValidate;
  var disabled = props.disabled;
  var addOrErr = props.addOrErr;
  var idx = props.idx;
  var action = props.action;
  var email = props.email;
  var prefLName = props.prefLName;
  var prefFName = props.prefFName;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var onSubmit = function (e) {
    e.preventDefault();
    var match = CS_Emails$Util.Email.fromPrimitive(email);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFName);
    if (match.TAG === "Ok") {
      var email$1 = match._0;
      if (match$1.TAG === "Ok") {
        var firstName = match$1._0;
        var applyDiff;
        if (action === "Attach") {
          var lastName = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
          var tmp;
          tmp = lastName.TAG === "Ok" ? Caml_option.some(lastName._0) : undefined;
          applyDiff = BookingDiff$Thin.Alter.attachAnInvitedPax(diff, idx, {
                email: email$1,
                firstName: firstName,
                lastName: tmp
              });
        } else {
          var lastName$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
          var tmp$1;
          tmp$1 = lastName$1.TAG === "Ok" ? Caml_option.some(lastName$1._0) : undefined;
          applyDiff = BookingDiff$Thin.Alter.replaceWithInvitedPax(diff, idx, {
                email: email$1,
                firstName: firstName,
                lastName: tmp$1
              });
        }
        return addOrErr(applyDiff);
      }
      console.log("Invalid email or first name");
      return ;
    }
    console.log("Invalid email or first name");
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        component: "form",
                        spacing: 2,
                        sx: {
                          cursor: disabled ? "not-allowed" : "pointer",
                          opacity: disabled ? "0.8" : "1",
                          py: {
                            xs: 2,
                            md: 1
                          },
                          px: {
                            xs: 2,
                            md: 0
                          }
                        },
                        children: [
                          JsxRuntime.jsx(Sheet, {
                                sx: {
                                  borderRadius: "sm",
                                  backgroundColor: "background.level1",
                                  p: 2
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              prefFName,
                                                              props.setPrefFName
                                                            ],
                                                            get: (function (prefFName) {
                                                                return prefFName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                                              }),
                                                            children: (function (param) {
                                                                return JsxRuntime.jsxs(FormControl, {
                                                                            error: param.error,
                                                                            required: true,
                                                                            children: [
                                                                              JsxRuntime.jsx(FormLabel, {
                                                                                    children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value)
                                                                                  }),
                                                                              JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "text",
                                                                                    placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                                                    variant: "plain",
                                                                                    value: param.get,
                                                                                    onChange: param.onChange.withCurrentTarget
                                                                                  })
                                                                            ]
                                                                          });
                                                              })
                                                          })),
                                                  xs: 12,
                                                  md: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              prefLName,
                                                              props.setPrefLName
                                                            ],
                                                            get: (function (prefLName) {
                                                                return prefLName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (param) {
                                                                return true;
                                                              }),
                                                            children: (function (param) {
                                                                return JsxRuntime.jsxs(FormControl, {
                                                                            error: param.error,
                                                                            children: [
                                                                              JsxRuntime.jsx(FormLabel, {
                                                                                    children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value)
                                                                                  }),
                                                                              JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "text",
                                                                                    placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                                                    variant: "plain",
                                                                                    value: param.get,
                                                                                    onChange: param.onChange.withCurrentTarget
                                                                                  })
                                                                            ]
                                                                          });
                                                              })
                                                          })),
                                                  xs: 12,
                                                  md: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              email,
                                                              props.setEmail
                                                            ],
                                                            get: (function (email) {
                                                                return email;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(v));
                                                              }),
                                                            children: (function (param) {
                                                                return JsxRuntime.jsxs(FormControl, {
                                                                            error: param.error,
                                                                            required: true,
                                                                            children: [
                                                                              JsxRuntime.jsx(FormLabel, {
                                                                                    children: Caml_option.some(Txt$Thin.BookingDetails.addPassInviteEmail.value)
                                                                                  }),
                                                                              JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: "email",
                                                                                    placeholder: Txt$Thin.BookingDetails.addPassInviteEmail.value,
                                                                                    variant: "plain",
                                                                                    value: param.get,
                                                                                    onChange: param.onChange.withCurrentTarget
                                                                                  })
                                                                            ]
                                                                          });
                                                              })
                                                          })),
                                                  xs: 12
                                                })
                                          ],
                                          container: true,
                                          spacing: {
                                            TAG: "Fixed",
                                            _0: 1
                                          },
                                          rowSpacing: 2,
                                          columnSpacing: 2,
                                          sx: {
                                            p: 1
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  display: {
                                    xs: "none",
                                    md: "flex"
                                  }
                                },
                                children: Caml_option.some(JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(AppButton$Thin.make, {
                                                fullWidth: false,
                                                loading: props.loading,
                                                onClick: onSubmit,
                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Send, {})),
                                                color: "primary",
                                                size: "md",
                                                disabled: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFName)),
                                                children: Caml_option.some(Txt$Thin.BookingDetails.addPassInviteSend.value)
                                              })
                                        }))
                              })
                        ]
                      }))
            });
}

var make = InvitePass;

exports.make = make;
/* Joy-Util Not a pure module */
