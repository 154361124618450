'use strict';


function equal(x, y) {
  return x === y;
}

var max = 2147483647;

var min = -2147483648;

exports.equal = equal;
exports.max = max;
exports.min = min;
/* No side effect */
