// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin = require("../../../__generated__/UpdateAccountNew_UpdateAccountDataMutation_graphql.bs.js");

var convertVariables = UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var UpdateAccountDataMutation_docGender_decode = UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode;

var UpdateAccountDataMutation_docGender_fromString = UpdateAccountNew_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_fromString;

var UpdateAccountDataMutation = {
  docGender_decode: UpdateAccountDataMutation_docGender_decode,
  docGender_fromString: UpdateAccountDataMutation_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onSuccess) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var updateUserAccount = function (i) {
    setError(function (param) {
          
        });
    mutate({
          input: i
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              return ;
            }
            var match = resp.updateAccountData;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                onSuccess();
                return ;
              }
              
            }
            setError(function (param) {
                  return "Failed to update account data";
                });
          }), (function (error) {
            console.log(error);
            setError(function (param) {
                  return "Error updating account data";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          updateUserAccount: updateUserAccount
        };
}

exports.UpdateAccountDataMutation = UpdateAccountDataMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
