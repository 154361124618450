// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin = require("../../../__generated__/FetchOwnOrConnectedUserVoyagesQuery_graphql.bs.js");
var FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin = require("../../../__generated__/FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql.bs.js");

var convertVariables = FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.node, convertVariables);

var CurrentUserVoyagesQuery_lifecycleStatus_decode = FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Utils.lifecycleStatus_decode;

var CurrentUserVoyagesQuery_lifecycleStatus_fromString = FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Utils.lifecycleStatus_fromString;

var CurrentUserVoyagesQuery = {
  lifecycleStatus_decode: CurrentUserVoyagesQuery_lifecycleStatus_decode,
  lifecycleStatus_fromString: CurrentUserVoyagesQuery_lifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.Internal.convertVariables;

var convertResponse$1 = FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$1 = FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.node, convertVariables$1);

var ConnectedAccountsVoyagesQuery_lifecycleStatus_decode = FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.Utils.lifecycleStatus_decode;

var ConnectedAccountsVoyagesQuery_lifecycleStatus_fromString = FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery_graphql$Thin.Utils.lifecycleStatus_fromString;

var ConnectedAccountsVoyagesQuery = {
  lifecycleStatus_decode: ConnectedAccountsVoyagesQuery_lifecycleStatus_decode,
  lifecycleStatus_fromString: ConnectedAccountsVoyagesQuery_lifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$1,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function useQuery(voyage) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setVoyOpt = match$1[1];
  var fetchVoyages = function (acctId, userType) {
    if (userType === "Self") {
      $$fetch(RelayEnv$Thin.environment, FetchOwnOrConnectedUserVoyagesQuery_graphql$Thin.Types.makeRefetchVariables(), (function (res) {
              if (res.TAG === "Ok") {
                var match = res._0.currentUserProfiles;
                if (match === undefined) {
                  return ;
                }
                var match$1 = match.nodes;
                if (match$1.length !== 1) {
                  return ;
                }
                var node = match$1[0];
                var voyages = Belt_Array.keepMap(node.passenger_record.nodes, (function (pax) {
                        var match = pax.bookings;
                        if (match === undefined) {
                          return ;
                        }
                        var match$1 = match.bookingListitem;
                        if (match$1 === undefined) {
                          return ;
                        }
                        var match$2 = match$1.voyage;
                        if (match$2 === undefined) {
                          return ;
                        }
                        var voyageSlug = match$2.voyageSlug;
                        var voyStartDate = match$2.voyStartDate;
                        if ((
                            Belt_Option.isSome(voyage) ? voyage !== Caml_option.some(voyageSlug) : true
                          ) && !DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(voyStartDate))), new Date())) {
                          return {
                                  voyageSlug: voyageSlug,
                                  voyageName: match$2.voyageName,
                                  voyStartDate: voyStartDate,
                                  voyEndDate: match$2.voyEndDate,
                                  imageSetSlug: match$2.imageSetSlug
                                };
                        }
                        
                      }));
                return setVoyOpt(function (param) {
                            return voyages;
                          });
              }
              console.log(res._0);
              setError(function (param) {
                    return "Couldn't fetch user voyages";
                  });
            }), undefined, undefined);
    } else {
      $$fetch$1(RelayEnv$Thin.environment, {
            acctId: acctId
          }, (function (res) {
              if (res.TAG === "Ok") {
                var match = res._0.currentUserConnectedAccounts;
                if (match === undefined) {
                  return ;
                }
                var match$1 = match.nodes;
                if (match$1.length !== 1) {
                  return ;
                }
                var node = match$1[0];
                var voyages = Belt_Array.keepMap(node.passenger_record.nodes, (function (pax) {
                        var match = pax.bookings;
                        if (match === undefined) {
                          return ;
                        }
                        var match$1 = match.bookingListitem;
                        if (match$1 === undefined) {
                          return ;
                        }
                        var match$2 = match$1.voyage;
                        if (match$2 === undefined) {
                          return ;
                        }
                        var voyageSlug = match$2.voyageSlug;
                        var voyStartDate = match$2.voyStartDate;
                        if ((
                            Belt_Option.isSome(voyage) ? voyage === Caml_option.some(voyageSlug) : true
                          ) && match.lifecycleStatus === "ACTIVE" && !DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(voyStartDate))), new Date())) {
                          return {
                                  voyageSlug: voyageSlug,
                                  voyageName: match$2.voyageName,
                                  voyStartDate: voyStartDate,
                                  voyEndDate: match$2.voyEndDate,
                                  imageSetSlug: match$2.imageSetSlug
                                };
                        }
                        
                      }));
                return setVoyOpt(function (param) {
                            return voyages;
                          });
              }
              console.log(res._0);
              setError(function (param) {
                    return "Couldn't fetch user voyages";
                  });
            }), undefined, undefined);
    }
  };
  return {
          voyOpt: match$1[0],
          error: match[0],
          setError: setError,
          fetchVoyages: fetchVoyages
        };
}

exports.CurrentUserVoyagesQuery = CurrentUserVoyagesQuery;
exports.ConnectedAccountsVoyagesQuery = ConnectedAccountsVoyagesQuery;
exports.useQuery = useQuery;
/* use Not a pure module */
