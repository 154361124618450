// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Utils = require("./utils");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var LiveResolverStore = require("relay-runtime/lib/store/experimental-live-resolvers/LiveResolverStore");
var LiveResolverStore$1 = require("relay-runtime/lib/store/experimental-live-resolvers/LiveResolverStore").default;

var SuspenseSentinel = {};

function convertObj(prim0, prim1, prim2, prim3) {
  return Utils.traverser(prim0, prim1, prim2, prim3);
}

function optArrayOfNullableToOptArrayOfOpt(x) {
  if (x !== undefined) {
    return Belt_Array.map(x, (function (prim) {
                  if (prim == null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }));
  }
  
}

function getLinkedRecords(t, name, $$arguments) {
  return optArrayOfNullableToOptArrayOfOpt(Caml_option.nullable_to_opt(t.getLinkedRecords(name, $$arguments)));
}

var RecordProxy = {
  getLinkedRecords: getLinkedRecords
};

function getPluralRootField(t, fieldName) {
  return optArrayOfNullableToOptArrayOfOpt(Caml_option.nullable_to_opt(t.getPluralRootField(fieldName)));
}

function invalidateRecordsByIds(store, recordIds) {
  recordIds.forEach(function (dataId) {
        Belt_Option.forEach(Caml_option.nullable_to_opt(store.get(dataId)), (function (r) {
                r.invalidateRecord();
              }));
      });
}

var RecordSourceSelectorProxy = {
  getPluralRootField: getPluralRootField,
  invalidateRecordsByIds: invalidateRecordsByIds
};

var ReadOnlyRecordSourceProxy = {};

function unwrapNormalizationArgument(wrapped) {
  var match = wrapped.kind;
  if (match === "Literal") {
    return {
            TAG: "Literal",
            _0: wrapped
          };
  } else if (match === "ListValue") {
    return {
            TAG: "ListValue",
            _0: wrapped
          };
  } else if (match === "ObjectValue") {
    return {
            TAG: "ObjectValue",
            _0: wrapped
          };
  } else {
    return {
            TAG: "Variable",
            _0: wrapped
          };
  }
}

function makeScalarMissingFieldHandler(handle) {
  return {
          kind: "scalar",
          handle: handle
        };
}

function makeLinkedMissingFieldHandler(handle) {
  return {
          kind: "linked",
          handle: handle
        };
}

function makePluralLinkedMissingFieldHandler(handle) {
  return {
          kind: "pluralLinked",
          handle: handle
        };
}

var MissingFieldHandler = {
  unwrapNormalizationArgument: unwrapNormalizationArgument,
  makeScalarMissingFieldHandler: makeScalarMissingFieldHandler,
  makeLinkedMissingFieldHandler: makeLinkedMissingFieldHandler,
  makePluralLinkedMissingFieldHandler: makePluralLinkedMissingFieldHandler
};

var nodeInterfaceMissingFieldHandler = makeLinkedMissingFieldHandler(function (field, record, args, _store) {
      var match = field.name;
      var match$1 = args.id;
      if ((record == null) || !(match === "node" && record.getType() === RelayRuntime.ROOT_TYPE && !(match$1 == null))) {
        return ;
      } else {
        return Caml_option.some(match$1);
      }
    });

var ConnectionHandler = {};

var Disposable = {};

var Observable = {};

var preloadResources = (function preloadResources(operation, variables, response) {
  let metadata = operation.metadata;
  if (metadata == null) return;
  let codesplits = metadata.codesplits;
  if (codesplits == null) return;
  let data = response.data;

  function pathExists(obj, path) {
    let current = obj;

    for (let i = 0; i < path.length; i++) {
      let segment = path[i];

      if (Array.isArray(current)) {
        return current.some((item) => pathExists(item, path.slice(i)));
      } else if (current != null && current.hasOwnProperty(segment)) {
        current = current[segment];
      } else if (current != null && (segment.startsWith("$$u$$") || segment.startsWith("$$i$$"))) {
        let isInterface = segment.startsWith("$$i$$");
        let expectedTypename = segment.slice(5);
        if (
          (
            !isInterface &&
            current.hasOwnProperty("__typename") &&
            current.__typename === expectedTypename) || 
          (
            isInterface &&
            current.hasOwnProperty("__is" + expectedTypename) &&
            current["__is" + expectedTypename] != null
          )
        ) {
          if (i + 1 === path.length) {
            // End
            return true;
          } else {
            continue;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return current != null;
  }

  function run() {
    for (let instruction of codesplits) {
      let path = instruction[0];
      let func = instruction[1];
      if (pathExists(data, path.split("."))) {
        func(variables);
      }
    }
  }

  if ("requestIdleCallback" in window) {
    requestIdleCallback(run);
  } else {
    setTimeout(() => {
      Promise.resolve().then(run);
    }, 1);
  }
});

var Network = {
  preloadResources: preloadResources
};

var RecordSource = {};

function make(source, gcReleaseBufferSize, queryCacheExpirationTime) {
  return new RelayRuntime.Store(source, {
              gcReleaseBufferSize: gcReleaseBufferSize,
              queryCacheExpirationTime: queryCacheExpirationTime
            });
}

function makeLiveStore(source, gcReleaseBufferSize, queryCacheExpirationTime) {
  return new LiveResolverStore$1(source, {
              gcReleaseBufferSize: gcReleaseBufferSize,
              queryCacheExpirationTime: queryCacheExpirationTime
            });
}

function _makeLiveStoreCjs(source, gcReleaseBufferSize, queryCacheExpirationTime) {
  return new LiveResolverStore(source, {
              gcReleaseBufferSize: gcReleaseBufferSize,
              queryCacheExpirationTime: queryCacheExpirationTime
            });
}

var Store = {
  make: make,
  makeLiveStore: makeLiveStore,
  _makeLiveStoreCjs: _makeLiveStoreCjs
};

var RelayFieldLogger = {};

function make$1(network, store, getDataID, treatMissingFieldsAsNull, missingFieldHandlers, relayFieldLogger, isServer) {
  return new RelayRuntime.Environment({
              network: network,
              store: store,
              getDataID: getDataID,
              treatMissingFieldsAsNull: treatMissingFieldsAsNull,
              missingFieldHandlers: missingFieldHandlers !== undefined ? Belt_Array.concat(missingFieldHandlers, [nodeInterfaceMissingFieldHandler]) : [nodeInterfaceMissingFieldHandler],
              relayFieldLogger: relayFieldLogger,
              isServer: isServer
            });
}

function findAllConnectionIds(environment, connectionKey, parentId) {
  var ids = [];
  var value = environment.getStore().getSource()._records.get(parentId);
  if (value !== undefined) {
    Js_dict.entries(value).forEach(function (param) {
          if (param[0].startsWith("__" + connectionKey + "_connection")) {
            ids.push(param[1].__ref);
            return ;
          }
          
        });
  }
  return ids;
}

function invalidateAllOfConnection(environment, connectionKey, parentId) {
  RelayRuntime.commitLocalUpdate(environment, (function (store) {
          findAllConnectionIds(environment, connectionKey, parentId).forEach(function (dataId) {
                Belt_Option.forEach(Caml_option.nullable_to_opt(store.get(dataId)), (function (r) {
                        r.invalidateRecord();
                      }));
              });
        }));
}

var Environment = {
  make: make$1,
  findAllConnectionIds: findAllConnectionIds,
  invalidateAllOfConnection: invalidateAllOfConnection
};

function RescriptRelay$Context$Provider(props) {
  var provider = ReactRelay.ReactRelayContext.Provider;
  return React.createElement(provider, {
              value: {
                environment: props.environment
              },
              children: props.children
            });
}

var Provider = {
  make: RescriptRelay$Context$Provider
};

var Context = {
  Provider: Provider
};

var EnvironmentNotFoundInContext = /* @__PURE__ */Caml_exceptions.create("RescriptRelay.EnvironmentNotFoundInContext");

function useEnvironmentFromContext() {
  var context = React.useContext(ReactRelay.ReactRelayContext);
  if (context !== undefined) {
    return Caml_option.valFromOption(context).environment;
  }
  throw {
        RE_EXN_ID: EnvironmentNotFoundInContext,
        Error: new Error()
      };
}

function MakeLoadQuery(C) {
  var load = function (environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
    return ReactRelay.loadQuery(environment, C.query, C.convertVariables(variables), {
                fetchKey: fetchKey,
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  var queryRefToObservable = function (token) {
    return Caml_option.nullable_to_opt(token.source);
  };
  var queryRefToPromise = function (token) {
    return new Promise((function (resolve, param) {
                  var o = queryRefToObservable(token);
                  if (o !== undefined) {
                    Caml_option.valFromOption(o).subscribe({
                          complete: (function () {
                              resolve({
                                    TAG: "Ok",
                                    _0: undefined
                                  });
                            })
                        });
                    return ;
                  } else {
                    return resolve({
                                TAG: "Error",
                                _0: undefined
                              });
                  }
                }));
  };
  return {
          load: load,
          queryRefToObservable: queryRefToObservable,
          queryRefToPromise: queryRefToPromise
        };
}

var Mutation_failed = /* @__PURE__ */Caml_exceptions.create("RescriptRelay.Mutation_failed");

exports.SuspenseSentinel = SuspenseSentinel;
exports.convertObj = convertObj;
exports.RecordProxy = RecordProxy;
exports.RecordSourceSelectorProxy = RecordSourceSelectorProxy;
exports.ReadOnlyRecordSourceProxy = ReadOnlyRecordSourceProxy;
exports.MissingFieldHandler = MissingFieldHandler;
exports.ConnectionHandler = ConnectionHandler;
exports.Observable = Observable;
exports.Network = Network;
exports.RecordSource = RecordSource;
exports.Store = Store;
exports.Disposable = Disposable;
exports.RelayFieldLogger = RelayFieldLogger;
exports.Environment = Environment;
exports.Context = Context;
exports.EnvironmentNotFoundInContext = EnvironmentNotFoundInContext;
exports.useEnvironmentFromContext = useEnvironmentFromContext;
exports.Mutation_failed = Mutation_failed;
exports.MakeLoadQuery = MakeLoadQuery;
/* nodeInterfaceMissingFieldHandler Not a pure module */
