// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var RelayRuntime = require("relay-runtime");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function useMutation(refetch, redirectUrlOpt, onSuccess) {
  var redirectUrl = redirectUrlOpt !== undefined ? Caml_option.valFromOption(redirectUrlOpt) : undefined;
  var localRequestState = React.useState(function () {
        return "NotAsked";
      });
  var isMutating = ReactState$Util.getter(localRequestState) === "Loading";
  var match = React.useState(function () {
        return "";
      });
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var password = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var login = function () {
    if (email === "" || password === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      setError(function (param) {
            
          });
      return RemoteData$Util.$$Promise.makeStatefulEffect(Common$Thin.cookieLogin({
                      allegedEmail: email,
                      allegedPass: password
                    }, Caml_option.some((function () {
                            refetch();
                            RelayRuntime.commitLocalUpdate(RelayEnv$Thin.environment, (function (store) {
                                    store.invalidateStore();
                                  }));
                            if (onSuccess !== undefined) {
                              onSuccess();
                            }
                            if (redirectUrl !== undefined) {
                              return RescriptReactRouter.push(redirectUrl);
                            }
                            
                          })), Caml_option.some((function (eString) {
                            setError(function (param) {
                                  console.log(eString);
                                  return eString;
                                });
                          }))), localRequestState);
    }
  };
  var onSubmit = function (e) {
    e.preventDefault();
    login();
  };
  return {
          isMutating: isMutating,
          email: email,
          setEmail: match[1],
          password: password,
          setPassword: match$1[1],
          onSubmit: onSubmit,
          error: match$2[0],
          setError: setError
        };
}

function useMutationOnSignup(onSuccess) {
  var localRequestState = React.useState(function () {
        return "NotAsked";
      });
  var isMutating = ReactState$Util.getter(localRequestState) === "Loading";
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var loginOnSignUp = function (email, password) {
    RemoteData$Util.$$Promise.makeStatefulEffect(Common$Thin.cookieLogin({
              allegedEmail: CS_Emails$Util.Email.toString(email),
              allegedPass: password
            }, Caml_option.some(onSuccess), Caml_option.some((function (eString) {
                    setError(function (param) {
                          console.log(eString);
                          return eString;
                        });
                  }))), localRequestState);
  };
  return {
          error: match[0],
          setError: setError,
          isMutating: isMutating,
          loginOnSignUp: loginOnSignUp
        };
}

exports.useMutation = useMutation;
exports.useMutationOnSignup = useMutationOnSignup;
/* react Not a pure module */
