// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Dialog$Thin = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Divider = require("@mui/joy/Divider").default;
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;

function FareClassSelector(props) {
  var displayTotal = props.displayTotal;
  var fc = props.fc;
  var match = displayTotal ? [
      fc.fcQuotePricePerBooking,
      fc.fcReferencePricePerBooking
    ] : [
      fc.fcQuotePricePerPerson,
      fc.fcReferencePricePerPerson
    ];
  var referencePrice = match[1];
  var quotePrice = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFcDetailsModalOpen = match$1[1];
  return JsxRuntime.jsxs(SelectorCard$Thin.make, {
              isSelected: props.isSelected,
              onClick: props.onClick,
              title: fc.fcName,
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              alignItems: "flex-end",
                              spacing: 1,
                              sx: {
                                my: 2
                              },
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "p",
                                      children: Caml_option.some(quotePrice !== undefined ? Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(quotePrice))) : Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                    }),
                                quotePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        level: "body-xs",
                                        sx: {
                                          textDecoration: "line-through"
                                        },
                                        children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                      }) : null,
                                JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      sx: {
                                        color: "text.secondary"
                                      },
                                      children: Caml_option.some(displayTotal ? "total" : "per person")
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fc.fcBlurb))
                            }),
                        JsxRuntime.jsx(Joy$Util.Link.make, {
                              children: "View details",
                              level: "body-sm",
                              component: "button",
                              color: "primary",
                              onClick: (function (e) {
                                  e.stopPropagation();
                                  setFcDetailsModalOpen(function (param) {
                                        return true;
                                      });
                                }),
                              sx: {
                                width: "fit-content"
                              },
                              endDecorator: Caml_option.some(JsxRuntime.jsx(OpenInNew, {}))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Dialog$Thin.make, {
                      open_: match$1[0],
                      title: fc.fcName + " Details",
                      onClose: (function () {
                          setFcDetailsModalOpen(function (param) {
                                return false;
                              });
                        }),
                      children: Caml_option.some(JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(fc.fcTerms))
                                        }))
                              }))
                    })
              ]
            });
}

var make = FareClassSelector;

exports.make = make;
/* react Not a pure module */
