// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RelayEnv$Thin = require("../../RelayEnv.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var BookingDiff$Thin = require("./BookingDiff.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDPreferences_detail_graphql$Thin = require("../../__generated__/BDPreferences_detail_graphql.bs.js");
var ConnectedAccountsPage_query_graphql$Thin = require("../../__generated__/ConnectedAccountsPage_query_graphql.bs.js");
var DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin = require("../../__generated__/DraftUtilsFetchLoyaltyUsersQuery_graphql.bs.js");

var empty_eligibleVoyageSlugs = [];

var empty = {
  accountId: undefined,
  docFName: undefined,
  docLName: undefined,
  docGender: undefined,
  docCitizenship: undefined,
  preferredFName: undefined,
  preferredLName: undefined,
  birthdate: undefined,
  preferredFullName: undefined,
  loyaltyNumber: undefined,
  numLoyaltyVoyages: 0,
  loyaltyTier: undefined,
  eligibleVoyageSlugs: empty_eligibleVoyageSlugs,
  email: undefined,
  paxStatus: "TBA",
  idx: 0,
  shouldAttachToIdx: undefined,
  paxPatchPerm: "None",
  cruiselineBookingNumber: undefined,
  halMarinerNumber: undefined
};

var genderOptions = [
  "F",
  "M",
  "X"
];

function genderToText(g) {
  if (!(g === "F" || g === "M" || g === "X")) {
    return "Unspecified";
  }
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

function getPaxDisplayName(p) {
  var name = p.preferredFullName;
  if (name !== undefined) {
    return name;
  }
  var match = p.docFName;
  var match$1 = p.docLName;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
    } else {
      return "TBA";
    }
  }
  var fName = Caml_option.valFromOption(match);
  if (match$1 !== undefined) {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
  } else {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
  }
}

var convertVariables = DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.node, convertVariables);

var Query_docGender_decode = DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.Utils.docGender_decode;

var Query_docGender_fromString = DraftUtilsFetchLoyaltyUsersQuery_graphql$Thin.Utils.docGender_fromString;

var Query = {
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function use$1(diff, _voyageSlug, _bookingId) {
  var match = React.useState(function () {
        
      });
  var setLoyaltyUsers = match[1];
  var match$1 = diff.attachExistingLoyaltyPax;
  var match$2 = diff.replaceExistingLoyaltyPax;
  var exit = 0;
  if (match$1 !== undefined || match$2 !== undefined) {
    exit = 1;
  }
  if (exit === 1) {
    var loyaltyArray = Belt_Array.concat(Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.attachExistingLoyaltyPax, undefined)), (function (param) {
                return param[1];
              })), Belt_Array.map(Belt_MapInt.toArray(Belt_Option.getWithDefault(diff.replaceExistingLoyaltyPax, undefined)), (function (param) {
                return param[1];
              })));
    var _loyaltyNumbers = Belt_Array.map(loyaltyArray, (function (user) {
            return Caml_option.some(user.loyalty);
          }));
    var acctIdToLoyNum = Belt_Array.reduce(loyaltyArray, undefined, (function (acc, user) {
            return Belt_MapString.set(acc, CS_NonemptyStrings$Util.AccountId.toString(user.acctId), [
                        user.loyalty,
                        CS_NonemptyStrings$Util.NonEmptyString.toString(user.protectedName)
                      ]);
          }));
    $$fetch(RelayEnv$Thin.environment, {
          _bookingId: _bookingId,
          _loyaltyNumbers: _loyaltyNumbers,
          _voyageSlug: _voyageSlug
        }, (function (res) {
            if (res.TAG !== "Ok") {
              return ;
            }
            var match = res._0.lookupDetailsByLoyalty;
            if (match === undefined) {
              return ;
            }
            var map = Belt_MapString.fromArray(Belt_Array.keepMap(match.nodes, (function (node) {
                        var account = node.accountId;
                        if (account === undefined) {
                          return ;
                        }
                        var account$1 = Caml_option.valFromOption(account);
                        var newrecord = Caml_obj.obj_dup(empty);
                        newrecord.halMarinerNumber = node.halMarinerNumber;
                        newrecord.paxPatchPerm = "Limited";
                        newrecord.email = node.email;
                        newrecord.loyaltyTier = node.currentTierTitle;
                        newrecord.numLoyaltyVoyages = Belt_Option.getWithDefault(node.numEligibleVoyages, 0);
                        var match = Belt_MapString.get(acctIdToLoyNum, CS_NonemptyStrings$Util.AccountId.toString(account$1));
                        newrecord.loyaltyNumber = match !== undefined ? Caml_option.some(match[0]) : undefined;
                        var match$1 = node.firstName;
                        var match$2 = node.lastName;
                        var tmp;
                        if (match$1 !== undefined) {
                          var fName = Caml_option.valFromOption(match$1);
                          tmp = match$2 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$2)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
                        } else if (match$2 !== undefined) {
                          tmp = CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$2));
                        } else {
                          var match$3 = Belt_MapString.get(acctIdToLoyNum, CS_NonemptyStrings$Util.AccountId.toString(account$1));
                          tmp = match$3 !== undefined ? match$3[1] : undefined;
                        }
                        newrecord.preferredFullName = tmp;
                        return [
                                CS_NonemptyStrings$Util.AccountId.toString(account$1),
                                (newrecord.birthdate = node.docBirthdate, newrecord.preferredLName = node.lastName, newrecord.preferredFName = node.firstName, newrecord.docCitizenship = node.docCitizenship, newrecord.docGender = node.docGender, newrecord.docLName = node.docLastName, newrecord.docFName = node.docFirstName, newrecord.accountId = Caml_option.some(account$1), newrecord)
                              ];
                      })));
            setLoyaltyUsers(function (param) {
                  return Caml_option.some(map);
                });
          }), undefined, undefined);
  }
  return match[0];
}

var FetchLoyaltyUsers = {
  Query: Query,
  use: use$1
};

function paxify(paxes) {
  return Belt_Array.map(paxes, (function (pax) {
                var bu = pax.bu;
                var eligibleVoyageSlugs = pax.eligibleVoyageSlugs;
                var match = pax.paxDataStatus;
                var tmp;
                tmp = (match === "INCOMPLETE" || match === "CONFIRMED" || match === "INVITED") && match === "INVITED" ? pax.invitationEmail : pax.email;
                return {
                        accountId: Caml_option.some(pax.accountId),
                        docFName: pax.docFirstName,
                        docLName: pax.docLastName,
                        docGender: pax.docGender,
                        docCitizenship: pax.docCitizenship,
                        preferredFName: pax.preferredFirstName,
                        preferredLName: pax.preferredLastName,
                        birthdate: pax.docBirthdate,
                        preferredFullName: pax.preferredFullName,
                        loyaltyNumber: bu !== undefined ? Caml_option.some(bu.loyaltyNumber) : undefined,
                        numLoyaltyVoyages: Belt_Option.getWithDefault(pax.numEligibleVoyages, 0),
                        loyaltyTier: pax.currentTierTitle,
                        eligibleVoyageSlugs: eligibleVoyageSlugs !== undefined ? Belt_Array.keepMap(eligibleVoyageSlugs, (function (v) {
                                  if (v !== undefined) {
                                    return Caml_option.some(Caml_option.valFromOption(v));
                                  }
                                  
                                })) : [],
                        email: tmp,
                        paxStatus: {
                          TAG: "Status",
                          _0: pax.paxDataStatus
                        },
                        idx: pax.idx,
                        shouldAttachToIdx: undefined,
                        paxPatchPerm: "Limited",
                        cruiselineBookingNumber: Belt_Option.map(pax.cruiselineBookingNumber, CS_NonemptyStrings$Util.CruiselineBookingNumber.toString),
                        halMarinerNumber: pax.halMarinerNumber
                      };
              }));
}

function getPaxPatchFromPax(pax, accountId) {
  return {
          accountId: accountId,
          pDocGender: pax.docGender,
          pDocCitizenship: pax.docCitizenship,
          updateUserAccount: true,
          updateOtherVoyages: []
        };
}

function getConnectedPatchFromPax(pax) {
  return {
          cpDocFirstName: Belt_Option.map(pax.docFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive),
          cpDocLastName: Belt_Option.map(pax.docLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive),
          cpDocGender: pax.docGender,
          cpDocCitizenship: pax.docCitizenship,
          cpBirthdate: pax.birthdate
        };
}

function postMushIdxMap(paxes) {
  return Belt_Array.reduceWithIndex(paxes, undefined, (function (acc, pax, i) {
                return Belt_MapInt.set(acc, pax.idx, i + 1 | 0);
              }));
}

function getFullPreferredName(pax) {
  var match = pax.preferredFName;
  var match$1 = pax.preferredLName;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
    } else {
      return ;
    }
  }
  var fName = Caml_option.valFromOption(match);
  if (match$1 !== undefined) {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
  } else {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
  }
}

function replaceDropDetachOrReturn(paxes, diff, connectedAccounts, ownAccount, toAttachOrReplaceExisting) {
  var existingPaxMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
  return Belt_Array.keep(Belt_Array.keep(Belt_Array.keep(Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                                        var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceInvitedPax, undefined), pax.idx);
                                        if (match === undefined) {
                                          return pax;
                                        }
                                        var lastName = match.lastName;
                                        var firstName = match.firstName;
                                        var newrecord = Caml_obj.obj_dup(empty);
                                        newrecord.paxPatchPerm = "None";
                                        newrecord.idx = pax.idx;
                                        newrecord.paxStatus = {
                                          TAG: "Draft",
                                          _0: "Invite"
                                        };
                                        newrecord.email = Caml_option.some(match.email);
                                        var lastName$1 = Belt_Option.map(lastName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
                                        newrecord.preferredFullName = CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(firstName) + (
                                          lastName$1 !== undefined ? " " + lastName$1 : ""
                                        );
                                        newrecord.preferredLName = lastName;
                                        newrecord.preferredFName = Caml_option.some(firstName);
                                        return newrecord;
                                      })), (function (pax) {
                                    var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceExistingLoyaltyPax, undefined), pax.idx);
                                    if (match === undefined) {
                                      return pax;
                                    }
                                    var acctId = match.acctId;
                                    var newrecord = Caml_obj.obj_dup(empty);
                                    newrecord.paxPatchPerm = "None";
                                    newrecord.idx = pax.idx;
                                    newrecord.paxStatus = {
                                      TAG: "Draft",
                                      _0: "Replace"
                                    };
                                    newrecord.email = match.loyEmail;
                                    newrecord.loyaltyNumber = Caml_option.some(match.loyalty);
                                    newrecord.preferredFullName = CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.protectedName);
                                    newrecord.accountId = Caml_option.some(acctId);
                                    var passenger = Belt_MapString.get(existingPaxMap, CS_NonemptyStrings$Util.AccountId.toString(acctId));
                                    if (passenger === undefined) {
                                      return newrecord;
                                    }
                                    var newrecord$1 = Caml_obj.obj_dup(passenger);
                                    newrecord$1.paxStatus = {
                                      TAG: "Draft",
                                      _0: "Replace"
                                    };
                                    return newrecord$1;
                                  })), (function (pax) {
                                var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replacePaxExistingConnected, undefined), pax.idx);
                                if (accountId === undefined) {
                                  return pax;
                                }
                                if (connectedAccounts === undefined) {
                                  return pax;
                                }
                                var accountId$1 = Caml_option.valFromOption(accountId);
                                var match = Belt_Array.get(Belt_Array.keep(connectedAccounts.nodes, (function (acct) {
                                            return Caml_obj.equal(acct.accountId, accountId$1);
                                          })), 0);
                                if (match !== undefined) {
                                  var newrecord = Caml_obj.obj_dup(empty);
                                  newrecord.paxPatchPerm = "Limited";
                                  newrecord.idx = pax.idx;
                                  newrecord.paxStatus = {
                                    TAG: "Draft",
                                    _0: "Replace"
                                  };
                                  newrecord.email = match.email;
                                  newrecord.loyaltyTier = match.currentTierTitle;
                                  newrecord.numLoyaltyVoyages = Belt_Option.getWithDefault(match.numEligibleVoyages, 0);
                                  newrecord.loyaltyNumber = Caml_option.some(match.loyaltyNumber);
                                  newrecord.preferredFullName = match.fullDisplayName;
                                  newrecord.birthdate = match.legalDocumentBirthdate;
                                  newrecord.preferredLName = match.lastName;
                                  newrecord.preferredFName = Caml_option.some(match.firstName);
                                  newrecord.docCitizenship = match.legalDocumentCitizenship;
                                  newrecord.docGender = match.legalDocumentGender;
                                  newrecord.docLName = match.legalDocumentLastName;
                                  newrecord.docFName = match.legalDocumentFirstName;
                                  newrecord.accountId = Caml_option.some(match.accountId);
                                  return newrecord;
                                }
                                if (ownAccount === undefined) {
                                  return pax;
                                }
                                var match$1 = Belt_Array.get(Belt_Array.keep(ownAccount.nodes, (function (acct) {
                                            return Caml_obj.equal(acct.accountId, accountId$1);
                                          })), 0);
                                if (match$1 === undefined) {
                                  return pax;
                                }
                                var eligibleVoyageSlugs = match$1.eligibleVoyageSlugs;
                                var newrecord$1 = Caml_obj.obj_dup(empty);
                                newrecord$1.paxPatchPerm = "Limited";
                                newrecord$1.idx = pax.idx;
                                newrecord$1.paxStatus = {
                                  TAG: "Draft",
                                  _0: "Replace"
                                };
                                newrecord$1.email = match$1.email;
                                newrecord$1.eligibleVoyageSlugs = eligibleVoyageSlugs !== undefined ? Belt_Array.keepMap(eligibleVoyageSlugs, (function (v) {
                                          if (v !== undefined) {
                                            return Caml_option.some(Caml_option.valFromOption(v));
                                          }
                                          
                                        })) : [];
                                newrecord$1.loyaltyTier = match$1.currentTierTitle;
                                newrecord$1.numLoyaltyVoyages = Belt_Option.getWithDefault(match$1.numEligibleVoyages, 0);
                                newrecord$1.loyaltyNumber = Caml_option.some(match$1.loyaltyNumber);
                                newrecord$1.preferredFullName = getFullPreferredName(pax);
                                newrecord$1.birthdate = match$1.legalDocumentBirthdate;
                                newrecord$1.preferredLName = match$1.lastName;
                                newrecord$1.preferredFName = match$1.firstName;
                                newrecord$1.docCitizenship = match$1.legalDocumentCitizenship;
                                newrecord$1.docGender = match$1.legalDocumentGender;
                                newrecord$1.docLName = match$1.legalDocumentLastName;
                                newrecord$1.docFName = match$1.legalDocumentFirstName;
                                newrecord$1.accountId = Caml_option.some(match$1.accountId);
                                return newrecord$1;
                              })), (function (pax) {
                            var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceNewConnectedPax, undefined), pax.idx);
                            if (match === undefined) {
                              return pax;
                            }
                            var preferredLastName = match.preferredLastName;
                            var preferredFirstName = match.preferredFirstName;
                            var docLastName = match.docLastName;
                            var docFirstName = match.docFirstName;
                            var newrecord = Caml_obj.obj_dup(empty);
                            newrecord.paxPatchPerm = "All";
                            newrecord.idx = pax.idx;
                            newrecord.paxStatus = {
                              TAG: "Draft",
                              _0: "Replace"
                            };
                            newrecord.email = undefined;
                            var tmp;
                            if (preferredFirstName !== undefined) {
                              var fName = Caml_option.valFromOption(preferredFirstName);
                              tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
                            } else {
                              tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(docFirstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(docLastName);
                            }
                            newrecord.preferredFullName = tmp;
                            newrecord.birthdate = Caml_option.some(match.docBirthdate);
                            newrecord.preferredLName = preferredLastName;
                            newrecord.preferredFName = preferredFirstName;
                            newrecord.docCitizenship = Caml_option.some(match.docCitizenship);
                            newrecord.docGender = match.docGender;
                            newrecord.docLName = Caml_option.some(docLastName);
                            newrecord.docFName = Caml_option.some(docFirstName);
                            return newrecord;
                          })), (function (pax) {
                        var match = diff.dropBerths;
                        var match$1 = pax.idx;
                        if (match !== undefined) {
                          return !Belt_Array.some(match, (function (b) {
                                        return b === match$1;
                                      }));
                        } else {
                          return true;
                        }
                      })), (function (pax) {
                    var match = diff.detachPax;
                    var match$1 = pax.idx;
                    if (match !== undefined) {
                      return !Belt_Array.some(match, (function (b) {
                                    return b === match$1;
                                  }));
                    } else {
                      return true;
                    }
                  })), (function (pax) {
                var match = diff.revokePaxInvitation;
                var match$1 = pax.paxStatus;
                var match$2 = pax.idx;
                if (match === undefined) {
                  return true;
                }
                if (typeof match$1 !== "object") {
                  return true;
                }
                if (match$1.TAG !== "Status") {
                  return true;
                }
                var tmp = match$1._0;
                if ((tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") && tmp === "INVITED") {
                  return !Belt_MapInt.has(Caml_option.valFromOption(match), match$2);
                } else {
                  return true;
                }
              }));
}

function attachOrReturn(paxes, diff, connectedAccounts, ownAccount, toAttachOrReplaceExisting) {
  var existingPaxMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
  return Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                            var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachInvitedPax, undefined), pax.idx);
                            if (match === undefined) {
                              return pax;
                            }
                            var lastName = match.lastName;
                            var firstName = match.firstName;
                            var newrecord = Caml_obj.obj_dup(pax);
                            newrecord.paxPatchPerm = "None";
                            newrecord.shouldAttachToIdx = undefined;
                            newrecord.idx = pax.idx;
                            newrecord.paxStatus = {
                              TAG: "Draft",
                              _0: "Invite"
                            };
                            newrecord.email = Caml_option.some(match.email);
                            var lastName$1 = Belt_Option.map(lastName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
                            newrecord.preferredFullName = CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(firstName) + (
                              lastName$1 !== undefined ? " " + lastName$1 : ""
                            );
                            newrecord.preferredLName = lastName;
                            newrecord.preferredFName = Caml_option.some(firstName);
                            return newrecord;
                          })), (function (pax) {
                        var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachExistingLoyaltyPax, undefined), pax.idx);
                        if (match === undefined) {
                          return pax;
                        }
                        var acctId = match.acctId;
                        var newrecord = Caml_obj.obj_dup(empty);
                        newrecord.paxPatchPerm = "None";
                        newrecord.idx = pax.idx;
                        newrecord.paxStatus = {
                          TAG: "Draft",
                          _0: "Attach"
                        };
                        newrecord.email = match.loyEmail;
                        newrecord.loyaltyNumber = Caml_option.some(match.loyalty);
                        newrecord.preferredFullName = CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.protectedName);
                        newrecord.accountId = Caml_option.some(acctId);
                        var passenger = Belt_MapString.get(existingPaxMap, CS_NonemptyStrings$Util.AccountId.toString(acctId));
                        if (passenger === undefined) {
                          return newrecord;
                        }
                        var newrecord$1 = Caml_obj.obj_dup(passenger);
                        newrecord$1.paxStatus = {
                          TAG: "Draft",
                          _0: "Attach"
                        };
                        return newrecord$1;
                      })), (function (pax) {
                    var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachExistingConnectedPax, undefined), pax.idx);
                    if (accountId === undefined) {
                      return pax;
                    }
                    if (connectedAccounts === undefined) {
                      return pax;
                    }
                    var accountId$1 = Caml_option.valFromOption(accountId);
                    var match = Belt_Array.get(Belt_Array.keep(connectedAccounts.nodes, (function (acct) {
                                return Caml_obj.equal(acct.accountId, accountId$1);
                              })), 0);
                    if (match !== undefined) {
                      return {
                              accountId: Caml_option.some(match.accountId),
                              docFName: match.legalDocumentFirstName,
                              docLName: match.legalDocumentLastName,
                              docGender: match.legalDocumentGender,
                              docCitizenship: match.legalDocumentCitizenship,
                              preferredFName: Caml_option.some(match.firstName),
                              preferredLName: match.lastName,
                              birthdate: match.legalDocumentBirthdate,
                              preferredFullName: match.fullDisplayName,
                              loyaltyNumber: Caml_option.some(match.loyaltyNumber),
                              numLoyaltyVoyages: Belt_Option.getWithDefault(match.numEligibleVoyages, 0),
                              loyaltyTier: match.currentTierTitle,
                              eligibleVoyageSlugs: [],
                              email: match.email,
                              paxStatus: {
                                TAG: "Draft",
                                _0: "Attach"
                              },
                              idx: pax.idx,
                              shouldAttachToIdx: undefined,
                              paxPatchPerm: "Limited",
                              cruiselineBookingNumber: undefined,
                              halMarinerNumber: match.halMarinerNumber
                            };
                    }
                    if (ownAccount === undefined) {
                      return pax;
                    }
                    var match$1 = Belt_Array.get(Belt_Array.keep(ownAccount.nodes, (function (acct) {
                                return Caml_obj.equal(acct.accountId, accountId$1);
                              })), 0);
                    if (match$1 === undefined) {
                      return pax;
                    }
                    var eligibleVoyageSlugs = match$1.eligibleVoyageSlugs;
                    return {
                            accountId: Caml_option.some(match$1.accountId),
                            docFName: match$1.legalDocumentFirstName,
                            docLName: match$1.legalDocumentLastName,
                            docGender: match$1.legalDocumentGender,
                            docCitizenship: match$1.legalDocumentCitizenship,
                            preferredFName: match$1.firstName,
                            preferredLName: match$1.lastName,
                            birthdate: match$1.legalDocumentBirthdate,
                            preferredFullName: getFullPreferredName(pax),
                            loyaltyNumber: Caml_option.some(match$1.loyaltyNumber),
                            numLoyaltyVoyages: Belt_Option.getWithDefault(match$1.numEligibleVoyages, 0),
                            loyaltyTier: match$1.currentTierTitle,
                            eligibleVoyageSlugs: eligibleVoyageSlugs !== undefined ? Belt_Array.keepMap(eligibleVoyageSlugs, (function (v) {
                                      if (v !== undefined) {
                                        return Caml_option.some(Caml_option.valFromOption(v));
                                      }
                                      
                                    })) : [],
                            email: match$1.email,
                            paxStatus: {
                              TAG: "Draft",
                              _0: "Replace"
                            },
                            idx: pax.idx,
                            shouldAttachToIdx: undefined,
                            paxPatchPerm: "Limited",
                            cruiselineBookingNumber: undefined,
                            halMarinerNumber: match$1.halMarinerNumber
                          };
                  })), (function (pax) {
                var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachNewConnectedPax, undefined), pax.idx);
                if (match === undefined) {
                  return pax;
                }
                var preferredLastName = match.preferredLastName;
                var preferredFirstName = match.preferredFirstName;
                var newrecord = Caml_obj.obj_dup(pax);
                newrecord.paxPatchPerm = "All";
                newrecord.shouldAttachToIdx = undefined;
                newrecord.idx = pax.idx;
                newrecord.paxStatus = {
                  TAG: "Draft",
                  _0: "Attach"
                };
                newrecord.email = undefined;
                var tmp;
                if (preferredFirstName !== undefined) {
                  var fName = Caml_option.valFromOption(preferredFirstName);
                  tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
                } else {
                  tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : undefined;
                }
                newrecord.preferredFullName = tmp;
                newrecord.birthdate = Caml_option.some(match.docBirthdate);
                newrecord.preferredLName = preferredLastName;
                newrecord.preferredFName = preferredFirstName;
                newrecord.docCitizenship = Caml_option.some(match.docCitizenship);
                newrecord.docGender = match.docGender;
                newrecord.docLName = Caml_option.some(match.docLastName);
                newrecord.docFName = Caml_option.some(match.docFirstName);
                return newrecord;
              }));
}

function patchPaxes(paxes, diff) {
  return Belt_Array.map(paxes, (function (pax) {
                var reco = Belt_MapInt.get(Belt_Option.getWithDefault(diff.patchPax, undefined), pax.idx);
                if (reco === undefined) {
                  return pax;
                }
                var newrecord = Caml_obj.obj_dup(pax);
                newrecord.docCitizenship = pax.docCitizenship === reco.pDocCitizenship ? pax.docCitizenship : reco.pDocCitizenship;
                newrecord.docGender = pax.docGender === reco.pDocGender ? pax.docGender : reco.pDocGender;
                return newrecord;
              }));
}

function createTBAs(attachIndex, paxCount, numBerths) {
  var _i = attachIndex;
  var _paxCount = paxCount;
  var _acc = [];
  while(true) {
    var acc = _acc;
    var paxCount$1 = _paxCount;
    var i = _i;
    if (paxCount$1 >= numBerths) {
      return acc;
    }
    var newrecord = Caml_obj.obj_dup(empty);
    _acc = Belt_Array.concat(acc, [(newrecord.paxPatchPerm = "None", newrecord.shouldAttachToIdx = attachIndex, newrecord.idx = i, newrecord)]);
    _paxCount = paxCount$1 + 1 | 0;
    _i = i + 1 | 0;
    continue ;
  };
}

function generatePaxList(paxes, diff, connectedAccounts, ownAccount, numBerths, bookingId, voyageSlug) {
  var toAttachOrReplaceExisting = use$1(diff, voyageSlug, bookingId);
  var nonTBAPaxes = replaceDropDetachOrReturn(paxify(paxes), diff, connectedAccounts, ownAccount, toAttachOrReplaceExisting);
  var attachIndex = Belt_Array.reduce(nonTBAPaxes, 0, (function (acc, pax) {
          return Math.max(acc, pax.idx);
        })) + 1 | 0;
  return patchPaxes(Belt_Array.concat(nonTBAPaxes, attachOrReturn(createTBAs(attachIndex, nonTBAPaxes.length, numBerths), diff, connectedAccounts, ownAccount, toAttachOrReplaceExisting)), diff);
}

var Pax = {
  empty: empty,
  genderOptions: genderOptions,
  genderToText: genderToText,
  getPaxDisplayName: getPaxDisplayName,
  FetchLoyaltyUsers: FetchLoyaltyUsers,
  paxify: paxify,
  getPaxPatchFromPax: getPaxPatchFromPax,
  getConnectedPatchFromPax: getConnectedPatchFromPax,
  postMushIdxMap: postMushIdxMap,
  getFullPreferredName: getFullPreferredName,
  replaceDropDetachOrReturn: replaceDropDetachOrReturn,
  attachOrReturn: attachOrReturn,
  patchPaxes: patchPaxes,
  createTBAs: createTBAs,
  generatePaxList: generatePaxList
};

function addToDraft(diff, url) {
  var searchStr = BookingDiff$Thin.serialize(diff);
  var urlStr = "/" + Belt_List.toArray(url.path).join("/");
  var url$1 = searchStr === "" ? urlStr : urlStr + "?" + searchStr;
  RescriptReactRouter.push(url$1);
}

function makeBookingDiffInput(diff, postMushIdxMap) {
  var dropBerths = Belt_Option.map(diff.dropBerths, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        return b;
                      }));
        }));
  var detachPax = Belt_Option.map(diff.detachPax, (function (pax) {
          return Belt_Array.map(pax, (function (a) {
                        return a;
                      }));
        }));
  var intMapToOptionWrappedArray = function (innerMapFn, intMap, idxMap) {
    return Belt_Array.map(Belt_MapInt.toArray(intMap), (function (p) {
                  return innerMapFn(p, idxMap);
                }));
  };
  var mapPaxApplication = function (p, idxMap) {
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            accountId: p[1]
          };
  };
  var mapPaxPatch = function (p, param) {
    var reco = p[1];
    var updateOtherVoyages = reco.updateOtherVoyages;
    var docGender = Belt_Option.map(reco.pDocGender, (function (g) {
            return Belt_Option.getWithDefault(ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode(g), "X");
          }));
    var updateOtherVoyages$1 = updateOtherVoyages.length === 0 ? undefined : Belt_Array.map(updateOtherVoyages, (function (v) {
              return Caml_option.some(v);
            }));
    return {
            accountId: reco.accountId,
            docGender: docGender,
            docCitizenship: reco.pDocCitizenship,
            updateOtherVoyages: updateOtherVoyages$1,
            updateUserAccount: reco.updateUserAccount
          };
  };
  var mapPaxInvitation = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            invitationEmail: reco.email,
            preferredFirstName: reco.firstName,
            preferredLastName: reco.lastName
          };
  };
  var mapNewConnectedPax = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            custodianAccountId: reco.custodianAccountId,
            docFirstname: reco.docFirstName,
            docLastname: reco.docLastName,
            docGender: Belt_Option.getWithDefault(ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode(reco.docGender), "X"),
            docCitizenship: reco.docCitizenship,
            docBirthdate: reco.docBirthdate,
            preferredFirstName: reco.preferredFirstName,
            preferredLastName: reco.preferredLastName
          };
  };
  var mapExistingLoyaltyPax = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            accountId: reco.acctId,
            loyaltyNumber: reco.loyalty
          };
  };
  var attachExistingConnectedPax = Belt_Option.map(diff.attachExistingConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxExistingConnected = Belt_Option.map(diff.replacePaxExistingConnected, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var revokePaxInvitation = Belt_Option.map(diff.revokePaxInvitation, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var attachInvitedPax = Belt_Option.map(diff.attachInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxInvited = Belt_Option.map(diff.replaceInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, undefined);
        }));
  var attachNewConnectedPax = Belt_Option.map(diff.attachNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, Caml_option.some(postMushIdxMap));
        }));
  var replaceNewConnectedPax = Belt_Option.map(diff.replaceNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, undefined);
        }));
  var attachExistingLoyaltyPax = Belt_Option.map(diff.attachExistingLoyaltyPax, (function (m) {
          return intMapToOptionWrappedArray(mapExistingLoyaltyPax, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxExistingLoyalty = Belt_Option.map(diff.replaceExistingLoyaltyPax, (function (m) {
          return intMapToOptionWrappedArray(mapExistingLoyaltyPax, m, undefined);
        }));
  var patchPax = Belt_Option.map(diff.patchPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxPatch, m, undefined);
        }));
  var updateBookingPreferences = Belt_Option.map(diff.updateBookingPreferences, (function (param) {
          var cabinLongitudePref = Belt_Option.map(param.cabinLongitudePref, (function (p) {
                  return Belt_Option.getWithDefault(BDPreferences_detail_graphql$Thin.Utils.longitudinality_decode(p), "FORE");
                }));
          var cabinElevatorPref = Belt_Option.map(param.cabinElevatorPref, (function (p) {
                  return Belt_Option.getWithDefault(BDPreferences_detail_graphql$Thin.Utils.elevatorProximity_decode(p), "FARELEVATORS");
                }));
          return {
                  bedsTogetherPref: param.bedsTogetherPref,
                  cabinLongitudePref: cabinLongitudePref,
                  cabinElevatorPref: cabinElevatorPref,
                  cabinPlacementRequest: param.cabinPlacementRequest,
                  earlyDinnerSeatingPref: param.earlyDinnerSeatingPref,
                  generalPref: param.generalPref
                };
        }));
  return {
          dropBerths: dropBerths,
          addBerths: diff.addBerths,
          detachPax: detachPax,
          attachExistingConnectedPax: attachExistingConnectedPax,
          attachExistingLoyaltyPax: attachExistingLoyaltyPax,
          attachInvitedPax: attachInvitedPax,
          attachNewConnectedPax: attachNewConnectedPax,
          replacePaxExistingConnected: replacePaxExistingConnected,
          replacePaxExistingLoyalty: replacePaxExistingLoyalty,
          replacePaxInvited: replacePaxInvited,
          replaceNewConnectedPax: replaceNewConnectedPax,
          revokePaxInvitation: revokePaxInvitation,
          changeCabinUnbooked: diff.changeCabinU,
          updateBookingPreferences: updateBookingPreferences,
          patchPax: patchPax
        };
}

function createPreviewCallback(bookingId, bookingVersionNumber, voyage, postMushIdxMap) {
  return function (diff) {
    return {
            bookingId: bookingId,
            bookingVersionNumber: bookingVersionNumber,
            voyage: voyage,
            thinDiff: makeBookingDiffInput(diff, postMushIdxMap)
          };
  };
}

var Preview = {
  makeBookingDiffInput: makeBookingDiffInput,
  createPreviewCallback: createPreviewCallback
};

exports.Pax = Pax;
exports.addToDraft = addToDraft;
exports.Preview = Preview;
/* use Not a pure module */
