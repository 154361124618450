// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var $$Option = require("@mui/joy/Option").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Check = require("@mui/icons-material/Check").default;
var CircularProgress = require("@mui/joy/CircularProgress").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var AccountCircleOutlined = require("@mui/icons-material/AccountCircleOutlined").default;

function ConnectedSelector$SaveButton(props) {
  var setLocalError = props.setLocalError;
  var handler = props.handler;
  return JsxRuntime.jsx(AppButton$Thin.make, {
              loading: props.loading,
              onClick: (function (param) {
                  if (handler.TAG === "Ok") {
                    return handler._0();
                  } else if (setLocalError !== undefined) {
                    return setLocalError(function (param) {
                                return "Error saving passenger information";
                              });
                  } else {
                    return ;
                  }
                }),
              disabled: Belt_Result.isError(handler),
              children: "Save"
            });
}

var SaveButton = {
  make: ConnectedSelector$SaveButton
};

function ConnectedSelector(props) {
  var setConnectedError = props.setConnectedError;
  var connectedError = props.connectedError;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var sessionPax = props.sessionPax;
  var paxNum = props.paxNum;
  var isSelected = props.isSelected;
  var isPaxOne = paxNum === 1;
  var sessionData = HopperState$Util.Observable.getRoot(sessionPax, "RetainSkipUpdateFromView");
  var match = HopperState$Util.Observable.useComputed(sessionPax, (function (p) {
          return [
                  p.accountId,
                  p.isComplete
                ];
        }), undefined, undefined);
  var isComplete = match[1];
  var accountId = match[0];
  var allTravelDocFieldsComplete = HopperState$Util.Observable.useComputed(sessionPax, SFState$Thin.allTravelDocFieldsComplete, undefined, undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setConnectedLoading = match$1[1];
  var connectedOptions = SFState$Thin.getConnectedSelectOptions(props.currentUserConnectedInfo, props.voyageSlug);
  var selectedConnected;
  if (accountId !== undefined) {
    var acctId = Caml_option.valFromOption(accountId);
    var u = Belt_Array.get(Belt_Array.keep(connectedOptions, (function (v) {
                return Caml_obj.equal(v.cId, acctId);
              })), 0);
    selectedConnected = u !== undefined ? u : undefined;
  } else {
    selectedConnected = undefined;
  }
  var alreadyUsed = HopperState$Util.Observable.useComputed(sessionData, (function (param) {
          return Belt_Array.keepMap(Belt_MapInt.toArray(param.paxes), (function (param) {
                        var v = param[1];
                        var match = v.paxType;
                        var match$1 = v.accountId;
                        if (match !== undefined && match === "AddConnected" && match$1 !== undefined) {
                          return CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(match$1));
                        }
                        
                      }));
        }), undefined, undefined);
  var sessionSaveHandler = props.useSessionSaveHandler(sessionData, setConnectedError, (function () {
          setConnectedLoading(function (param) {
                return false;
              });
          if (!isComplete && allTravelDocFieldsComplete) {
            HopperState$Util.Observable.notify(sessionPax, (function (p) {
                    var newrecord = Caml_obj.obj_dup(p);
                    newrecord.isComplete = true;
                    return newrecord;
                  }));
          }
          
        }), setConnectedLoading);
  React.useEffect((function () {
          if (isSelected && selectedConnected !== undefined && sessionSaveHandler.TAG === "Ok") {
            sessionSaveHandler._0();
          }
          
        }), [Belt_Option.getWithDefault(Belt_Option.flatMap(selectedConnected, (function (v) {
                    return JSON.stringify(v);
                  })), "")]);
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsxs(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            spacing: 2,
                            sx: {
                              pt: 1
                            },
                            children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedLabel.value)
                                            }),
                                        JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                              children: Caml_option.some(Belt_Array.mapWithIndex(connectedOptions, (function (param, p) {
                                                          var acctId = CS_NonemptyStrings$Util.AccountId.toString(p.cId);
                                                          var name = p.cName;
                                                          var isAgeGated = SFState$Thin.isAgeGated(p.cBirthdate, pax1MustBeBornBy, isPaxOne);
                                                          var match = Belt_Array.some(alreadyUsed, (function (v) {
                                                                  return v === acctId;
                                                                })) || !p.bookable ? [
                                                              true,
                                                              JsxRuntime.jsx(DirectionsBoat, {}),
                                                              "Already booked"
                                                            ] : (
                                                              isAgeGated ? [
                                                                  true,
                                                                  JsxRuntime.jsx(DoNotDisturbAlt, {}),
                                                                  "Cannot be primary"
                                                                ] : [
                                                                  false,
                                                                  JsxRuntime.jsx(AccountCircleOutlined, {}),
                                                                  ""
                                                                ]
                                                            );
                                                          return JsxRuntime.jsxs($$Option, {
                                                                      value: p,
                                                                      children: [
                                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                                              children: Caml_option.some(match[1]),
                                                                              sx: {
                                                                                "--ListItemDecorator-size": "1.75rem"
                                                                              }
                                                                            }),
                                                                        name
                                                                      ],
                                                                      disabled: match[0]
                                                                    }, acctId);
                                                        }))),
                                              onChange: (function (param, v) {
                                                  if (v === undefined) {
                                                    return ;
                                                  }
                                                  var match = Caml_option.valFromOption(v);
                                                  if (match === undefined) {
                                                    return ;
                                                  }
                                                  var prof = match.prof;
                                                  if (accountId !== undefined && Caml_obj.equal(match.cId, Caml_option.valFromOption(accountId))) {
                                                    return ;
                                                  }
                                                  setConnectedLoading(function (param) {
                                                        return true;
                                                      });
                                                  HopperState$Util.Observable.notify(sessionPax, (function (param) {
                                                          return SFState$Thin.prefillFromConnectedAccount(SFState$Thin.blankPax(paxNum), prof);
                                                        }));
                                                }),
                                              placeholder: Txt$Thin.BookingDetails.addPassConnectedPlaceholder.value,
                                              slotProps: {
                                                listbox: {
                                                  sx: {
                                                    zIndex: "modal"
                                                  }
                                                }
                                              },
                                              value: selectedConnected,
                                              variant: "outlined"
                                            }),
                                        JsxRuntime.jsx(FormHelperText, {
                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedHelperText.value)
                                            })
                                      ]
                                    }))
                          }),
                      match$1[0] ? JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                                        size: "md",
                                        variant: "solid"
                                      }))
                            }) : (
                          selectedConnected !== undefined ? (
                              isComplete ? JsxRuntime.jsx(Alert, {
                                      children: "Passenger information is on file. You can update it later.",
                                      color: "success",
                                      startDecorator: Caml_option.some(JsxRuntime.jsx(Check, {})),
                                      sx: {
                                        borderLeft: "4px solid",
                                        borderColor: "success.500",
                                        alignItems: "flex-start"
                                      }
                                    }) : JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      children: [
                                        allTravelDocFieldsComplete ? null : JsxRuntime.jsx(Alert, {
                                                children: "Passenger information is incomplete. Please fill out the form below!",
                                                color: "warning",
                                                startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {})),
                                                sx: {
                                                  borderLeft: "4px solid",
                                                  borderColor: "warningSecondary.500",
                                                  alignItems: "flex-start"
                                                }
                                              }),
                                        JsxRuntime.jsx(Stack, {
                                              sx: {
                                                p: 2,
                                                border: "1px solid",
                                                borderColor: "neutral.300",
                                                backgroundColor: "neutral.200",
                                                borderRadius: "sm"
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                                                        interface: PassengerForms$Thin.Travel.PassengerState.$$interface(sessionPax),
                                                        titleSize: "md",
                                                        disabled: false
                                                      }))
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              direction: "column",
                                              sx: {
                                                width: "fit-content",
                                                py: 1
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(ConnectedSelector$SaveButton, {
                                                        loading: props.loading,
                                                        handler: sessionSaveHandler,
                                                        setLocalError: setConnectedError
                                                      }))
                                            })
                                      ]
                                    })
                            ) : null
                        ),
                      connectedError !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: connectedError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              locked: props.locked
            });
}

var make = ConnectedSelector;

exports.SaveButton = SaveButton;
exports.make = make;
/* react Not a pure module */
