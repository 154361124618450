"use strict";
var scriptPromiseCache = {};
function loadScript(options) {
    var scriptLoadPromise;
    var stringifiedOptions = JSON.stringify(options);
    if (!options.forceScriptReload) {
        scriptLoadPromise = scriptPromiseCache[stringifiedOptions];
        if (scriptLoadPromise) {
            return scriptLoadPromise;
        }
    }
    var script = document.createElement("script");
    var attrs = options.dataAttributes || {};
    var container = options.container || document.head;
    script.src = options.src;
    script.id = options.id || "";
    script.async = true;
    if (options.type) {
        script.setAttribute("type", "".concat(options.type));
    }
    if (options.crossorigin) {
        script.setAttribute("crossorigin", "".concat(options.crossorigin));
    }
    Object.keys(attrs).forEach(function (key) {
        script.setAttribute("data-".concat(key), "".concat(attrs[key]));
    });
    scriptLoadPromise = new Promise(function (resolve, reject) {
        script.addEventListener("load", function () {
            resolve(script);
        });
        script.addEventListener("error", function () {
            reject(new Error("".concat(options.src, " failed to load.")));
        });
        script.addEventListener("abort", function () {
            reject(new Error("".concat(options.src, " has aborted.")));
        });
        container.appendChild(script);
    });
    scriptPromiseCache[stringifiedOptions] = scriptLoadPromise;
    return scriptLoadPromise;
}
loadScript.clearCache = function () {
    scriptPromiseCache = {};
};
module.exports = loadScript;
