// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ConfirmPaxCard$Thin = require("./ConfirmPaxCard.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Close = require("@mui/icons-material/Close").default;
var Search = require("@mui/icons-material/Search").default;
var SearchByLoyaltyNumber$Thin = require("../../common/queries/SearchByLoyaltyNumber.bs.js");
var ReportOutlined = require("@mui/icons-material/ReportOutlined").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function LoyaltySelector(props) {
  var sessionPax = props.sessionPax;
  var isSelected = props.isSelected;
  var isPaxOne = props.paxNum === 1;
  var sessionData = HopperState$Util.Observable.getRoot(sessionPax, "RetainSkipUpdateFromView");
  var match = React.useState(function () {
        
      });
  var saveError = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = AppConfig$Thin.Context.use();
  var match$3 = React.useState(function () {
        
      });
  var setErrorType = match$3[1];
  var errorType = match$3[0];
  var match$4 = HopperState$Util.Observable.useComputed(sessionPax, (function (p) {
          return [
                  p.accountId,
                  SFState$Thin.loyalty.getValue(p)
                ];
        }), undefined, undefined);
  var loyalty = match$4[1];
  var accountId = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setLoyaltyNum = match$5[1];
  var loyaltyNum = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setLoyaltyUser = match$6[1];
  var loyaltyUser = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setSearching = match$7[1];
  var clearAll = function () {
    setLoyaltyUser(function (param) {
          
        });
    setLoyaltyNum(function (param) {
          
        });
    setErrorType(function (param) {
          
        });
    setSearching(function (param) {
          return false;
        });
  };
  var match$8 = SearchByLoyaltyNumber$Thin.useQuery(setErrorType, (function () {
          setErrorType(function (param) {
                
              });
          setLoyaltyUser(function (param) {
                
              });
        }), setSearching, props.voyageSlug, undefined);
  var lookupByLoyalty = match$8.lookupByLoyalty;
  var fetchError = match$8.error;
  var onSuccess = function (l) {
    if (l === undefined) {
      return setErrorType(function (param) {
                  return "Invalid";
                });
    }
    var match = l.travelDocComplete;
    if (isPaxOne && !match) {
      return setErrorType(function (param) {
                  return "IncompletePrimary";
                });
    }
    setLoyaltyUser(function (param) {
          return l;
        });
    if (Caml_obj.equal(Caml_option.some(l.accountId), accountId)) {
      
    } else {
      HopperState$Util.Observable.notify(sessionPax, (function (p) {
              var newrecord = Caml_obj.obj_dup(p);
              return SFState$Thin.loyalty.set((newrecord.accountId = Caml_option.some(l.accountId), newrecord), CS_NonemptyStrings$Util.LoyaltyNumber.toString(l.loyalty), "Value");
            }));
    }
    if (!match) {
      return setErrorType(function (param) {
                  return "Incomplete";
                });
    }
    
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var match = Belt_Option.map(loyaltyNum, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
    if (match !== undefined && match.TAG === "Ok") {
      return lookupByLoyalty(match._0, onSuccess);
    }
    
  };
  var sessionSaveHandler = props.useSessionSaveHandler(sessionData, match[1], undefined, match$1[1]);
  React.useEffect((function () {
          var exit = 0;
          if (!(isPaxOne && !(loyaltyUser !== undefined && loyaltyUser.travelDocComplete))) {
            exit = 1;
          }
          if (exit === 1 && isSelected && loyaltyUser !== undefined && sessionSaveHandler.TAG === "Ok") {
            sessionSaveHandler._0();
            if (!loyaltyUser.travelDocComplete) {
              setErrorType(function (param) {
                    return "Incomplete";
                  });
            }
            
          }
          
        }), [Belt_Option.getWithDefault(Belt_Option.flatMap(loyaltyUser, (function (v) {
                    return JSON.stringify(v);
                  })), "")]);
  var tmp;
  if (loyaltyUser !== undefined && CS_NonemptyStrings$Util.LoyaltyNumber.toString(loyaltyUser.loyalty) === loyaltyNum) {
    var tmp$1;
    if (errorType !== undefined) {
      switch (errorType) {
        case "Invalid" :
        case "IncompletePrimary" :
            tmp$1 = "danger";
            break;
        default:
          tmp$1 = "inherit";
      }
    } else {
      tmp$1 = "inherit";
    }
    tmp = JsxRuntime.jsx(Close, {
          color: tmp$1
        });
  } else {
    tmp = JsxRuntime.jsx(Search, {});
  }
  var endDecorator = JsxRuntime.jsx(IconButton, {
        onClick: (function (param) {
            var match = Belt_Option.map(loyaltyNum, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
            if (loyaltyUser !== undefined) {
              return clearAll();
            } else if (match !== undefined && match.TAG === "Ok") {
              return lookupByLoyalty(match._0, onSuccess);
            } else {
              return ;
            }
          }),
        type: Belt_Option.isNone(loyaltyUser) ? "submit" : "button",
        "aria-label": "search",
        loading: match$7[0],
        children: Caml_option.some(tmp)
      });
  React.useEffect((function () {
          var match = Belt_Option.map(loyalty, CS_NonemptyStrings$Util.LoyaltyNumber.fromPrimitive);
          if (match !== undefined && match.TAG === "Ok") {
            if (isSelected) {
              setLoyaltyNum(function (param) {
                    return loyalty;
                  });
              if (loyaltyUser === undefined) {
                lookupByLoyalty(match._0, onSuccess);
              }
              
            }
            
          }
          
        }), [
        loyalty,
        isSelected
      ]);
  var tmp$2;
  if (errorType !== undefined) {
    switch (errorType) {
      case "Invalid" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Invalid loyalty number"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "We couldn’t find a passenger with that loyalty number that can be added to your booking."
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "IncompletePrimary" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Incomplete account"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "The passenger with that loyalty number has an incomplete travel document record. They must complete the travel document information in their user profile before they can serve as the primary contact in a booking."
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "Incomplete" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "warning",
                                  level: "title-sm",
                                  children: "Incomplete account"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "warning",
                                  level: "body-xs",
                                  children: "This account is incomplete. It can be completed after booking."
                                })
                          ]
                        })),
                color: "warning",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                          sx: {
                            color: "warning.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "warningSecondary.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "AlreadyBooked" :
          tmp$2 = JsxRuntime.jsx(Alert, {
                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "title-sm",
                                  children: "Already booked"
                                }),
                            JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: "body-xs",
                                  children: "The passenger with that loyalty number is already booked on this voyage!"
                                })
                          ]
                        })),
                color: "danger",
                size: "sm",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ReportOutlined, {
                          sx: {
                            color: "danger.500"
                          }
                        })),
                sx: {
                  borderLeft: "4px solid",
                  borderColor: "danger.500",
                  alignItems: "flex-start"
                }
              });
          break;
      case "AlreadyInDraft" :
          tmp$2 = null;
          break;
      
    }
  } else {
    tmp$2 = null;
  }
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsxs(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            component: "form",
                            spacing: 2,
                            sx: {
                              pt: 1
                            },
                            onSubmit: onSubmit,
                            children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                      error: errorType === "Invalid" || errorType === "IncompletePrimary",
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: Caml_option.some(match$2.brandFamily.brandFamilyName + " Loyalty Number")
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Input.make, {
                                              type_: "text",
                                              placeholder: "Loyalty number",
                                              variant: "outlined",
                                              value: Belt_Option.getWithDefault(loyaltyNum, ""),
                                              onChange: (function (e) {
                                                  var val = e.currentTarget.value;
                                                  setLoyaltyNum(function (param) {
                                                        return val;
                                                      });
                                                }),
                                              endDecorator: Caml_option.some(endDecorator)
                                            })
                                      ]
                                    }))
                          }),
                      loyaltyUser !== undefined ? JsxRuntime.jsx(ConfirmPaxCard$Thin.make, {
                              name: CS_NonemptyStrings$Util.NonEmptyString.toString(loyaltyUser.protectedName),
                              email: Belt_Option.map(loyaltyUser.email, CS_Emails$Util.Email.toString),
                              custodian: Belt_Option.map(loyaltyUser.custodianProtectedName, CS_NonemptyStrings$Util.NonEmptyString.toString),
                              custodianEmail: Belt_Option.map(loyaltyUser.custodianEmail, CS_Emails$Util.Email.toString),
                              isComplete: loyaltyUser.travelDocComplete
                            }) : null,
                      tmp$2,
                      fetchError !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: fetchError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                      saveError !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: saveError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              locked: props.locked
            });
}

var make = LoyaltySelector;

exports.make = make;
/* react Not a pure module */
