// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../utils/sales-flow/SFState.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var SalesByCategoryPage$Thin = require("../pages/SalesByCategoryPage.bs.js");

function SalesFlowRoute(props) {
  var voyage = props.voyage;
  var sessionData = HopperState$Util.Observable.useStore(SFState$Thin.blankModel, true);
  HopperState$Util.Observable.useStorageBackedState(sessionData, SFState$Thin.modelSchema, (function (param) {
          return CS_Slugs$Util.VoyageSlug.toString(voyage) + "_bookingSession";
        }), undefined, undefined, undefined);
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              dash: false,
              alwaysHamburger: true,
              bg: "#FAFAFA",
              children: JsxRuntime.jsx(Stack, {
                    direction: "column",
                    alignItems: "center",
                    children: Caml_option.some(JsxRuntime.jsx(Stack, {
                              sx: {
                                maxWidth: "1536px",
                                width: "100%",
                                flex: 1,
                                mx: 0
                              },
                              children: Caml_option.some(JsxRuntime.jsx(SalesByCategoryPage$Thin.make, {
                                        voyage: voyage,
                                        sessionData: sessionData
                                      }))
                            }))
                  })
            });
}

var make = SalesFlowRoute;

exports.make = make;
/* SFState-Thin Not a pure module */
