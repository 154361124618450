// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"derefClaimNewPassengerInvitation_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"derefClaimNewPassengerInvitation_preferredLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"derefClaimNewPassengerInvitation_preferredFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"derefClaimNewPassengerInvitation_invitedBy":{"c":"Util.CustomScalars.NonemptyString.Exn"},"derefClaimNewPassengerInvitation_invitationEmail":{"c":"Util.CustomScalars.Email.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "allegedToken"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimNewPaxInvitationModelDeref_frag",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "allegedToken",
          "variableName": "allegedToken"
        }
      ],
      "concreteType": "NewPassengerInvitationMeta",
      "kind": "LinkedField",
      "name": "derefClaimNewPassengerInvitation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitationEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferredFirstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferredLastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitedBy",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
