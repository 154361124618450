// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Theming$Thin = require("../../ui/Theming.bs.js");
var Styles = require("@mui/joy/styles");
var JsxRuntime = require("react/jsx-runtime");

function Theme(props) {
  var theme = Styles.extendTheme({
        colorSchemes: {
          light: {
            palette: {
              primary: Theming$Thin.generatePalette("#FF690F", "primary"),
              secondary: Theming$Thin.generatePalette("#30557A", "secondary"),
              warningSecondary: Theming$Thin.generatePalette("#DBA313", "warningSecondary"),
              neutral: {
                outlinedDisabledColor: "#757575",
                softDisabledColor: "#676E79"
              },
              danger: {
                outlinedDisabledColor: "#757575",
                softDisabledColor: "#676E79"
              }
            }
          }
        }
      });
  return JsxRuntime.jsx(Styles.CssVarsProvider, {
              children: Caml_option.some(props.children),
              theme: Caml_option.some(theme)
            });
}

var make = Theme;

exports.make = make;
/* @mui/joy/styles Not a pure module */
