// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"currentUserProfiles_nodes_residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"currentUserProfiles_nodes_preferredLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_preferredFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"currentUserProfiles_nodes_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"currentUserProfiles_nodes_fullPhone":{"c":"Util.CustomScalars.Phone.Exn"},"currentUserProfiles_nodes_email":{"c":"Util.CustomScalars.Email.Exn"},"currentUserProfiles_nodes_docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"currentUserProfiles_nodes_docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserProfiles_nodes_acquisitionSlug":{"c":"Util.CustomScalars.Slug.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.Slug.Exn": CS_Slugs$Util.Slug.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.Phone.Exn": CS_Phone$Util.PhoneImpl.Exn.parse,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.parse,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimNewPaxInvitationModelCurrentAccount_frag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUserProfilesConnection",
      "kind": "LinkedField",
      "name": "currentUserProfiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserProfile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": "preferredFirstName",
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": "preferredLastName",
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": "docFirstName",
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentFirstName",
              "storageKey": null
            },
            {
              "alias": "docLastName",
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentLastName",
              "storageKey": null
            },
            {
              "alias": "docBirthdate",
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentBirthdate",
              "storageKey": null
            },
            {
              "alias": "docCitizenship",
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentCitizenship",
              "storageKey": null
            },
            {
              "alias": "docGender",
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentGender",
              "storageKey": null
            },
            {
              "alias": "fullPhone",
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "residenceCountry",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": "acquisitionSlug",
              "args": null,
              "kind": "ScalarField",
              "name": "custAcquisitionSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "halMarinerNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Phone-Util Not a pure module */
