// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var BDInvoiceDetails$Thin = require("./BDInvoiceDetails.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var BDDepositSchedule$Thin = require("./BDDepositSchedule.bs.js");

function BDBillPayDrawer$DrawerSwitcher(props) {
  var setTab = props.setTab;
  var tab = props.tab;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                      children: [
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            setTab(function (param) {
                                                  return "invoice";
                                                });
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Joy$Util.Link.make, {
                                                          children: Txt$Thin.BookingDetails.invoiceTitle.value,
                                                          level: "body-sm",
                                                          component: "button",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "invoice" ? "text.primary" : "neutral.500",
                                                            "&:hover": {
                                                              textDecoration: "none"
                                                            }
                                                          }
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "invoice" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            setTab(function (param) {
                                                  return "deposit";
                                                });
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Joy$Util.Link.make, {
                                                          children: Txt$Thin.BookingDetails.depositScheduleTitle.value,
                                                          level: "body-sm",
                                                          component: "button",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "deposit" ? "text.primary" : "neutral.500",
                                                            "&:hover": {
                                                              textDecoration: "none"
                                                            }
                                                          }
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "deposit" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            })
                      ],
                      container: true,
                      sx: {
                        backgroundColor: "background.level1"
                      }
                    }),
                JsxRuntime.jsx(Divider, {})
              ]
            });
}

var DrawerSwitcher = {
  make: BDBillPayDrawer$DrawerSwitcher
};

function BDBillPayDrawer(props) {
  var draftMode = props.draftMode;
  var invoiceContent = props.invoiceContent;
  var isActive = props.isActive;
  var match = React.useState(function () {
        return "invoice";
      });
  var tab = match[0];
  var match$1 = invoiceContent.depositSchedule;
  var shouldRenderDepositSchedule = draftMode || !(isActive && match$1 !== undefined) ? false : match$1.length !== 0;
  var schedule = invoiceContent.depositSchedule;
  var tmp;
  var exit = 0;
  if (schedule !== undefined && schedule.length !== 0) {
    tmp = tab === "deposit" ? JsxRuntime.jsx(BDDepositSchedule$Thin.make, {
            schedule: schedule
          }) : JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
            isActive: isActive,
            invoiceContent: invoiceContent,
            draftMode: draftMode
          });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
          isActive: isActive,
          invoiceContent: invoiceContent,
          draftMode: draftMode
        });
  }
  var match$2 = !props.noPaymentsToMake;
  var match$3 = !props.cantPay;
  return JsxRuntime.jsxs(AppDrawer$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.BookingDetails.billingAndPaymentsTitle.value,
              onClose: props.onClose,
              children: [
                shouldRenderDepositSchedule ? JsxRuntime.jsx(BDBillPayDrawer$DrawerSwitcher, {
                        tab: tab,
                        setTab: match[1]
                      }) : null,
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  p: {
                                    xs: 2,
                                    md: 4
                                  }
                                },
                                children: Caml_option.some(tmp)
                              }))
                    }),
                JsxRuntime.jsx(Divider, {
                      sx: {
                        display: {
                          xs: "block",
                          md: "none"
                        }
                      }
                    }),
                isActive && match$2 && match$3 ? JsxRuntime.jsx(DialogActions, {
                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                  direction: {
                                    xs: "column-reverse",
                                    sm: "row"
                                  },
                                  component: "form",
                                  justifyContent: "flex-end",
                                  spacing: 1,
                                  sx: {
                                    width: "100%",
                                    p: 2
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Button, {
                                            type: "submit",
                                            onClick: props.openPaymentDrawer,
                                            children: "Make a payment"
                                          }))
                                })),
                        sx: {
                          borderTop: "1px solid",
                          borderColor: "divider"
                        }
                      }) : null
              ]
            });
}

var make = BDBillPayDrawer;

exports.DrawerSwitcher = DrawerSwitcher;
exports.make = make;
/* react Not a pure module */
