// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CloseRounded = require("@mui/icons-material/CloseRounded").default;

function blankGlobalQueryState() {
  return {
          dismissibleAlerts: [],
          loginRedirect: undefined,
          token: undefined,
          landing: undefined,
          expiryMillis: undefined
        };
}

function blankRootModel() {
  return {
          globalQueryState: {
            dismissibleAlerts: [],
            loginRedirect: undefined,
            token: undefined,
            landing: undefined,
            expiryMillis: undefined
          },
          routerUrl: RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined)
        };
}

var scalar = QueryString$Util.Codec.scalar;

var customScalar = QueryString$Util.Codec.customScalar;

var required = QueryString$Util.Codec.required;

var string = QueryString$Util.Codec.string;

var $$float = QueryString$Util.Codec.$$float;

var bool = QueryString$Util.Codec.bool;

function fieldToString(queryStringField) {
  return queryStringField;
}

function parameterlessAlert(field, pless) {
  return QueryString$Util.Param.Singular.make(field, fieldToString, scalar(required(bool)), undefined, (function (globalQueryState, optVoyageSlug) {
                var dismissibleAlerts = globalQueryState.dismissibleAlerts;
                return {
                        dismissibleAlerts: Belt_Option.mapWithDefault(optVoyageSlug, dismissibleAlerts, (function (voyageSlug) {
                                return Belt_Array.concat(dismissibleAlerts, [pless]);
                              })),
                        loginRedirect: globalQueryState.loginRedirect,
                        token: globalQueryState.token,
                        landing: globalQueryState.landing,
                        expiryMillis: globalQueryState.expiryMillis
                      };
              }), (function (param) {
                if (Belt_Option.isSome(Belt_Array.get(Belt_Array.keep(param.dismissibleAlerts, (function (alert) {
                                  return Caml_obj.equal(alert, pless);
                                })), 0))) {
                  return true;
                }
                
              }));
}

var addedToBooking = QueryString$Util.Param.Singular.make("addedToBooking", fieldToString, scalar(required(customScalar(CS_Slugs$Util.VoyageSlug))), undefined, (function (globalQueryState, optVoyageSlug) {
        var dismissibleAlerts = globalQueryState.dismissibleAlerts;
        return {
                dismissibleAlerts: Belt_Option.mapWithDefault(optVoyageSlug, dismissibleAlerts, (function (voyageSlug) {
                        return Belt_Array.concat(dismissibleAlerts, [{
                                      TAG: "YouWereAddedToABooking",
                                      _0: voyageSlug
                                    }]);
                      })),
                loginRedirect: globalQueryState.loginRedirect,
                token: globalQueryState.token,
                landing: globalQueryState.landing,
                expiryMillis: globalQueryState.expiryMillis
              };
      }), (function (param) {
        return Belt_Array.get(Belt_Array.keepMap(param.dismissibleAlerts, (function (alert) {
                          if (typeof alert !== "object") {
                            return ;
                          } else {
                            return Caml_option.some(alert._0);
                          }
                        })), 0);
      }));

var verifyPrefilledAlert = parameterlessAlert("verifyPrefill", "PleaseVerifyYourPrefilledAccount");

var loginRedirect = QueryString$Util.Param.Singular.make("loginRedirect", fieldToString, scalar(required(string)), undefined, (function (globalQueryState, loginRedirect) {
        return {
                dismissibleAlerts: globalQueryState.dismissibleAlerts,
                loginRedirect: loginRedirect,
                token: globalQueryState.token,
                landing: globalQueryState.landing,
                expiryMillis: globalQueryState.expiryMillis
              };
      }), (function (param) {
        return param.loginRedirect;
      }));

var token = QueryString$Util.Param.Singular.make("token", fieldToString, scalar(required(customScalar(CS_UUID$Util.PlainUUID))), undefined, (function (globalQueryState, token) {
        return {
                dismissibleAlerts: globalQueryState.dismissibleAlerts,
                loginRedirect: globalQueryState.loginRedirect,
                token: token,
                landing: globalQueryState.landing,
                expiryMillis: globalQueryState.expiryMillis
              };
      }), (function (param) {
        return param.token;
      }));

var landing = QueryString$Util.Param.Singular.make("landing", fieldToString, scalar(required(string)), undefined, (function (globalQueryState, landing) {
        return {
                dismissibleAlerts: globalQueryState.dismissibleAlerts,
                loginRedirect: globalQueryState.loginRedirect,
                token: globalQueryState.token,
                landing: landing,
                expiryMillis: globalQueryState.expiryMillis
              };
      }), (function (param) {
        return param.landing;
      }));

var isExpired = QueryString$Util.Param.Singular.make("expiry", fieldToString, scalar(required($$float)), undefined, (function (globalQueryState, expiry) {
        return {
                dismissibleAlerts: globalQueryState.dismissibleAlerts,
                loginRedirect: globalQueryState.loginRedirect,
                token: globalQueryState.token,
                landing: globalQueryState.landing,
                expiryMillis: Belt_Option.map(expiry, (function (v) {
                        return v * 1000;
                      }))
              };
      }), (function (param) {
        return Belt_Option.map(param.expiryMillis, (function (v) {
                      return v / 1000;
                    }));
      }));

var parser = QueryString$Util.Parser.makeExtended([
      addedToBooking,
      verifyPrefilledAlert,
      loginRedirect,
      token,
      landing,
      isExpired
    ], {
      dismissibleAlerts: [],
      loginRedirect: undefined,
      token: undefined,
      landing: undefined,
      expiryMillis: undefined
    });

function getRenderedLink(sfunc) {
  var curUrl = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  var priorGlobals = parser.codec.decode(curUrl.search);
  var priorPath = Belt_List.toArray(curUrl.path).join("/");
  var match = sfunc(priorPath, priorGlobals);
  var a = match[0].split("?", 2);
  var match$1;
  if (a.length !== 2) {
    match$1 = [
      Belt_Array.getExn(a, 0),
      undefined,
      undefined
    ];
  } else {
    var path = a[0];
    var search = a[1];
    var a$1 = search.split("#", 2);
    if (a$1.length !== 2) {
      match$1 = [
        path,
        Belt_Array.getExn(a$1, 0),
        undefined
      ];
    } else {
      var search$1 = a$1[0];
      var hash = a$1[1];
      match$1 = [
        path,
        search$1,
        hash
      ];
    }
  }
  var str = parser.codec.encode(match[1]);
  var tmp = str === "" ? undefined : str;
  var finalRedirectUrl_path = Belt_List.fromArray(Belt_Array.keep(match$1[0].split("/"), (function (str) {
              return str !== "";
            })));
  var finalRedirectUrl_hash = Belt_Option.getWithDefault(match$1[2], "");
  var finalRedirectUrl_search = Belt_Option.getWithDefault(QueryString$Util.QSUtil.mergeRepr(tmp, Belt_Option.flatMap(match$1[1], parser.getUnsubscribedParams)), "");
  var finalRedirectUrl = {
    path: finalRedirectUrl_path,
    hash: finalRedirectUrl_hash,
    search: finalRedirectUrl_search
  };
  return HopperState$Util.renderUrl(finalRedirectUrl);
}

function redirect(sfunc, routerActionOpt) {
  var routerAction = routerActionOpt !== undefined ? routerActionOpt : RescriptReactRouter.push;
  routerAction(getRenderedLink(sfunc));
}

function setGlobals(sfunc, routerActionOpt) {
  var routerAction = routerActionOpt !== undefined ? routerActionOpt : RescriptReactRouter.push;
  redirect((function (prevPath, prevGlobals) {
          return [
                  prevPath,
                  sfunc(prevGlobals)
                ];
        }), routerAction);
}

function redirectToPath(redirectPath, setGlobalsOpt, routerActionOpt) {
  var setGlobals = setGlobalsOpt !== undefined ? setGlobalsOpt : (function (a) {
        return a;
      });
  var routerAction = routerActionOpt !== undefined ? routerActionOpt : RescriptReactRouter.push;
  redirect((function (param, prevGlobals) {
          return [
                  redirectPath,
                  setGlobals(prevGlobals)
                ];
        }), routerAction);
}

function renderAlerts(dismissibleAlerts) {
  return Belt_Array.mapWithIndex(dismissibleAlerts, (function (idx, alert) {
                var onClick = function (param) {
                  setGlobals((function (prevGlobals) {
                          return {
                                  dismissibleAlerts: Belt_Array.keep(prevGlobals.dismissibleAlerts, (function (a) {
                                          return Caml_obj.notequal(a, alert);
                                        })),
                                  loginRedirect: prevGlobals.loginRedirect,
                                  token: prevGlobals.token,
                                  landing: prevGlobals.landing,
                                  expiryMillis: prevGlobals.expiryMillis
                                };
                        }), undefined);
                };
                var tmp;
                tmp = typeof alert !== "object" ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: Txt$Thin.Account.pleaseVerifyYourPrefilledAccount.value,
                        type_: "info",
                        endDecorator: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                  onClick: onClick,
                                  variant: "soft",
                                  color: "success",
                                  children: Caml_option.some(JsxRuntime.jsx(CloseRounded, {}))
                                })),
                        size: "sm"
                      }) : JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: Txt$Thin.Bookings.youWereAddedToABookingSuccess.value,
                        type_: "success",
                        endDecorator: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                  onClick: onClick,
                                  variant: "soft",
                                  color: "success",
                                  children: Caml_option.some(JsxRuntime.jsx(CloseRounded, {}))
                                })),
                        size: "sm"
                      });
                return JsxRuntime.jsx(React.Fragment, {
                            children: tmp
                          }, "alert-" + String(idx));
              }));
}

function useDecodedQueryString(store, decodedLens, urlLens, param) {
  var decode = param.codec.decode;
  var url = HopperState$Util.Observable.makeView(store, urlLens, undefined);
  var decoded = HopperState$Util.Observable.makeView(store, decodedLens, undefined);
  React.useEffect((function () {
          var initialUrl = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
          HopperState$Util.Observable.notify(url, (function (param) {
                  return initialUrl;
                }));
          HopperState$Util.Observable.notify(decoded, (function (param) {
                  return decode(initialUrl.search);
                }));
          var watcherId = RescriptReactRouter.watchUrl(function (newUrl) {
                HopperState$Util.Observable.notify(url, (function (param) {
                        return newUrl;
                      }));
              });
          return (function () {
                    RescriptReactRouter.unwatchUrl(watcherId);
                  });
        }), []);
  HopperState$Util.Observable.useSubscribeOnChange(url, (function (url) {
          HopperState$Util.Observable.notify(decoded, (function (param) {
                  return decode(url.search);
                }));
        }), "RescriptEquals", undefined);
  return {
          url: url,
          queryState: decoded
        };
}

function useUrl() {
  var store = HopperState$Util.Observable.useStore((function () {
          return {
                  globalQueryState: parser.codec.decode(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search),
                  routerUrl: RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined)
                };
        }), undefined);
  var globalQueryStateLens_read = function (param) {
    return param.globalQueryState;
  };
  var globalQueryStateLens_write = function (m, globalQueryState) {
    return {
            globalQueryState: globalQueryState,
            routerUrl: m.routerUrl
          };
  };
  var globalQueryStateLens = {
    read: globalQueryStateLens_read,
    write: globalQueryStateLens_write
  };
  var urlLens_read = function (param) {
    return param.routerUrl;
  };
  var urlLens_write = function (m, routerUrl) {
    return {
            globalQueryState: m.globalQueryState,
            routerUrl: routerUrl
          };
  };
  var urlLens = {
    read: urlLens_read,
    write: urlLens_write
  };
  return useDecodedQueryString(store, globalQueryStateLens, urlLens, parser);
}

var defaultContextWhichIsBroken = React.createContext("ERROR");

function useImpl() {
  return React.useContext(defaultContextWhichIsBroken);
}

function useUrlProjection(fn, equalityFn, intervention) {
  var match = React.useContext(defaultContextWhichIsBroken);
  return HopperState$Util.Observable.useComputed(match.url, fn, equalityFn, intervention);
}

function useQueryStateProjection(fn, equalityFn, intervention) {
  var match = React.useContext(defaultContextWhichIsBroken);
  return HopperState$Util.Observable.useComputed(match.queryState, fn, equalityFn, intervention);
}

function useDismissibleAlerts() {
  return useQueryStateProjection((function (param) {
                return param.dismissibleAlerts;
              }), undefined, undefined);
}

var make = defaultContextWhichIsBroken.Provider;

var Provider = {
  make: make
};

var Context = {
  defaultContextWhichIsBroken: defaultContextWhichIsBroken,
  useImpl: useImpl,
  useUrlProjection: useUrlProjection,
  useQueryStateProjection: useQueryStateProjection,
  useDismissibleAlerts: useDismissibleAlerts,
  Provider: Provider
};

exports.blankGlobalQueryState = blankGlobalQueryState;
exports.blankRootModel = blankRootModel;
exports.parser = parser;
exports.getRenderedLink = getRenderedLink;
exports.redirect = redirect;
exports.setGlobals = setGlobals;
exports.redirectToPath = redirectToPath;
exports.renderAlerts = renderAlerts;
exports.useDecodedQueryString = useDecodedQueryString;
exports.useUrl = useUrl;
exports.Context = Context;
/* addedToBooking Not a pure module */
