// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CS_UnverifiedJWT$Util = require("util/src/CustomScalars/CS_UnverifiedJWT.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var linkTokenStruct = S$RescriptSchema.object(function (ctx) {
      return {
              expiration: ctx.f("expiration", CS_Make$Util.Dateable.Plain.schema),
              link_token: ctx.f("link_token", S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 5, undefined)),
              request_id: ctx.f("request_id", S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 5, undefined))
            };
    });

var selectedAccount = S$RescriptSchema.object(function (ctx) {
      return {
              publicToken: ctx.f("publicToken", S$RescriptSchema.string),
              publicTokenExpirationMillis: ctx.f("publicTokenExpirationMillis", S$RescriptSchema.$$float),
              selectedAccountId: ctx.f("selectedAccountId", S$RescriptSchema.string),
              accountNiceName: ctx.f("accountNiceName", S$RescriptSchema.string)
            };
    });

var plaidDebitInit = S$RescriptSchema.object(function (ctx) {
      return {
              public_token: ctx.f("public_token", S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 5, undefined)),
              account_id: ctx.f("account_id", S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 5, undefined))
            };
    });

var PlaidDebit = {
  linkTokenStruct: linkTokenStruct,
  selectedAccount: selectedAccount,
  plaidDebitInit: plaidDebitInit
};

function mapError(t) {
  var source = Object.assign({}, t);
  ((delete source["details"]));
  var match = t.code;
  if (match === "HOSTED_FIELDS_FIELDS_INVALID") {
    return {
            displayableString: "Some credit card fields are invalid: [" + t.details["invalidFieldKeys"] + "]. Please fill them out before continuing.",
            source: source,
            braintreeErrorCode: t.code
          };
  } else if (match === "HOSTED_FIELDS_FIELDS_EMPTY") {
    return {
            displayableString: "All credit card fields are empty! Please fill out the form.",
            source: source,
            braintreeErrorCode: t.code
          };
  } else if (match === "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE") {
    return {
            displayableString: "This payment method is in use elsewhere. Please try another payment method.",
            source: source,
            braintreeErrorCode: t.code
          };
  } else if (match === "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR") {
    return {
            displayableString: "There was a network error while trying to verify your card. Would you please check your network connection and try again?",
            source: source,
            braintreeErrorCode: t.code
          };
  } else if (match === "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED") {
    return {
            displayableString: "Credit Card CVV code did not pass verification.",
            source: source,
            braintreeErrorCode: t.code
          };
  } else if (match === "HOSTED_FIELDS_FAILED_TOKENIZATION") {
    return {
            displayableString: "We could not verify your card. Would you please check your data and try again?",
            source: source,
            braintreeErrorCode: t.code
          };
  } else {
    return {
            displayableString: "An unidentified error occurred while processing your card. Please verify your data and try again. [\"" + t.code + "\": \"" + t.message + "\"]",
            source: source,
            braintreeErrorCode: t.code
          };
  }
}

var clientToken = S$RescriptSchema.object(function (ctx) {
      return {
              tok: ctx.f("tok", S$RescriptSchema.string),
              expEpochTimestamp: ctx.f("exp", S$RescriptSchema.$$float)
            };
    });

var braintreePaymentInit = S$RescriptSchema.object(function (ctx) {
      return {
              payment_method_nonce: ctx.f("payment_method_nonce", S$RescriptSchema.string),
              device_data: ctx.f("device_data", S$RescriptSchema.string)
            };
    });

var BraintreeHostedFields = {
  mapError: mapError,
  clientToken: clientToken,
  braintreePaymentInit: braintreePaymentInit
};

var paymentReturn = S$RescriptSchema.object(function (s) {
      return {
              pmt_id: s.f("pmt_id", S$RescriptSchema.nullable(CS_NonemptyStrings$Util.PmtTransactionId.schema)),
              err_msg: s.f("err_msg", S$RescriptSchema.nullable(S$RescriptSchema.string))
            };
    });

var paymentIntentMethod = S$RescriptSchema.object(function (s) {
      return s.f("argv", S$RescriptSchema.object(function (s) {
                      return s.f("mthd", S$RescriptSchema.string);
                    }));
    });

function jwtExpiresAfter(jwt) {
  return RemoteData$Util.toOption(RemoteData$Util.mapSuccess(jwt, (function (jwt) {
                    return CS_UnverifiedJWT$Util.UnverifiedJWT.meta(jwt).exp * 1000;
                  })));
}

function useTokenizationIntent() {
  return ReactState$Util.Wrap.useExpiringStorageBackedState(React.useState(function () {
                  return "NotAsked";
                }), RemoteData$Util.schema(S$RescriptSchema.string, CS_UnverifiedJWT$Util.UnverifiedJWT.schema), "PaymentProcessor.tokenizationIntent", RemoteData$Util.isSuccess, undefined, jwtExpiresAfter, "NotAsked");
}

function usePaymentIntent() {
  return ReactState$Util.Wrap.useExpiringStorageBackedState(React.useState(function () {
                  return "NotAsked";
                }), RemoteData$Util.schema(S$RescriptSchema.string, CS_UnverifiedJWT$Util.UnverifiedJWT.schema), "PaymentProcessor.paymentIntent", RemoteData$Util.isSuccess, undefined, jwtExpiresAfter, "NotAsked");
}

function usePlaidLinkToken() {
  return ReactState$Util.Wrap.useExpiringStorageBackedState(React.useState(function () {
                  return "NotAsked";
                }), RemoteData$Util.schema(S$RescriptSchema.string, linkTokenStruct), "PaymentProcessor.plaidLinkToken", RemoteData$Util.isSuccess, undefined, (function (rd) {
                if (typeof rd !== "object" || rd.TAG !== "Success") {
                  return ;
                } else {
                  return DateFns.getTime(CS_Make$Util.Dateable.Plain.toDate(rd._0.expiration));
                }
              }), "NotAsked");
}

function usePlaidAccountSelection() {
  return ReactState$Util.Wrap.useExpiringStorageBackedState(React.useState(function () {
                  return "NotAsked";
                }), RemoteData$Util.schema(S$RescriptSchema.never, selectedAccount), "PaymentProcessor.plaidAccountSelection", RemoteData$Util.isSuccess, undefined, (function (rd) {
                if (typeof rd !== "object" || rd.TAG !== "Success") {
                  return ;
                } else {
                  return rd._0.publicTokenExpirationMillis;
                }
              }), "NotAsked");
}

function useBraintreeClientToken() {
  return ReactState$Util.Wrap.useExpiringStorageBackedState(React.useState(function () {
                  return "NotAsked";
                }), RemoteData$Util.schema(S$RescriptSchema.string, clientToken), "PaymentProcessor.braintreeClientToken", RemoteData$Util.isSuccess, undefined, (function (rd) {
                if (typeof rd !== "object" || rd.TAG !== "Success") {
                  return ;
                } else {
                  return rd._0.expEpochTimestamp * 1000;
                }
              }), "NotAsked");
}

exports.PlaidDebit = PlaidDebit;
exports.BraintreeHostedFields = BraintreeHostedFields;
exports.paymentReturn = paymentReturn;
exports.paymentIntentMethod = paymentIntentMethod;
exports.jwtExpiresAfter = jwtExpiresAfter;
exports.useTokenizationIntent = useTokenizationIntent;
exports.usePaymentIntent = usePaymentIntent;
exports.usePlaidLinkToken = usePlaidLinkToken;
exports.usePlaidAccountSelection = usePlaidAccountSelection;
exports.useBraintreeClientToken = useBraintreeClientToken;
/* linkTokenStruct Not a pure module */
