// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var PassengerState$Thin = require("../passengers/PassengerState.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SalesByCategoryPage_query_graphql$Thin = require("../../__generated__/SalesByCategoryPage_query_graphql.bs.js");

function blankModel() {
  return {
          ca: undefined
        };
}

function paxViewByIdx(model, idx) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (model) {
                  return Belt_MapInt.getWithDefault(model.ca, idx, PassengerState$Thin.blankPax(idx));
                }),
              write: (function (model, ca) {
                  return {
                          ca: Belt_MapInt.set(model.ca, idx, ca)
                        };
                })
            }, undefined);
}

function prefillPaxFromConnectedAccount(idx, prof) {
  var newrecord = Caml_obj.obj_dup(PassengerState$Thin.blankPax(idx));
  return PassengerState$Thin.halMarinerNumber.setFromValid(PassengerState$Thin.loyaltyTier.setFromValid(PassengerState$Thin.loyalty.setFromValid(PassengerState$Thin.acquisitionSlug.setFromValid(PassengerState$Thin.email.setFromValid(PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.accountId = Caml_option.some(prof.accountId), newrecord), Caml_option.some(prof.firstName), "InitialValueAsPrefill"), prof.lastName, "InitialValueAsPrefill"), prof.legalDocumentFirstName, "InitialValueAsPrefill"), prof.legalDocumentLastName, "InitialValueAsPrefill"), prof.legalDocumentBirthdate, "InitialValueAsPrefill"), prof.legalDocumentCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prof.legalDocumentGender, SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                                    return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                                  })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                                    return CS_Phone$Util.Phone.meta(p).callingCode;
                                                  })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill"), prof.email, "InitialValueAsPrefill"), prof.custAcquisitionSlug, "InitialValueAsPrefill"), Caml_option.some(prof.loyaltyNumber), "InitialValueAsPrefill"), prof.currentTierTitle, "InitialValueAsPrefill"), prof.halMarinerNumber, "InitialValueAsPrefill");
}

function createCardDetails(prof) {
  var lName = prof.lastName;
  var initials;
  if (lName !== undefined) {
    var firstInitial = CS_NonemptyStrings$Util.NonEmptyString.toString(prof.firstName)[0];
    var lastInitial = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lName))[0];
    initials = firstInitial + lastInitial;
  } else {
    initials = CS_NonemptyStrings$Util.NonEmptyString.toString(prof.firstName)[0];
  }
  var match = prof.legalDocumentFirstName;
  var match$1 = prof.legalDocumentLastName;
  var tmp;
  if (match !== undefined) {
    var firstName = Caml_option.valFromOption(match);
    tmp = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName);
  } else {
    tmp = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$1)) : undefined;
  }
  return {
          accountId: prof.accountId,
          gender: prof.legalDocumentGender,
          birthdate: prof.legalDocumentBirthdate,
          loyaltyNumber: prof.loyaltyNumber,
          citizenship: prof.legalDocumentCitizenship,
          invitationEmail: Belt_Option.map(prof.invitationEmail, CS_Emails$Util.Email.toString),
          invitationExpiry: prof.invitationExpiry,
          fullName: prof.fullDisplayName,
          legalName: tmp,
          initials: initials,
          email: Belt_Option.map(prof.email, CS_Emails$Util.Email.toString),
          accountStatus: prof.accountStatus,
          numLoyaltyVoyages: Belt_Option.getWithDefault(prof.numEligibleVoyages, 0),
          loyaltyVoyages: Belt_Array.concat(Belt_Array.keepMap(prof.loyalty_eligible_voyage.nodes, (function (v) {
                      var match = v.voyage;
                      if (match !== undefined) {
                        return {
                                voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                isPending: false
                              };
                      }
                      
                    })), Belt_Array.keepMap(prof.loyalty_pending_voyage.nodes, (function (v) {
                      var match = v.voyage;
                      if (match !== undefined) {
                        return {
                                voyageName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.voyageName),
                                isPending: true
                              };
                      }
                      
                    }))),
          loyaltyTier: Belt_Option.map(prof.currentTierTitle, CS_NonemptyStrings$Util.NonEmptyString.toString),
          voyageFragment: prof.passenger_record.fragmentRefs,
          bookings: prof.passenger_record.nodes
        };
}

exports.blankModel = blankModel;
exports.paxViewByIdx = paxViewByIdx;
exports.prefillPaxFromConnectedAccount = prefillPaxFromConnectedAccount;
exports.createCardDetails = createCardDetails;
/* CS_Phone-Util Not a pure module */
