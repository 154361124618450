// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ConnectedAccountsPage$Thin = require("../pages/ConnectedAccountsPage.bs.js");
var ConnectedAccountsRouteQuery_graphql$Thin = require("../__generated__/ConnectedAccountsRouteQuery_graphql.bs.js");

var convertVariables = ConnectedAccountsRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ConnectedAccountsRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ConnectedAccountsRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ConnectedAccountsRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ConnectedAccountsRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ConnectedAccountsRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ConnectedAccountsRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ConnectedAccountsRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ConnectedAccountsRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ConnectedAccountsRoute(props) {
  var data = use(undefined, undefined, undefined, undefined);
  var match = CurrentUser$Thin.Context.use();
  var hasConnectedAccounts = match.hasConnectedAccounts;
  React.useEffect((function () {
          if (hasConnectedAccounts) {
            
          } else {
            RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
          }
        }), []);
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              mustBeLoggedIn: true,
              children: hasConnectedAccounts ? JsxRuntime.jsx(ConnectedAccountsPage$Thin.make, {
                      fragmentRefs: data.fragmentRefs
                    }) : null
            });
}

var make = ConnectedAccountsRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
