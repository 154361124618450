// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var Box = require("@mui/joy/Box").default;
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var List = require("@mui/joy/List").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppBanner$Thin = require("../../common/atoms/AppBanner.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Check = require("@mui/icons-material/Check").default;
var $$Event = require("@mui/icons-material/Event").default;
var CreditCard = require("@mui/icons-material/CreditCard").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var QuestionMark = require("@mui/icons-material/QuestionMark").default;
var HistoryToggleOff = require("@mui/icons-material/HistoryToggleOff").default;

var emptyPaymentPlan = {
  dueNow: undefined,
  overdueAmount: undefined,
  nextAmountDue: undefined,
  nextAmountDueAfter: undefined
};

function getPaymentPlan(data) {
  if (data.TAG !== "Current") {
    return {
            dueNow: data._0,
            overdueAmount: undefined,
            nextAmountDue: undefined,
            nextAmountDueAfter: undefined
          };
  }
  var current = data._0;
  if (current === undefined) {
    return emptyPaymentPlan;
  }
  var match = current.overdueAmount;
  var match$1 = current.overdueByDays;
  var match$2;
  if (match !== undefined) {
    var overdueAmount = Caml_option.valFromOption(match);
    var exit = 0;
    if (match$1 !== undefined && match$1 <= 0.25 && CS_Decimal$Util.Decimal10_2.toFloat(overdueAmount) > 0.0) {
      match$2 = [
        Caml_option.some(overdueAmount),
        undefined
      ];
    } else {
      exit = 1;
    }
    if (exit === 1) {
      match$2 = CS_Decimal$Util.Decimal10_2.toFloat(overdueAmount) > 0.0 ? [
          undefined,
          Caml_option.some(overdueAmount)
        ] : [
          undefined,
          undefined
        ];
    }
    
  } else {
    match$2 = [
      undefined,
      undefined
    ];
  }
  return {
          dueNow: match$2[0],
          overdueAmount: match$2[1],
          nextAmountDue: current.nextAmountDue,
          nextAmountDueAfter: current.nextAmountDueAfter
        };
}

function getChipText(p, draftMode) {
  var match = p.dueNow;
  if (match !== undefined) {
    if (draftMode) {
      return "Due when changes applied";
    } else {
      return "Due now";
    }
  }
  var match$1 = p.overdueAmount;
  if (match$1 !== undefined) {
    return "Amount overdue";
  }
  var match$2 = p.nextAmountDue;
  var match$3 = p.nextAmountDueAfter;
  if (match$2 !== undefined && match$3 !== undefined) {
    return "Next amount due";
  } else {
    return "";
  }
}

function getColor(p) {
  var match = p.dueNow;
  if (match !== undefined) {
    return "Warning2";
  }
  var match$1 = p.overdueAmount;
  if (match$1 !== undefined) {
    return "Danger2";
  }
  return "Neutral";
}

function getIcon(p) {
  var color = Common$Thin.NotificationColor.getColorNoSuccess(getColor(p));
  var match = p.dueNow;
  if (match !== undefined) {
    return JsxRuntime.jsx(CreditCard, {
                sx: {
                  color: color
                }
              });
  }
  var match$1 = p.overdueAmount;
  if (match$1 !== undefined) {
    return JsxRuntime.jsx(InfoOutlined, {
                sx: {
                  color: color
                }
              });
  }
  var match$2 = p.nextAmountDue;
  var match$3 = p.nextAmountDueAfter;
  if (match$2 !== undefined && match$3 !== undefined) {
    return JsxRuntime.jsx($$Event, {
                sx: {
                  color: color
                }
              });
  } else {
    return null;
  }
}

function getAmountText(p, levelOpt, secondaryColor) {
  var level = levelOpt !== undefined ? levelOpt : "body-xs";
  var color = Common$Thin.NotificationColor.getColorNoSuccess(getColor(p));
  var amt = p.dueNow;
  if (amt !== undefined) {
    return JsxRuntime.jsx(Typography, {
                level: level,
                sx: {
                  color: color
                },
                fontWeight: "900",
                children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(amt))))
              });
  }
  var amt$1 = p.overdueAmount;
  if (amt$1 !== undefined) {
    return JsxRuntime.jsx(Typography, {
                level: level,
                sx: {
                  color: color
                },
                fontWeight: "900",
                children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(amt$1))))
              });
  }
  var match = p.nextAmountDue;
  var match$1 = p.nextAmountDueAfter;
  if (match !== undefined && match$1 !== undefined) {
    return JsxRuntime.jsxs(Typography, {
                level: level,
                sx: {
                  color: color
                },
                children: [
                  JsxRuntime.jsx(Typography, {
                        component: "span",
                        fontWeight: "900",
                        children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(match))))
                      }),
                  JsxRuntime.jsx(Typography, {
                        component: "span",
                        sx: {
                          color: Belt_Option.getWithDefault(secondaryColor, "inherit")
                        },
                        children: Caml_option.some(" on " + CS_Make$Util.Dateable.Plain.format(Caml_option.valFromOption(match$1), SysCtx$Thin.vars.longDateFormat))
                      })
                ]
              });
  } else {
    return null;
  }
}

function getPaymentStatusChip(p, draftMode) {
  var match = p.dueNow;
  var match$1 = p.overdueAmount;
  var match$2 = p.nextAmountDue;
  if (match === undefined && match$1 === undefined && match$2 === undefined) {
    return null;
  }
  var icon = getIcon(p);
  var colorVariant = getColor(p);
  var text = getChipText(p, draftMode);
  var amount = getAmountText(p, undefined, undefined);
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              alignItems: "center",
              flexWrap: "wrap",
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        pr: 1
                      },
                      children: Caml_option.some(JsxRuntime.jsx(AppChip$Thin.make, {
                                children: text,
                                startDecorator: Caml_option.some(icon),
                                color: colorVariant
                              }))
                    }),
                amount
              ]
            });
}

function getPaymentStatusBanner(p, draftMode) {
  var icon = getIcon(p);
  var colorVariant = getColor(p);
  var color = Common$Thin.NotificationColor.getColorNoSuccess(colorVariant);
  var text = getChipText(p, draftMode);
  var amount = getAmountText(p, "title-sm", "text.tertiary");
  var match = p.dueNow;
  var match$1 = p.overdueAmount;
  var match$2 = p.nextAmountDue;
  if (match === undefined && match$1 === undefined && match$2 === undefined) {
    return ;
  }
  return Caml_option.some(JsxRuntime.jsx(AppBanner$Thin.make, {
                  children: JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        sx: {
                          width: "100%"
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(icon),
                                level: "title-sm",
                                sx: {
                                  color: color
                                },
                                children: Caml_option.some(text)
                              }),
                          amount
                        ]
                      }),
                  color: colorVariant
                }));
}

var PaymentsStatus = {
  getChipText: getChipText,
  getColor: getColor,
  getIcon: getIcon,
  getAmountText: getAmountText,
  getPaymentStatusChip: getPaymentStatusChip,
  getPaymentStatusBanner: getPaymentStatusBanner
};

function getDepSched(data) {
  if (data === undefined) {
    return ;
  }
  var appliedArray = data.appliedPaymentPlan;
  if (appliedArray !== undefined) {
    return Belt_Array.keepMap(appliedArray, (function (app) {
                  if (app !== undefined) {
                    return {
                            periodStatus: app.periodStatus,
                            dueDate: app.period.activateAfter,
                            newAmountDue: app.newlyDueThisPeriod,
                            numericAmount: app.period.numericAmount,
                            paymentAmtType: app.period.paymentAmountType
                          };
                  }
                  
                }));
  }
  
}

function parse(v) {
  if (v.TAG === "Diff") {
    var diff = v._0;
    var lines = diff.quoteLines;
    var billingLines = lines !== undefined ? Belt_Array.map(Belt_Array.keepMap(lines, (function (n) {
                  if (n !== undefined) {
                    return n;
                  }
                  
                })), (function (node) {
              return {
                      billingLineDescription: CS_NonemptyStrings$Util.NonEmptyString.toString(node.billingLineDescription),
                      lineAmount: node.lineAmount,
                      hookFiredAt: node.originatedAt,
                      fareRuleSlug: node.fareRuleSlug
                    };
            })) : [];
    var payments = diff.quotePayments;
    var paymentLines = payments !== undefined ? Belt_Array.map(Belt_Array.keepMap(payments, (function (n) {
                  if (n !== undefined) {
                    return n;
                  }
                  
                })), (function (node) {
              return {
                      date: CS_Make$Util.Dateable.Plain.format(node.createdAt, "MM/dd/yyyy"),
                      payer: CS_NonemptyStrings$Util.AccountId.toString(node.payer),
                      lifecycleStatus: Common$Thin.PmtLifecycle.toString(node.lifecycleStatus),
                      shouldRenderStatus: Common$Thin.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                      paymentMethod: Belt_Option.getWithDefault(Belt_Option.map(node.bfcustPmtMethodDescription, CS_NonemptyStrings$Util.NonEmptyString.toString), "Payment"),
                      paymentMethodImage: Belt_Option.map(node.bfcustPmtImageUrl, CS_NonemptyStrings$Util.NonEmptyString.toString),
                      amount: node.netAmount,
                      bfFeeAmount: node.bfFeeAmount
                    };
            })) : [];
    var match = diff.billingAndPaymentTotals;
    var tmp;
    if (match !== undefined) {
      var amount = match.grossBilled;
      tmp = amount !== undefined ? Caml_option.valFromOption(amount) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    } else {
      tmp = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    }
    var match$1 = diff.billingAndPaymentTotals;
    var tmp$1;
    if (match$1 !== undefined) {
      var amount$1 = match$1.netCompletedPaymentsAndRefunds;
      tmp$1 = amount$1 !== undefined ? Caml_option.valFromOption(amount$1) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    } else {
      tmp$1 = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    }
    var match$2 = diff.billingAndPaymentTotals;
    var tmp$2;
    if (match$2 !== undefined) {
      var npp = match$2.netPendingPayments;
      tmp$2 = npp !== undefined ? Caml_option.valFromOption(npp) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    } else {
      tmp$2 = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    }
    var match$3 = diff.billingAndPaymentTotals;
    var tmp$3;
    if (match$3 !== undefined) {
      var amount$2 = match$3.combinedBalanceWithPending;
      tmp$3 = amount$2 !== undefined ? Caml_option.valFromOption(amount$2) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    } else {
      tmp$3 = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    }
    var match$4 = diff.billingAndPaymentTotals;
    var tmp$4;
    if (match$4 !== undefined) {
      var npp$1 = match$4.netPendingPayments;
      tmp$4 = npp$1 !== undefined ? CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(npp$1)) > 0.0 : false;
    } else {
      tmp$4 = false;
    }
    var match$5 = diff.billingAndPaymentTotals;
    var tmp$5;
    if (match$5 !== undefined) {
      var amount$3 = match$5.combinedBalance;
      tmp$5 = amount$3 !== undefined ? Caml_option.valFromOption(amount$3) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    } else {
      tmp$5 = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
    }
    return {
            billing: {
              billingLines: billingLines,
              billingLineSubtotal: tmp
            },
            payment: {
              completedPaymentSubtotal: tmp$1,
              pendingPaymentSubtotal: tmp$2,
              paymentLines: paymentLines
            },
            totalAmountIncludingPending: tmp$3,
            arePendingPayments: tmp$4,
            totalAmount: tmp$5,
            paymentPlan: getPaymentPlan({
                  TAG: "Draft",
                  _0: v._1
                }),
            depositSchedule: undefined
          };
  }
  var data = v._0;
  var match$6 = data.billing_summary;
  var completedPaymentSubtotal = match$6 !== undefined ? match$6.netCompletedPaymentsAndRefunds : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  var match$7 = data.billing_summary;
  var pendingPaymentSubtotal;
  if (match$7 !== undefined) {
    var npp$2 = match$7.netPendingPayments;
    pendingPaymentSubtotal = npp$2 !== undefined ? Caml_option.valFromOption(npp$2) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  } else {
    pendingPaymentSubtotal = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  }
  var match$8 = data.billing_summary;
  var totalAmountIncludingPending = match$8 !== undefined ? match$8.combinedBalanceWithPending : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  var match$9 = data.billing_summary;
  var billingLineSubtotal = match$9 !== undefined ? match$9.grossBilled : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  var match$10 = data.billing_summary;
  var totalAmount = match$10 !== undefined ? match$10.combinedBalance : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  var billingLines$1 = Belt_Array.map(data.billing_line.nodes, (function (node) {
          return {
                  billingLineDescription: CS_NonemptyStrings$Util.NonEmptyString.toString(node.billingLineDescription),
                  lineAmount: node.lineAmount,
                  hookFiredAt: node.originatedAt,
                  fareRuleSlug: node.fareRuleSlug
                };
        }));
  var paymentLines$1 = Belt_Array.map(data.payment.nodes, (function (node) {
          return {
                  date: CS_Make$Util.Dateable.Plain.format(node.createdAt, SysCtx$Thin.vars.dateFormat),
                  payer: node.payerDisplayName,
                  lifecycleStatus: Common$Thin.PmtLifecycle.toString(node.lifecycleStatus),
                  shouldRenderStatus: Common$Thin.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                  paymentMethod: Belt_Option.getWithDefault(Belt_Option.map(node.bfcustPmtMethodDescription, CS_NonemptyStrings$Util.NonEmptyString.toString), "Payment"),
                  paymentMethodImage: Belt_Option.map(node.bfcustPmtImageUrl, CS_NonemptyStrings$Util.NonEmptyString.toString),
                  amount: node.netAmount,
                  bfFeeAmount: node.bfFeeAmount
                };
        }));
  return {
          billing: {
            billingLines: billingLines$1,
            billingLineSubtotal: billingLineSubtotal
          },
          payment: {
            completedPaymentSubtotal: completedPaymentSubtotal,
            pendingPaymentSubtotal: pendingPaymentSubtotal,
            paymentLines: paymentLines$1
          },
          totalAmountIncludingPending: totalAmountIncludingPending,
          arePendingPayments: CS_Decimal$Util.Decimal10_2.toFloat(pendingPaymentSubtotal) > 0.0,
          totalAmount: totalAmount,
          paymentPlan: getPaymentPlan({
                TAG: "Current",
                _0: data.paymentPlan
              }),
          depositSchedule: getDepSched(data.paymentPlan)
        };
}

function getBlKey(b) {
  var match = b.fareRuleSlug;
  var match$1 = b.hookFiredAt;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return CS_Make$Util.Dateable.Plain.toString(Caml_option.valFromOption(match$1));
    } else {
      return "nonce";
    }
  }
  var slug = Caml_option.valFromOption(match);
  if (match$1 !== undefined) {
    return CS_Slugs$Util.FareRuleSlug.toString(slug) + CS_Make$Util.Dateable.Plain.toString(Caml_option.valFromOption(match$1));
  } else {
    return CS_Slugs$Util.FareRuleSlug.toString(slug);
  }
}

function getChangelog(current, draft) {
  var totalInclPending_value = current.totalAmountIncludingPending;
  var totalInclPending_newValue = Caml_obj.equal(current.totalAmountIncludingPending, draft.totalAmountIncludingPending) ? undefined : Caml_option.some(draft.totalAmountIncludingPending);
  var totalInclPending = {
    value: totalInclPending_value,
    newValue: totalInclPending_newValue
  };
  var total_value = current.totalAmount;
  var total_newValue = Caml_obj.equal(current.totalAmount, draft.totalAmount) ? undefined : Caml_option.some(draft.totalAmount);
  var total = {
    value: total_value,
    newValue: total_newValue
  };
  var arePending = draft.arePendingPayments;
  var blSubtotal_value = current.billing.billingLineSubtotal;
  var blSubtotal_newValue = Caml_obj.equal(current.billing.billingLineSubtotal, draft.billing.billingLineSubtotal) ? undefined : Caml_option.some(draft.billing.billingLineSubtotal);
  var blSubtotal = {
    value: blSubtotal_value,
    newValue: blSubtotal_newValue
  };
  var currentBlMap = Belt_Array.reduce(current.billing.billingLines, undefined, (function (acc, bl) {
          return Belt_MapString.set(acc, getBlKey(bl), bl);
        }));
  var draftBlMap = Belt_Array.reduce(draft.billing.billingLines, undefined, (function (acc, bl) {
          return Belt_MapString.set(acc, getBlKey(bl), bl);
        }));
  var currentBillingChanges = Belt_Array.map(current.billing.billingLines, (function (bl) {
          var desc = bl.billingLineDescription;
          var amt = bl.lineAmount;
          var draftBl = Belt_MapString.get(draftBlMap, getBlKey(bl));
          var change = draftBl !== undefined ? (
              Caml_obj.equal(draftBl.lineAmount, bl.lineAmount) ? undefined : ({
                    TAG: "Update",
                    _0: draftBl.lineAmount
                  })
            ) : "Delete";
          return {
                  desc: desc,
                  amt: amt,
                  change: change
                };
        }));
  var toAdd = Belt_Array.keepMap(draft.billing.billingLines, (function (bl) {
          var match = Belt_MapString.get(currentBlMap, getBlKey(bl));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    desc: bl.billingLineDescription,
                    amt: bl.lineAmount,
                    change: "New"
                  };
          }
        }));
  return {
          bill: {
            bLines: Belt_Array.concat(currentBillingChanges, toAdd),
            blSubtotal: blSubtotal
          },
          totalInclPending: totalInclPending,
          arePending: arePending,
          total: total
        };
}

function getIcon$1(s) {
  var tmp;
  if (s === "PAID_UP" || s === "BALANCE_DUE" || s === "BALANCE_OVERDUE" || s === "UPCOMING_BALANCE") {
    switch (s) {
      case "BALANCE_OVERDUE" :
          tmp = JsxRuntime.jsx(InfoOutlined, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "BALANCE_DUE" :
          tmp = JsxRuntime.jsx(HistoryToggleOff, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "PAID_UP" :
          tmp = JsxRuntime.jsx(Check, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "UPCOMING_BALANCE" :
          tmp = JsxRuntime.jsx($$Event, {
                fontSize: "md",
                sx: {
                  color: "#6E7F8C"
                }
              });
          break;
      
    }
  } else {
    tmp = JsxRuntime.jsx(QuestionMark, {
          fontSize: "md",
          sx: {
            color: "#6E7F8C"
          }
        });
  }
  var tmp$1;
  if (s === "PAID_UP" || s === "BALANCE_DUE" || s === "BALANCE_OVERDUE" || s === "UPCOMING_BALANCE") {
    switch (s) {
      case "BALANCE_OVERDUE" :
          tmp$1 = "#DE4F48";
          break;
      case "BALANCE_DUE" :
          tmp$1 = "#30557A";
          break;
      case "PAID_UP" :
          tmp$1 = "primary.500";
          break;
      case "UPCOMING_BALANCE" :
          tmp$1 = "#F0F3F5";
          break;
      
    }
  } else {
    tmp$1 = "#F0F3F5";
  }
  var tmp$2;
  if (s === "PAID_UP" || s === "BALANCE_DUE" || s === "BALANCE_OVERDUE" || s === "UPCOMING_BALANCE") {
    switch (s) {
      case "BALANCE_OVERDUE" :
          tmp$2 = "#DE4F48";
          break;
      case "BALANCE_DUE" :
          tmp$2 = "#30557A";
          break;
      case "PAID_UP" :
          tmp$2 = "primary.500";
          break;
      case "UPCOMING_BALANCE" :
          tmp$2 = "#6E7F8C";
          break;
      
    }
  } else {
    tmp$2 = "#F0F3F5";
  }
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(tmp),
              sx: {
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                backgroundColor: tmp$1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid",
                borderColor: tmp$2
              }
            });
}

function getText(dueDate, newAmountDue, numericAmount, paymentAmtType, periodStatus) {
  var dateClause = dueDate !== undefined ? "By " + CS_Make$Util.Dateable.Plain.format(Caml_option.valFromOption(dueDate), SysCtx$Thin.vars.longDateFormat) + ", " : "";
  var parenthetical;
  var exit = 0;
  if (periodStatus === "PAID_UP" || periodStatus === "BALANCE_DUE" || periodStatus === "BALANCE_OVERDUE" || periodStatus === "UPCOMING_BALANCE") {
    switch (periodStatus) {
      case "BALANCE_OVERDUE" :
      case "UPCOMING_BALANCE" :
          exit = 1;
          break;
      default:
        parenthetical = "";
    }
  } else {
    parenthetical = "";
  }
  if (exit === 1) {
    parenthetical = " (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(newAmountDue)) + ")";
  }
  var amtClause;
  if (paymentAmtType === "FLAT_AMOUNT" || paymentAmtType === "PER_GUEST_AMOUNT" || paymentAmtType === "PERCENTAGE_OF_APPLICABLE_BALANCE") {
    switch (paymentAmtType) {
      case "PER_GUEST_AMOUNT" :
          amtClause = Common$Thin.formatCurrency(numericAmount) + " per berth" + parenthetical;
          break;
      case "FLAT_AMOUNT" :
          amtClause = Common$Thin.formatCurrency(numericAmount);
          break;
      case "PERCENTAGE_OF_APPLICABLE_BALANCE" :
          amtClause = String(numericAmount * 100.0 | 0) + "% of applicable balance" + parenthetical;
          break;
      
    }
  } else {
    amtClause = "If you're seeing this, something went wrong";
  }
  return dateClause + amtClause;
}

function BillPayData$DepositScheduleList(props) {
  var content = props.content;
  var len = content.length;
  return JsxRuntime.jsx(List, {
              children: Caml_option.some(Belt_Array.mapWithIndex(content, (function (i, item) {
                          var periodStatus = item.periodStatus;
                          var tmp;
                          tmp = (periodStatus === "PAID_UP" || periodStatus === "BALANCE_DUE" || periodStatus === "BALANCE_OVERDUE" || periodStatus === "UPCOMING_BALANCE") && periodStatus === "PAID_UP" ? "neutral.400" : "inherit";
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 2,
                                                sx: {
                                                  py: 1,
                                                  width: "100%"
                                                },
                                                children: [
                                                  JsxRuntime.jsx(Stack, {
                                                        direction: "column",
                                                        alignItems: "center",
                                                        sx: {
                                                          position: "relative",
                                                          "&::before": {
                                                            content: "\"\"",
                                                            position: "absolute",
                                                            top: "28px",
                                                            borderLeft: (i + 1 | 0) === len ? "0px" : "2px dashed",
                                                            height: "90%",
                                                            borderColor: "#93A5B1"
                                                          }
                                                        },
                                                        children: Caml_option.some(getIcon$1(periodStatus))
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        sx: {
                                                          color: tmp
                                                        },
                                                        children: Caml_option.some(getText(item.dueDate, item.newAmountDue, item.numericAmount, item.paymentAmtType, periodStatus))
                                                      })
                                                ]
                                              }))
                                    }, String(i));
                        }))),
              size: "lg",
              sx: {
                position: "relative"
              }
            });
}

var DepositScheduleList = {
  getIcon: getIcon$1,
  getText: getText,
  make: BillPayData$DepositScheduleList
};

var gracePeriod = 0.25;

exports.gracePeriod = gracePeriod;
exports.emptyPaymentPlan = emptyPaymentPlan;
exports.getPaymentPlan = getPaymentPlan;
exports.PaymentsStatus = PaymentsStatus;
exports.getDepSched = getDepSched;
exports.parse = parse;
exports.getBlKey = getBlKey;
exports.getChangelog = getChangelog;
exports.DepositScheduleList = DepositScheduleList;
/* Common-Thin Not a pure module */
