// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"preferences_generalPref":{"c":"Util.CustomScalars.NonemptyString.Exn"},"preferences_cabinPlacementRequest":{"c":"Util.CustomScalars.NonemptyString.Exn"},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function elevatorProximity_decode($$enum) {
  if ($$enum === "NEARELEVATORS" || $$enum === "FARELEVATORS") {
    return $$enum;
  }
  
}

function elevatorProximity_fromString(str) {
  return elevatorProximity_decode(str);
}

function longitudinality_decode($$enum) {
  if ($$enum === "AFT" || $$enum === "FORE" || $$enum === "MID") {
    return $$enum;
  }
  
}

function longitudinality_fromString(str) {
  return longitudinality_decode(str);
}

var Utils = {
  elevatorProximity_decode: elevatorProximity_decode,
  elevatorProximity_fromString: elevatorProximity_fromString,
  longitudinality_decode: longitudinality_decode,
  longitudinality_fromString: longitudinality_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDPreferences_detail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingPreference",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bedsTogetherPref",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cabinElevatorPref",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cabinLongitudePref",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cabinPlacementRequest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "earlyDinnerSeatingPref",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generalPref",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefManagementLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinPlacementRequestsLocked",
      "storageKey": null
    }
  ],
  "type": "BookingDetail",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
