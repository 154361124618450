// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var SignUp_SignUpMutation_graphql$Thin = require("../../../__generated__/SignUp_SignUpMutation_graphql.bs.js");

var convertVariables = SignUp_SignUpMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = SignUp_SignUpMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = SignUp_SignUpMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, SignUp_SignUpMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SignUp_SignUpMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var SignUpMutation_docGender_decode = SignUp_SignUpMutation_graphql$Thin.Utils.docGender_decode;

var SignUpMutation_docGender_fromString = SignUp_SignUpMutation_graphql$Thin.Utils.docGender_fromString;

var SignUpMutation_signUpErr_decode = SignUp_SignUpMutation_graphql$Thin.Utils.signUpErr_decode;

var SignUpMutation_signUpErr_fromString = SignUp_SignUpMutation_graphql$Thin.Utils.signUpErr_fromString;

var SignUpMutation = {
  docGender_decode: SignUpMutation_docGender_decode,
  docGender_fromString: SignUpMutation_docGender_fromString,
  signUpErr_decode: SignUpMutation_signUpErr_decode,
  signUpErr_fromString: SignUpMutation_signUpErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(setPageLevelError, onSuccess, loginOnSignUp) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var signUp = function (i) {
    setError(function (param) {
          
        });
    mutate({
          input: i
        }, undefined, undefined, undefined, (function (res, err) {
            if (err === undefined) {
              var match = res.signUp;
              if (match !== undefined) {
                var err$1 = match.signUpErr;
                if (err$1 !== undefined) {
                  var exit = 0;
                  if (err$1 === "ACCOUNT_UPDATE_ERROR" || err$1 === "INVITATION_NOT_FOUND" || err$1 === "BOOKING_UPDATE_ERROR" || err$1 === "USER_CANT_BE_CREATED_ASK_SUPPORT" || err$1 === "EMAIL_ALREADY_IN_USE" || err$1 === "CANNOT_CLAIM") {
                    switch (err$1) {
                      case "EMAIL_ALREADY_IN_USE" :
                          setError(function (param) {
                                return "Email already in use";
                              });
                          return ;
                      case "USER_CANT_BE_CREATED_ASK_SUPPORT" :
                          setError(function (param) {
                                return "User can't be created, please contact support";
                              });
                          return ;
                      default:
                        exit = 2;
                    }
                  } else {
                    exit = 2;
                  }
                  if (exit === 2) {
                    setError(function (param) {
                          return "An error occurred creating this user! Please try again.";
                        });
                    return ;
                  }
                  
                }
                
              }
              if (onSuccess !== undefined) {
                onSuccess();
              }
              if (loginOnSignUp !== undefined) {
                loginOnSignUp(i.accountEmail, i.newPasswd);
              }
              return ;
            }
            setError(function (param) {
                  return "An error occurred creating this user! Please try again.";
                });
          }), (function (err) {
            console.error(err);
            setError(function (param) {
                  return "A server error occurred creating this user! Please try again.";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          signUp: signUp
        };
}

exports.SignUpMutation = SignUpMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
