// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var AppConfigFragment_graphql$Thin = require("./AppConfigFragment_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

function makeNode(rescript_graphql_node_AppConfigFragment) {
  return ({
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_AppConfigFragment
    }
  },
  "name": "AppConfig_frag",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppConfigCountries_frag"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppConfigCountriesAcquisitionSlugs_frag"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppConfigBrandFamily_frag"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppConfigVoyages_frag"
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(AppConfigFragment_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
