// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../context/AppConfig.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var Button = require("@mui/joy/Button").default;
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JustAnError$Thin = require("./JustAnError.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var PassengerForms$Thin = require("../components/common/molecules/PassengerForms.bs.js");
var GroupAdd = require("@mui/icons-material/GroupAdd").default;
var KeyOutlined = require("@mui/icons-material/KeyOutlined").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var ClaimPrefilledInvitationModel$Thin = require("../utils/accounts/ClaimPrefilledInvitationModel.bs.js");

function doNothingOnSubmit(e) {
  e.preventDefault();
}

function ClaimPrefilledInvitationPage$SignupError(props) {
  return HopperState$Util.Observable.useComputed(props.view, (function (param) {
                var claimProgress = param.claimProgress;
                if (typeof claimProgress !== "object" || claimProgress.TAG !== "Failure") {
                  return null;
                } else {
                  return JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: claimProgress._0,
                              type_: "error"
                            });
                }
              }), undefined, undefined);
}

var SignupError = {
  make: ClaimPrefilledInvitationPage$SignupError
};

function ClaimPrefilledInvitationPage$InvitationNotFound(props) {
  return JsxRuntime.jsx(JustAnError$Thin.make, {
              text: "This invitation does not exist, is expired, or is otherwise unable to be claimed (" + CS_UUID$Util.PlainUUID.toString(props.allegedToken) + ")."
            });
}

var InvitationNotFound = {
  make: ClaimPrefilledInvitationPage$InvitationNotFound
};

function ClaimPrefilledInvitationPage$MustBeLoggedOut(props) {
  return JsxRuntime.jsx(JustAnError$Thin.make, {
              text: "This invitation is not eligible to be claimed by logged in users (" + CS_UUID$Util.PlainUUID.toString(props.allegedToken) + ")."
            });
}

var MustBeLoggedOut = {
  make: ClaimPrefilledInvitationPage$MustBeLoggedOut
};

function ClaimPrefilledInvitationPage$FormWrapper(props) {
  return JsxRuntime.jsx(PassengerForms$Thin.FormWrapper.make, {
              startDecorator: null,
              title: "",
              titleSize: "md",
              variant: "Gray",
              showTitleSection: false,
              children: JsxRuntime.jsx(Stack, {
                    spacing: 1,
                    children: Caml_option.some(props.children)
                  })
            });
}

var FormWrapper = {
  make: ClaimPrefilledInvitationPage$FormWrapper
};

function ClaimPrefilledInvitationPage$ClaimWrapper(props) {
  var match = AppConfig$Thin.Context.use();
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              sx: {
                maxWidth: "800px"
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      startDecorator: Caml_option.some(JsxRuntime.jsx(PersonOutlined, {})),
                      level: "title-lg",
                      sx: {
                        maxWidth: "600px",
                        alignItems: "flex-start",
                        "word-break": "break-word"
                      },
                      children: Caml_option.some("(" + Common$Thin.emailStringBreak(CS_Emails$Util.Email.toString(props.prefill.obfuscatedEmail)) + ") was invited to claim a user account for " + match.brandFamily.brandFamilyName)
                    }),
                props.children
              ]
            });
}

var ClaimWrapper = {
  make: ClaimPrefilledInvitationPage$ClaimWrapper
};

function ClaimPrefilledInvitationPage$ClaimAnonymously$SubmitStack(props) {
  var allegedToken = props.allegedToken;
  var view = props.view;
  var validated = HopperState$Util.Observable.useComputed(view, (function (m) {
          return ClaimPrefilledInvitationModel$Thin.validateClaimAnonymouslyInput(m, allegedToken);
        }), undefined, undefined);
  var isDirty = HopperState$Util.Observable.useComputed(view, ClaimPrefilledInvitationModel$Thin.checkIsDirty, undefined, undefined);
  var loading = HopperState$Util.Observable.useComputed(view, (function (model) {
          var match = model.claimProgress;
          if (typeof match !== "object" && match === "Loading") {
            return true;
          } else {
            return false;
          }
        }), undefined, undefined);
  var mutation = RemoteData$Util.Relay.useMutationWithNotify(ClaimPrefilledInvitationModel$Thin.Mutation.ClaimAnonymously.use, (function (response) {
          var match = response.claimPrefilledAccountInvitationAnonymously;
          if (match === undefined) {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
          var signUpErr = match.signUpErr;
          if (signUpErr !== undefined) {
            return {
                    TAG: "Failure",
                    _0: ClaimPrefilledInvitationModel$Thin.describeSignUpErr(signUpErr)
                  };
          } else {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
        }), (function (claimProgress) {
          HopperState$Util.Observable.notify(view, (function (model) {
                  return {
                          claimProgress: claimProgress,
                          password: model.password,
                          preferredLastName: model.preferredLastName,
                          docBirthdate: model.docBirthdate,
                          gatedFields: model.gatedFields
                        };
                }));
        }));
  var match;
  if (validated.TAG === "Ok") {
    var input = validated._0;
    match = [
      "Claim My Account",
      "success",
      JsxRuntime.jsx(GroupAdd, {}),
      (function (param) {
          mutation({
                input: input
              }, undefined, undefined, undefined, undefined);
        }),
      undefined
    ];
  } else {
    var tmp;
    if (isDirty) {
      switch (validated._0) {
        case "PasswordError" :
            tmp = "There was an error with the passwords you provided and they cannot be used";
            break;
        case "MissingBday" :
            tmp = "You must provide a birthdate";
            break;
        case "MissingLName" :
            tmp = "You must provide a last name";
            break;
        
      }
    } else {
      tmp = "Your submission is incomplete";
    }
    match = [
      "Show Errors",
      "warning",
      JsxRuntime.jsx(WarningAmber, {}),
      (function (param) {
          HopperState$Util.Observable.notify(view, ClaimPrefilledInvitationModel$Thin.forceShowErrors);
        }),
      tmp
    ];
  }
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              sx: {
                pt: 2
              },
              children: [
                Belt_Option.mapWithDefault(match[4], null, (function (expl) {
                        return JsxRuntime.jsx(Typography, {
                                    sx: {
                                      color: "danger.400"
                                    },
                                    children: Caml_option.some(expl)
                                  });
                      })),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        width: "fit-content"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                loading: loading,
                                type: "submit",
                                onClick: match[3],
                                endDecorator: Caml_option.some(match[2]),
                                loadingPosition: "end",
                                color: match[1],
                                size: "lg",
                                children: Caml_option.some(match[0])
                              }))
                    })
              ]
            });
}

var SubmitStack = {
  make: ClaimPrefilledInvitationPage$ClaimAnonymously$SubmitStack
};

function ClaimPrefilledInvitationPage$ClaimAnonymously(props) {
  var prefill = props.prefill;
  var view = props.view;
  React.useEffect((function () {
          HopperState$Util.Observable.notify(view, (function (m) {
                  return ClaimPrefilledInvitationModel$Thin.prefill(m, prefill);
                }));
        }), [Belt_Option.getWithDefault(JSON.stringify(prefill), "")]);
  var match = HopperState$Util.Observable.useComputed(view, (function (param) {
          return param.gatedFields;
        }), undefined, undefined);
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(ClaimPrefilledInvitationPage$ClaimWrapper, {
                    prefill: prefill,
                    children: [
                      JsxRuntime.jsx(ClaimPrefilledInvitationPage$SignupError, {
                            view: view
                          }),
                      JsxRuntime.jsxs(ClaimPrefilledInvitationPage$FormWrapper, {
                            children: [
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    component: "form",
                                    spacing: 3,
                                    onSubmit: doNothingOnSubmit,
                                    children: [
                                      JsxRuntime.jsxs(Stack, {
                                            children: [
                                              JsxRuntime.jsx(Typography, {
                                                    startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                                                    level: "h4",
                                                    component: "h2",
                                                    sx: {
                                                      mb: 2
                                                    },
                                                    children: "Account Login Details"
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                              children: [
                                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                                                                view: {
                                                                                  TAG: "Direct",
                                                                                  _0: view
                                                                                },
                                                                                input: ClaimPrefilledInvitationModel$Thin.newPassword,
                                                                                children: (function (param) {
                                                                                    var error = param.error;
                                                                                    var set = param.set;
                                                                                    var wasTouched = param.wasTouched;
                                                                                    return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                                                label: "Password",
                                                                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                                                                errorText: param.errorText,
                                                                                                error: error,
                                                                                                children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                                      placeholder: "Password",
                                                                                                      error: error && wasTouched,
                                                                                                      value: param.value,
                                                                                                      onChange: (function (e) {
                                                                                                          set(function (param) {
                                                                                                                return e.target.value;
                                                                                                              });
                                                                                                        }),
                                                                                                      autoComplete: "new-password"
                                                                                                    }),
                                                                                                wasTouched: wasTouched
                                                                                              });
                                                                                  })
                                                                              })),
                                                                      xs: 12,
                                                                      md: 6
                                                                    }),
                                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                                                                view: {
                                                                                  TAG: "Direct",
                                                                                  _0: view
                                                                                },
                                                                                input: ClaimPrefilledInvitationModel$Thin.confirmNewPassword,
                                                                                children: (function (param) {
                                                                                    var error = param.error;
                                                                                    var set = param.set;
                                                                                    var wasTouched = param.wasTouched;
                                                                                    return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                                                label: "Confirm Password",
                                                                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                                                                errorText: param.errorText,
                                                                                                error: error,
                                                                                                children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                                      placeholder: "Confirm Password",
                                                                                                      error: error && wasTouched,
                                                                                                      value: param.value,
                                                                                                      onChange: (function (e) {
                                                                                                          set(function (param) {
                                                                                                                return e.target.value;
                                                                                                              });
                                                                                                        }),
                                                                                                      autoComplete: "new-password"
                                                                                                    }),
                                                                                                wasTouched: wasTouched
                                                                                              });
                                                                                  })
                                                                              })),
                                                                      xs: 12,
                                                                      md: 6
                                                                    })
                                                              ]
                                                            })),
                                                    container: true,
                                                    spacing: {
                                                      TAG: "Fixed",
                                                      _0: 1
                                                    },
                                                    rowSpacing: 2,
                                                    columnSpacing: 2
                                                  })
                                            ]
                                          }),
                                      match.hasAnyGatedFields ? JsxRuntime.jsxs(Stack, {
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                                                      level: "h4",
                                                      component: "h2",
                                                      sx: {
                                                        mb: 2
                                                      },
                                                      children: "Verify Account Details"
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      sx: {
                                                        pb: 2
                                                      },
                                                      children: "In order to claim this account, you must verify some of your information to ensure that it matches with your account."
                                                    }),
                                                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                                      children: [
                                                        match.isLastNameGated ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                                          view: {
                                                                            TAG: "Direct",
                                                                            _0: view
                                                                          },
                                                                          input: ClaimPrefilledInvitationModel$Thin.preferredLastName,
                                                                          map: [
                                                                            (function (o) {
                                                                                return Belt_Option.getWithDefault(o, "");
                                                                              }),
                                                                            (function (s) {
                                                                                if (s === "") {
                                                                                  return ;
                                                                                } else {
                                                                                  return s;
                                                                                }
                                                                              })
                                                                          ],
                                                                          children: (function (param) {
                                                                              var set = param.set;
                                                                              return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                                          label: "Last Name",
                                                                                          errorText: param.errorText,
                                                                                          error: param.error,
                                                                                          children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                                type_: "text",
                                                                                                placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                                                                disabled: false,
                                                                                                variant: "plain",
                                                                                                value: param.value,
                                                                                                onChange: (function (e) {
                                                                                                    set(function (param) {
                                                                                                          return e.target.value;
                                                                                                        });
                                                                                                  })
                                                                                              }),
                                                                                          wasTouched: param.wasTouched
                                                                                        });
                                                                            })
                                                                        })),
                                                                xs: 12,
                                                                md: 6
                                                              }) : null,
                                                        match.isBirthdateGated ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                                          view: {
                                                                            TAG: "Direct",
                                                                            _0: view
                                                                          },
                                                                          input: ClaimPrefilledInvitationModel$Thin.docBirthdate,
                                                                          map: [
                                                                            (function (o) {
                                                                                return Belt_Option.getWithDefault(o, "");
                                                                              }),
                                                                            (function (s) {
                                                                                if (s === "") {
                                                                                  return ;
                                                                                } else {
                                                                                  return s;
                                                                                }
                                                                              })
                                                                          ],
                                                                          children: (function (param) {
                                                                              return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                                          label: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                                                          errorText: param.errorText,
                                                                                          error: param.error,
                                                                                          disabled: false,
                                                                                          children: JsxRuntime.jsx(PassengerForms$Thin.DateInput.make, {
                                                                                                placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                                                                value: param.value,
                                                                                                set: param.set,
                                                                                                disabled: false,
                                                                                                variant: "plain"
                                                                                              }),
                                                                                          required: true,
                                                                                          wasTouched: param.wasTouched
                                                                                        });
                                                                            })
                                                                        })),
                                                                xs: 12,
                                                                md: 6
                                                              }) : null
                                                      ],
                                                      container: true,
                                                      rowSpacing: 2,
                                                      columnSpacing: 2
                                                    })
                                              ]
                                            }) : null
                                    ]
                                  }),
                              JsxRuntime.jsx(ClaimPrefilledInvitationPage$ClaimAnonymously$SubmitStack, {
                                    view: view,
                                    allegedToken: props.allegedToken
                                  })
                            ]
                          })
                    ]
                  }),
              auth: true
            });
}

var ClaimAnonymously = {
  SubmitStack: SubmitStack,
  make: ClaimPrefilledInvitationPage$ClaimAnonymously
};

var FormControl;

var Model;

var variant = "Gray";

exports.FormControl = FormControl;
exports.Model = Model;
exports.variant = variant;
exports.doNothingOnSubmit = doNothingOnSubmit;
exports.SignupError = SignupError;
exports.InvitationNotFound = InvitationNotFound;
exports.MustBeLoggedOut = MustBeLoggedOut;
exports.FormWrapper = FormWrapper;
exports.ClaimWrapper = ClaimWrapper;
exports.ClaimAnonymously = ClaimAnonymously;
/* react Not a pure module */
