// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Stack = require("@mui/joy/Stack").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Menu$1 = require("@mui/icons-material/Menu").default;
var ConfirmActionDialog$Thin = require("./ConfirmActionDialog.bs.js");

function NavMenu(props) {
  var logout = props.logout;
  var match = CurrentUser$Thin.Context.use();
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoseBookingChangesModalOpen = match$1[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = React.useState(function () {
        return "BOOKING";
      });
  var setNavDestination = match$2[1];
  var navDestination = match$2[0];
  var diff = BookingDiff$Thin.parse(url.search);
  var onCancel = function () {
    setLoseBookingChangesModalOpen(function (param) {
          return false;
        });
  };
  var onSubmit = function () {
    setLoseBookingChangesModalOpen(function (param) {
          return false;
        });
    switch (navDestination) {
      case "BOOKING" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
      case "CONNECTED" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.connectedAccountsLink);
      case "ACCOUNT" :
          return RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
      case "LOGOUT" :
          return logout();
      
    }
  };
  var onAttemptToNavigateAway = function (link) {
    var match = diff.hasDiff;
    if (match) {
      return setLoseBookingChangesModalOpen(function (param) {
                  return true;
                });
    } else if (link !== undefined) {
      return RescriptReactRouter.push(link);
    } else {
      return logout();
    }
  };
  return JsxRuntime.jsxs(Stack, {
              sx: {
                display: {
                  xs: "flex",
                  md: props.alwaysHamburger ? "flex" : "none"
                }
              },
              children: [
                JsxRuntime.jsxs(Dropdown, {
                      children: [
                        JsxRuntime.jsx(MenuButton, {
                              children: Caml_option.some(JsxRuntime.jsx(Menu$1, {})),
                              size: "md",
                              slotProps: {
                                root: {
                                  variant: "outlined",
                                  color: "neutral"
                                }
                              },
                              slots: {
                                root: IconButton
                              }
                            }),
                        JsxRuntime.jsxs(Menu, {
                              children: [
                                JsxRuntime.jsx(MenuItem, {
                                      children: Caml_option.some(Txt$Thin.Nav.bookings.value),
                                      onClick: (function (param) {
                                          setNavDestination(function (param) {
                                                return "BOOKING";
                                              });
                                          onAttemptToNavigateAway(SysCtx$Thin.vars.bookingsLink);
                                        })
                                    }),
                                match.hasConnectedAccounts ? JsxRuntime.jsx(MenuItem, {
                                        children: Caml_option.some(Txt$Thin.Nav.connectedAccounts.value),
                                        onClick: (function (param) {
                                            setNavDestination(function (param) {
                                                  return "CONNECTED";
                                                });
                                            onAttemptToNavigateAway(SysCtx$Thin.vars.connectedAccountsLink);
                                          })
                                      }) : null,
                                JsxRuntime.jsx(MenuItem, {
                                      children: Caml_option.some(Txt$Thin.Nav.account.value),
                                      onClick: (function (param) {
                                          setNavDestination(function (param) {
                                                return "ACCOUNT";
                                              });
                                          onAttemptToNavigateAway(SysCtx$Thin.vars.accountLink);
                                        })
                                    }),
                                JsxRuntime.jsx(MenuItem, {
                                      children: Caml_option.some(Txt$Thin.Nav.logout.value),
                                      onClick: (function (param) {
                                          setNavDestination(function (param) {
                                                return "LOGOUT";
                                              });
                                          onAttemptToNavigateAway(undefined);
                                        })
                                    })
                              ],
                              placement: "bottom-end"
                            })
                      ]
                    }),
                JsxRuntime.jsx(ConfirmActionDialog$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setLoseBookingChangesModalOpen(function (param) {
                                return false;
                              });
                        }),
                      action: Txt$Thin.BookingDetails.draftConfirmLoseChangesTitle.value,
                      actionSlug: "cancel-booking-changes",
                      description: Txt$Thin.BookingDetails.draftConfirmLoseChangesText.value,
                      onConfirm: (function (param) {
                          onSubmit();
                        }),
                      confirmText: Txt$Thin.BookingDetails.draftConfirmLoseChangesButtonText.value,
                      onCancel: onCancel,
                      cancelText: Txt$Thin.BookingDetails.draftCancelLoseChangesButtonText.value,
                      loading: false
                    })
              ]
            });
}

var make = NavMenu;

exports.make = make;
/* react Not a pure module */
