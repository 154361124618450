// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var Stack = require("@mui/joy/Stack").default;
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var WaveLoader$Thin = require("../../common/atoms/WaveLoader.bs.js");
var CategoryList$Thin = require("./CategoryList.bs.js");
var SalesHeading$Thin = require("../shared/SalesHeading.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var SubclassGallery$Thin = require("./SubclassGallery.bs.js");
var PeopleOutlined = require("@mui/icons-material/PeopleOutlined").default;
var SquareFootOutlined = require("@mui/icons-material/SquareFootOutlined").default;

function CategorySelection(props) {
  var sc = props.sc;
  var scimages = SalesCabin$Thin.useSalesCabinLinkedImagesForVariant(Belt_Option.flatMap(sc, (function (v) {
              return v.imageSetSlug;
            })), "Aspect13to10");
  var tmp;
  if (sc !== undefined) {
    var maxOccupancy = sc.maxOccupancy;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Stack, {
                  spacing: 1,
                  sx: {
                    px: {
                      xs: 2,
                      md: 4
                    },
                    py: 2
                  },
                  children: Caml_option.some(JsxRuntime.jsx(SalesHeading$Thin.make, {
                            title: sc.name,
                            subtitleComponent: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                      direction: "row",
                                      flexWrap: "wrap",
                                      children: [
                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                              children: String(sc.minSqFoot) + "-" + String(sc.maxSqFoot) + " SQ FT",
                                              startDecorator: Caml_option.some(JsxRuntime.jsx(SquareFootOutlined, {}))
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              sx: {
                                                width: "0.5rem"
                                              }
                                            }),
                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                              children: maxOccupancy !== 1 ? "Up to " + String(maxOccupancy) + " guests" : "1 guest",
                                              startDecorator: Caml_option.some(JsxRuntime.jsx(PeopleOutlined, {}))
                                            })
                                      ]
                                    })),
                            voyage: props.voyage
                          }))
                }),
            JsxRuntime.jsx(Stack, {
                  spacing: 1,
                  sx: {
                    px: {
                      xs: 0,
                      sm: 2,
                      md: 4
                    }
                  },
                  children: Caml_option.some(JsxRuntime.jsx(SubclassGallery$Thin.make, {
                            images: scimages
                          }))
                }),
            JsxRuntime.jsx(Stack, {
                  spacing: 1,
                  sx: {
                    px: {
                      xs: 2,
                      md: 4
                    },
                    py: 2
                  },
                  children: Caml_option.some(JsxRuntime.jsx(CategoryList$Thin.make, {
                            occupancy: props.occupancy,
                            flow: props.flow,
                            url: props.url,
                            urlState: props.urlState,
                            signOnStatus: props.signOnStatus,
                            categories: sc.categories,
                            voyageSlug: props.voyageSlug,
                            refetchCurrentUserInfo: props.refetchCurrentUserInfo,
                            sessionData: props.sessionData,
                            pricingMode: props.pricingMode,
                            setPricingMode: props.setPricingMode
                          }))
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "column",
          justifyContent: "center",
          alignItems: "center",
          sx: {
            zIndex: 1,
            py: 4,
            height: "60vh"
          },
          children: Caml_option.some(JsxRuntime.jsx(WaveLoader$Thin.make, {}))
        });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = CategorySelection;

exports.make = make;
/* AppChip-Thin Not a pure module */
