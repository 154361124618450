// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var BDHero$Thin = require("../components/booking-details/BDHero.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thin = require("../RelayEnv.bs.js");
var Stack = require("@mui/joy/Stack").default;
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var DraftUtils$Thin = require("../utils/booking/DraftUtils.bs.js");
var EditBanner$Thin = require("../components/booking-details/EditBanner.bs.js");
var BillPayData$Thin = require("../components/booking-details/drafts/BillPayData.bs.js");
var BookingDiff$Thin = require("../utils/booking/BookingDiff.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var WelcomeModal$Thin = require("../components/booking-details/WelcomeModal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BDPreferences$Thin = require("../components/booking-details/BDPreferences.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BDPassengerList$Thin = require("../components/booking-details/BDPassengerList.bs.js");
var BDBillingSummary$Thin = require("../components/booking-details/billing-payments/BDBillingSummary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDStateroomDetails$Thin = require("../components/booking-details/BDStateroomDetails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var PaymentSuggestions$Thin = require("../utils/sales-flow/PaymentSuggestions.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var PreviewChangesModal$Thin = require("../components/booking-details/drafts/PreviewChangesModal.bs.js");
var AccountAccessSection$Thin = require("../components/account/AccountAccessSection.bs.js");
var BDPageRefetchQuery_graphql$Thin = require("../__generated__/BDPageRefetchQuery_graphql.bs.js");
var BookingDetailsPageQuery_graphql$Thin = require("../__generated__/BookingDetailsPageQuery_graphql.bs.js");
var BookingDetailsPage_query_graphql$Thin = require("../__generated__/BookingDetailsPage_query_graphql.bs.js");
var BookingDetailsPage_detail_graphql$Thin = require("../__generated__/BookingDetailsPage_detail_graphql.bs.js");
var BookingDetailsPage_passengers_graphql$Thin = require("../__generated__/BookingDetailsPage_passengers_graphql.bs.js");

var convertFragment = BookingDetailsPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsPage_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = BDPageRefetchQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = BDPageRefetchQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingDetailsPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = BookingDetailsPage_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = BookingDetailsPage_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment_docGender_decode = BookingDetailsPage_query_graphql$Thin.Utils.docGender_decode;

var QueryFragment_docGender_fromString = BookingDetailsPage_query_graphql$Thin.Utils.docGender_fromString;

var QueryFragment_lifecycleStatus_decode = BookingDetailsPage_query_graphql$Thin.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BookingDetailsPage_query_graphql$Thin.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  docGender_decode: QueryFragment_docGender_decode,
  docGender_fromString: QueryFragment_docGender_fromString,
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = BookingDetailsPage_detail_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_detail_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_detail_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsPage_detail_graphql$Thin.node, convertFragment$1, fRef);
}

var DetailFragment_paymentAmountType_decode = BookingDetailsPage_detail_graphql$Thin.Utils.paymentAmountType_decode;

var DetailFragment_paymentAmountType_fromString = BookingDetailsPage_detail_graphql$Thin.Utils.paymentAmountType_fromString;

var DetailFragment_paymentPlanPeriodStatus_decode = BookingDetailsPage_detail_graphql$Thin.Utils.paymentPlanPeriodStatus_decode;

var DetailFragment_paymentPlanPeriodStatus_fromString = BookingDetailsPage_detail_graphql$Thin.Utils.paymentPlanPeriodStatus_fromString;

var DetailFragment_pmtLifecycleStatus_decode = BookingDetailsPage_detail_graphql$Thin.Utils.pmtLifecycleStatus_decode;

var DetailFragment_pmtLifecycleStatus_fromString = BookingDetailsPage_detail_graphql$Thin.Utils.pmtLifecycleStatus_fromString;

var DetailFragment = {
  paymentAmountType_decode: DetailFragment_paymentAmountType_decode,
  paymentAmountType_fromString: DetailFragment_paymentAmountType_fromString,
  paymentPlanPeriodStatus_decode: DetailFragment_paymentPlanPeriodStatus_decode,
  paymentPlanPeriodStatus_fromString: DetailFragment_paymentPlanPeriodStatus_fromString,
  pmtLifecycleStatus_decode: DetailFragment_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: DetailFragment_pmtLifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertFragment$2 = BookingDetailsPage_passengers_graphql$Thin.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_passengers_graphql$Thin.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_passengers_graphql$Thin.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsPage_passengers_graphql$Thin.node, convertFragment$2, fRef);
}

var PassengersFragment_docGender_decode = BookingDetailsPage_passengers_graphql$Thin.Utils.docGender_decode;

var PassengersFragment_docGender_fromString = BookingDetailsPage_passengers_graphql$Thin.Utils.docGender_fromString;

var PassengersFragment_fareClassPaxManagement_decode = BookingDetailsPage_passengers_graphql$Thin.Utils.fareClassPaxManagement_decode;

var PassengersFragment_fareClassPaxManagement_fromString = BookingDetailsPage_passengers_graphql$Thin.Utils.fareClassPaxManagement_fromString;

var PassengersFragment_paxDataStatus_decode = BookingDetailsPage_passengers_graphql$Thin.Utils.paxDataStatus_decode;

var PassengersFragment_paxDataStatus_fromString = BookingDetailsPage_passengers_graphql$Thin.Utils.paxDataStatus_fromString;

var PassengersFragment = {
  docGender_decode: PassengersFragment_docGender_decode,
  docGender_fromString: PassengersFragment_docGender_fromString,
  fareClassPaxManagement_decode: PassengersFragment_fareClassPaxManagement_decode,
  fareClassPaxManagement_fromString: PassengersFragment_fareClassPaxManagement_fromString,
  paxDataStatus_decode: PassengersFragment_paxDataStatus_decode,
  paxDataStatus_fromString: PassengersFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

var convertVariables = BookingDetailsPageQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = BookingDetailsPageQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = BookingDetailsPageQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables, BookingDetailsPageQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BookingDetailsPageQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BookingDetailsPageQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BookingDetailsPageQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BookingDetailsPageQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BookingDetailsPageQuery_graphql$Thin.node, convertVariables);

var Query_docGender_decode = BookingDetailsPageQuery_graphql$Thin.Utils.docGender_decode;

var Query_docGender_fromString = BookingDetailsPageQuery_graphql$Thin.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BookingDetailsPageQuery_graphql$Thin.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BookingDetailsPageQuery_graphql$Thin.Utils.elevatorProximity_fromString;

var Query_longitudinality_decode = BookingDetailsPageQuery_graphql$Thin.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BookingDetailsPageQuery_graphql$Thin.Utils.longitudinality_fromString;

var Query_pmtLifecycleStatus_decode = BookingDetailsPageQuery_graphql$Thin.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BookingDetailsPageQuery_graphql$Thin.Utils.pmtLifecycleStatus_fromString;

var Query = {
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$3,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BookingDetailsPage(props) {
  var fragmentRefs = props.fragmentRefs;
  var match = CurrentUser$Thin.Context.use();
  var match$1 = useRefetchable(fragmentRefs);
  var refetch = match$1[1];
  var query = match$1[0];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var signOnStatus = query.currentUserSignonStatus;
  var match$2 = query.bookingDetails;
  var booking;
  if (match$2 !== undefined) {
    var node = Belt_Array.get(match$2.nodes, 0);
    booking = node !== undefined ? node : undefined;
  } else {
    booking = undefined;
  }
  var bookingListItem = booking !== undefined ? booking.bookingListitem : undefined;
  var isBc = bookingListItem !== undefined ? bookingListItem.isBc : false;
  var bookingId = bookingListItem !== undefined ? CS_NonemptyStrings$Util.BookingId.toString(bookingListItem.bookingId) : "";
  var isActive = booking !== undefined && bookingListItem !== undefined ? booking.lifecycleStatus === "ACTIVE" && !DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(bookingListItem.voyStartDate))), new Date()) : false;
  var match$3 = query.numConnected;
  var areConnectedAccts = match$3 !== undefined ? match$3.totalCount > 0 : false;
  var match$4 = query.currentUserAcctId;
  var custodianAccountId;
  if (match$4 !== undefined) {
    var match$5 = Belt_Array.get(match$4.nodes, 0);
    custodianAccountId = match$5 !== undefined ? Caml_option.some(match$5.accountId) : undefined;
  } else {
    custodianAccountId = undefined;
  }
  React.useEffect((function () {
          if (booking !== undefined) {
            
          } else {
            RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
          }
        }), [booking]);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  React.useEffect(function () {
        var handleOnBeforeUnload = (function (event) {
    event.preventDefault();
    event.returnValue = true;
  });
        if (diff.hasDiff) {
          addEventListener("beforeunload", handleOnBeforeUnload, true);
          return (function () {
                    removeEventListener("beforeunload", handleOnBeforeUnload, true);
                  });
        } else {
          return (function () {
                    
                  });
        }
      });
  var canEdit = isBc && isActive && signOnStatus === "SIGNED_ON";
  var match$6 = React.useState(function () {
        if (diff.hasDiff) {
          return canEdit;
        } else {
          return false;
        }
      });
  var setDraftMode = match$6[1];
  var draftMode = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var editBannerVisible = match$7[0];
  var match$8 = React.useState(function () {
        return false;
      });
  var setPreviewModalOpen = match$8[1];
  var match$9 = React.useState(function () {
        
      });
  var setDraftDetails = match$9[1];
  var draftDetails = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setDraftDueNow = match$10[1];
  var match$11 = React.useState(function () {
        
      });
  var setFetchErr = match$11[1];
  var match$12 = React.useState(function () {
        return false;
      });
  var setWelcomeModalOpen = match$12[1];
  React.useEffect((function () {
          if (booking !== undefined && Caml_obj.equal(Storage$Util.Session.get(SysCtx$Thin.getBookedSessionKey(booking.bookingId)), "true")) {
            setWelcomeModalOpen(function (param) {
                  return true;
                });
          }
          
        }), []);
  React.useEffect((function () {
          if (diff.hasDiff && canEdit) {
            setDraftMode(function (param) {
                  return true;
                });
          } else {
            setDraftMode(function (param) {
                  return false;
                });
          }
        }), [
        diff.hasDiff,
        url.search,
        canEdit
      ]);
  if (booking === undefined) {
    return null;
  }
  var passengersRaw = use$2(booking.fragmentRefs);
  var match$13 = diff.addBerths;
  var match$14 = diff.dropBerths;
  var numBerths = match$13 !== undefined ? (
      match$14 !== undefined ? (passengersRaw.numBerths + match$13 | 0) - match$14.length | 0 : passengersRaw.numBerths + match$13 | 0
    ) : (
      match$14 !== undefined ? passengersRaw.numBerths - match$14.length | 0 : passengersRaw.numBerths
    );
  var passengers = DraftUtils$Thin.Pax.generatePaxList(passengersRaw.passengers.nodes, diff, query.allConnected, query.ownAccount, numBerths, booking.bookingId, booking.voyageSlug);
  var getPreviewFromDiff = DraftUtils$Thin.Preview.createPreviewCallback(booking.bookingId, booking.bookingVersionNumber, booking.voyageSlug, DraftUtils$Thin.Pax.postMushIdxMap(passengers));
  var billing = use$1(booking.fragmentRefs);
  var currentBilling = BillPayData$Thin.parse({
        TAG: "Current",
        _0: billing
      });
  var paymentSuggestions = PaymentSuggestions$Thin.getPaymentSuggestions({
        TAG: "ManageBooking",
        _0: billing.paymentPlan
      });
  var match$15 = query.brandFamilyVoyages;
  var voyageName;
  if (match$15 !== undefined) {
    var voyageName$1 = Belt_Array.get(Belt_Array.keepMap(match$15.nodes, (function (n) {
                if (n.voyageSlug === booking.voyageSlug) {
                  return Caml_option.some(n.voyageName);
                }
                
              })), 0);
    voyageName = voyageName$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(voyageName$1)) : CS_Slugs$Util.VoyageSlug.toString(booking.voyageSlug);
  } else {
    voyageName = CS_Slugs$Util.VoyageSlug.toString(booking.voyageSlug);
  }
  var match$16 = React.useState(function () {
        return "NotAsked";
      });
  var setResendMutation = match$16[1];
  var resendMutation = match$16[0];
  var resendVerify = AccountAccessSection$Thin.ResendVerify.useMutationWithNotify(function (mut) {
        setResendMutation(function (param) {
              return mut;
            });
      });
  var resendVerifyIsLoading = AccountAccessSection$Thin.ResendVerify.resendVerifyIsLoading(resendMutation);
  var verifyResendError = AccountAccessSection$Thin.ResendVerify.verifyResendError(resendMutation);
  React.useEffect((function () {
          if (diff.hasDiff) {
            var match = getPreviewFromDiff(diff);
            $$fetch(RelayEnv$Thin.environment, {
                  _bookingId: match.bookingId,
                  _bookingVersionNumber: match.bookingVersionNumber,
                  _thinDiff: match.thinDiff,
                  _voyageSlug: match.voyage
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.previewBookingChanges;
                      if (match !== undefined) {
                        var match$1 = match.nodes;
                        if (match$1.length === 1) {
                          var match$2 = match$1[0];
                          var pbc = match$2.quote;
                          if (pbc !== undefined) {
                            var dueNowAmount = match$2.dueNowAmount;
                            setDraftDetails(function (param) {
                                  return pbc;
                                });
                            return setDraftDueNow(function (param) {
                                        return dueNowAmount;
                                      });
                          }
                          
                        }
                        
                      }
                      
                    } else {
                      console.error(res._0);
                      return ;
                    }
                    setFetchErr(function (param) {
                          return Txt$Thin.BookingDetails.draftBillingFetchError.value;
                        });
                  }), undefined, undefined);
          } else {
            setDraftDetails(function (param) {
                  
                });
            setFetchErr(function (param) {
                  
                });
          }
        }), [url.search]);
  var draftBilling = draftDetails !== undefined ? BillPayData$Thin.parse({
          TAG: "Diff",
          _0: draftDetails,
          _1: match$10[0]
        }) : undefined;
  var tmp;
  tmp = (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "VERIFY_EMAIL" ? JsxRuntime.jsx(Joy$Util.Grid.make, {
          children: Caml_option.some(JsxRuntime.jsx(AppAlert$Thin.make, {
                    message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            Merge$Util.render(Txt$Thin.BookingDetails.emailVerify, {
                                  userEmail: match.userContext.userEmail
                                }),
                            "\u00A0",
                            JsxRuntime.jsx(Joy$Util.Link.make, {
                                  children: Belt_Option.getWithDefault(verifyResendError, resendVerifyIsLoading ? "Resending..." : "Click here to resend your verification email."),
                                  level: "body-sm",
                                  component: "button",
                                  onClick: (function (param) {
                                      if (resendVerifyIsLoading) {
                                        return ;
                                      } else {
                                        return resendVerify();
                                      }
                                    }),
                                  sx: {
                                    textDecoration: "underline",
                                    "&:hover": {
                                      cursor: "pointer"
                                    },
                                    color: "text.tertiary"
                                  }
                                })
                          ]
                        }),
                    type_: "warning",
                    size: "sm"
                  })),
          sx: {
            px: 0,
            mx: 0
          },
          xs: 12
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        height: "100%",
                        width: "100%",
                        overflowX: "auto",
                        display: draftMode ? "initial" : "flex",
                        overflow: "initial",
                        backgroundColor: draftMode ? "#D9E2E8" : "background.level1"
                      },
                      children: [
                        canEdit ? JsxRuntime.jsx(EditBanner$Thin.make, {
                                draftMode: draftMode,
                                bookingId: booking.bookingId,
                                voyageSlug: booking.voyageSlug,
                                setDraftMode: setDraftMode,
                                editBannerVisible: editBannerVisible,
                                setPreviewModalOpen: setPreviewModalOpen
                              }) : null,
                        JsxRuntime.jsx(PageFrame$Thin.make, {
                              children: JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                    children: [
                                      bookingListItem !== undefined && !draftMode ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(BDHero$Thin.make, {
                                                        fragmentRefs: bookingListItem.fragmentRefs,
                                                        draftMode: draftMode,
                                                        setDraftMode: setDraftMode,
                                                        editBannerVisible: editBannerVisible,
                                                        setEditBannerVisible: match$7[1],
                                                        canEdit: canEdit,
                                                        voyageName: voyageName
                                                      })),
                                              sx: {
                                                px: 0,
                                                mx: 0
                                              },
                                              xs: 12
                                            }) : null,
                                      tmp,
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                      sx: {
                                                        height: "100%",
                                                        width: "100%"
                                                      },
                                                      children: Caml_option.some(JsxRuntime.jsx(BDStateroomDetails$Thin.make, {
                                                                fragmentRefs: booking.fragmentRefs,
                                                                bookingId: bookingId,
                                                                canEdit: canEdit,
                                                                draftMode: draftMode
                                                              }))
                                                    })),
                                            sx: {
                                              width: "100%",
                                              pl: 0,
                                              pr: {
                                                xs: 0,
                                                md: "calc(var(--Grid-rowSpacing) / 2)"
                                              }
                                            },
                                            md: 12,
                                            lg: 6
                                          }),
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                      sx: {
                                                        height: "100%"
                                                      },
                                                      children: Caml_option.some(JsxRuntime.jsx(BDBillingSummary$Thin.make, {
                                                                fragmentRefs: booking.fragmentRefs,
                                                                isActive: isActive,
                                                                lifecycleStatus: booking.lifecycleStatus,
                                                                draftBilling: draftBilling,
                                                                currentBilling: currentBilling,
                                                                getPreviewFromDiff: getPreviewFromDiff,
                                                                fetchErr: match$11[0],
                                                                draftMode: draftMode,
                                                                paymentSuggestions: paymentSuggestions,
                                                                voyageSlug: booking.voyageSlug,
                                                                bookingId: booking.bookingId,
                                                                refetch: refetch$1,
                                                                cantPay: signOnStatus !== "SIGNED_ON"
                                                              }))
                                                    })),
                                            sx: {
                                              width: "100%",
                                              pr: 0,
                                              pl: {
                                                xs: 0,
                                                md: "calc(var(--Grid-rowSpacing) / 2)"
                                              }
                                            },
                                            md: 12,
                                            lg: 6
                                          }),
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$Thin.make, {
                                                      fragmentRefs: passengersRaw.fragmentRefs,
                                                      currentUserEmail: query.currentUserEmail,
                                                      isBc: isBc,
                                                      isActive: isActive,
                                                      refetch: refetch$1,
                                                      queryFrag: fragmentRefs,
                                                      areConnectedAccts: areConnectedAccts,
                                                      allConnected: query.allConnected,
                                                      ownAccount: query.ownAccount,
                                                      custodianAccountId: custodianAccountId,
                                                      getPreviewFromDiff: getPreviewFromDiff,
                                                      canEdit: canEdit,
                                                      draftMode: draftMode,
                                                      countryFrag: query.fragmentRefs,
                                                      voyageSlug: booking.voyageSlug,
                                                      bookingId: booking.bookingId,
                                                      bookingVersionNumber: booking.bookingVersionNumber,
                                                      passengers: passengers,
                                                      salesMinBerth: passengersRaw.salesMinBerth,
                                                      numBerths: numBerths,
                                                      paxManagementLocked: passengersRaw.paxManagementLocked || signOnStatus !== "SIGNED_ON",
                                                      fareClassManagePaxBlocked: passengersRaw.fareClassManagePaxBlocked,
                                                      bookingListitem: passengersRaw.bookingListitem
                                                    })),
                                            sx: {
                                              px: 0,
                                              mx: 0
                                            },
                                            xs: 12
                                          }),
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            children: Caml_option.some(JsxRuntime.jsx(BDPreferences$Thin.make, {
                                                      fragmentRefs: booking.fragmentRefs,
                                                      draftMode: draftMode,
                                                      canEdit: canEdit,
                                                      prefManagementLocked: passengersRaw.prefManagementLocked,
                                                      dinnerSeatingLocked: passengersRaw.dinnerSeatingLocked,
                                                      cabinPlacementReleased: passengersRaw.cabinPlacementReleased
                                                    })),
                                            sx: {
                                              px: 0,
                                              mx: 0
                                            },
                                            xs: 12
                                          })
                                    ],
                                    container: true,
                                    rowSpacing: 2,
                                    columnSpacing: 2,
                                    sx: {
                                      width: "100%",
                                      flexGrow: 1,
                                      flexShrink: 1,
                                      px: 0,
                                      mx: 0
                                    }
                                  }),
                              backgroundColor: draftMode ? "#D9E2E8" : "background.level1"
                            }),
                        canEdit && isBc ? JsxRuntime.jsx(Stack, {
                                sx: {
                                  height: {
                                    xs: "5rem",
                                    md: "0"
                                  }
                                }
                              }) : null,
                        JsxRuntime.jsx(PreviewChangesModal$Thin.make, {
                              open_: match$8[0],
                              onClose: (function () {
                                  setPreviewModalOpen(function (param) {
                                        return false;
                                      });
                                }),
                              setDraftMode: setDraftMode,
                              refetch: refetch$1,
                              getPreviewFromDiff: getPreviewFromDiff,
                              currentBilling: currentBilling,
                              draftBilling: draftBilling
                            })
                      ]
                    }),
                JsxRuntime.jsx(WelcomeModal$Thin.make, {
                      open_: match$12[0],
                      onClose: (function () {
                          var bookingId = booking.bookingId;
                          Storage$Util.Session.$$delete(SysCtx$Thin.getBookedSessionKey(bookingId));
                          setWelcomeModalOpen(function (param) {
                                return false;
                              });
                        })
                    })
              ]
            });
}

var make = BookingDetailsPage;

exports.QueryFragment = QueryFragment;
exports.DetailFragment = DetailFragment;
exports.PassengersFragment = PassengersFragment;
exports.Query = Query;
exports.make = make;
/* use Not a pure module */
