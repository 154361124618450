// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetInt = require("rescript/lib/js/belt_SetInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var emptyDiff = {
  hasDiff: false,
  addBerths: undefined,
  autoAdd: undefined,
  dropBerths: undefined,
  detachPax: undefined,
  attachExistingConnectedPax: undefined,
  attachInvitedPax: undefined,
  attachNewConnectedPax: undefined,
  attachExistingLoyaltyPax: undefined,
  replacePaxExistingConnected: undefined,
  replaceInvitedPax: undefined,
  replaceNewConnectedPax: undefined,
  replaceExistingLoyaltyPax: undefined,
  updateBookingPreferences: undefined,
  revokePaxInvitation: undefined,
  changeCabinU: undefined,
  patchPax: undefined
};

function setHasDiff(d) {
  if (Caml_obj.equal(d, emptyDiff)) {
    return emptyDiff;
  } else {
    return {
            hasDiff: true,
            addBerths: d.addBerths,
            autoAdd: d.autoAdd,
            dropBerths: d.dropBerths,
            detachPax: d.detachPax,
            attachExistingConnectedPax: d.attachExistingConnectedPax,
            attachInvitedPax: d.attachInvitedPax,
            attachNewConnectedPax: d.attachNewConnectedPax,
            attachExistingLoyaltyPax: d.attachExistingLoyaltyPax,
            replacePaxExistingConnected: d.replacePaxExistingConnected,
            replaceInvitedPax: d.replaceInvitedPax,
            replaceNewConnectedPax: d.replaceNewConnectedPax,
            replaceExistingLoyaltyPax: d.replaceExistingLoyaltyPax,
            updateBookingPreferences: d.updateBookingPreferences,
            revokePaxInvitation: d.revokePaxInvitation,
            changeCabinU: d.changeCabinU,
            patchPax: d.patchPax
          };
  }
}

function arePreferenceRecordsSame(record1, record2) {
  if (Caml_obj.equal(record1.bedsTogetherPref, record2.bedsTogetherPref) && Caml_obj.equal(record1.cabinLongitudePref, record2.cabinLongitudePref) && Caml_obj.equal(record1.cabinElevatorPref, record2.cabinElevatorPref) && Caml_obj.equal(record1.cabinPlacementRequest, record2.cabinPlacementRequest) && Caml_obj.equal(record1.earlyDinnerSeatingPref, record2.earlyDinnerSeatingPref)) {
    return Caml_obj.equal(record1.generalPref, record2.generalPref);
  } else {
    return false;
  }
}

function addBerthsIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.addBerths, 0);
}

function addBerthsIfAny_set(diff, num) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: num < 1 ? undefined : num,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var addBerthsIfAny = {
  get: addBerthsIfAny_get,
  set: addBerthsIfAny_set
};

function autoAddIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.autoAdd, false);
}

function autoAddIfAny_set(diff, bool) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: bool ? bool : undefined,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var autoAddIfAny = {
  get: autoAddIfAny_get,
  set: autoAddIfAny_set
};

function dropBerthsIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.dropBerths, undefined, Belt_SetInt.fromArray);
}

function dropBerthsIfAny_set(diff, set) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: Belt_SetInt.isEmpty(set) ? undefined : Belt_SetInt.toArray(set),
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var dropBerthsIfAny = {
  get: dropBerthsIfAny_get,
  set: dropBerthsIfAny_set
};

function patchPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.patchPax, undefined);
}

function patchPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map)
            });
}

var patchPaxIfAny = {
  get: patchPaxIfAny_get,
  set: patchPaxIfAny_set
};

function attachExistingConnectedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachExistingConnectedPax, undefined);
}

function attachExistingConnectedPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var attachExistingConnectedPaxIfAny = {
  get: attachExistingConnectedPaxIfAny_get,
  set: attachExistingConnectedPaxIfAny_set
};

function attachInvitedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachInvitedPax, undefined);
}

function attachInvitedPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var attachInvitedPaxIfAny = {
  get: attachInvitedPaxIfAny_get,
  set: attachInvitedPaxIfAny_set
};

function replacePaxExistingConnectedIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replacePaxExistingConnected, undefined);
}

function replacePaxExistingConnectedIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var replacePaxExistingConnectedIfAny = {
  get: replacePaxExistingConnectedIfAny_get,
  set: replacePaxExistingConnectedIfAny_set
};

function replaceInvitedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replaceInvitedPax, undefined);
}

function replaceInvitedPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var replaceInvitedPaxIfAny = {
  get: replaceInvitedPaxIfAny_get,
  set: replaceInvitedPaxIfAny_set
};

function attachNewConnectedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachNewConnectedPax, undefined);
}

function attachNewConnectedPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var attachNewConnectedPaxIfAny = {
  get: attachNewConnectedPaxIfAny_get,
  set: attachNewConnectedPaxIfAny_set
};

function replaceNewConnectedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replaceNewConnectedPax, undefined);
}

function replaceNewConnectedPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var replaceNewConnectedPaxIfAny = {
  get: replaceNewConnectedPaxIfAny_get,
  set: replaceNewConnectedPaxIfAny_set
};

function attachExistingLoyaltyPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachExistingLoyaltyPax, undefined);
}

function attachExistingLoyaltyPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var attachExistingLoyaltyPaxIfAny = {
  get: attachExistingLoyaltyPaxIfAny_get,
  set: attachExistingLoyaltyPaxIfAny_set
};

function replaceExistingLoyaltyPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replaceExistingLoyaltyPax, undefined);
}

function replaceExistingLoyaltyPaxIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var replaceExistingLoyaltyPaxIfAny = {
  get: replaceExistingLoyaltyPaxIfAny_get,
  set: replaceExistingLoyaltyPaxIfAny_set
};

function detachPaxIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.detachPax, undefined, Belt_SetInt.fromArray);
}

function detachPaxIfAny_set(diff, set) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: Belt_SetInt.isEmpty(set) ? undefined : Belt_SetInt.toArray(set),
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var detachPaxIfAny = {
  get: detachPaxIfAny_get,
  set: detachPaxIfAny_set
};

function updateBookingPreferencesIfAny_get(diff) {
  return diff.updateBookingPreferences;
}

function updateBookingPreferencesIfAny_set(diff, newPrefs, versionPrefs) {
  var tmp;
  if (newPrefs !== undefined) {
    var exit = 0;
    if (newPrefs.bedsTogetherPref !== undefined || newPrefs.cabinLongitudePref !== undefined || newPrefs.cabinElevatorPref !== undefined || newPrefs.cabinPlacementRequest !== undefined || newPrefs.earlyDinnerSeatingPref !== undefined || newPrefs.generalPref !== undefined) {
      exit = 1;
    } else {
      tmp = undefined;
    }
    if (exit === 1) {
      tmp = arePreferenceRecordsSame(newPrefs, versionPrefs) ? undefined : newPrefs;
    }
    
  } else {
    tmp = undefined;
  }
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: tmp,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var updateBookingPreferencesIfAny = {
  get: updateBookingPreferencesIfAny_get,
  set: updateBookingPreferencesIfAny_set
};

function revokePaxInvitationIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.revokePaxInvitation, undefined);
}

function revokePaxInvitationIfAny_set(diff, map) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map),
              changeCabinU: diff.changeCabinU,
              patchPax: diff.patchPax
            });
}

var revokePaxInvitationIfAny = {
  get: revokePaxInvitationIfAny_get,
  set: revokePaxInvitationIfAny_set
};

function changeCabinUIfAny_get(diff) {
  return diff.changeCabinU;
}

function changeCabinUIfAny_set(diff, cabin) {
  return setHasDiff({
              hasDiff: diff.hasDiff,
              addBerths: diff.addBerths,
              autoAdd: diff.autoAdd,
              dropBerths: diff.dropBerths,
              detachPax: diff.detachPax,
              attachExistingConnectedPax: diff.attachExistingConnectedPax,
              attachInvitedPax: diff.attachInvitedPax,
              attachNewConnectedPax: diff.attachNewConnectedPax,
              attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
              replacePaxExistingConnected: diff.replacePaxExistingConnected,
              replaceInvitedPax: diff.replaceInvitedPax,
              replaceNewConnectedPax: diff.replaceNewConnectedPax,
              replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
              updateBookingPreferences: diff.updateBookingPreferences,
              revokePaxInvitation: diff.revokePaxInvitation,
              changeCabinU: cabin,
              patchPax: diff.patchPax
            });
}

var changeCabinUIfAny = {
  get: changeCabinUIfAny_get,
  set: changeCabinUIfAny_set
};

var UnnestedDiff = {
  setHasDiff: setHasDiff,
  arePreferenceRecordsSame: arePreferenceRecordsSame,
  addBerthsIfAny: addBerthsIfAny,
  autoAddIfAny: autoAddIfAny,
  dropBerthsIfAny: dropBerthsIfAny,
  patchPaxIfAny: patchPaxIfAny,
  attachExistingConnectedPaxIfAny: attachExistingConnectedPaxIfAny,
  attachInvitedPaxIfAny: attachInvitedPaxIfAny,
  replacePaxExistingConnectedIfAny: replacePaxExistingConnectedIfAny,
  replaceInvitedPaxIfAny: replaceInvitedPaxIfAny,
  attachNewConnectedPaxIfAny: attachNewConnectedPaxIfAny,
  replaceNewConnectedPaxIfAny: replaceNewConnectedPaxIfAny,
  attachExistingLoyaltyPaxIfAny: attachExistingLoyaltyPaxIfAny,
  replaceExistingLoyaltyPaxIfAny: replaceExistingLoyaltyPaxIfAny,
  detachPaxIfAny: detachPaxIfAny,
  updateBookingPreferencesIfAny: updateBookingPreferencesIfAny,
  revokePaxInvitationIfAny: revokePaxInvitationIfAny,
  changeCabinUIfAny: changeCabinUIfAny
};

function patchConnected(patch, connected) {
  var firstName = patch.cpDocFirstName;
  var lastName = patch.cpDocLastName;
  var gender = patch.cpDocGender;
  var birthdate = patch.cpBirthdate;
  var tmp;
  if (birthdate !== undefined) {
    var birthdate$1 = Caml_option.valFromOption(birthdate);
    tmp = Caml_obj.notequal(birthdate$1, connected.docBirthdate) ? birthdate$1 : connected.docBirthdate;
  } else {
    tmp = connected.docBirthdate;
  }
  var citizenship = patch.cpDocCitizenship;
  var tmp$1;
  if (citizenship !== undefined) {
    var citizenship$1 = Caml_option.valFromOption(citizenship);
    tmp$1 = Caml_obj.notequal(citizenship$1, connected.docCitizenship) ? citizenship$1 : connected.docCitizenship;
  } else {
    tmp$1 = connected.docCitizenship;
  }
  return {
          custodianAccountId: connected.custodianAccountId,
          docFirstName: firstName !== undefined && firstName !== CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(connected.docFirstName) ? CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(firstName) : connected.docFirstName,
          docLastName: lastName !== undefined && lastName !== CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(connected.docLastName) ? CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(lastName) : connected.docLastName,
          docGender: gender !== undefined && Caml_obj.notequal(gender, connected.docGender) ? gender : connected.docGender,
          docBirthdate: tmp,
          docCitizenship: tmp$1,
          preferredFirstName: connected.preferredFirstName,
          preferredLastName: connected.preferredLastName
        };
}

function changeCabinUnbooked(diff, newCabin) {
  return changeCabinUIfAny_set(diff, newCabin);
}

function updateBookingPreferences(diff, newPrefs, versionPrefs) {
  return updateBookingPreferencesIfAny_set(diff, newPrefs, versionPrefs);
}

function updateNewConnected(diff, idx, connectedPatch) {
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var match = Belt_MapInt.get(attachNewConnectedPaxMap, idx);
  var match$1 = Belt_MapInt.get(replaceNewConnectedPaxMap, idx);
  if (match !== undefined) {
    return attachNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(attachNewConnectedPaxMap, idx, patchConnected(connectedPatch, match)));
  } else if (match$1 !== undefined) {
    return replaceNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(replaceNewConnectedPaxMap, idx, patchConnected(connectedPatch, match$1)));
  } else {
    return diff;
  }
}

function patchPax(diff, idx, paxPatch) {
  var patchPaxMap = patchPaxIfAny_get(diff);
  return patchPaxIfAny_set(diff, Belt_MapInt.set(patchPaxMap, idx, paxPatch));
}

function revokePaxInvitation(diff, revokeAtIndex, revokeAccountWithId) {
  return revokePaxInvitationIfAny_set(diff, Belt_MapInt.set(revokePaxInvitationIfAny_get(diff), revokeAtIndex, revokeAccountWithId));
}

function attachAnExistingPax(diff, attachAtIndex, attachAccountWithId) {
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replacePaxExistingConnectedMap = replacePaxExistingConnectedIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replacePaxExistingConnectedIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replacePaxExistingConnectedMap, attachAtIndex, attachAccountWithId));
  } else {
    return attachExistingConnectedPaxIfAny_set(diff, Belt_MapInt.set(attachExistingConnectedPaxMap, attachAtIndex, attachAccountWithId));
  }
}

function attachAnInvitedPax(diff, attachAtIndex, paxInvitation) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replaceInvitedPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replaceInvitedPaxMap, attachAtIndex, paxInvitation));
  } else {
    return attachInvitedPaxIfAny_set(diff, Belt_MapInt.set(attachInvitedPaxMap, attachAtIndex, paxInvitation));
  }
}

function attachNewConnectedPax(diff, attachAtIndex, connectedAcct) {
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replaceNewConnectedPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replaceNewConnectedPaxMap, attachAtIndex, connectedAcct));
  } else {
    return attachNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(attachNewConnectedPaxMap, attachAtIndex, connectedAcct));
  }
}

function attachExistingLoyaltyPax(diff, attachAtIndex, loyaltyPatch) {
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replaceExistingLoyaltyPaxMap = replaceExistingLoyaltyPaxIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replaceExistingLoyaltyPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replaceExistingLoyaltyPaxMap, attachAtIndex, loyaltyPatch));
  } else {
    return attachExistingLoyaltyPaxIfAny_set(diff, Belt_MapInt.set(attachExistingLoyaltyPaxMap, attachAtIndex, loyaltyPatch));
  }
}

function replaceWithExistingPax(diff, replaceAtIndex, replaceWithAccountWithId) {
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replacePaxExistingConnectedMap = replacePaxExistingConnectedIfAny_get(diff);
  var paxPatchMap = patchPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachExistingLoyaltyPaxMap, replaceAtIndex)) {
    return attachExistingLoyaltyPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingConnectedPaxIfAny_set(diff, Belt_MapInt.set(attachExistingConnectedPaxMap, replaceAtIndex, replaceWithAccountWithId)), Belt_MapInt.remove(attachInvitedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachNewConnectedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, replaceAtIndex));
  } else {
    return patchPaxIfAny_set(replacePaxExistingConnectedIfAny_set(diff, Belt_MapInt.set(replacePaxExistingConnectedMap, replaceAtIndex, replaceWithAccountWithId)), Belt_MapInt.remove(paxPatchMap, replaceAtIndex));
  }
}

function replaceWithInvitedPax(diff, replaceAtIndex, paxInvitation) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  var paxPatchMap = patchPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachExistingLoyaltyPaxMap, replaceAtIndex)) {
    return attachExistingLoyaltyPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingConnectedPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingConnectedPaxMap, replaceAtIndex)), Belt_MapInt.set(attachInvitedPaxMap, replaceAtIndex, paxInvitation)), Belt_MapInt.remove(attachNewConnectedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, replaceAtIndex));
  } else {
    return patchPaxIfAny_set(replaceInvitedPaxIfAny_set(diff, Belt_MapInt.set(replaceInvitedPaxMap, replaceAtIndex, paxInvitation)), Belt_MapInt.remove(paxPatchMap, replaceAtIndex));
  }
}

function replaceWithNewConnectedPax(diff, replaceAtIndex, connectedAcct) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var paxPatchMap = patchPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachExistingLoyaltyPaxMap, replaceAtIndex)) {
    return attachExistingLoyaltyPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingConnectedPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingConnectedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachInvitedPaxMap, replaceAtIndex)), Belt_MapInt.set(attachNewConnectedPaxMap, replaceAtIndex, connectedAcct)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, replaceAtIndex));
  } else {
    return patchPaxIfAny_set(replaceNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(replaceNewConnectedPaxMap, replaceAtIndex, connectedAcct)), Belt_MapInt.remove(paxPatchMap, replaceAtIndex));
  }
}

function replaceWithExistingLoyaltyPax(diff, replaceAtIndex, loyaltyPatch) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replaceExistingLoyaltyPaxMap = replaceExistingLoyaltyPaxIfAny_get(diff);
  var paxPatchMap = patchPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachExistingLoyaltyPaxMap, replaceAtIndex)) {
    return attachExistingConnectedPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingLoyaltyPaxIfAny_set(diff, Belt_MapInt.set(attachExistingLoyaltyPaxMap, replaceAtIndex, loyaltyPatch)), Belt_MapInt.remove(attachInvitedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachNewConnectedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachExistingConnectedPaxMap, replaceAtIndex));
  } else {
    return patchPaxIfAny_set(replaceExistingLoyaltyPaxIfAny_set(diff, Belt_MapInt.set(replaceExistingLoyaltyPaxMap, replaceAtIndex, loyaltyPatch)), Belt_MapInt.remove(paxPatchMap, replaceAtIndex));
  }
}

function addSingleBerth(diff) {
  var addBerthsNum = addBerthsIfAny_get(diff);
  var dropBerthsSet = dropBerthsIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var higestDroppingIdx = Belt_SetInt.maximum(dropBerthsSet);
  if (higestDroppingIdx !== undefined) {
    return autoAddIfAny_set(dropBerthsIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.add(detachPaxSet, higestDroppingIdx)), Belt_SetInt.remove(dropBerthsSet, higestDroppingIdx)), true);
  } else {
    return autoAddIfAny_set(addBerthsIfAny_set(diff, addBerthsNum + 1 | 0), true);
  }
}

function clearAutoAdd(diff) {
  return autoAddIfAny_set(diff, false);
}

function dropSingleBerth(diff, newIdxToDrop) {
  var addBerthsNum = addBerthsIfAny_get(diff);
  var dropBerthsSet = dropBerthsIfAny_get(diff);
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replacePaxExistingConnectedMap = replacePaxExistingConnectedIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var replaceExistingLoyaltyPaxMap = replaceExistingLoyaltyPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var patchPaxMap = patchPaxIfAny_get(diff);
  if (addBerthsNum > 0) {
    return patchPaxIfAny_set(replaceExistingLoyaltyPaxIfAny_set(replaceNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(replacePaxExistingConnectedIfAny_set(attachExistingLoyaltyPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingConnectedPaxIfAny_set(detachPaxIfAny_set(addBerthsIfAny_set(diff, addBerthsNum - 1 | 0), Belt_SetInt.remove(detachPaxSet, newIdxToDrop)), Belt_MapInt.remove(attachExistingConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, newIdxToDrop)), Belt_MapInt.remove(replacePaxExistingConnectedMap, newIdxToDrop)), Belt_MapInt.remove(replaceInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceExistingLoyaltyPaxMap, newIdxToDrop)), Belt_MapInt.remove(patchPaxMap, newIdxToDrop));
  } else {
    return patchPaxIfAny_set(replaceExistingLoyaltyPaxIfAny_set(replaceNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(replacePaxExistingConnectedIfAny_set(attachExistingLoyaltyPaxIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingConnectedPaxIfAny_set(detachPaxIfAny_set(dropBerthsIfAny_set(diff, Belt_SetInt.add(dropBerthsSet, newIdxToDrop)), Belt_SetInt.remove(detachPaxSet, newIdxToDrop)), Belt_MapInt.remove(attachExistingConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, newIdxToDrop)), Belt_MapInt.remove(replacePaxExistingConnectedMap, newIdxToDrop)), Belt_MapInt.remove(replaceInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceExistingLoyaltyPaxMap, newIdxToDrop)), Belt_MapInt.remove(patchPaxMap, newIdxToDrop));
  }
}

function detachSingleBerth(diff, indexToDetach) {
  var attachExistingConnectedPaxMap = attachExistingConnectedPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var attachExistingLoyaltyPaxMap = attachExistingLoyaltyPaxIfAny_get(diff);
  var replacePaxExistingConnectedMap = replacePaxExistingConnectedIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var replaceExistingLoyaltyPaxMap = replaceExistingLoyaltyPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var patchPaxMap = patchPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingConnectedPaxMap, indexToDetach) || Belt_MapInt.has(replacePaxExistingConnectedMap, indexToDetach) || Belt_MapInt.has(attachInvitedPaxMap, indexToDetach) || Belt_MapInt.has(replaceInvitedPaxMap, indexToDetach) || Belt_MapInt.has(attachNewConnectedPaxMap, indexToDetach) || Belt_MapInt.has(replaceNewConnectedPaxMap, indexToDetach) || Belt_MapInt.has(attachExistingLoyaltyPaxMap, indexToDetach) || Belt_MapInt.has(replaceExistingLoyaltyPaxMap, indexToDetach)) {
    return patchPaxIfAny_set(replaceExistingLoyaltyPaxIfAny_set(attachExistingLoyaltyPaxIfAny_set(replaceNewConnectedPaxIfAny_set(attachNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(attachInvitedPaxIfAny_set(replacePaxExistingConnectedIfAny_set(attachExistingConnectedPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingConnectedPaxMap, indexToDetach)), Belt_MapInt.remove(replacePaxExistingConnectedMap, indexToDetach)), Belt_MapInt.remove(attachInvitedPaxMap, indexToDetach)), Belt_MapInt.remove(replaceInvitedPaxMap, indexToDetach)), Belt_MapInt.remove(attachNewConnectedPaxMap, indexToDetach)), Belt_MapInt.remove(replaceNewConnectedPaxMap, indexToDetach)), Belt_MapInt.remove(attachExistingLoyaltyPaxMap, indexToDetach)), Belt_MapInt.remove(replaceExistingLoyaltyPaxMap, indexToDetach)), Belt_MapInt.remove(patchPaxMap, indexToDetach));
  } else {
    return patchPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.add(detachPaxSet, indexToDetach)), Belt_MapInt.remove(patchPaxMap, indexToDetach));
  }
}

var Alter = {
  patchConnected: patchConnected,
  changeCabinUnbooked: changeCabinUnbooked,
  updateBookingPreferences: updateBookingPreferences,
  updateNewConnected: updateNewConnected,
  patchPax: patchPax,
  revokePaxInvitation: revokePaxInvitation,
  attachAnExistingPax: attachAnExistingPax,
  attachAnInvitedPax: attachAnInvitedPax,
  attachNewConnectedPax: attachNewConnectedPax,
  attachExistingLoyaltyPax: attachExistingLoyaltyPax,
  replaceWithExistingPax: replaceWithExistingPax,
  replaceWithInvitedPax: replaceWithInvitedPax,
  replaceWithNewConnectedPax: replaceWithNewConnectedPax,
  replaceWithExistingLoyaltyPax: replaceWithExistingLoyaltyPax,
  addSingleBerth: addSingleBerth,
  clearAutoAdd: clearAutoAdd,
  dropSingleBerth: dropSingleBerth,
  detachSingleBerth: detachSingleBerth
};

function fieldToString(diffField) {
  return diffField;
}

var genderCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "M",
        "F",
        "X"
      ],
      toString: (function (a) {
          return a;
        })
    });

var longitudeCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "FORE",
        "MID",
        "AFT"
      ],
      toString: (function (a) {
          return a;
        })
    });

var elevProximityCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "NEARELEVATORS",
        "FARELEVATORS"
      ],
      toString: (function (a) {
          return a;
        })
    });

var preferencesCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple6(QueryString$Util.Codec.bool, longitudeCodec, elevProximityCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.bool, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: {
                    bedsTogetherPref: param[0],
                    cabinLongitudePref: param[1],
                    cabinElevatorPref: param[2],
                    cabinPlacementRequest: param[3],
                    earlyDinnerSeatingPref: param[4],
                    generalPref: param[5]
                  }
                };
        }),
      encode: (function (param) {
          return [
                  param.bedsTogetherPref,
                  param.cabinLongitudePref,
                  param.cabinElevatorPref,
                  param.cabinPlacementRequest,
                  param.earlyDinnerSeatingPref,
                  param.generalPref
                ];
        })
    });

function paxApplicationMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), set, get, (function (v) {
                return v;
              }), (function (v) {
                return v;
              }));
}

function paxInvitationMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple4(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Emails$Util.Email)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          email: param[1],
                          firstName: param[2],
                          lastName: param[3]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.email,
                        match.firstName,
                        match.lastName
                      ];
              }));
}

function connectedPaxMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple9(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.required(genderCodec), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_SQLDatesAndTimes$Util.DateOnly)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          custodianAccountId: param[1],
                          docFirstName: param[2],
                          docLastName: param[3],
                          docGender: param[4],
                          docBirthdate: param[5],
                          docCitizenship: param[6],
                          preferredFirstName: param[7],
                          preferredLastName: param[8]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.custodianAccountId,
                        match.docFirstName,
                        match.docLastName,
                        match.docGender,
                        match.docBirthdate,
                        match.docCitizenship,
                        match.preferredFirstName,
                        match.preferredLastName
                      ];
              }));
}

function patchPaxMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple6(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), genderCodec, QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3), QueryString$Util.Codec.required(QueryString$Util.Codec.bool), QueryString$Util.Codec.delimitedArray(undefined, QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Slugs$Util.VoyageSlug)))), set, get, (function (param) {
                return [
                        param[0],
                        {
                          accountId: param[1],
                          pDocGender: param[2],
                          pDocCitizenship: param[3],
                          updateUserAccount: param[4],
                          updateOtherVoyages: param[5]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.accountId,
                        match.pDocGender,
                        match.pDocCitizenship,
                        match.updateUserAccount,
                        match.updateOtherVoyages
                      ];
              }));
}

function loyaltyPatchMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple5(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.LoyaltyNumber)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.customScalar(CS_Emails$Util.Email)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          acctId: param[1],
                          loyalty: param[2],
                          protectedName: param[3],
                          loyEmail: param[4]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.acctId,
                        match.loyalty,
                        match.protectedName,
                        match.loyEmail
                      ];
              }));
}

var bookingDiffCodec = QueryString$Util.Parser.make([
      QueryString$Util.Param.Singular.make("drop", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, dropBerths) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.dropBerths;
            })),
      QueryString$Util.Param.Singular.make("add", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, addBerths) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.addBerths;
            })),
      QueryString$Util.Param.Singular.make("aa", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), false, (function (diff, autoAdd) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.autoAdd;
            })),
      QueryString$Util.Param.Singular.make("detach", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, detachPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.detachPax;
            })),
      QueryString$Util.Param.Multiple.AsIntMap.make("attachEx", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), (function (diff, attachExistingConnectedPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.attachExistingConnectedPax;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            })),
      QueryString$Util.Param.Multiple.AsIntMap.make("replaceEx", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), (function (diff, replacePaxExistingConnected) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.replacePaxExistingConnected;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            })),
      paxInvitationMapField("attachInv", fieldToString, (function (diff) {
              return diff.attachInvitedPax;
            }), (function (diff, attachInvitedPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      paxInvitationMapField("replaceInv", fieldToString, (function (diff) {
              return diff.replaceInvitedPax;
            }), (function (diff, replaceInvitedPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      connectedPaxMapField("attachNew", fieldToString, (function (diff) {
              return diff.attachNewConnectedPax;
            }), (function (diff, attachNewConnectedPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      connectedPaxMapField("replaceNew", fieldToString, (function (diff) {
              return diff.replaceNewConnectedPax;
            }), (function (diff, replaceNewConnectedPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      QueryString$Util.Param.Singular.make("bookPref", fieldToString, preferencesCodec, false, (function (diff, updateBookingPreferences) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.updateBookingPreferences;
            })),
      paxApplicationMapField("revoke", fieldToString, (function (diff) {
              return diff.revokePaxInvitation;
            }), (function (diff, revokePaxInvitation) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      QueryString$Util.Param.Singular.make("change", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber))), false, (function (diff, changeCabinU) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: changeCabinU,
                      patchPax: diff.patchPax
                    };
            }), (function (diff) {
              return diff.changeCabinU;
            })),
      patchPaxMapField("patch", fieldToString, (function (diff) {
              return diff.patchPax;
            }), (function (diff, patchPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: patchPax
                    };
            })),
      loyaltyPatchMapField("attachLoyalty", fieldToString, (function (diff) {
              return diff.attachExistingLoyaltyPax;
            }), (function (diff, attachExistingLoyaltyPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: diff.replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            })),
      loyaltyPatchMapField("replaceLoyalty", fieldToString, (function (diff) {
              return diff.replaceExistingLoyaltyPax;
            }), (function (diff, replaceExistingLoyaltyPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      autoAdd: diff.autoAdd,
                      dropBerths: diff.dropBerths,
                      detachPax: diff.detachPax,
                      attachExistingConnectedPax: diff.attachExistingConnectedPax,
                      attachInvitedPax: diff.attachInvitedPax,
                      attachNewConnectedPax: diff.attachNewConnectedPax,
                      attachExistingLoyaltyPax: diff.attachExistingLoyaltyPax,
                      replacePaxExistingConnected: diff.replacePaxExistingConnected,
                      replaceInvitedPax: diff.replaceInvitedPax,
                      replaceNewConnectedPax: diff.replaceNewConnectedPax,
                      replaceExistingLoyaltyPax: replaceExistingLoyaltyPax,
                      updateBookingPreferences: diff.updateBookingPreferences,
                      revokePaxInvitation: diff.revokePaxInvitation,
                      changeCabinU: diff.changeCabinU,
                      patchPax: diff.patchPax
                    };
            }))
    ], emptyDiff);

function parse(queryString) {
  var bd = bookingDiffCodec.decode(queryString);
  if (Caml_obj.equal(bd, emptyDiff)) {
    return emptyDiff;
  } else {
    return {
            hasDiff: true,
            addBerths: bd.addBerths,
            autoAdd: bd.autoAdd,
            dropBerths: bd.dropBerths,
            detachPax: bd.detachPax,
            attachExistingConnectedPax: bd.attachExistingConnectedPax,
            attachInvitedPax: bd.attachInvitedPax,
            attachNewConnectedPax: bd.attachNewConnectedPax,
            attachExistingLoyaltyPax: bd.attachExistingLoyaltyPax,
            replacePaxExistingConnected: bd.replacePaxExistingConnected,
            replaceInvitedPax: bd.replaceInvitedPax,
            replaceNewConnectedPax: bd.replaceNewConnectedPax,
            replaceExistingLoyaltyPax: bd.replaceExistingLoyaltyPax,
            updateBookingPreferences: bd.updateBookingPreferences,
            revokePaxInvitation: bd.revokePaxInvitation,
            changeCabinU: bd.changeCabinU,
            patchPax: bd.patchPax
          };
  }
}

function serialize(queryString) {
  return bookingDiffCodec.encode(queryString);
}

var $$String;

var Int;

var $$JSON;

var emptyPrefs = {
  bedsTogetherPref: undefined,
  cabinLongitudePref: undefined,
  cabinElevatorPref: undefined,
  cabinPlacementRequest: undefined,
  earlyDinnerSeatingPref: undefined,
  generalPref: undefined
};

exports.$$String = $$String;
exports.Int = Int;
exports.$$JSON = $$JSON;
exports.emptyDiff = emptyDiff;
exports.emptyPrefs = emptyPrefs;
exports.UnnestedDiff = UnnestedDiff;
exports.Alter = Alter;
exports.fieldToString = fieldToString;
exports.genderCodec = genderCodec;
exports.longitudeCodec = longitudeCodec;
exports.elevProximityCodec = elevProximityCodec;
exports.preferencesCodec = preferencesCodec;
exports.bookingDiffCodec = bookingDiffCodec;
exports.parse = parse;
exports.serialize = serialize;
/* genderCodec Not a pure module */
