// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

function innerToken_decode(s) {
  var v = decodeURIComponent(s);
  if (v === "") {
    return ;
  } else {
    return v;
  }
}

function innerToken_encode(o) {
  return Belt_Option.mapWithDefault(o, "", (function (s) {
                return encodeURIComponent(s);
              }));
}

var innerToken = {
  decode: innerToken_decode,
  encode: innerToken_encode
};

var delimiter = "+";

function paramValue_decode(a) {
  return Belt_Array.map(a.split(delimiter), innerToken_decode);
}

function paramValue_encode(a) {
  return Belt_Array.map(a, (function (v) {
                  return innerToken_encode(v);
                })).join(delimiter);
}

var paramValue = {
  decode: paramValue_decode,
  encode: paramValue_encode
};

function params_decode(v) {
  return Belt_Array.reduce(Belt_Array.keepMap(v.split("&"), (function (parameter) {
                    if (parameter === "") {
                      return ;
                    }
                    var splitted = parameter.split("=");
                    return [
                            Belt_Array.getExn(splitted, 0),
                            Belt_Array.sliceToEnd(splitted, 1).join("=")
                          ];
                  })), undefined, (function (carry, param) {
                var value = param[1];
                return Belt_MapString.update(carry, param[0], (function (existingVals) {
                              return Belt_Array.concat(Belt_Option.getWithDefault(existingVals, []), [paramValue_decode(value)]);
                            }));
              }));
}

function params_encode(v) {
  return Belt_Array.concatMany(Belt_Array.map(Belt_MapString.toArray(v), (function (param) {
                      var key = param[0];
                      return Belt_Array.map(param[1], (function (value) {
                                    if (value.length === 0) {
                                      return key;
                                    } else {
                                      return key + "=" + paramValue_encode(value);
                                    }
                                  }));
                    }))).join("&");
}

var params = {
  decode: params_decode,
  encode: params_encode
};

var Raw = {
  innerToken: innerToken,
  paramValue: paramValue,
  params: params
};

function required(optional) {
  return {
          decode: (function (innerToken) {
              var e = optional.decode(innerToken);
              if (e.TAG !== "Ok") {
                return {
                        TAG: "Error",
                        _0: e._0
                      };
              }
              var v = e._0;
              if (v !== undefined) {
                return {
                        TAG: "Ok",
                        _0: Caml_option.valFromOption(v)
                      };
              } else {
                return {
                        TAG: "Error",
                        _0: "Value is required"
                      };
              }
            }),
          encode: (function (v) {
              return optional.encode(Caml_option.some(v));
            })
        };
}

function string_decode(a) {
  return {
          TAG: "Ok",
          _0: a
        };
}

function string_encode(v) {
  return v;
}

var string = {
  decode: string_decode,
  encode: string_encode
};

function int_decode(a) {
  var match = Belt_Option.map(a, Belt_Int.fromString);
  if (match === undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var $$int = Caml_option.valFromOption(match);
  if ($$int !== undefined) {
    return {
            TAG: "Ok",
            _0: $$int
          };
  } else {
    return {
            TAG: "Error",
            _0: "Value must be an integer"
          };
  }
}

function int_encode(opt) {
  return Belt_Option.map(opt, (function (prim) {
                return String(prim);
              }));
}

var $$int = {
  decode: int_decode,
  encode: int_encode
};

function float_decode(a) {
  var match = Belt_Option.map(a, Belt_Float.fromString);
  if (match === undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var $$float = Caml_option.valFromOption(match);
  if ($$float !== undefined) {
    return {
            TAG: "Ok",
            _0: $$float
          };
  } else {
    return {
            TAG: "Error",
            _0: "Value must be an float"
          };
  }
}

function float_encode(opt) {
  return Belt_Option.map(opt, (function (prim) {
                return String(prim);
              }));
}

var $$float = {
  decode: float_decode,
  encode: float_encode
};

function bool_decode(a) {
  if (a === undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  switch (a) {
    case "false" :
        return {
                TAG: "Ok",
                _0: false
              };
    case "true" :
        return {
                TAG: "Ok",
                _0: true
              };
    default:
      return {
              TAG: "Error",
              _0: "Value must be true or false"
            };
  }
}

function bool_encode(opt) {
  return Belt_Option.map(opt, (function (v) {
                if (v) {
                  return "true";
                } else {
                  return "false";
                }
              }));
}

var bool = {
  decode: bool_decode,
  encode: bool_encode
};

function customScalar(cs) {
  return {
          decode: (function (innerToken) {
              if (innerToken !== undefined) {
                return Belt_Result.map(Belt_Result.flatMap(cs.QueryVar.fromString(innerToken), cs.fromPrimitive), (function (a) {
                              return Caml_option.some(a);
                            }));
              } else {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              }
            }),
          encode: (function (opt) {
              return Belt_Option.map(opt, (function (v) {
                            return cs.QueryVar.toString(cs.toPrimitive(v));
                          }));
            })
        };
}

function customStringable(param) {
  var toString = param.toString;
  var validStrs = Belt_MapString.fromArray(Belt_Array.map(param.legalVals, (function (val) {
              return [
                      toString(val),
                      val
                    ];
            })));
  return {
          decode: (function (innerToken) {
              if (innerToken !== undefined) {
                if (Belt_MapString.has(validStrs, innerToken)) {
                  return {
                          TAG: "Ok",
                          _0: Caml_option.some(Belt_MapString.getExn(validStrs, innerToken))
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: "Not a valid input"
                        };
                }
              } else {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              }
            }),
          encode: (function (o) {
              return Belt_Option.map(o, (function (t) {
                            return toString(t);
                          }));
            })
        };
}

function scalar(tok) {
  return {
          decode: (function (pv) {
              var match = pv.length;
              var match$1 = Belt_Array.get(pv, 0);
              if (match !== 1 || match$1 === undefined) {
                return {
                        TAG: "Error",
                        _0: "Singular parameter value required"
                      };
              } else {
                return tok.decode(Caml_option.valFromOption(match$1));
              }
            }),
          encode: (function (v) {
              return [tok.encode(v)];
            })
        };
}

function array(tok) {
  return {
          decode: (function (pv) {
              return Belt_Array.reduce(Belt_Array.map(pv, (function (v) {
                                return tok.decode(v);
                              })), {
                          TAG: "Ok",
                          _0: []
                        }, (function (carry, next) {
                            return Belt_Result.flatMap(carry, (function (succArr) {
                                          return Belt_Result.map(next, (function (okNext) {
                                                        return Belt_Array.concat(succArr, [okNext]);
                                                      }));
                                        }));
                          }));
            }),
          encode: (function (array) {
              return Belt_Array.map(array, (function (innerT) {
                            return tok.encode(innerT);
                          }));
            })
        };
}

function delimitedArray(delimOpt, inner) {
  var delim = delimOpt !== undefined ? delimOpt : "*";
  return {
          decode: (function (a) {
              if (a !== undefined) {
                if (a === "") {
                  return {
                          TAG: "Ok",
                          _0: []
                        };
                } else {
                  return Belt_Array.reduce(Belt_Array.map(a.split(delim), (function (v) {
                                    var tmp = v === "" ? undefined : v;
                                    return inner.decode(tmp);
                                  })), {
                              TAG: "Ok",
                              _0: []
                            }, (function (carry, next) {
                                return Belt_Result.flatMap(carry, (function (succArr) {
                                              return Belt_Result.map(next, (function (okNext) {
                                                            return Belt_Array.concat(succArr, [okNext]);
                                                          }));
                                            }));
                              }));
                }
              } else {
                return {
                        TAG: "Ok",
                        _0: []
                      };
              }
            }),
          encode: (function (arr) {
              var str = Belt_Array.map(arr, (function (tok) {
                        return Belt_Option.mapWithDefault(inner.encode(tok), "", (function (a) {
                                      return a;
                                    }));
                      })).join(delim);
              if (str === "") {
                return ;
              } else {
                return str;
              }
            })
        };
}

function oreq(tok) {
  return {
          decode: (function (o) {
              return Belt_Option.mapWithDefault(o, {
                          TAG: "Error",
                          _0: "Tuple member is required"
                        }, (function (v) {
                            return tok.decode(v);
                          }));
            }),
          encode: (function (v) {
              return Caml_option.some(tok.encode(v));
            })
        };
}

function countError(pv, n) {
  if (pv.length === n) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Parameter value is not a Tuple of " + String(n) + " members"
          };
  }
}

var TupleImpl = {
  oreq: oreq,
  countError: countError
};

function tuple2(a, b) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var match = countError(pv, 2);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    return {
                            TAG: "Ok",
                            _0: [
                              match$1._0,
                              match$2._0
                            ]
                          };
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1])
                    ];
            })
        };
}

function tuple3(a, b, c) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var match = countError(pv, 3);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: [
                                match$1._0,
                                match$2._0,
                                match$3._0
                              ]
                            };
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2])
                    ];
            })
        };
}

function tuple4(a, b, c, d) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var match = countError(pv, 4);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: [
                                  match$1._0,
                                  match$2._0,
                                  match$3._0,
                                  match$4._0
                                ]
                              };
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3])
                    ];
            })
        };
}

function tuple5(a, b, c, d, e) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var match = countError(pv, 5);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          return {
                                  TAG: "Ok",
                                  _0: [
                                    match$1._0,
                                    match$2._0,
                                    match$3._0,
                                    match$4._0,
                                    match$5._0
                                  ]
                                };
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4])
                    ];
            })
        };
}

function tuple6(a, b, c, d, e, f) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var match = countError(pv, 6);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: [
                                      match$1._0,
                                      match$2._0,
                                      match$3._0,
                                      match$4._0,
                                      match$5._0,
                                      match$6._0
                                    ]
                                  };
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5])
                    ];
            })
        };
}

function tuple7(a, b, c, d, e, f, g) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var match = countError(pv, 7);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              return {
                                      TAG: "Ok",
                                      _0: [
                                        match$1._0,
                                        match$2._0,
                                        match$3._0,
                                        match$4._0,
                                        match$5._0,
                                        match$6._0,
                                        match$7._0
                                      ]
                                    };
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6])
                    ];
            })
        };
}

function tuple8(a, b, c, d, e, f, g, h) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var match = countError(pv, 8);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                return {
                                        TAG: "Ok",
                                        _0: [
                                          match$1._0,
                                          match$2._0,
                                          match$3._0,
                                          match$4._0,
                                          match$5._0,
                                          match$6._0,
                                          match$7._0,
                                          match$8._0
                                        ]
                                      };
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7])
                    ];
            })
        };
}

function tuple9(a, b, c, d, e, f, g, h, i) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var match = countError(pv, 9);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  return {
                                          TAG: "Ok",
                                          _0: [
                                            match$1._0,
                                            match$2._0,
                                            match$3._0,
                                            match$4._0,
                                            match$5._0,
                                            match$6._0,
                                            match$7._0,
                                            match$8._0,
                                            match$9._0
                                          ]
                                        };
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8])
                    ];
            })
        };
}

function tuple10(a, b, c, d, e, f, g, h, i, j) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var match = countError(pv, 10);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: [
                                              match$1._0,
                                              match$2._0,
                                              match$3._0,
                                              match$4._0,
                                              match$5._0,
                                              match$6._0,
                                              match$7._0,
                                              match$8._0,
                                              match$9._0,
                                              match$10._0
                                            ]
                                          };
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9])
                    ];
            })
        };
}

function tuple12(a, b, c, d, e, f, g, h, i, j, k, l) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var k$1 = oreq(k);
              var l$1 = oreq(l);
              var match = countError(pv, 12);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              var match$11 = k$1.decode(Belt_Array.get(pv, 10));
              var match$12 = l$1.decode(Belt_Array.get(pv, 11));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    if (match$11.TAG === "Ok") {
                                      if (match$12.TAG === "Ok") {
                                        return {
                                                TAG: "Ok",
                                                _0: [
                                                  match$1._0,
                                                  match$2._0,
                                                  match$3._0,
                                                  match$4._0,
                                                  match$5._0,
                                                  match$6._0,
                                                  match$7._0,
                                                  match$8._0,
                                                  match$9._0,
                                                  match$10._0,
                                                  match$11._0,
                                                  match$12._0
                                                ]
                                              };
                                      } else {
                                        return match$12;
                                      }
                                    } else {
                                      return match$11;
                                    }
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9]),
                      k.encode(param[10]),
                      l.encode(param[11])
                    ];
            })
        };
}

function tuple13(a, b, c, d, e, f, g, h, i, j, k, l, m) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var k$1 = oreq(k);
              var l$1 = oreq(l);
              var m$1 = oreq(m);
              var match = countError(pv, 13);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              var match$11 = k$1.decode(Belt_Array.get(pv, 10));
              var match$12 = l$1.decode(Belt_Array.get(pv, 11));
              var match$13 = m$1.decode(Belt_Array.get(pv, 12));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    if (match$11.TAG === "Ok") {
                                      if (match$12.TAG === "Ok") {
                                        if (match$13.TAG === "Ok") {
                                          return {
                                                  TAG: "Ok",
                                                  _0: [
                                                    match$1._0,
                                                    match$2._0,
                                                    match$3._0,
                                                    match$4._0,
                                                    match$5._0,
                                                    match$6._0,
                                                    match$7._0,
                                                    match$8._0,
                                                    match$9._0,
                                                    match$10._0,
                                                    match$11._0,
                                                    match$12._0,
                                                    match$13._0
                                                  ]
                                                };
                                        } else {
                                          return match$13;
                                        }
                                      } else {
                                        return match$12;
                                      }
                                    } else {
                                      return match$11;
                                    }
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9]),
                      k.encode(param[10]),
                      l.encode(param[11]),
                      m.encode(param[12])
                    ];
            })
        };
}

function tuple14(a, b, c, d, e, f, g, h, i, j, k, l, m, n) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var k$1 = oreq(k);
              var l$1 = oreq(l);
              var m$1 = oreq(m);
              var n$1 = oreq(n);
              var match = countError(pv, 14);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              var match$11 = k$1.decode(Belt_Array.get(pv, 10));
              var match$12 = l$1.decode(Belt_Array.get(pv, 11));
              var match$13 = m$1.decode(Belt_Array.get(pv, 12));
              var match$14 = n$1.decode(Belt_Array.get(pv, 13));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    if (match$11.TAG === "Ok") {
                                      if (match$12.TAG === "Ok") {
                                        if (match$13.TAG === "Ok") {
                                          if (match$14.TAG === "Ok") {
                                            return {
                                                    TAG: "Ok",
                                                    _0: [
                                                      match$1._0,
                                                      match$2._0,
                                                      match$3._0,
                                                      match$4._0,
                                                      match$5._0,
                                                      match$6._0,
                                                      match$7._0,
                                                      match$8._0,
                                                      match$9._0,
                                                      match$10._0,
                                                      match$11._0,
                                                      match$12._0,
                                                      match$13._0,
                                                      match$14._0
                                                    ]
                                                  };
                                          } else {
                                            return match$14;
                                          }
                                        } else {
                                          return match$13;
                                        }
                                      } else {
                                        return match$12;
                                      }
                                    } else {
                                      return match$11;
                                    }
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9]),
                      k.encode(param[10]),
                      l.encode(param[11]),
                      m.encode(param[12]),
                      n.encode(param[13])
                    ];
            })
        };
}

function tuple15(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var k$1 = oreq(k);
              var l$1 = oreq(l);
              var m$1 = oreq(m);
              var n$1 = oreq(n);
              var o$1 = oreq(o);
              var match = countError(pv, 15);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              var match$11 = k$1.decode(Belt_Array.get(pv, 10));
              var match$12 = l$1.decode(Belt_Array.get(pv, 11));
              var match$13 = m$1.decode(Belt_Array.get(pv, 12));
              var match$14 = n$1.decode(Belt_Array.get(pv, 13));
              var match$15 = o$1.decode(Belt_Array.get(pv, 14));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    if (match$11.TAG === "Ok") {
                                      if (match$12.TAG === "Ok") {
                                        if (match$13.TAG === "Ok") {
                                          if (match$14.TAG === "Ok") {
                                            if (match$15.TAG === "Ok") {
                                              return {
                                                      TAG: "Ok",
                                                      _0: [
                                                        match$1._0,
                                                        match$2._0,
                                                        match$3._0,
                                                        match$4._0,
                                                        match$5._0,
                                                        match$6._0,
                                                        match$7._0,
                                                        match$8._0,
                                                        match$9._0,
                                                        match$10._0,
                                                        match$11._0,
                                                        match$12._0,
                                                        match$13._0,
                                                        match$14._0,
                                                        match$15._0
                                                      ]
                                                    };
                                            } else {
                                              return match$15;
                                            }
                                          } else {
                                            return match$14;
                                          }
                                        } else {
                                          return match$13;
                                        }
                                      } else {
                                        return match$12;
                                      }
                                    } else {
                                      return match$11;
                                    }
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9]),
                      k.encode(param[10]),
                      l.encode(param[11]),
                      m.encode(param[12]),
                      n.encode(param[13]),
                      o.encode(param[14])
                    ];
            })
        };
}

function tuple16(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p) {
  return {
          decode: (function (pv) {
              var a$1 = oreq(a);
              var b$1 = oreq(b);
              var c$1 = oreq(c);
              var d$1 = oreq(d);
              var e$1 = oreq(e);
              var f$1 = oreq(f);
              var g$1 = oreq(g);
              var h$1 = oreq(h);
              var i$1 = oreq(i);
              var j$1 = oreq(j);
              var k$1 = oreq(k);
              var l$1 = oreq(l);
              var m$1 = oreq(m);
              var n$1 = oreq(n);
              var o$1 = oreq(o);
              var p$1 = oreq(p);
              var match = countError(pv, 16);
              var match$1 = a$1.decode(Belt_Array.get(pv, 0));
              var match$2 = b$1.decode(Belt_Array.get(pv, 1));
              var match$3 = c$1.decode(Belt_Array.get(pv, 2));
              var match$4 = d$1.decode(Belt_Array.get(pv, 3));
              var match$5 = e$1.decode(Belt_Array.get(pv, 4));
              var match$6 = f$1.decode(Belt_Array.get(pv, 5));
              var match$7 = g$1.decode(Belt_Array.get(pv, 6));
              var match$8 = h$1.decode(Belt_Array.get(pv, 7));
              var match$9 = i$1.decode(Belt_Array.get(pv, 8));
              var match$10 = j$1.decode(Belt_Array.get(pv, 9));
              var match$11 = k$1.decode(Belt_Array.get(pv, 10));
              var match$12 = l$1.decode(Belt_Array.get(pv, 11));
              var match$13 = m$1.decode(Belt_Array.get(pv, 12));
              var match$14 = n$1.decode(Belt_Array.get(pv, 13));
              var match$15 = o$1.decode(Belt_Array.get(pv, 14));
              var match$16 = p$1.decode(Belt_Array.get(pv, 15));
              if (match.TAG === "Ok") {
                if (match$1.TAG === "Ok") {
                  if (match$2.TAG === "Ok") {
                    if (match$3.TAG === "Ok") {
                      if (match$4.TAG === "Ok") {
                        if (match$5.TAG === "Ok") {
                          if (match$6.TAG === "Ok") {
                            if (match$7.TAG === "Ok") {
                              if (match$8.TAG === "Ok") {
                                if (match$9.TAG === "Ok") {
                                  if (match$10.TAG === "Ok") {
                                    if (match$11.TAG === "Ok") {
                                      if (match$12.TAG === "Ok") {
                                        if (match$13.TAG === "Ok") {
                                          if (match$14.TAG === "Ok") {
                                            if (match$15.TAG === "Ok") {
                                              if (match$16.TAG === "Ok") {
                                                return {
                                                        TAG: "Ok",
                                                        _0: [
                                                          match$1._0,
                                                          match$2._0,
                                                          match$3._0,
                                                          match$4._0,
                                                          match$5._0,
                                                          match$6._0,
                                                          match$7._0,
                                                          match$8._0,
                                                          match$9._0,
                                                          match$10._0,
                                                          match$11._0,
                                                          match$12._0,
                                                          match$13._0,
                                                          match$14._0,
                                                          match$15._0,
                                                          match$16._0
                                                        ]
                                                      };
                                              } else {
                                                return match$16;
                                              }
                                            } else {
                                              return match$15;
                                            }
                                          } else {
                                            return match$14;
                                          }
                                        } else {
                                          return match$13;
                                        }
                                      } else {
                                        return match$12;
                                      }
                                    } else {
                                      return match$11;
                                    }
                                  } else {
                                    return match$10;
                                  }
                                } else {
                                  return match$9;
                                }
                              } else {
                                return match$8;
                              }
                            } else {
                              return match$7;
                            }
                          } else {
                            return match$6;
                          }
                        } else {
                          return match$5;
                        }
                      } else {
                        return match$4;
                      }
                    } else {
                      return match$3;
                    }
                  } else {
                    return match$2;
                  }
                } else {
                  return match$1;
                }
              } else {
                return match;
              }
            }),
          encode: (function (param) {
              return [
                      a.encode(param[0]),
                      b.encode(param[1]),
                      c.encode(param[2]),
                      d.encode(param[3]),
                      e.encode(param[4]),
                      f.encode(param[5]),
                      g.encode(param[6]),
                      h.encode(param[7]),
                      i.encode(param[8]),
                      j.encode(param[9]),
                      k.encode(param[10]),
                      l.encode(param[11]),
                      m.encode(param[12]),
                      n.encode(param[13]),
                      o.encode(param[14]),
                      p.encode(param[15])
                    ];
            })
        };
}

function map(from, onto) {
  return {
          decode: (function (v) {
              return Belt_Result.flatMap(from.decode(v), (function (decoded) {
                            return onto.decode(decoded);
                          }));
            }),
          encode: (function (v) {
              return from.encode(onto.encode(v));
            })
        };
}

var Codec = {
  Raw: Raw,
  required: required,
  string: string,
  $$int: $$int,
  $$float: $$float,
  bool: bool,
  customScalar: customScalar,
  customStringable: customStringable,
  scalar: scalar,
  array: array,
  delimitedArray: delimitedArray,
  TupleImpl: TupleImpl,
  tuple2: tuple2,
  tuple3: tuple3,
  tuple4: tuple4,
  tuple5: tuple5,
  tuple6: tuple6,
  tuple7: tuple7,
  tuple8: tuple8,
  tuple9: tuple9,
  tuple10: tuple10,
  tuple12: tuple12,
  tuple13: tuple13,
  tuple14: tuple14,
  tuple15: tuple15,
  tuple16: tuple16,
  map: map
};

function make(field, fieldToString, codec, set, get, aggregate, disaggregate) {
  return {
          field: field,
          fieldToString: fieldToString,
          ingest: (function (dest, params) {
              var successes = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_MapString.get(params, fieldToString(field)), []), (function (paramValues) {
                      var e = codec.decode(paramValues);
                      if (e.TAG === "Ok") {
                        return Caml_option.some(e._0);
                      }
                      console.log("queryString parseErr:", paramValues, e._0);
                    }));
              var opt = aggregate(successes);
              return set(dest, opt);
            }),
          excrete: (function (dest) {
              var arr = Belt_Array.map(disaggregate(get(dest)), (function (v) {
                      return codec.encode(v);
                    }));
              return Belt_MapString.fromArray([[
                            fieldToString(field),
                            arr
                          ]]);
            }),
          isset: (function (dest, emptyDest) {
              return Caml_obj.notequal(get(emptyDest), get(dest));
            }),
          unvalidatedParam: (function (params) {
              return Belt_Option.flatMap(Belt_MapString.get(params, fieldToString(field)), (function (array) {
                            var s = Belt_Array.map(array, (function (v) {
                                      return fieldToString(field) + "=" + paramValue_encode(v);
                                    })).join("&");
                            if (s.trim() === "") {
                              return ;
                            } else {
                              return s;
                            }
                          }));
            })
        };
}

var Impl = {
  make: make
};

function aggregate(useFirstOccurenceOpt, v) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  return Belt_Array.get(v, useFirstOccurence ? 0 : v.length - 1 | 0);
}

function aggregateRequired(useFirstOccurenceOpt, v) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  return Belt_Option.getExn(Belt_Array.get(v, useFirstOccurence ? 0 : v.length - 1 | 0));
}

function disaggregate(v) {
  return Belt_Option.mapWithDefault(v, [], (function (v) {
                return [v];
              }));
}

function disaggregateRequired(v) {
  return [v];
}

function make$1(field, fieldToString, codec, useFirstOccurenceOpt, set, get) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  return make(field, fieldToString, codec, set, get, (function (extra) {
                return aggregate(useFirstOccurence, extra);
              }), disaggregate);
}

var Singular = {
  aggregate: aggregate,
  aggregateRequired: aggregateRequired,
  disaggregate: disaggregate,
  disaggregateRequired: disaggregateRequired,
  make: make$1
};

function make$2(field, fieldToString, codec, set, get, map) {
  return make(field, fieldToString, codec, set, get, (function (a) {
                return map.decode(a);
              }), (function (a) {
                return map.encode(a);
              }));
}

var Custom = {
  make: make$2
};

function make$3(field, fieldToString, codec, set, get) {
  return make$2(field, fieldToString, codec, set, get, {
              decode: (function (a) {
                  if (a.length === 0) {
                    return ;
                  } else {
                    return a;
                  }
                }),
              encode: (function (a) {
                  return Belt_Option.getWithDefault(a, []);
                })
            });
}

var AsArray = {
  make: make$3
};

function make$4(field, fieldToString, codec, set, get, toKeyValue, fromKeyValue) {
  return make$2(field, fieldToString, codec, set, get, {
              decode: (function (v) {
                  var map = Belt_MapInt.fromArray(Belt_Array.map(v, toKeyValue));
                  if (Belt_MapInt.isEmpty(map)) {
                    return ;
                  } else {
                    return Caml_option.some(map);
                  }
                }),
              encode: (function (map) {
                  return Belt_Option.mapWithDefault(map, [], (function (a) {
                                return Belt_Array.map(Belt_MapInt.toArray(a), fromKeyValue);
                              }));
                })
            });
}

var AsIntMap = {
  make: make$4
};

function make$5(field, fieldToString, codec, set, get, toKeyValue, fromKeyValue) {
  return make$2(field, fieldToString, codec, set, get, {
              decode: (function (v) {
                  var map = Belt_MapString.fromArray(Belt_Array.map(v, toKeyValue));
                  if (Belt_MapString.isEmpty(map)) {
                    return ;
                  } else {
                    return Caml_option.some(map);
                  }
                }),
              encode: (function (map) {
                  return Belt_Option.mapWithDefault(map, [], (function (map) {
                                return Belt_Array.map(Belt_MapString.toArray(map), fromKeyValue);
                              }));
                })
            });
}

var AsStringMap = {
  make: make$5
};

var Multiple = {
  Custom: Custom,
  AsArray: AsArray,
  AsIntMap: AsIntMap,
  AsStringMap: AsStringMap
};

var Param = {
  Impl: Impl,
  Singular: Singular,
  Multiple: Multiple
};

function toRepr(fname, vals) {
  var s = Belt_Array.map(vals, (function (v) {
            return fname + "=" + paramValue_encode(v);
          })).join("&");
  if (s.trim() === "") {
    return ;
  } else {
    return s;
  }
}

function mergeRepr(l, r) {
  if (l !== undefined) {
    if (r !== undefined) {
      return l + "&" + r;
    } else {
      return l;
    }
  } else if (r !== undefined) {
    return r;
  } else {
    return ;
  }
}

var QSUtil = {
  toRepr: toRepr,
  mergeRepr: mergeRepr
};

function makeExtended(parameters, emptyDest) {
  return {
          codec: {
            decode: (function (queryString) {
                var rawParams = params_decode(queryString);
                return Belt_Array.reduce(parameters, emptyDest, (function (carry, queryParam) {
                              return queryParam.ingest(carry, rawParams);
                            }));
              }),
            encode: (function (dest) {
                var encoded = Belt_Array.keepMap(parameters, (function (parameter) {
                        if (parameter.isset(dest, emptyDest)) {
                          return Caml_option.some(parameter.excrete(dest));
                        }
                        
                      }));
                return params_encode(Belt_MapString.fromArray(Belt_Array.concatMany(Belt_Array.map(encoded, Belt_MapString.toArray))));
              })
          },
          getUnvalidatedParams: (function (queryString) {
              var rawParams = params_decode(queryString);
              return Belt_Array.reduce(parameters, undefined, (function (carry, queryParam) {
                            return mergeRepr(carry, queryParam.unvalidatedParam(rawParams));
                          }));
            }),
          getUnsubscribedParams: (function (queryString) {
              var rawParams = params_decode(queryString);
              return Belt_Array.reduce(Belt_Array.map(Belt_MapString.toArray(Belt_Array.reduce(parameters, rawParams, (function (carry, param) {
                                        return Belt_MapString.remove(carry, param.fieldToString(param.field));
                                      }))), (function (param) {
                                return toRepr(param[0], param[1]);
                              })), undefined, (function (carry, next) {
                            return mergeRepr(carry, next);
                          }));
            })
        };
}

function make$6(parameters, emptyDest) {
  return makeExtended(parameters, emptyDest).codec;
}

var Parser = {
  makeExtended: makeExtended,
  make: make$6
};

var logParseErrors = true;

exports.logParseErrors = logParseErrors;
exports.Codec = Codec;
exports.Param = Param;
exports.QSUtil = QSUtil;
exports.Parser = Parser;
/* No side effect */
