// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var DirectionsBoatFilledOutlined = require("@mui/icons-material/DirectionsBoatFilledOutlined").default;

function WelcomeModal(props) {
  var onClose = props.onClose;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                sx: {
                                  p: {
                                    xs: 1,
                                    md: 4
                                  }
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "62px",
                                          height: "62px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(DirectionsBoatFilledOutlined, {
                                                  color: "success",
                                                  sx: {
                                                    width: "100%",
                                                    height: "100%"
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "h3",
                                        component: "h2",
                                        sx: {
                                          textAlign: "center"
                                        },
                                        children: "Your booking is reserved!"
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        component: "p",
                                        sx: {
                                          textAlign: "center"
                                        },
                                        children: "This is your manage booking page, where you can view and make changes to your booking details.\n          Be sure to set your Booking Preferences!"
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                sx: {
                                  pb: 1,
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          onClick: onClose,
                                          size: "lg",
                                          sx: {
                                            borderRadius: "6px"
                                          },
                                          children: "Got it!"
                                        }))
                              }))
                    })
              ]
            });
}

var make = WelcomeModal;

exports.make = make;
/* Dialog-Thin Not a pure module */
