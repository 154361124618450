"use strict";
var set_attributes_1 = require("./lib/set-attributes");
var default_attributes_1 = require("./lib/default-attributes");
var assign_1 = require("./lib/assign");
module.exports = function createFrame(options) {
    if (options === void 0) { options = {}; }
    var iframe = document.createElement("iframe");
    var config = (0, assign_1.assign)({}, default_attributes_1.defaultAttributes, options);
    if (config.style && typeof config.style !== "string") {
        (0, assign_1.assign)(iframe.style, config.style);
        delete config.style;
    }
    (0, set_attributes_1.setAttributes)(iframe, config);
    if (!iframe.getAttribute("id")) {
        iframe.id = iframe.name;
    }
    return iframe;
};
