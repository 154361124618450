// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

var regex = new RegExp("^[a-zA-Z0-9_-]{1,72}$");

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.string, (function (s) {
        return function (value) {
          var match = regex.exec(value);
          if (match == null) {
            return s.fail("Must be slug characters, 1-72 in length", undefined);
          }
          
        };
      }));

var Slug_FromStruct_QueryVar = CS_Make$Util.QueryVar.$$String;

var Slug_FromStruct = {
  toPrimitive: toPrimitive,
  primitiveToEl: primitiveToEl,
  primitiveToJson: primitiveToJson,
  struct: struct,
  QueryVar: Slug_FromStruct_QueryVar
};

var BrandFamilySlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var VoyageSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var OrgSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var CabinCategorySlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var CabinClassSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var CabinSubclassSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var FareRuleSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var FareClassSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var BookingChannelSlug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

var Slug = CS_Make$Util.Stringable.MakeFromStruct(Slug_FromStruct);

exports.Slug_FromStruct = Slug_FromStruct;
exports.BrandFamilySlug = BrandFamilySlug;
exports.VoyageSlug = VoyageSlug;
exports.OrgSlug = OrgSlug;
exports.CabinCategorySlug = CabinCategorySlug;
exports.CabinClassSlug = CabinClassSlug;
exports.CabinSubclassSlug = CabinSubclassSlug;
exports.FareRuleSlug = FareRuleSlug;
exports.FareClassSlug = FareClassSlug;
exports.BookingChannelSlug = BookingChannelSlug;
exports.Slug = Slug;
/* regex Not a pure module */
