// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function AppChip(props) {
  var __tooltip = props.tooltip;
  var __noPad = props.noPad;
  var __color = props.color;
  var __endDecorator = props.endDecorator;
  var __startDecorator = props.startDecorator;
  var startDecorator = __startDecorator !== undefined ? Caml_option.valFromOption(__startDecorator) : null;
  var endDecorator = __endDecorator !== undefined ? Caml_option.valFromOption(__endDecorator) : null;
  var color = __color !== undefined ? __color : "Neutral";
  var noPad = __noPad !== undefined ? __noPad : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        endDecorator: Caml_option.some(JsxRuntime.jsx(Stack, {
                                  sx: {
                                    color: Common$Thin.NotificationColor.getColorNoSuccess(color)
                                  },
                                  children: Caml_option.some(tooltip)
                                })),
                        level: "body-xs",
                        sx: {
                          color: Common$Thin.NotificationColor.getColorWithSuccess(color)
                        },
                        children: Caml_option.some(props.children)
                      })),
              endDecorator: Caml_option.some(JsxRuntime.jsx(Stack, {
                        sx: {
                          color: Common$Thin.NotificationColor.getColorNoSuccess(color)
                        },
                        children: Caml_option.some(endDecorator)
                      })),
              size: "sm",
              startDecorator: Caml_option.some(JsxRuntime.jsx(Stack, {
                        sx: {
                          color: Common$Thin.NotificationColor.getColorNoSuccess(color)
                        },
                        children: Caml_option.some(startDecorator)
                      })),
              sx: {
                backgroundColor: Common$Thin.NotificationColor.getBackgroundColor(color),
                borderRadius: "4px",
                p: noPad ? "2px" : "6px",
                my: "4px"
              }
            });
}

var make = AppChip;

exports.make = make;
/* Common-Thin Not a pure module */
