// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var PaymentFrame$Thin = require("./PaymentFrame.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomPaymentSelector$Thin = require("./CustomPaymentSelector.bs.js");

function PaymentAmountSelection(props) {
  var paymentSuggestions = props.paymentSuggestions;
  var selectPaymentOption = props.selectPaymentOption;
  var currentPaymentOption = props.currentPaymentOption;
  var match = React.useState(function () {
        if (currentPaymentOption === undefined) {
          return ;
        }
        switch (currentPaymentOption.TAG) {
          case "PIF" :
              return "Full";
          case "Minimum" :
              return "Min";
          case "Current" :
              return "Curr";
          case "Custom" :
              return "Cust";
          
        }
      });
  var setWhichSelected = match[1];
  var whichSelected = match[0];
  var discountDisplayPct = paymentSuggestions.fullRecDetails.discountDisplayPct;
  var match$1 = paymentSuggestions.payMin;
  var tmp;
  if (match$1 !== undefined) {
    var minNow = match$1.minNow;
    tmp = JsxRuntime.jsx(SelectorCard$Thin.make, {
          isSelected: Caml_obj.equal(whichSelected, "Min"),
          onClick: (function () {
              setWhichSelected(function (param) {
                    return "Min";
                  });
              selectPaymentOption({
                    TAG: "Minimum",
                    _0: {
                      amt: minNow
                    }
                  });
            }),
          title: "Pay the minimum required amount (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minNow)) + ")",
          description: "Total due within two weeks: " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match$1.minSoon)),
          children: null
        });
  } else {
    tmp = null;
  }
  var match$2 = paymentSuggestions.payCurrent;
  var tmp$1;
  if (match$2 !== undefined) {
    var currNow = match$2.currNow;
    tmp$1 = JsxRuntime.jsx(SelectorCard$Thin.make, {
          isSelected: Caml_obj.equal(whichSelected, "Curr"),
          onClick: (function () {
              setWhichSelected(function (param) {
                    return "Curr";
                  });
              selectPaymentOption({
                    TAG: "Current",
                    _0: {
                      amt: currNow
                    }
                  });
            }),
          title: "Make the current deposit (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(currNow)) + ")",
          description: "Next payment due " + DateOnly$Util.format(DateOnly$Util.fromDate(CS_Make$Util.Dateable.Plain.toDate(match$2.nextDue)), SysCtx$Thin.vars.dateFormat),
          children: null
        });
  } else {
    tmp$1 = null;
  }
  var match$3 = paymentSuggestions.payCustom;
  return JsxRuntime.jsx(PaymentFrame$Thin.make, {
              title: "Choose amount to pay",
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsx(SelectorCard$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "Full"),
                            onClick: (function () {
                                setWhichSelected(function (param) {
                                      return "Full";
                                    });
                                selectPaymentOption({
                                      TAG: "PIF",
                                      _0: {
                                        amt: paymentSuggestions.payFull
                                      }
                                    });
                              }),
                            title: "Pay in full",
                            titleAppendix: Caml_option.some(discountDisplayPct !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        level: "body-sm",
                                        children: Caml_option.some(discountDisplayPct + " off")
                                      }) : null),
                            description: "Pay the full balance of " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(paymentSuggestions.payFull)),
                            children: null
                          }),
                      tmp,
                      tmp$1,
                      match$3 !== undefined ? JsxRuntime.jsx(CustomPaymentSelector$Thin.make, {
                              currentPaymentOption: currentPaymentOption,
                              minimum: match$3.minimum,
                              maximum: paymentSuggestions.payFull,
                              isSelected: Caml_obj.equal(whichSelected, "Cust"),
                              onClick: (function () {
                                  setWhichSelected(function (param) {
                                        return "Cust";
                                      });
                                }),
                              title: "Pay a different amount",
                              description: "Pay a custom amount",
                              selectPaymentOption: selectPaymentOption
                            }) : null
                    ]
                  })
            });
}

var make = PaymentAmountSelection;

exports.make = make;
/* react Not a pure module */
