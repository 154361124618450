// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../common/molecules/PasswordInput.bs.js");
var MyAccountState$Thin = require("../../utils/accounts/MyAccountState.bs.js");
var PassengerForms$Thin = require("../common/molecules/PassengerForms.bs.js");
var ChangePasswordSubmitNew$Thin = require("./mutations/ChangePasswordSubmitNew.bs.js");

function ChangePasswordForm(props) {
  var onClose = props.onClose;
  var match = ChangePasswordSubmitNew$Thin.useMutation();
  var changePassword = match.changePassword;
  var setError = match.setError;
  var error = match.error;
  var pw = MyAccountState$Thin.pwView(props.accountState);
  var passwordsMatch = HopperState$Util.Observable.useComputed(pw, (function (pw) {
          return Caml_obj.equal(MyAccountState$Thin.newPassword.validate(pw, undefined), MyAccountState$Thin.confirmPassword.validate(pw, undefined));
        }), undefined, undefined);
  var passwordError = HopperState$Util.Observable.useComputed(pw, (function (pw) {
          return Belt_Result.isError(MyAccountState$Thin.newPassword.validate(pw, undefined));
        }), undefined, undefined);
  var onSubmit = function (e) {
    e.preventDefault();
    var input = MyAccountState$Thin.validateChangePassword(HopperState$Util.Observable.dangerouslyRead(pw));
    if (input.TAG === "Ok") {
      return changePassword(input._0);
    }
    var err = input._0;
    setError(function (param) {
          return err;
        });
  };
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.Account.passwordModalHeading.value,
              description: Caml_option.some(Txt$Thin.Account.passwordModalDescription.value),
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                  HopperState$Util.Observable.notify(pw, (function (param) {
                          return MyAccountState$Thin.blankPw;
                        }));
                }),
              titleId: "password-dialog-title",
              titleDescId: "password-dialog-description",
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(match.success ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                  message: Merge$Util.render(Txt$Thin.Account.passwordModalSuccess, SysCtx$Thin.vars),
                                  type_: "success"
                                }) : JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 2,
                                  sx: {
                                    width: "100%"
                                  },
                                  onSubmit: onSubmit,
                                  disabled: passwordError || !passwordsMatch,
                                  children: [
                                    JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                          view: {
                                            TAG: "Direct",
                                            _0: pw
                                          },
                                          input: MyAccountState$Thin.currentPassword,
                                          children: (function (param) {
                                              var error = param.error;
                                              var set = param.set;
                                              return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                          label: "Current Password",
                                                          errorText: param.errorText,
                                                          error: error,
                                                          children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                placeholder: Txt$Thin.Account.passwordModalCurrentPasswordPlaceholder.value,
                                                                error: error,
                                                                value: param.value,
                                                                onChange: (function (e) {
                                                                    set(function (param) {
                                                                          return e.target.value;
                                                                        });
                                                                  }),
                                                                autoComplete: "current-password"
                                                              }),
                                                          wasTouched: param.wasTouched
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                          view: {
                                            TAG: "Direct",
                                            _0: pw
                                          },
                                          input: MyAccountState$Thin.newPassword,
                                          children: (function (param) {
                                              var error = param.error;
                                              var set = param.set;
                                              return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                          label: "New Password",
                                                          helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                          errorText: param.errorText,
                                                          error: error,
                                                          children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                placeholder: Txt$Thin.Account.passwordModalNewPasswordPlaceholder.value,
                                                                error: error,
                                                                value: param.value,
                                                                onChange: (function (e) {
                                                                    set(function (param) {
                                                                          return e.target.value;
                                                                        });
                                                                  }),
                                                                autoComplete: "new-password"
                                                              }),
                                                          wasTouched: param.wasTouched
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                          view: {
                                            TAG: "Direct",
                                            _0: pw
                                          },
                                          input: MyAccountState$Thin.confirmPassword,
                                          children: (function (param) {
                                              var error = param.error;
                                              var set = param.set;
                                              return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                          label: "Confirm Password",
                                                          errorText: param.errorText,
                                                          error: error,
                                                          children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                placeholder: Txt$Thin.Account.passwordModalConfirmPasswordPlaceholder.value,
                                                                error: error,
                                                                value: param.value,
                                                                onChange: (function (e) {
                                                                    set(function (param) {
                                                                          return e.target.value;
                                                                        });
                                                                  }),
                                                                autoComplete: "confirm-password"
                                                              }),
                                                          wasTouched: param.wasTouched
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(AppButton$Thin.make, {
                                          fullWidth: true,
                                          loading: match.isMutating,
                                          type_: "submit",
                                          disabled: passwordError || !passwordsMatch,
                                          children: Caml_option.some(Txt$Thin.Account.passwordModalChangeButton.value)
                                        })
                                  ]
                                }))
                    }),
                error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ChangePasswordForm;

exports.make = make;
/* Txt-Thin Not a pure module */
