// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../../../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var BDPaymentAmountSelection$Thin = require("./BDPaymentAmountSelection.bs.js");
var BDPaymentMethodSelection$Thin = require("./BDPaymentMethodSelection.bs.js");
var ArrowForward = require("@mui/icons-material/ArrowForward").default;

function MakePaymentDrawer(props) {
  var setProcessPaymentModalOpen = props.setProcessPaymentModalOpen;
  var paymentAction = props.paymentAction;
  var amount = props.amount;
  var clearAll = props.clearAll;
  var whichSelected = props.whichSelected;
  var setPane = props.setPane;
  var pane = props.pane;
  var title;
  title = pane === "Amount" ? "Payment amount" : "Payment method";
  var titleStartDecorator;
  titleStartDecorator = pane === "Amount" ? JsxRuntime.jsx(Box, {
          sx: {
            height: "1.75rem"
          }
        }) : JsxRuntime.jsx(IconButton, {
          onClick: (function (param) {
              setPane(function (param) {
                    return "Amount";
                  });
            }),
          children: Caml_option.some(JsxRuntime.jsx(ArrowForward, {
                    fontSize: "md",
                    sx: {
                      transform: "rotate(180deg)"
                    }
                  })),
          slotProps: {
            root: {
              sx: {
                minWidth: 0,
                minHeight: 0,
                width: "1.75rem",
                height: "1.75rem"
              }
            }
          }
        });
  var transformStyle;
  transformStyle = pane === "Amount" ? "translateX(0%)" : "translateX(-50%)";
  var tmp;
  tmp = pane === "Amount" ? JsxRuntime.jsx(BDPaymentAmountSelection$Thin.make, {
          paymentSuggestions: props.paymentSuggestions,
          whichSelected: whichSelected,
          setWhichSelected: props.setWhichSelected,
          amount: amount,
          setAmount: props.setAmount
        }) : null;
  var tmp$1;
  tmp$1 = pane === "Amount" ? null : JsxRuntime.jsx(BDPaymentMethodSelection$Thin.make, {
          setPaymentAction: props.setPaymentAction,
          paymentReturn: props.paymentReturn,
          amount: amount,
          calculatedFee: props.calculatedFee,
          setCalculatedFee: props.setCalculatedFee,
          voyageSlug: props.voyageSlug,
          bookingId: props.bookingId,
          clearer: props.clearer
        });
  var tmp$2;
  tmp$2 = pane === "Amount" ? clearAll : (function () {
        setPane(function (param) {
              return "Amount";
            });
      });
  var tmp$3;
  tmp$3 = pane === "Amount" ? "Cancel" : "Previous";
  var tmp$4;
  tmp$4 = pane === "Amount" ? (function (param) {
        setPane(function (param) {
              return "Method";
            });
      }) : (function (param) {
        if (paymentAction !== undefined) {
          paymentAction();
          return setProcessPaymentModalOpen(function (param) {
                      return true;
                    });
        }
        
      });
  var tmp$5;
  tmp$5 = pane === "Amount" ? Belt_Option.isNone(whichSelected) || Belt_Option.isNone(amount) : Belt_Option.isNone(paymentAction);
  var tmp$6;
  tmp$6 = pane === "Amount" ? "Next" : "Make Payment";
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(AppDrawer$Thin.make, {
                        open_: props.open_,
                        title: title,
                        titleStartDecorator: Caml_option.some(titleStartDecorator),
                        onClose: clearAll,
                        children: [
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "row",
                                          sx: {
                                            width: "200%",
                                            transition: "transform " + (
                                              pane !== "Amount" ? "0.3s" : "0.2s"
                                            ) + " ease-in-out",
                                            transform: transformStyle
                                          },
                                          children: [
                                            JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: "100%"
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                            direction: "column",
                                                            spacing: 2,
                                                            sx: {
                                                              px: 2,
                                                              py: 3
                                                            },
                                                            children: Caml_option.some(tmp)
                                                          }))
                                                }),
                                            JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: "100%"
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                            direction: "column",
                                                            spacing: 2,
                                                            sx: {
                                                              px: 2,
                                                              py: 3
                                                            },
                                                            children: Caml_option.some(tmp$1)
                                                          }))
                                                })
                                          ]
                                        })),
                                sx: {
                                  overflowX: "hidden"
                                }
                              }),
                          JsxRuntime.jsx(DialogActions, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: {
                                            xs: "column-reverse",
                                            sm: "row"
                                          },
                                          justifyContent: "space-between",
                                          spacing: 1,
                                          sx: {
                                            width: "100%",
                                            p: 2
                                          },
                                          children: [
                                            JsxRuntime.jsx(Button, {
                                                  onClick: tmp$2,
                                                  variant: "outlined",
                                                  color: "neutral",
                                                  children: Caml_option.some(tmp$3)
                                                }),
                                            JsxRuntime.jsx(Button, {
                                                  onClick: tmp$4,
                                                  disabled: tmp$5,
                                                  children: Caml_option.some(tmp$6)
                                                })
                                          ]
                                        }))
                              })
                        ]
                      }))
            });
}

var make = MakePaymentDrawer;

exports.make = make;
/* @mui/joy/Box Not a pure module */
