// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Storage$Util = require("./Storage.bs.js");
var RemoteData$Util = require("./RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function catchStatusError(resp) {
  if (resp.status === 401) {
    return "Wrong username or password. Try again.";
  } else {
    return "Request failed: " + String(resp.status) + " " + resp.statusText;
  }
}

async function $$fetch$1(url, method, headers, body) {
  try {
    var resp = await fetch(url, {
          method: method,
          body: Belt_Option.map(body, (function (body) {
                  return JSON.stringify(body);
                })),
          headers: Caml_option.some(new Headers(Belt_Array.keep(headers, (function (param) {
                          return param[1].trim() !== "";
                        }))))
        });
    if (resp.ok) {
      return {
              TAG: "Success",
              _0: resp
            };
    } else {
      return {
              TAG: "Failure",
              _0: catchStatusError(resp)
            };
    }
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      var m = obj._1.message;
      if (m !== undefined) {
        return {
                TAG: "Failure",
                _0: "Exception: " + m
              };
      } else {
        return {
                TAG: "Failure",
                _0: "Exception: unknown"
              };
      }
    }
    throw obj;
  }
}

var Impl = {
  catchStatusError: catchStatusError,
  $$fetch: $$fetch$1
};

function jsonRequest(url, method, body, headers, schema) {
  return RemoteData$Util.$$Promise.then($$fetch$1(url, method, Belt_Array.concat([
                      [
                        "content-type",
                        "application/json"
                      ],
                      [
                        "accept",
                        "application/json"
                      ]
                    ], headers), body !== undefined ? Belt_Result.getExn(S$RescriptSchema.serializeWith(body[0], body[1])) : undefined), (async function (resp) {
                var json = await resp.json();
                var r = S$RescriptSchema.parseWith(json, schema);
                if (r.TAG === "Ok") {
                  return {
                          TAG: "Success",
                          _0: r._0
                        };
                } else {
                  return {
                          TAG: "Failure",
                          _0: S$RescriptSchema.$$Error.message(r._0)
                        };
                }
              }));
}

function statefulJsonRequest(state, url, method, body, headers, schema) {
  return function () {
    RemoteData$Util.$$Promise.makeStatefulEffect(jsonRequest(url, method, body, headers, schema), state);
  };
}

var passwordCredentialsSchema = S$RescriptSchema.object(function (s) {
      return {
              allegedEmail: s.f("alleged_email", S$RescriptSchema.string),
              allegedPass: s.f("alleged_pass", S$RescriptSchema.string)
            };
    });

function either(url, credentials, onSuccess, onError, clearSession) {
  return RemoteData$Util.$$Promise.mapBoth(RemoteData$Util.$$Promise.thenResolve($$fetch$1(url, "POST", [[
                        "content-type",
                        "application/json"
                      ]], Belt_Option.map(credentials, (function (credentials) {
                            return Belt_Result.getExn(S$RescriptSchema.serializeWith(credentials, passwordCredentialsSchema));
                          }))), (function (resp) {
                    if (resp.status === 204) {
                      return {
                              TAG: "Success",
                              _0: undefined
                            };
                    } else {
                      return {
                              TAG: "Failure",
                              _0: "204 not returned"
                            };
                    }
                  })), (function () {
                if (onSuccess !== undefined) {
                  onSuccess();
                  if (clearSession) {
                    Storage$Util.Session.clear();
                  }
                  return ;
                }
                
              }), (function (error) {
                if (onError !== undefined) {
                  onError(error);
                  return error;
                } else {
                  return error;
                }
              }));
}

function withPassword(url, credentials, onSuccessOpt, onErrorOpt, clearSessionOpt) {
  var onSuccess = onSuccessOpt !== undefined ? Caml_option.valFromOption(onSuccessOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var clearSession = clearSessionOpt !== undefined ? clearSessionOpt : false;
  return either(url, credentials, onSuccess, onError, clearSession);
}

function logout(url, onSuccessOpt, onErrorOpt, clearSessionOpt) {
  var onSuccess = onSuccessOpt !== undefined ? Caml_option.valFromOption(onSuccessOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var clearSession = clearSessionOpt !== undefined ? clearSessionOpt : false;
  return either(url, undefined, onSuccess, onError, clearSession);
}

var Login = {
  passwordCredentialsSchema: passwordCredentialsSchema,
  withPassword: withPassword,
  logout: logout
};

exports.Impl = Impl;
exports.jsonRequest = jsonRequest;
exports.statefulJsonRequest = statefulJsonRequest;
exports.Login = Login;
/* passwordCredentialsSchema Not a pure module */
