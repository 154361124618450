// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin = require("../../../__generated__/ChangePasswordSubmitNew_ChangePasswordMutation_graphql.bs.js");

var convertVariables = ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangePasswordSubmitNew_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ChangePasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSuccess = match$2[1];
  var changePassword = function (input) {
    setError(function (param) {
          
        });
    mutate({
          input: input
        }, undefined, undefined, (function (store, resp) {
            var match = resp.changePassword;
            if (match !== undefined && match.boolean) {
              store.invalidateStore();
              setTimeout((function () {
                      RescriptReactRouter.push(SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink);
                    }), 3000);
              return ;
            }
            
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.changePassword;
              if (match !== undefined && match.boolean) {
                Common$Thin.cookieLogout(Caml_option.some((function () {
                            setSuccess(function (param) {
                                  return true;
                                });
                            setTimeout((function () {
                                    RescriptReactRouter.push(SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink);
                                  }), 3000);
                          })), undefined);
                return ;
              }
              setError(function (param) {
                    return Txt$Thin.Account.passwordModalError.value;
                  });
              return ;
            }
            setError(function (param) {
                  return Txt$Thin.Account.passwordModalError.value;
                });
          }), (function (param) {
            setError(function (param) {
                  return Txt$Thin.Account.heading.value;
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          success: match$2[0],
          changePassword: changePassword
        };
}

exports.ChangePasswordMutation = ChangePasswordMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
