// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var Obj = {};

var $$Option = {};

var Type = {};

var $$Promise = {};

var Re = {};

var $$Object = {};

var $$WeakMap = {};

function has(array, idx) {
  return array[idx];
}

function isArray(prim) {
  return Array.isArray(prim);
}

var $$Array = {
  has: has,
  isArray: isArray
};

function raiseAny(any) {
  throw any;
}

var Exn = {
  raiseAny: raiseAny,
  raiseError: raiseAny
};

function plus(int1, int2) {
  return int1 + int2;
}

var Int = {
  plus: plus
};

function has$1(dict, key) {
  return dict[key];
}

function deleteInPlace(dict, key) {
  Js_dict.unsafeDeleteKey(dict, key);
}

var Dict = {
  has: has$1,
  deleteInPlace: deleteInPlace
};

var Float = {};

function toString(bigint) {
  return bigint.toString() + "n";
}

var $$BigInt = {
  toString: toString
};

function make2(ctxVarName1, ctxVarValue1, ctxVarName2, ctxVarValue2, inlinedFunction) {
  return new Function(ctxVarName1, ctxVarName2, "return " + inlinedFunction)(ctxVarValue1, ctxVarValue2);
}

var $$Function$1 = {
  make2: make2
};

var $$Symbol = {};

function stringify(any) {
  if (any === (void 0)) {
    return "undefined";
  } else {
    return JSON.stringify(any);
  }
}

function fromString(string) {
  return JSON.stringify(string);
}

var Value = {
  stringify: stringify,
  fromString: fromString
};

function toRescript($$float) {
  return $$float.toString() + (
          $$float % 1 === 0 ? "." : ""
        );
}

var Float$1 = {
  toRescript: toRescript
};

var Inlined = {
  Value: Value,
  Float: Float$1
};

var Stdlib = {
  $$Option: $$Option,
  Type: Type,
  $$Promise: $$Promise,
  Re: Re,
  $$Object: $$Object,
  $$WeakMap: $$WeakMap,
  $$Array: $$Array,
  Exn: Exn,
  Int: Int,
  Dict: Dict,
  Float: Float,
  $$BigInt: $$BigInt,
  $$Function: $$Function$1,
  $$Symbol: $$Symbol,
  Inlined: Inlined
};

function toArray(path) {
  if (path === "") {
    return [];
  } else {
    return JSON.parse(path.split("\"][\"").join("\",\""));
  }
}

function fromInlinedLocation(inlinedLocation) {
  return "[" + inlinedLocation + "]";
}

function fromLocation($$location) {
  return "[" + JSON.stringify($$location) + "]";
}

function fromArray(array) {
  var len = array.length;
  if (len !== 1) {
    if (len !== 0) {
      return "[" + array.map(fromString).join("][") + "]";
    } else {
      return "";
    }
  }
  var $$location = array[0];
  return "[" + JSON.stringify($$location) + "]";
}

function concat(path, concatedPath) {
  return path + concatedPath;
}

var Path = {
  toArray: toArray,
  fromInlinedLocation: fromInlinedLocation,
  fromLocation: fromLocation,
  fromArray: fromArray,
  concat: concat
};

var symbol = Symbol("rescript-schema");

var itemSymbol = Symbol("item");

var Raised = /* @__PURE__ */Caml_exceptions.create("S_Core-RescriptSchema.Raised");

function unsafeGetVariantPayload(variant) {
  return variant._0;
}

function unsafeGetVarianTag(variant) {
  return variant.TAG;
}

function isSchemaObject(object) {
  return object.serializeToJsonOrThrow;
}

function isSchema(any) {
  if (any) {
    return any.serializeToJsonOrThrow;
  } else {
    return false;
  }
}

function isLiteralSchema(schema) {
  return schema.t.TAG === "Literal";
}

function isPrimitiveSchema(schema) {
  return typeof schema.t === "string";
}

var globalConfig = {
  r: 0,
  u: "Strip",
  n: false
};

function toJsResult(result) {
  if (result.TAG === "Ok") {
    return {
            success: true,
            value: result._0
          };
  } else {
    return {
            success: false,
            error: result._0
          };
  }
}

class RescriptSchemaError extends Error {
      constructor(code, operation, path) {
        super();
        this.operation = operation;
        this.code = code;
        this.path = path;
        this.s = symbol;
        this.RE_EXN_ID = Raised;
        this._1 = this;
        this.Error = this;
        this.name = "RescriptSchemaError";
      }
      get message() {
        return message(this);
      }
      get reason() {
        return reason(this);
      }
    }
;

function getOrRethrow(exn) {
  if ((exn&&exn.s===symbol)) {
    return exn;
  }
  throw exn;
}

function panic(message) {
  throw new Error("[rescript-schema] " + message);
}

var InternalError = {
  getOrRethrow: getOrRethrow,
  panic: panic
};

function classify(schema) {
  return schema.t;
}

var Flag = {};

function make() {
  return 0;
}

function addFlag(operation, flag) {
  return operation | flag;
}

function unsafeHasFlag(operation, flag) {
  return operation & flag;
}

function toPublic(operation) {
  if (operation & 4) {
    return "Assert";
  } else if (operation & 1) {
    if (operation & 2) {
      return "ParseAsync";
    } else {
      return "Parse";
    }
  } else if (operation & 8) {
    return "SerializeToJson";
  } else {
    return "SerializeToUnknown";
  }
}

var Operation = {
  Flag: Flag,
  make: make,
  addFlag: addFlag,
  unsafeHasFlag: unsafeHasFlag,
  toPublic: toPublic
};

function make$1(prim) {
  return prim;
}

function embed(b, value) {
  return "e[" + (b.g.e.push(value) - 1) + "]";
}

function scope(b) {
  return {
          c: "",
          l: "",
          a: false,
          g: b.g
        };
}

function allocateScope(b) {
  var varsAllocation = b.l;
  b.a = true;
  if (varsAllocation === "") {
    return b.c;
  } else {
    return "let " + varsAllocation + ";" + b.c;
  }
}

function varWithoutAllocation(b) {
  var newCounter = b.g.v + 1;
  b.g.v = newCounter;
  return "v" + newCounter;
}

function allocateVal(b) {
  return {
          s: b,
          a: false
        };
}

function val(b, initial) {
  return {
          i: initial,
          s: b,
          a: false
        };
}

function asyncVal(b, initial) {
  return {
          i: initial,
          s: b,
          a: true
        };
}

function inline(_b, val) {
  var $$var = val.v;
  if ($$var !== undefined) {
    return $$var;
  } else {
    return val.i;
  }
}

function $$var(b, val) {
  var _var = val.v;
  if (_var !== undefined) {
    return _var;
  }
  var $$var$1 = varWithoutAllocation(b);
  var i = val.i;
  var allocation = i !== undefined ? $$var$1 + "=" + i : $$var$1;
  var varsAllocation = val.s.l;
  val.s.l = varsAllocation === "" ? allocation : varsAllocation + "," + allocation;
  val.v = $$var$1;
  return $$var$1;
}

function push(b, input, val) {
  return $$var(b, input) + ".push(" + inline(b, val) + ")";
}

function addKey(b, input, key, val) {
  return $$var(b, input) + "[" + key + "]=" + inline(b, val);
}

function set(b, input, val) {
  if (input === val) {
    return "";
  }
  var match = input.a;
  if (match) {
    var match$1 = val.a;
    if (!match$1) {
      return $$var(b, input) + "=Promise.resolve(" + inline(b, val) + ")";
    }
    
  } else {
    var match$2 = val.a;
    if (match$2) {
      input.a = true;
      return $$var(b, input) + "=" + inline(b, val);
    }
    
  }
  return $$var(b, input) + "=" + inline(b, val);
}

function setInlined(b, input, inlined) {
  return $$var(b, input) + "=" + inlined;
}

function map(b, inlinedFn, input) {
  return val(b, inlinedFn + "(" + inline(b, input) + ")");
}

var Val = {
  inline: inline,
  $$var: $$var,
  push: push,
  addKey: addKey,
  set: set,
  setInlined: setInlined,
  map: map
};

function isInternalError(_b, $$var) {
  return $$var + "&&" + $$var + ".s===s";
}

function transform(b, input, operation) {
  if (!input.a) {
    return operation(b, input);
  }
  var bb = scope(b);
  var operationInput = {
    v: varWithoutAllocation(bb),
    s: bb,
    a: false
  };
  var operationOutputVal = operation(bb, operationInput);
  var operationCode = allocateScope(bb);
  return asyncVal(b, inline(b, input) + ".then(" + $$var(b, operationInput) + "=>{" + operationCode + "return " + inline(b, operationOutputVal) + "})");
}

function raise(b, code, path) {
  var operation = b.g.o;
  throw new RescriptSchemaError(code, operation & 4 ? "Assert" : (
              operation & 1 ? (
                  operation & 2 ? "ParseAsync" : "Parse"
                ) : (
                  operation & 8 ? "SerializeToJson" : "SerializeToUnknown"
                )
            ), path);
}

function embedSyncOperation(b, input, fn) {
  if (input.a) {
    return asyncVal(b, inline(b, input) + ".then(" + ("e[" + (b.g.e.push(fn) - 1) + "]") + ")");
  } else {
    return map(b, "e[" + (b.g.e.push(fn) - 1) + "]", input);
  }
}

function embedAsyncOperation(b, input, fn) {
  if (!(b.g.o & 2)) {
    raise(b, "UnexpectedAsync", "");
  }
  var val = embedSyncOperation(b, input, (function (v) {
          return fn(v)();
        }));
  val.a = true;
  return val;
}

function failWithArg(b, path, fn, arg) {
  return "e[" + (b.g.e.push(function (arg) {
                return raise(b, fn(arg), path);
              }) - 1) + "](" + arg + ")";
}

function fail(b, message, path) {
  return "e[" + (b.g.e.push(function () {
                return raise(b, {
                            TAG: "OperationFailed",
                            _0: message
                          }, path);
              }) - 1) + "]()";
}

function effectCtx(b, selfSchema, path) {
  return {
          schema: selfSchema,
          fail: (function (message, customPathOpt) {
              var customPath = customPathOpt !== undefined ? customPathOpt : "";
              return raise(b, {
                          TAG: "OperationFailed",
                          _0: message
                        }, path + customPath);
            })
        };
}

function registerInvalidJson(b, selfSchema, path) {
  if (b.g.o & 8) {
    return raise(b, {
                TAG: "InvalidJsonSchema",
                _0: selfSchema
              }, path);
  }
  
}

function invalidOperation(b, path, description) {
  return raise(b, {
              TAG: "InvalidOperation",
              description: description
            }, path);
}

function withCatch(b, input, $$catch, fn) {
  var prevCode = b.c;
  b.c = "";
  var errorVar = varWithoutAllocation(b);
  var maybeResolveVal = $$catch(b, errorVar);
  var catchCode = "if(" + (errorVar + "&&" + errorVar + ".s===s") + "){" + b.c;
  b.c = "";
  var bb = scope(b);
  var fnOutput = fn(bb);
  b.c = b.c + allocateScope(bb);
  if (fnOutput === input && b.c === "") {
    return fnOutput;
  }
  var isAsync = fnOutput.a;
  var output = input === fnOutput ? input : ({
        s: b,
        a: isAsync
      });
  var catchCode$1 = maybeResolveVal !== undefined ? (function (catchLocation) {
        return catchCode + (
                catchLocation === 1 ? "return " + inline(b, maybeResolveVal) : set(b, output, maybeResolveVal)
              ) + ("}else{throw " + errorVar + "}");
      }) : (function (param) {
        return catchCode + "}throw " + errorVar;
      });
  b.c = prevCode + ("try{" + b.c + (
      isAsync ? setInlined(b, output, inline(b, fnOutput) + ".catch(" + errorVar + "=>{" + catchCode$1(1) + "})") : set(b, output, fnOutput)
    ) + "}catch(" + errorVar + "){" + catchCode$1(0) + "}");
  return output;
}

function withPathPrepend(b, input, path, maybeDynamicLocationVar, fn) {
  if (path === "" && maybeDynamicLocationVar === undefined) {
    return fn(b, input, path);
  }
  try {
    return withCatch(b, input, (function (b, errorVar) {
                  b.c = errorVar + ".path=" + JSON.stringify(path) + "+" + (
                    maybeDynamicLocationVar !== undefined ? "'[\"'+" + maybeDynamicLocationVar + "+'\"]'+" : ""
                  ) + errorVar + ".path";
                }), (function (b) {
                  return fn(b, input, "");
                }));
  }
  catch (exn){
    var error = getOrRethrow(exn);
    throw new RescriptSchemaError(error.code, error.operation, path + "[]" + error.path);
  }
}

function typeFilterCode(b, typeFilter, schema, input, path) {
  var inputVar = $$var(b, input);
  return "if(" + typeFilter(b, inputVar) + "){" + failWithArg(b, path, (function (input) {
                return {
                        TAG: "InvalidType",
                        expected: schema,
                        received: input
                      };
              }), inputVar) + "}";
}

function parse(b, schema, input, path) {
  return schema.b(b, input, schema, path);
}

function parseWithTypeCheck(b, schema, input, path) {
  var typeFilter = schema.f;
  if (typeFilter === undefined) {
    return schema.b(b, input, schema, path);
  }
  if (!(schema.t.TAG === "Literal" || b.g.o & 1)) {
    return schema.b(b, input, schema, path);
  }
  b.c = b.c + typeFilterCode(b, typeFilter, schema, input, path);
  var bb = scope(b);
  var val = schema.b(bb, input, schema, path);
  b.c = b.c + allocateScope(bb);
  return val;
}

var B = {
  embed: embed,
  scope: scope,
  allocateScope: allocateScope,
  varWithoutAllocation: varWithoutAllocation,
  allocateVal: allocateVal,
  val: val,
  asyncVal: asyncVal,
  Val: Val,
  isInternalError: isInternalError,
  transform: transform,
  raise: raise,
  embedSyncOperation: embedSyncOperation,
  embedAsyncOperation: embedAsyncOperation,
  failWithArg: failWithArg,
  fail: fail,
  effectCtx: effectCtx,
  registerInvalidJson: registerInvalidJson,
  invalidOperation: invalidOperation,
  withCatch: withCatch,
  withPathPrepend: withPathPrepend,
  typeFilterCode: typeFilterCode,
  parse: parse,
  parseWithTypeCheck: parseWithTypeCheck
};

function noop(_b, input, param, param$1) {
  return input;
}

function invalidJson(b, input, selfSchema, path) {
  registerInvalidJson(b, selfSchema, path);
  return input;
}

function noopOperation(i) {
  return i;
}

function compile(builder, schema, operation) {
  if (operation & 8 && schema.r().t.TAG === "Option") {
    throw new RescriptSchemaError({
              TAG: "InvalidJsonSchema",
              _0: schema
            }, "SerializeToJson", "");
  }
  var b = {
    c: "",
    l: "",
    a: false,
    g: {
      v: -1,
      o: operation,
      e: []
    }
  };
  var input = {
    v: "i",
    s: b,
    a: false
  };
  var output = builder(b, input, schema, "");
  schema.i = output.a;
  if (b.l !== "") {
    b.c = "let " + b.l + ";" + b.c;
  }
  if (operation & 1 || schema.t.TAG === "Literal") {
    var typeFilter = schema.f;
    if (typeFilter !== undefined) {
      b.c = typeFilterCode(b, typeFilter, schema, input, "") + b.c;
    }
    
  }
  if (b.c === "" && output === input && !(operation & 22)) {
    return noopOperation;
  }
  var inlinedOutput = operation & 4 ? "void 0" : inline(b, output);
  if (operation & 16) {
    inlinedOutput = "JSON.stringify(" + inlinedOutput + ")";
  }
  if (operation & 2 && !output.a) {
    inlinedOutput = "Promise.resolve(" + inlinedOutput + ")";
  }
  var inlinedFunction = "i=>{" + b.c + "return " + inlinedOutput + "}";
  return new Function("e", "s", "return " + inlinedFunction)(b.g.e, symbol);
}

var Builder = {
  make: make$1,
  B: B,
  noop: noop,
  invalidJson: invalidJson,
  noopOperation: noopOperation,
  compile: compile
};

function operationFn(s, o) {
  if ((o in s)) {
    return (s[o]);
  }
  var ss = o & 32 ? s.r() : s;
  var f = compile(ss.b, ss, o);
  ((s[o] = f));
  return f;
}

function compile$1(schema, input, output, mode, typeValidation) {
  var operation = 0;
  switch (output) {
    case "Output" :
    case "Unknown" :
        break;
    case "Assert" :
        operation = operation | 4;
        break;
    case "Json" :
        operation = operation | 8;
        break;
    case "JsonString" :
        operation = operation | 24;
        break;
    
  }
  if (mode !== "Sync") {
    operation = operation | 2;
  }
  if (typeValidation) {
    operation = operation | 1;
  }
  var fn = operationFn(schema, operation);
  if (input === "JsonString") {
    return function (jsonString) {
      try {
        return fn(JSON.parse(jsonString));
      }
      catch (raw_error){
        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
        if (error.RE_EXN_ID === Js_exn.$$Error) {
          throw new RescriptSchemaError({
                    TAG: "OperationFailed",
                    _0: error._1.message
                  }, "Parse", "");
        }
        throw error;
      }
    };
  } else {
    return fn;
  }
}

function toSelf() {
  return this;
}

function onlyChild(factory, schema) {
  return function () {
    var reversed = schema.r();
    if (reversed === schema) {
      return this;
    } else {
      return factory(reversed);
    }
  };
}

var Reverse = {
  toSelf: toSelf,
  onlyChild: onlyChild
};

function value(literal) {
  return literal.value;
}

function isJsonable(literal) {
  return literal.j;
}

function toString$1(literal) {
  return literal.s;
}

function arrayFilterBuilder(b, inputVar, literal) {
  var items = literal.i;
  return inputVar + "!==" + ("e[" + (b.g.e.push(literal.value) - 1) + "]") + "&&(!Array.isArray(" + inputVar + ")||" + inputVar + ".length!==" + items.length + (
          items.length > 0 ? "||" + items.map(function (literal, idx) {
                    return literal.f(b, inputVar + "[" + idx + "]", literal);
                  }).join("||") : ""
        ) + ")";
}

function dictFilterBuilder(b, inputVar, literal) {
  var items = literal.i;
  var fields = Object.keys(items);
  var numberOfFields = fields.length;
  return inputVar + "!==" + ("e[" + (b.g.e.push(value) - 1) + "]") + "&&(!" + inputVar + "||" + inputVar + ".constructor!==Object||Object.keys(" + inputVar + ").length!==" + numberOfFields + (
          numberOfFields > 0 ? "||" + fields.map(function (field) {
                    var literal = items[field];
                    return literal.f(b, inputVar + "[" + JSON.stringify(field) + "]", literal);
                  }).join("||") : ""
        ) + ")";
}

function inlinedStrictEqualFilterBuilder(param, inputVar, literal) {
  return inputVar + "!==" + literal.s;
}

function strictEqualFilterBuilder(b, inputVar, literal) {
  return inputVar + "!==" + ("e[" + (b.g.e.push(literal.value) - 1) + "]");
}

var undefined_value = undefined;

var $$undefined = {
  kind: "Undefined",
  value: undefined_value,
  s: "undefined",
  f: inlinedStrictEqualFilterBuilder,
  j: false
};

var null_value = null;

var $$null = {
  kind: "Null",
  value: null_value,
  s: "null",
  f: inlinedStrictEqualFilterBuilder,
  j: true
};

var nan_value = NaN;

function nan_f(param, inputVar, param$1) {
  return "!Number.isNaN(" + inputVar + ")";
}

var nan = {
  kind: "NaN",
  value: nan_value,
  s: "NaN",
  f: nan_f,
  j: false
};

function string(value) {
  return {
          kind: "String",
          value: value,
          s: JSON.stringify(value),
          f: inlinedStrictEqualFilterBuilder,
          j: true
        };
}

function $$boolean(value) {
  return {
          kind: "Boolean",
          value: value,
          s: value ? "true" : "false",
          f: inlinedStrictEqualFilterBuilder,
          j: true
        };
}

function number(value) {
  return {
          kind: "Number",
          value: value,
          s: value.toString(),
          f: inlinedStrictEqualFilterBuilder,
          j: true
        };
}

function symbol$1(value) {
  return {
          kind: "Symbol",
          value: value,
          s: value.toString(),
          f: strictEqualFilterBuilder,
          j: false
        };
}

function bigint(value) {
  return {
          kind: "BigInt",
          value: value,
          s: value.toString() + "n",
          f: inlinedStrictEqualFilterBuilder,
          j: false
        };
}

function $$function(value) {
  return {
          kind: "Function",
          value: value,
          s: value.toString(),
          f: strictEqualFilterBuilder,
          j: false
        };
}

function object(value) {
  return {
          kind: "Object",
          value: value,
          s: Object.prototype.toString.call(value),
          f: strictEqualFilterBuilder,
          j: false
        };
}

function parseInternal(value) {
  var typeOfValue = typeof value;
  if (typeOfValue === "symbol") {
    return symbol$1(value);
  } else if (typeOfValue === "boolean") {
    return $$boolean(value);
  } else if (typeOfValue === "string") {
    return string(value);
  } else if (typeOfValue === "function") {
    return $$function(value);
  } else if (typeOfValue === "object") {
    if (value === null) {
      return $$null;
    } else if (Array.isArray(value)) {
      return array(value);
    } else if (value.constructor === Object) {
      return dict(value);
    } else {
      return object(value);
    }
  } else if (typeOfValue === "undefined") {
    return $$undefined;
  } else if (typeOfValue === "number") {
    if (Number.isNaN(value)) {
      return nan;
    } else {
      return number(value);
    }
  } else {
    return bigint(value);
  }
}

function dict(value) {
  var items = {};
  var string = "{";
  var isJsonable = true;
  var fields = Object.keys(value);
  var numberOfFields = fields.length;
  for(var idx = 0; idx < numberOfFields; ++idx){
    var field = fields[idx];
    var itemValue = value[field];
    var itemLiteral = parseInternal(itemValue);
    if (isJsonable && !itemLiteral.j) {
      isJsonable = false;
    }
    if (idx !== 0) {
      string = string + ",";
    }
    string = string + (JSON.stringify(field) + ":" + itemLiteral.s);
    items[field] = itemLiteral;
  }
  return {
          kind: "Dict",
          value: value,
          s: string + "}",
          f: dictFilterBuilder,
          j: isJsonable,
          i: Caml_option.some(items)
        };
}

function array(value) {
  var items = [];
  var isJsonable = true;
  var string = "[";
  for(var idx = 0 ,idx_finish = value.length; idx < idx_finish; ++idx){
    var itemValue = value[idx];
    var itemLiteral = parseInternal(itemValue);
    if (isJsonable && !itemLiteral.j) {
      isJsonable = false;
    }
    if (idx !== 0) {
      string = string + ",";
    }
    string = string + itemLiteral.s;
    items.push(itemLiteral);
  }
  return {
          kind: "Array",
          value: value,
          s: string + "]",
          f: arrayFilterBuilder,
          j: isJsonable,
          i: Caml_option.some(items)
        };
}

function parse$1(any) {
  return parseInternal(any);
}

function unsafeFromSchema(schema) {
  return schema.t._0;
}

var Literal = {
  value: value,
  isJsonable: isJsonable,
  toString: toString$1,
  arrayFilterBuilder: arrayFilterBuilder,
  dictFilterBuilder: dictFilterBuilder,
  inlinedStrictEqualFilterBuilder: inlinedStrictEqualFilterBuilder,
  strictEqualFilterBuilder: strictEqualFilterBuilder,
  $$undefined: $$undefined,
  $$null: $$null,
  nan: nan,
  string: string,
  $$boolean: $$boolean,
  number: number,
  symbol: symbol$1,
  bigint: bigint,
  $$function: $$function,
  object: object,
  parseInternal: parseInternal,
  dict: dict,
  array: array,
  parse: parse$1,
  unsafeFromSchema: unsafeFromSchema
};

function isAsync(schema) {
  var v = schema.i;
  if (typeof v === "boolean") {
    return v;
  }
  try {
    var b = {
      c: "",
      l: "",
      a: false,
      g: {
        v: -1,
        o: 2,
        e: []
      }
    };
    var input = {
      v: "i",
      s: b,
      a: false
    };
    var output = schema.b(b, input, schema, "");
    schema.i = output.a;
    return schema.i;
  }
  catch (exn){
    getOrRethrow(exn);
    return false;
  }
}

function reverse(schema) {
  return schema.r();
}

function wrapExnToError(exn) {
  if ((exn&&exn.s===symbol)) {
    return {
            TAG: "Error",
            _0: exn
          };
  }
  throw exn;
}

function wrapExnToFailure(exn) {
  if ((exn&&exn.s===symbol)) {
    return {
            success: false,
            error: exn
          };
  }
  throw exn;
}

function useSyncOperation(schema, operation, input) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, operation)(input)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function asyncPrepareOk(value) {
  return {
          TAG: "Ok",
          _0: value
        };
}

function useAsyncOperation(schema, operation, input) {
  try {
    return operationFn(schema, operation | 2)(input).then(asyncPrepareOk, wrapExnToError);
  }
  catch (exn){
    return Promise.resolve(wrapExnToError(exn));
  }
}

function convertWith(input, schema) {
  return operationFn(schema, 0)(input);
}

function convertToJsonStringWith(input, schema) {
  return operationFn(schema, 24)(input);
}

function convertAnyWith(any, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 0)(any)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function convertAnyToJsonWith(any, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 8)(any)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function convertAnyToJsonStringWith(any, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 24)(any)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function convertAnyAsyncWith(any, schema) {
  return useAsyncOperation(schema, 0, any);
}

function parseAnyOrRaiseWith(any, schema) {
  return operationFn(schema, 1)(any);
}

function assertWith(any, schema) {
  return operationFn(schema, 5)(any);
}

function parseAnyWith(any, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 1)(any)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function parseAnyAsyncWith(any, schema) {
  return useAsyncOperation(schema, 1, any);
}

function serializeOrRaiseWith(value, schema) {
  return schema.serializeToJsonOrThrow(value);
}

function serializeWith(value, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 40)(value)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function serializeToUnknownOrRaiseWith(value, schema) {
  return operationFn(schema, 32)(value);
}

function serializeToUnknownWith(value, schema) {
  try {
    return {
            TAG: "Ok",
            _0: operationFn(schema, 32)(value)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function serializeToJsonStringOrRaiseWith(value, schema, spaceOpt) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  return JSON.stringify(schema.serializeToJsonOrThrow(value), null, space);
}

function serializeToJsonStringWith(value, schema, spaceOpt) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  try {
    return {
            TAG: "Ok",
            _0: serializeToJsonStringOrRaiseWith(value, schema, space)
          };
  }
  catch (exn){
    return wrapExnToError(exn);
  }
}

function parseJsonStringWith(jsonString, schema) {
  var json;
  try {
    json = {
      TAG: "Ok",
      _0: JSON.parse(jsonString)
    };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error.RE_EXN_ID === Js_exn.$$Error) {
      json = {
        TAG: "Error",
        _0: new RescriptSchemaError({
              TAG: "OperationFailed",
              _0: error._1.message
            }, "Parse", "")
      };
    } else {
      throw error;
    }
  }
  if (json.TAG === "Ok") {
    return parseAnyWith(json._0, schema);
  } else {
    return json;
  }
}

function initialParseOrRaise(unknown) {
  return operationFn(this, 1)(unknown);
}

function initialAssertOrRaise(unknown) {
  return operationFn(this, 5)(unknown);
}

function initialSerializeToUnknownOrRaise(unknown) {
  return operationFn(this, 32)(unknown);
}

function initialSerializeOrRaise(unknown) {
  return operationFn(this, 40)(unknown);
}

function jsParse(unknown) {
  try {
    return {
            success: true,
            value: this.parseOrThrow(unknown)
          };
  }
  catch (exn){
    return wrapExnToFailure(exn);
  }
}

function jsParseAsync(data) {
  return parseAnyAsyncWith(data, this).then(toJsResult);
}

function jsSerialize(value) {
  try {
    return {
            success: true,
            value: serializeToUnknownOrRaiseWith(value, this)
          };
  }
  catch (exn){
    return wrapExnToFailure(exn);
  }
}

function js_parseAsyncWith(input, schema) {
  return operationFn(schema, 3)(input);
}

function js_safe(fn) {
  try {
    return {
            success: true,
            value: fn()
          };
  }
  catch (exn){
    return wrapExnToFailure(exn);
  }
}

function js_safeAsync(fn) {
  try {
    return fn().then((function (value) {
                  return {
                          success: true,
                          value: value
                        };
                }), wrapExnToFailure);
  }
  catch (exn){
    return Promise.resolve(wrapExnToFailure(exn));
  }
}

function makeReverseSchema(name, tagged, metadataMap, builder, maybeTypeFilter) {
  return {
          t: tagged,
          n: name,
          r: toSelf,
          b: builder,
          f: maybeTypeFilter,
          i: 0,
          m: metadataMap,
          parseOrThrow: initialParseOrRaise,
          parse: jsParse,
          parseAsync: jsParseAsync,
          serialize: jsSerialize,
          serializeOrThrow: initialSerializeToUnknownOrRaise,
          serializeToJsonOrThrow: initialSerializeOrRaise,
          assert: initialAssertOrRaise
        };
}

function makeSchema(name, tagged, metadataMap, builder, maybeTypeFilter, reverse) {
  return {
          t: tagged,
          n: name,
          r: (function () {
              var original = this;
              var reversed = reverse.call(original);
              var reversed$1 = original !== reversed && typeof reversed.t === "string" ? makeReverseSchema(reversed.n, reversed.t, reversed.m, reversed.b, reversed.f) : reversed;
              original.r = (function () {
                  return reversed$1;
                });
              reversed$1.r = (function () {
                  return original;
                });
              return reversed$1;
            }),
          b: builder,
          f: maybeTypeFilter,
          i: 0,
          m: metadataMap,
          parseOrThrow: initialParseOrRaise,
          parse: jsParse,
          parseAsync: jsParseAsync,
          serialize: jsSerialize,
          serializeOrThrow: initialSerializeToUnknownOrRaise,
          serializeToJsonOrThrow: initialSerializeOrRaise,
          assert: initialAssertOrRaise
        };
}

function make$2(namespace, name) {
  return namespace + ":" + name;
}

var Id = {
  make: make$2
};

var empty = {};

function set$1(map, id, metadata) {
  if (map === empty) {
    return ({[id]:metadata});
  }
  var copy = Object.assign({}, map);
  copy[id] = metadata;
  return copy;
}

var $$Map = {
  empty: empty,
  set: set$1
};

function get(schema, id) {
  return schema.m[id];
}

function set$2(schema, id, metadata) {
  var metadataMap = set$1(schema.m, id, metadata);
  return makeSchema(schema.n, schema.t, metadataMap, schema.b, schema.f, (function () {
                var schema$1 = schema.r();
                return makeReverseSchema(schema$1.n, schema$1.t, metadataMap, schema$1.b, schema$1.f);
              }));
}

var Metadata = {
  Id: Id,
  $$Map: $$Map,
  get: get,
  set: set$2
};

function primitiveName() {
  return this.t;
}

function containerName() {
  var tagged = this.t;
  return tagged.TAG + "(" + tagged._0.n() + ")";
}

function makePrimitiveSchema(tagged, builder, maybeTypeFilter) {
  return makeSchema(primitiveName, tagged, empty, builder, maybeTypeFilter, toSelf);
}

function recursive(fn) {
  var r = "r" + globalConfig.r;
  globalConfig.r = globalConfig.r + 1 | 0;
  var placeholder = {
    m: empty,
    t: "Unknown",
    n: (function () {
        return "<recursive>";
      }),
    b: (function (b, input, param, param$1) {
        return transform(b, input, (function (b, input) {
                      return map(b, r, input);
                    }));
      }),
    r: (function () {
        return makeReverseSchema(primitiveName, "Unknown", empty, (function (b, input, param, param$1) {
                      return map(b, r, input);
                    }), undefined);
      })
  };
  var schema = fn(placeholder);
  placeholder.f = schema.f;
  schema.d = undefined;
  schema.c = undefined;
  var initialParseOperationBuilder = schema.b;
  schema.b = (function (b, input, selfSchema, path) {
      var bb = scope(b);
      var opOutput = initialParseOperationBuilder(bb, input, selfSchema, "");
      var opBodyCode = allocateScope(bb) + ("return " + inline(b, opOutput));
      b.c = b.c + ("let " + r + "=" + $$var(b, input) + "=>{" + opBodyCode + "};");
      return withPathPrepend(b, input, path, undefined, (function (b, input, param) {
                    return transform(b, input, (function (b, input) {
                                  var output = map(b, r, input);
                                  if (opOutput.a) {
                                    output.a = true;
                                    placeholder.b = (function (b, input, param, param$1) {
                                        return transform(b, input, (function (b, input) {
                                                      var output = map(b, r, input);
                                                      output.a = true;
                                                      return output;
                                                    }));
                                      });
                                  }
                                  return output;
                                }));
                  }));
    });
  var initialReverse = schema.r.bind(schema);
  schema.r = (function () {
      var initialReversed = initialReverse();
      var reversed = makeReverseSchema(initialReversed.n, initialReversed.t, initialReversed.m, (function (b, input, selfSchema, path) {
              var bb = scope(b);
              var opOutput = initialReversed.b(bb, input, selfSchema, "");
              var opBodyCode = allocateScope(bb) + ("return " + inline(b, opOutput));
              b.c = b.c + ("let " + r + "=" + $$var(b, input) + "=>{" + opBodyCode + "};");
              return withPathPrepend(b, input, path, undefined, (function (b, input, param) {
                            return map(b, r, input);
                          }));
            }), initialReversed.f);
      reversed.r = (function () {
          return schema;
        });
      schema.r = (function () {
          return reversed;
        });
      return reversed;
    });
  return schema;
}

function setName(schema, name) {
  return makeSchema((function () {
                return name;
              }), schema.t, schema.m, schema.b, schema.f, (function () {
                return schema.r();
              }));
}

function removeTypeValidation(schema) {
  return makeSchema(schema.n, schema.t, schema.m, schema.b, undefined, (function () {
                return schema.r();
              }));
}

function internalRefine(schema, refiner) {
  return makeSchema(schema.n, schema.t, schema.m, (function (b, input, selfSchema, path) {
                return transform(b, schema.b(b, input, schema, path), (function (b, input) {
                              var input$1;
                              if (b.c === "" && input.v !== undefined) {
                                input$1 = input;
                              } else {
                                var scopedInput = allocateVal(b);
                                b.c = b.c + set(b, scopedInput, input) + ";";
                                input$1 = scopedInput;
                              }
                              var rCode = refiner(b, $$var(b, input$1), selfSchema, path);
                              b.c = b.c + rCode;
                              return input$1;
                            }));
              }), schema.f, (function () {
                var schema$1 = schema.r();
                return makeReverseSchema(schema$1.n, schema$1.t, schema$1.m, (function (b, input, selfSchema, path) {
                              var input$1 = transform(b, input, (function (b, input) {
                                      b.c = b.c + refiner(b, $$var(b, input), selfSchema, path);
                                      return input;
                                    }));
                              return schema$1.b(b, input$1, schema$1, path);
                            }), schema$1.f);
              }));
}

function refine(schema, refiner) {
  return internalRefine(schema, (function (b, inputVar, selfSchema, path) {
                var value = refiner(effectCtx(b, selfSchema, path));
                return "e[" + (b.g.e.push(value) - 1) + "](" + inputVar + ");";
              }));
}

function addRefinement(schema, metadataId, refinement, refiner) {
  var refinements = schema.m[metadataId];
  return internalRefine(set$2(schema, metadataId, refinements !== undefined ? refinements.concat(refinement) : [refinement]), refiner);
}

function transform$1(schema, transformer) {
  return makeSchema(schema.n, schema.t, schema.m, (function (b, input, selfSchema, path) {
                var input$1 = schema.b(b, input, schema, path);
                var match = transformer(effectCtx(b, selfSchema, path));
                var parser = match.p;
                if (parser !== undefined) {
                  if (match.a !== undefined) {
                    return invalidOperation(b, path, "The S.transform doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser");
                  } else {
                    return embedSyncOperation(b, input$1, parser);
                  }
                }
                var asyncParser = match.a;
                if (asyncParser !== undefined) {
                  return embedAsyncOperation(b, input$1, asyncParser);
                } else if (match.s !== undefined) {
                  return invalidOperation(b, path, "The S.transform parser is missing");
                } else {
                  return input$1;
                }
              }), schema.f, (function () {
                var schema$1 = schema.r();
                return makeReverseSchema(primitiveName, "Unknown", empty, (function (b, input, selfSchema, path) {
                              var match = transformer(effectCtx(b, selfSchema, path));
                              var serializer = match.s;
                              if (serializer === undefined) {
                                if (match.a !== undefined || match.p !== undefined) {
                                  return invalidOperation(b, path, "The S.transform serializer is missing");
                                } else {
                                  return schema$1.b(b, input, schema$1, path);
                                }
                              }
                              var input$1 = embedSyncOperation(b, input, serializer);
                              return schema$1.b(b, input$1, schema$1, path);
                            }), undefined);
              }));
}

function custom(name, definer) {
  return makeSchema((function () {
                return name;
              }), "Unknown", empty, (function (b, input, selfSchema, path) {
                registerInvalidJson(b, selfSchema, path);
                var match = definer(effectCtx(b, selfSchema, path));
                var parser = match.p;
                if (parser !== undefined) {
                  if (match.a !== undefined) {
                    return invalidOperation(b, path, "The S.custom doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser");
                  } else {
                    return embedSyncOperation(b, input, parser);
                  }
                }
                var asyncParser = match.a;
                if (asyncParser !== undefined) {
                  return embedAsyncOperation(b, input, asyncParser);
                } else if (match.s !== undefined) {
                  return invalidOperation(b, path, "The S.custom parser is missing");
                } else {
                  return input;
                }
              }), undefined, (function () {
                return makeReverseSchema((function () {
                              return name;
                            }), "Unknown", empty, (function (b, input, selfSchema, path) {
                              registerInvalidJson(b, selfSchema, path);
                              var match = definer(effectCtx(b, selfSchema, path));
                              var serializer = match.s;
                              if (serializer !== undefined) {
                                return embedSyncOperation(b, input, serializer);
                              } else if (match.a !== undefined || match.p !== undefined) {
                                return invalidOperation(b, path, "The S.custom serializer is missing");
                              } else {
                                return input;
                              }
                            }), undefined);
              }));
}

function literal(value) {
  var literal$1 = parseInternal(value);
  return makeSchema((function () {
                return literal$1.s;
              }), {
              TAG: "Literal",
              _0: literal$1
            }, empty, literal$1.j ? noop : invalidJson, (function (b, inputVar) {
                return literal$1.f(b, inputVar, literal$1);
              }), toSelf);
}

var unit = literal((void 0));

function isNode(definition) {
  if (typeof definition === "object") {
    return definition !== null;
  } else {
    return false;
  }
}

function toConstant(prim) {
  return prim;
}

function toEmbeded(prim) {
  return prim;
}

function toNode(prim) {
  return prim;
}

function isEmbededItem(definition) {
  return definition.s === itemSymbol;
}

var Definition = {
  isNode: isNode,
  toConstant: toConstant,
  toEmbeded: toEmbeded,
  toNode: toNode,
  isEmbededItem: isEmbededItem
};

var defaultMetadataId = "rescript-schema:Option.default";

function $$default(schema) {
  return schema.m[defaultMetadataId];
}

function makeBuilder(isNullInput, isNullOutput) {
  return function (b, input, selfSchema, path) {
    var childSchema = selfSchema.t._0;
    var childSchemaTag = childSchema.t.TAG;
    var bb = scope(b);
    var itemInput;
    if (!(b.g.o & 1) && (childSchema.t === "Unknown" || childSchemaTag === "Option" || childSchemaTag === "Literal" && childSchema.t._0.value === (void 0))) {
      var value = Caml_option.valFromOption;
      itemInput = val(bb, "e[" + (bb.g.e.push(value) - 1) + "](" + $$var(b, input) + ")");
    } else {
      itemInput = input;
    }
    var itemOutput = childSchema.b(bb, itemInput, childSchema, path);
    var itemCode = allocateScope(bb);
    var inputLiteral = isNullInput ? "null" : "void 0";
    var ouputLiteral = isNullOutput ? "null" : "void 0";
    var isTransformed = inputLiteral !== ouputLiteral || itemOutput !== input;
    var output = isTransformed ? ({
          s: b,
          a: itemOutput.a
        }) : input;
    if (itemCode !== "" || isTransformed) {
      b.c = b.c + ("if(" + $$var(b, input) + "!==" + inputLiteral + "){" + itemCode + set(b, output, itemOutput) + "}" + (
          inputLiteral !== ouputLiteral || output.a ? "else{" + set(b, output, val(b, ouputLiteral)) + "}" : ""
        ));
    }
    return output;
  };
}

function maybeTypeFilter(schema, inlinedNoneValue) {
  var typeFilter = schema.f;
  if (typeFilter !== undefined) {
    return (function (b, inputVar) {
              return inputVar + "!==" + inlinedNoneValue + "&&(" + typeFilter(b, inputVar) + ")";
            });
  }
  
}

function factory(schema) {
  return makeSchema(containerName, {
              TAG: "Option",
              _0: schema
            }, empty, makeBuilder(false, false), maybeTypeFilter(schema, "void 0"), onlyChild(factory, schema));
}

function getWithDefault(schema, $$default) {
  return makeSchema(schema.n, schema.t, set$1(schema.m, defaultMetadataId, $$default), (function (b, input, param, path) {
                return transform(b, schema.b(b, input, schema, path), (function (b, input) {
                              var inputVar = $$var(b, input);
                              var tmp;
                              tmp = $$default.TAG === "Value" ? "e[" + (b.g.e.push($$default._0) - 1) + "]" : "e[" + (b.g.e.push($$default._0) - 1) + "]()";
                              return val(b, inputVar + "===void 0?" + tmp + ":" + inputVar);
                            }));
              }), schema.f, (function () {
                var reversed = schema.r();
                if (reversed.t.TAG === "Option") {
                  return reversed.t._0;
                } else {
                  return reversed;
                }
              }));
}

function getOr(schema, defalutValue) {
  return getWithDefault(schema, {
              TAG: "Value",
              _0: defalutValue
            });
}

function getOrWith(schema, defalutCb) {
  return getWithDefault(schema, {
              TAG: "Callback",
              _0: defalutCb
            });
}

var $$Option$1 = {
  defaultMetadataId: defaultMetadataId,
  $$default: $$default,
  makeBuilder: makeBuilder,
  maybeTypeFilter: maybeTypeFilter,
  factory: factory,
  getWithDefault: getWithDefault,
  getOr: getOr,
  getOrWith: getOrWith
};

function factory$1(schema) {
  return makeSchema(containerName, {
              TAG: "Null",
              _0: schema
            }, empty, makeBuilder(true, false), maybeTypeFilter(schema, "null"), (function () {
                var child = schema.r();
                return makeReverseSchema(containerName, {
                            TAG: "Option",
                            _0: child
                          }, empty, makeBuilder(false, true), maybeTypeFilter(schema, "void 0"));
              }));
}

var Null = {
  factory: factory$1
};

function nullable(schema) {
  return factory(factory$1(schema));
}

function builder(b, input, selfSchema, path) {
  b.c = b.c + failWithArg(b, path, (function (input) {
          return {
                  TAG: "InvalidType",
                  expected: selfSchema,
                  received: input
                };
        }), inline(b, input)) + ";";
  return input;
}

var schema = makeSchema(primitiveName, "Never", empty, builder, undefined, toSelf);

var Never = {
  builder: builder,
  schema: schema
};

var metadataId = "rescript-schema:Array.refinements";

var Refinement = {
  metadataId: metadataId
};

function refinements(schema) {
  var m = schema.m[metadataId];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter(_b, inputVar) {
  return "!Array.isArray(" + inputVar + ")";
}

function factory$2(schema) {
  return makeSchema(containerName, {
              TAG: "Array",
              _0: schema
            }, empty, (function (b, input, param, path) {
                var inputVar = $$var(b, input);
                var iteratorVar = varWithoutAllocation(b);
                var bb = scope(b);
                var itemInput = val(bb, inputVar + "[" + iteratorVar + "]");
                var itemOutput = withPathPrepend(bb, itemInput, path, iteratorVar, (function (b, input, path) {
                        return parseWithTypeCheck(b, schema, input, path);
                      }));
                var itemCode = allocateScope(bb);
                var isTransformed = itemInput !== itemOutput;
                var output = isTransformed ? val(b, "[]") : input;
                if (isTransformed || itemCode !== "") {
                  b.c = b.c + ("for(let " + iteratorVar + "=0;" + iteratorVar + "<" + inputVar + ".length;++" + iteratorVar + "){" + itemCode + (
                      isTransformed ? push(b, output, itemOutput) : ""
                    ) + "}");
                }
                if (itemOutput.a) {
                  return asyncVal(b, "Promise.all(" + inline(b, output) + ")");
                } else {
                  return output;
                }
              }), typeFilter, onlyChild(factory$2, schema));
}

var $$Array$1 = {
  Refinement: Refinement,
  refinements: refinements,
  typeFilter: typeFilter,
  factory: factory$2
};

function getItems(schema) {
  return schema.t.items;
}

function getOutputDefinition(schema) {
  return schema.t.definition;
}

function make$3(selfSchema) {
  return {
          a: 0,
          o: new WeakMap(),
          s: selfSchema
        };
}

function addItemOutput(b, ctx, item, output) {
  var output$1;
  if (output.a) {
    var a = ctx.a;
    var asyncOutputs;
    if (Array.isArray(a)) {
      asyncOutputs = a;
    } else {
      var a$1 = [];
      ctx.a = a$1;
      asyncOutputs = a$1;
    }
    var index = asyncOutputs.length;
    asyncOutputs.push(output);
    output$1 = val(b, "a[" + index + "]");
  } else {
    output$1 = output;
  }
  ctx.o.set(item, output$1);
}

function toOutputVal(b, ctx, outputDefinition) {
  var definitionToValue = function (definition, outputPath) {
    var val = ctx.o.get(definition);
    if (val !== undefined) {
      return inline(b, val);
    }
    if (!(typeof definition === "object" && definition !== null)) {
      return "e[" + (b.g.e.push(definition) - 1) + "]";
    }
    var isArray = Array.isArray(definition);
    var keys = Object.keys(definition);
    var codeRef = isArray ? "[" : "{";
    for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
      var key = keys[idx];
      var definition$1 = definition[key];
      var output = definitionToValue(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
      if (idx !== 0) {
        codeRef = codeRef + ",";
      }
      codeRef = codeRef + (
        isArray ? output : JSON.stringify(key) + ":" + output
      );
    }
    return codeRef + (
            isArray ? "]" : "}"
          );
  };
  var syncOutput = definitionToValue(outputDefinition, "");
  if (ctx.a === 0) {
    return val(b, syncOutput);
  }
  var asyncOutputs = ctx.a;
  return asyncVal(b, "Promise.all([" + asyncOutputs.map(function (val) {
                    return inline(b, val);
                  }).toString() + "]).then(a=>(" + syncOutput + "))");
}

var BuildCtx = {
  make: make$3,
  addItemOutput: addItemOutput,
  toOutputVal: toOutputVal
};

function typeFilter$1(_b, inputVar) {
  return "!" + inputVar + "||" + inputVar + ".constructor!==Object";
}

function processInputItems(b, ctx, input, schema, path) {
  var inputVar = $$var(b, input);
  var items = schema.t.items;
  var isObject = schema.t.TAG === "Object";
  for(var idx = 0 ,idx_finish = items.length; idx < idx_finish; ++idx){
    var prevCode = b.c;
    b.c = "";
    var item = items[idx];
    var itemPath = item.p;
    var schema$1 = item.t;
    var itemInput = val(b, inputVar + itemPath);
    var path$1 = path + itemPath;
    var isLiteral = schema$1.t.TAG === "Literal";
    var typeFilter = schema$1.f;
    if (typeFilter !== undefined && (isLiteral || b.g.o & 1)) {
      b.c = b.c + typeFilterCode(b, typeFilter, schema$1, itemInput, path$1);
    }
    var bb = scope(b);
    if (isObject && schema$1.d) {
      processInputItems(bb, ctx, itemInput, schema$1, path$1);
    } else {
      var itemOutput = schema$1.b(bb, itemInput, schema$1, path$1);
      addItemOutput(b, ctx, item, itemOutput);
    }
    if (isLiteral) {
      b.c = b.c + allocateScope(bb) + prevCode;
    } else {
      b.c = prevCode + b.c + allocateScope(bb);
    }
  }
  if (!(isObject && ctx.s.t.unknownKeys === "Strict" && b.g.o & 1)) {
    return ;
  }
  var key = allocateVal(b);
  var keyVar = $$var(b, key);
  b.c = b.c + ("for(" + keyVar + " in " + inputVar + "){if(");
  if (items.length !== 0) {
    for(var idx$1 = 0 ,idx_finish$1 = items.length; idx$1 < idx_finish$1; ++idx$1){
      var item$1 = items[idx$1];
      if (idx$1 !== 0) {
        b.c = b.c + "&&";
      }
      b.c = b.c + (keyVar + "!==" + item$1.i);
    }
  } else {
    b.c = b.c + "true";
  }
  b.c = b.c + ("){" + failWithArg(b, path, (function (exccessFieldName) {
            return {
                    TAG: "ExcessField",
                    _0: exccessFieldName
                  };
          }), keyVar) + "}}");
}

function builder$1(b, input, selfSchema, path) {
  var ctx = make$3(selfSchema);
  processInputItems(b, ctx, input, selfSchema, path);
  return toOutputVal(b, ctx, selfSchema.t.definition);
}

function name() {
  var schema = this;
  return "Object({" + schema.t.items.map(function (item) {
                return item.i + ": " + item.t.n();
              }).join(", ") + "})";
}

function makeReverseOutput(b, toItem, original, outputs, path) {
  var isRootObject = original.t.TAG === "Object";
  var schemaOutput = function (schema, isObject, path) {
    var items = schema.t.items;
    var output = "";
    for(var idx = 0 ,idx_finish = items.length; idx < idx_finish; ++idx){
      var item = items[idx];
      var o = outputs.get(item);
      var itemOutput = o !== undefined ? inline(b, o) : fallbackOutput(item, path);
      if (idx !== 0) {
        output = output + ",";
      }
      output = output + (
        isObject ? item.i + ":" + itemOutput : itemOutput
      );
    }
    if (isObject) {
      return "{" + output + "}";
    } else {
      return "[" + output + "]";
    }
  };
  var fallbackOutput = function (item, path) {
    var itemSchema = item.t;
    if (itemSchema.t.TAG !== "Literal") {
      if (isRootObject && itemSchema.d) {
        return schemaOutput(itemSchema, true, path + item.p);
      } else {
        return invalidOperation(b, path, "Schema for " + item.i + " isn't registered");
      }
    }
    var value = itemSchema.t._0.value;
    return "e[" + (b.g.e.push(value) - 1) + "]";
  };
  if (toItem === undefined) {
    return val(b, schemaOutput(original, isRootObject, path));
  }
  var o = outputs.get(toItem);
  if (o !== undefined) {
    return o;
  } else {
    return val(b, fallbackOutput(toItem, path));
  }
}

function reverse$1(inputDefinition, toItem) {
  return function () {
    var original = this;
    return makeReverseSchema(primitiveName, "Unknown", empty, (function (b, input, param, path) {
                  if (b.g.o & 1) {
                    invalidOperation(b, path, "Type validation mode is not supported. Use convert operation instead");
                  }
                  if (b.g.o & 2) {
                    invalidOperation(b, path, "Async mode is not supported");
                  }
                  var inputVar = $$var(b, input);
                  var ctx = {
                    d: ""
                  };
                  var embededOutputs = new WeakMap();
                  var definitionToOutput = function (definition, outputPath) {
                    if (typeof definition === "object" && definition !== null) {
                      if (definition.s === itemSymbol) {
                        var embededOutput = embededOutputs.get(definition);
                        if (embededOutput !== undefined) {
                          var itemInput = outputPath === "" ? input : val(b, inputVar + outputPath);
                          var schema = definition.t.r();
                          var itemOutput = schema.b(b, itemInput, schema, path + outputPath);
                          b.c = b.c + ("if(" + $$var(b, embededOutput) + "!==" + $$var(b, itemOutput) + "){" + fail(b, "Multiple sources provided not equal data for " + definition.i, path) + "}");
                          return ;
                        }
                        var itemInput$1 = outputPath === "" ? input : val(b, inputVar + outputPath);
                        var schema$1 = definition.t.r();
                        var itemOutput$1 = schema$1.b(b, itemInput$1, schema$1, path + outputPath);
                        embededOutputs.set(definition, itemOutput$1);
                        return ;
                      }
                      var keys = Object.keys(definition);
                      for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                        var key = keys[idx];
                        var definition$1 = definition[key];
                        definitionToOutput(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
                      }
                      return ;
                    }
                    var tagSchema = literal(definition);
                    var itemInputVar = inputVar + outputPath;
                    ctx.d = ctx.d + ("if(" + tagSchema.f(b, itemInputVar) + "){" + failWithArg(b, path + outputPath, (function (received) {
                              return {
                                      TAG: "InvalidType",
                                      expected: tagSchema,
                                      received: received
                                    };
                            }), itemInputVar) + "}");
                  };
                  definitionToOutput(inputDefinition, "");
                  b.c = ctx.d + b.c;
                  return makeReverseOutput(b, toItem, original, embededOutputs, path);
                }), undefined);
  };
}

function factory$3(definer) {
  var fields = {};
  var items = [];
  var flatten = function (schema) {
    if (schema.d) {
      return schema.d(this);
    }
    var message = "The " + schema.n() + " schema can't be flattened";
    throw new Error("[rescript-schema] " + message);
  };
  var field = function (fieldName, schema) {
    var inlinedLocation = JSON.stringify(fieldName);
    var item = fields[fieldName];
    if (item !== undefined) {
      if (item.t.d && schema.d) {
        return schema.d(item.t.c);
      }
      throw new Error("[rescript-schema] " + ("The field " + inlinedLocation + " defined twice with incompatible schemas"));
    }
    var schema$1 = schema.d ? factory$3(schema.d) : schema;
    var item_p = "[" + inlinedLocation + "]";
    var item$1 = {
      t: schema$1,
      p: item_p,
      l: fieldName,
      i: inlinedLocation,
      s: itemSymbol
    };
    fields[fieldName] = item$1;
    items.push(item$1);
    if (schema$1.d) {
      return schema$1.t.definition;
    } else {
      return item$1;
    }
  };
  var tag = function (tag$1, asValue) {
    field(tag$1, literal(asValue));
  };
  var fieldOr = function (fieldName, schema, or) {
    return field(fieldName, getOr(factory(schema), or));
  };
  var nestedField = function (fieldName, nestedFieldName, schema) {
    var item = fields[fieldName];
    if (item === undefined) {
      return field(fieldName, factory$3(function (s) {
                      return s.f(nestedFieldName, schema);
                    }));
    }
    if (item.t.d) {
      return item.t.c.f(nestedFieldName, schema);
    }
    var message = "The field " + JSON.stringify(fieldName) + " defined twice with incompatible schemas";
    throw new Error("[rescript-schema] " + message);
  };
  var ctx = {
    field: field,
    f: field,
    fieldOr: fieldOr,
    tag: tag,
    nestedField: nestedField,
    flatten: flatten
  };
  var definition = definer(ctx);
  return {
          t: {
            TAG: "Object",
            items: items,
            fields: fields,
            unknownKeys: globalConfig.u,
            definition: definition
          },
          n: name,
          r: reverse$1(definition, undefined),
          b: builder$1,
          f: typeFilter$1,
          i: 0,
          d: definer,
          c: ctx,
          m: empty,
          parseOrThrow: initialParseOrRaise,
          parse: jsParse,
          parseAsync: jsParseAsync,
          serialize: jsSerialize,
          serializeOrThrow: initialSerializeToUnknownOrRaise,
          serializeToJsonOrThrow: initialSerializeOrRaise,
          assert: initialAssertOrRaise
        };
}

function to(schema, definer) {
  if (schema.d) {
    return factory$3(function (ctx) {
                return definer(schema.d(ctx));
              });
  }
  var item = {
    t: schema,
    p: "",
    l: "",
    i: "\"\"",
    s: itemSymbol
  };
  var definition = definer(item);
  return makeSchema(schema.n, schema.t, schema.m, (function (b, input, selfSchema, path) {
                var ctx = make$3(selfSchema);
                var itemOutput = schema.b(b, input, schema, path);
                addItemOutput(b, ctx, item, itemOutput);
                return toOutputVal(b, ctx, definition);
              }), schema.f, reverse$1(definition, item));
}

function setUnknownKeys(schema, unknownKeys) {
  var match = schema.t;
  if (typeof match !== "object" || !(match.TAG === "Object" && match.unknownKeys !== unknownKeys)) {
    return schema;
  } else {
    return {
            t: {
              TAG: "Object",
              items: match.items,
              fields: match.fields,
              unknownKeys: unknownKeys,
              definition: match.definition
            },
            n: schema.n,
            r: schema.r,
            b: schema.b,
            f: schema.f,
            i: schema.i,
            d: schema.d,
            c: schema.c,
            m: schema.m,
            parseOrThrow: initialParseOrRaise,
            parse: jsParse,
            parseAsync: jsParseAsync,
            serialize: jsSerialize,
            serializeOrThrow: initialSerializeToUnknownOrRaise,
            serializeToJsonOrThrow: initialSerializeOrRaise,
            assert: initialAssertOrRaise
          };
  }
}

function strip(schema) {
  return setUnknownKeys(schema, "Strip");
}

function strict(schema) {
  return setUnknownKeys(schema, "Strict");
}

var $$Object$1 = {
  getItems: getItems,
  getOutputDefinition: getOutputDefinition,
  BuildCtx: BuildCtx,
  typeFilter: typeFilter$1,
  processInputItems: processInputItems,
  builder: builder$1,
  name: name,
  makeReverseOutput: makeReverseOutput,
  reverse: reverse$1,
  to: to,
  factory: factory$3,
  setUnknownKeys: setUnknownKeys,
  strip: strip,
  strict: strict
};

function name$1() {
  var schema = this;
  return "Tuple(" + schema.t.items.map(function (item) {
                return item.t.n();
              }).join(", ") + ")";
}

function typeFilter$2(length) {
  return function (b, inputVar) {
    return typeFilter(b, inputVar) + ("||" + inputVar + ".length!==" + length);
  };
}

function factory$4(definer) {
  var items = [];
  var item = function (idx, schema) {
    var $$location = idx.toString();
    var inlinedLocation = "\"" + $$location + "\"";
    if (items[idx]) {
      throw new Error("[rescript-schema] " + ("The item " + inlinedLocation + " is defined multiple times"));
    }
    var item_p = "[" + inlinedLocation + "]";
    var item$1 = {
      t: schema,
      p: item_p,
      l: $$location,
      i: inlinedLocation,
      s: itemSymbol
    };
    items[idx] = item$1;
    return item$1;
  };
  var tag = function (idx, asValue) {
    item(idx, literal(asValue));
  };
  var ctx = {
    item: item,
    tag: tag
  };
  var definition = definer(ctx);
  var length = items.length;
  for(var idx = 0; idx < length; ++idx){
    if (!items[idx]) {
      var $$location = idx.toString();
      var inlinedLocation = "\"" + $$location + "\"";
      var item_p = "[" + inlinedLocation + "]";
      var item$1 = {
        t: unit,
        p: item_p,
        l: $$location,
        i: inlinedLocation,
        s: itemSymbol
      };
      items[idx] = item$1;
    }
    
  }
  return makeSchema(name$1, {
              TAG: "Tuple",
              items: items,
              definition: definition
            }, empty, builder$1, (function (b, inputVar) {
                return typeFilter(b, inputVar) + ("||" + inputVar + ".length!==" + length);
              }), reverse$1(definition, undefined));
}

var Tuple = {
  name: name$1,
  typeFilter: typeFilter$2,
  factory: factory$4
};

function factory$5(schema) {
  return makeSchema(containerName, {
              TAG: "Dict",
              _0: schema
            }, empty, (function (b, input, param, path) {
                var inputVar = $$var(b, input);
                var keyVar = varWithoutAllocation(b);
                var bb = scope(b);
                var itemInput = val(bb, inputVar + "[" + keyVar + "]");
                var itemOutput = withPathPrepend(bb, itemInput, path, keyVar, (function (b, input, path) {
                        return parseWithTypeCheck(b, schema, input, path);
                      }));
                var itemCode = allocateScope(bb);
                var isTransformed = itemInput !== itemOutput;
                var output = isTransformed ? val(b, "{}") : input;
                if (isTransformed || itemCode !== "") {
                  b.c = b.c + ("for(let " + keyVar + " in " + inputVar + "){" + itemCode + (
                      isTransformed ? addKey(b, output, keyVar, itemOutput) : ""
                    ) + "}");
                }
                if (!itemOutput.a) {
                  return output;
                }
                var resolveVar = varWithoutAllocation(b);
                var rejectVar = varWithoutAllocation(b);
                var asyncParseResultVar = varWithoutAllocation(b);
                var counterVar = varWithoutAllocation(b);
                var outputVar = $$var(b, output);
                return asyncVal(b, "new Promise((" + resolveVar + "," + rejectVar + ")=>{let " + counterVar + "=Object.keys(" + outputVar + ").length;for(let " + keyVar + " in " + outputVar + "){" + outputVar + "[" + keyVar + "].then(" + asyncParseResultVar + "=>{" + outputVar + "[" + keyVar + "]=" + asyncParseResultVar + ";if(" + counterVar + "--===1){" + resolveVar + "(" + outputVar + ")}}," + rejectVar + ")}})");
              }), typeFilter$1, onlyChild(factory$5, schema));
}

var Dict$1 = {
  factory: factory$5
};

var schema$1 = makeSchema(primitiveName, "Unknown", empty, invalidJson, undefined, toSelf);

var Unknown = {
  schema: schema$1
};

var metadataId$1 = "rescript-schema:String.refinements";

var Refinement$1 = {
  metadataId: metadataId$1
};

function refinements$1(schema) {
  var m = schema.m[metadataId$1];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

var cuidRegex = /^c[^\s-]{8,}$/i;

var uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

var emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;

var datetimeRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

function typeFilter$3(_b, inputVar) {
  return "typeof " + inputVar + "!==\"string\"";
}

var schema$2 = makePrimitiveSchema("String", noop, typeFilter$3);

var $$String = {
  Refinement: Refinement$1,
  refinements: refinements$1,
  cuidRegex: cuidRegex,
  uuidRegex: uuidRegex,
  emailRegex: emailRegex,
  datetimeRe: datetimeRe,
  typeFilter: typeFilter$3,
  schema: schema$2
};

function factory$6(schema, spaceOpt) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  return makeSchema(primitiveName, "String", empty, (function (b, input, param, path) {
                var jsonVal = allocateVal(b);
                b.c = b.c + ("try{" + set(b, jsonVal, map(b, "JSON.parse", input)) + "}catch(t){" + failWithArg(b, path, (function (message) {
                          return {
                                  TAG: "OperationFailed",
                                  _0: message
                                };
                        }), "t.message") + "}");
                var bb = scope(b);
                var val = parseWithTypeCheck(bb, schema, jsonVal, path);
                b.c = b.c + allocateScope(bb);
                return val;
              }), typeFilter$3, (function () {
                var reversed = schema.r();
                return makeReverseSchema(reversed.n, reversed.t, reversed.m, (function (b, input, param, path) {
                              var prevOperation = b.g.o;
                              b.g.o = prevOperation | 8;
                              if (reversed.t.TAG === "Option") {
                                raise(b, {
                                      TAG: "InvalidJsonSchema",
                                      _0: reversed
                                    }, "");
                              }
                              var output = val(b, "JSON.stringify(" + inline(b, reversed.b(b, input, reversed, path)) + (
                                    space > 0 ? ",null," + space : ""
                                  ) + ")");
                              b.g.o = prevOperation;
                              return output;
                            }), reversed.f);
              }));
}

var JsonString = {
  factory: factory$6
};

function typeFilter$4(_b, inputVar) {
  return "typeof " + inputVar + "!==\"boolean\"";
}

var schema$3 = makePrimitiveSchema("Bool", noop, typeFilter$4);

var Bool = {
  typeFilter: typeFilter$4,
  schema: schema$3
};

var metadataId$2 = "rescript-schema:Int.refinements";

var Refinement$2 = {
  metadataId: metadataId$2
};

function refinements$2(schema) {
  var m = schema.m[metadataId$2];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter$5(_b, inputVar) {
  return "typeof " + inputVar + "!==\"number\"||" + inputVar + ">2147483647||" + inputVar + "<-2147483648||" + inputVar + "%1!==0";
}

var schema$4 = makePrimitiveSchema("Int", noop, typeFilter$5);

var Int$1 = {
  Refinement: Refinement$2,
  refinements: refinements$2,
  typeFilter: typeFilter$5,
  schema: schema$4
};

var metadataId$3 = "rescript-schema:Float.refinements";

var Refinement$3 = {
  metadataId: metadataId$3
};

function refinements$3(schema) {
  var m = schema.m[metadataId$3];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter$6(_b, inputVar) {
  return "typeof " + inputVar + "!==\"number\"" + (
          globalConfig.n ? "" : "||Number.isNaN(" + inputVar + ")"
        );
}

var schema$5 = makePrimitiveSchema("Float", noop, typeFilter$6);

var Float$2 = {
  Refinement: Refinement$3,
  refinements: refinements$3,
  typeFilter: typeFilter$6,
  schema: schema$5
};

function typeFilter$7(_b, inputVar) {
  return "typeof " + inputVar + "!==\"bigint\"";
}

var schema$6 = makePrimitiveSchema("Unknown", invalidJson, typeFilter$7);

schema$6.n = (() => "BigInt");

var $$BigInt$1 = {
  typeFilter: typeFilter$7,
  schema: schema$6
};

function parse$2(b, schemas, path, input, output) {
  var isMultiple = schemas.length > 1;
  var loop = function (idx, errorCodes) {
    if (idx === schemas.length) {
      return failWithArg(b, path, (function (internalErrors) {
                    return {
                            TAG: "InvalidUnion",
                            _0: internalErrors
                          };
                  }), "[" + errorCodes + "]");
    }
    var schema = schemas[idx];
    var parserCode;
    try {
      var bb = scope(b);
      var itemOutput = schema.b(bb, input, schema, "");
      if (isMultiple && !(b.g.o & 1)) {
        var reversed = schema.r();
        var typeFilter = reversed.f;
        if (typeFilter !== undefined) {
          var code = typeFilterCode(bb, typeFilter, reversed, itemOutput, "");
          bb.c = bb.c + code;
        }
        
      }
      if (itemOutput !== input) {
        bb.c = bb.c + set(bb, output, itemOutput);
      }
      parserCode = allocateScope(bb);
    }
    catch (exn){
      var value = getOrRethrow(exn);
      parserCode = "throw " + ("e[" + (b.g.e.push(value) - 1) + "]");
    }
    if (!isMultiple) {
      return parserCode;
    }
    var errorVar = "e" + idx;
    return "try{" + parserCode + "}catch(" + errorVar + "){" + loop(idx + 1 | 0, errorCodes + errorVar + ",") + "}";
  };
  return loop(0, "");
}

function factory$7(schemas) {
  var len = schemas.length;
  if (len === 1) {
    return schemas[0];
  }
  if (len !== 0) {
    return makeSchema((function () {
                  return "Union(" + schemas.map(function (s) {
                                return s.n();
                              }).join(", ") + ")";
                }), {
                TAG: "Union",
                _0: schemas
              }, empty, (function (b, input, selfSchema, path) {
                  var schemas = selfSchema.t._0;
                  var inputVar = $$var(b, input);
                  var output = val(b, inputVar);
                  var byTypeFilter = {};
                  var typeFilters = [];
                  for(var idx = 0 ,idx_finish = schemas.length; idx < idx_finish; ++idx){
                    var schema = schemas[idx];
                    var typeFilter = schema.f;
                    var typeFilterCode = typeFilter !== undefined ? typeFilter(b, inputVar) : "";
                    var schemas$1 = Js_dict.get(byTypeFilter, typeFilterCode);
                    if (schemas$1 !== undefined) {
                      schemas$1.push(schema);
                    } else {
                      typeFilters.push(typeFilterCode);
                      byTypeFilter[typeFilterCode] = [schema];
                    }
                  }
                  var loopTypeFilters = function (_idx, _maybeUnknownParser) {
                    while(true) {
                      var maybeUnknownParser = _maybeUnknownParser;
                      var idx = _idx;
                      if (idx === typeFilters.length) {
                        if (maybeUnknownParser !== undefined) {
                          return maybeUnknownParser;
                        } else {
                          return failWithArg(b, path, (function (received) {
                                        return {
                                                TAG: "InvalidType",
                                                expected: selfSchema,
                                                received: received
                                              };
                                      }), inputVar);
                        }
                      }
                      var typeFilterCode = typeFilters[idx];
                      var schemas = byTypeFilter[typeFilterCode];
                      var parserCode = parse$2(b, schemas, path, input, output);
                      if (typeFilterCode === "") {
                        _maybeUnknownParser = parserCode;
                        _idx = idx + 1 | 0;
                        continue ;
                      }
                      var tmp = parserCode === "" ? "" : "}else{" + parserCode;
                      return "if(" + typeFilterCode + "){" + loopTypeFilters(idx + 1 | 0, maybeUnknownParser) + tmp + "}";
                    };
                  };
                  b.c = b.c + loopTypeFilters(0, undefined);
                  if (output.a) {
                    return asyncVal(b, "Promise.resolve(" + inline(b, output) + ")");
                  } else {
                    return output;
                  }
                }), undefined, (function () {
                  var original = this;
                  var schemas = original.t._0;
                  return factory$7(schemas.map(function (s) {
                                  return s.r();
                                }));
                }));
  }
  throw new Error("[rescript-schema] S.union requires at least one item");
}

var Union = {
  parse: parse$2,
  factory: factory$7
};

function $$enum(values) {
  return factory$7(values.map(literal));
}

function preprocess(schema, transformer) {
  var unionSchemas = schema.t;
  if (typeof unionSchemas === "object" && unionSchemas.TAG === "Union") {
    return makeSchema(schema.n, {
                TAG: "Union",
                _0: unionSchemas._0.map(function (unionSchema) {
                      return preprocess(unionSchema, transformer);
                    })
              }, schema.m, schema.b, schema.f, schema.r);
  }
  return makeSchema(schema.n, schema.t, schema.m, (function (b, input, selfSchema, path) {
                var match = transformer(effectCtx(b, selfSchema, path));
                var parser = match.p;
                if (parser !== undefined) {
                  if (match.a !== undefined) {
                    return invalidOperation(b, path, "The S.preprocess doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser");
                  } else {
                    return parseWithTypeCheck(b, schema, embedSyncOperation(b, input, parser), path);
                  }
                }
                var asyncParser = match.a;
                if (asyncParser !== undefined) {
                  return transform(b, embedAsyncOperation(b, input, asyncParser), (function (b, input) {
                                return parseWithTypeCheck(b, schema, input, path);
                              }));
                } else {
                  return parseWithTypeCheck(b, schema, input, path);
                }
              }), undefined, (function () {
                var reversed = schema.r();
                return makeReverseSchema(primitiveName, "Unknown", empty, (function (b, input, param, path) {
                              var input$1 = reversed.b(b, input, reversed, path);
                              var match = transformer(effectCtx(b, schema, path));
                              var serializer = match.s;
                              if (serializer !== undefined) {
                                return embedSyncOperation(b, input$1, serializer);
                              } else {
                                return input$1;
                              }
                            }), undefined);
              }));
}

function list(schema) {
  return transform$1(factory$2(schema), (function (param) {
                return {
                        p: Belt_List.fromArray,
                        s: Belt_List.toArray
                      };
              }));
}

function json(validate) {
  return makeSchema((function () {
                return "JSON";
              }), {
              TAG: "JSON",
              validated: validate
            }, empty, validate ? (function (b, input, selfSchema, path) {
                  var parse = function (input, pathOpt) {
                    var path$1 = pathOpt !== undefined ? pathOpt : path;
                    var match = typeof input;
                    if (match === "string" || match === "boolean") {
                      return input;
                    }
                    if (match === "object") {
                      if (input === null) {
                        return input;
                      }
                      if (Array.isArray(input)) {
                        var output = [];
                        for(var idx = 0 ,idx_finish = input.length; idx < idx_finish; ++idx){
                          var inputItem = input[idx];
                          var $$location = idx.toString();
                          output.push(parse(inputItem, path$1 + ("[" + JSON.stringify($$location) + "]")));
                        }
                        return output;
                      }
                      var keys = Object.keys(input);
                      var output$1 = {};
                      for(var idx$1 = 0 ,idx_finish$1 = keys.length; idx$1 < idx_finish$1; ++idx$1){
                        var key = keys[idx$1];
                        var field = input[key];
                        output$1[key] = parse(field, path$1 + ("[" + JSON.stringify(key) + "]"));
                      }
                      return output$1;
                    }
                    if (match === "number" && !Number.isNaN(input)) {
                      return input;
                    }
                    return raise(b, {
                                TAG: "InvalidType",
                                expected: selfSchema,
                                received: input
                              }, path$1);
                  };
                  return map(b, "e[" + (b.g.e.push(parse) - 1) + "]", input);
                }) : noop, undefined, (function () {
                if (validate) {
                  return json(false);
                } else {
                  return this;
                }
              }));
}

var Catch = {};

function $$catch(schema, getFallbackValue) {
  return makeSchema(schema.n, schema.t, schema.m, (function (b, input, selfSchema, path) {
                var inputVar = $$var(b, input);
                return withCatch(b, input, (function (b, errorVar) {
                              return val(b, "e[" + (b.g.e.push(function (input, internalError) {
                                                return getFallbackValue({
                                                            e: internalError,
                                                            i: input,
                                                            s: selfSchema,
                                                            f: (function (message, customPathOpt) {
                                                                var customPath = customPathOpt !== undefined ? customPathOpt : "";
                                                                return raise(b, {
                                                                            TAG: "OperationFailed",
                                                                            _0: message
                                                                          }, path + customPath);
                                                              })
                                                          });
                                              }) - 1) + "](" + inputVar + "," + errorVar + ")");
                            }), (function (b) {
                              return parseWithTypeCheck(b, schema, input, path);
                            }));
              }), undefined, (function () {
                return schema.r();
              }));
}

var deprecationMetadataId = "rescript-schema:deprecation";

function deprecate(schema, message) {
  return set$2(schema, deprecationMetadataId, message);
}

function deprecation(schema) {
  return schema.m[deprecationMetadataId];
}

var descriptionMetadataId = "rescript-schema:description";

function describe(schema, description) {
  return set$2(schema, descriptionMetadataId, description);
}

function description(schema) {
  return schema.m[descriptionMetadataId];
}

function definitionToSchema(definition) {
  if (!(typeof definition === "object" && definition !== null)) {
    return literal(definition);
  }
  if (definition.serializeToJsonOrThrow) {
    return definition;
  }
  if (Array.isArray(definition)) {
    var items = [];
    var isTransformed = false;
    for(var idx = 0 ,idx_finish = definition.length; idx < idx_finish; ++idx){
      var $$location = idx.toString();
      var inlinedLocation = "\"" + $$location + "\"";
      var schema = definitionToSchema(definition[idx]);
      var item_p = "[" + inlinedLocation + "]";
      var item = {
        t: schema,
        p: item_p,
        l: $$location,
        i: inlinedLocation,
        s: itemSymbol
      };
      items[idx] = item;
      if (!isTransformed && schema !== schema.r()) {
        isTransformed = true;
      }
      
    }
    var length = items.length;
    return makeSchema(name$1, {
                TAG: "Tuple",
                items: items,
                definition: items
              }, empty, builder$1, (function (b, inputVar) {
                  return typeFilter(b, inputVar) + ("||" + inputVar + ".length!==" + length);
                }), isTransformed ? reverse$1(items, undefined) : toSelf);
  }
  var items$1 = [];
  var fields = {};
  var fieldNames = Object.keys(definition);
  var isTransformed$1 = false;
  for(var idx$1 = 0 ,idx_finish$1 = fieldNames.length; idx$1 < idx_finish$1; ++idx$1){
    var $$location$1 = fieldNames[idx$1];
    var inlinedLocation$1 = "\"" + $$location$1 + "\"";
    var schema$1 = definitionToSchema(definition[$$location$1]);
    var item_p$1 = "[" + inlinedLocation$1 + "]";
    var item$1 = {
      t: schema$1,
      p: item_p$1,
      l: $$location$1,
      i: inlinedLocation$1,
      s: itemSymbol
    };
    items$1[idx$1] = item$1;
    fields[$$location$1] = item$1;
    if (!isTransformed$1 && schema$1 !== schema$1.r()) {
      isTransformed$1 = true;
    }
    
  }
  return makeSchema(name, {
              TAG: "Object",
              items: items$1,
              fields: fields,
              unknownKeys: globalConfig.u,
              definition: fields
            }, empty, builder$1, typeFilter$1, isTransformed$1 ? reverse$1(fields, undefined) : toSelf);
}

function matches(schema) {
  return schema;
}

var ctx = {
  matches: matches
};

function factory$8(definer) {
  var definition = typeof definer === "function" ? definer(ctx) : definer;
  return definitionToSchema(definition);
}

var Schema = {
  definitionToSchema: definitionToSchema,
  matches: matches,
  ctx: ctx,
  factory: factory$8
};

var $$class = RescriptSchemaError;

function make$4(prim0, prim1, prim2) {
  return new RescriptSchemaError(prim0, prim1, prim2);
}

function raise$1(error) {
  throw error;
}

function reason(error, nestedLevelOpt) {
  var nestedLevel = nestedLevelOpt !== undefined ? nestedLevelOpt : 0;
  var reason$1 = error.code;
  if (typeof reason$1 !== "object") {
    return "Encountered unexpected async transform or refine. Use ParseAsync operation instead";
  }
  switch (reason$1.TAG) {
    case "OperationFailed" :
        return reason$1._0;
    case "InvalidOperation" :
        return reason$1.description;
    case "InvalidType" :
        return "Expected " + reason$1.expected.n() + ", received " + parseInternal(reason$1.received).s;
    case "ExcessField" :
        return "Encountered disallowed excess key " + JSON.stringify(reason$1._0) + " on an object";
    case "InvalidUnion" :
        var lineBreak = "\n" + " ".repeat((nestedLevel << 1));
        var reasonsDict = {};
        reason$1._0.forEach(function (error) {
              var reason$2 = reason(error, nestedLevel + 1);
              var nonEmptyPath = error.path;
              var $$location = nonEmptyPath === "" ? "" : "Failed at " + nonEmptyPath + ". ";
              reasonsDict["- " + $$location + reason$2] = undefined;
            });
        var uniqueReasons = Object.keys(reasonsDict);
        return "Invalid union with following errors" + lineBreak + uniqueReasons.join(lineBreak);
    case "InvalidJsonSchema" :
        return "The " + reason$1._0.n() + " schema is not compatible with JSON";
    
  }
}

function reason$1(error) {
  return reason(error, undefined);
}

function message(error) {
  var match = error.operation;
  var operation;
  switch (match) {
    case "Parse" :
        operation = "parsing";
        break;
    case "ParseAsync" :
        operation = "parsing async";
        break;
    case "SerializeToJson" :
        operation = "serializing to JSON";
        break;
    case "SerializeToUnknown" :
        operation = "serializing";
        break;
    case "Assert" :
        operation = "asserting";
        break;
    
  }
  var nonEmptyPath = error.path;
  var pathText = nonEmptyPath === "" ? "root" : nonEmptyPath;
  return "Failed " + operation + " at " + pathText + ". Reason: " + reason(error, undefined);
}

var $$Error$1 = {
  $$class: $$class,
  make: make$4,
  raise: raise$1,
  reason: reason$1,
  message: message
};

function internalInline(schema, maybeVariant, param) {
  var metadataMap = Object.assign({}, schema.m);
  var literal = schema.t;
  var inlinedSchema;
  if (typeof literal !== "object") {
    switch (literal) {
      case "Never" :
          inlinedSchema = "S.never";
          break;
      case "Unknown" :
          inlinedSchema = "S.unknown";
          break;
      case "String" :
          inlinedSchema = "S.string";
          break;
      case "Int" :
          inlinedSchema = "S.int";
          break;
      case "Float" :
          inlinedSchema = "S.float";
          break;
      case "Bool" :
          inlinedSchema = "S.bool";
          break;
      
    }
  } else {
    switch (literal.TAG) {
      case "Literal" :
          inlinedSchema = "S.literal(%raw(\`" + literal._0.s + "\`))";
          break;
      case "Option" :
          inlinedSchema = "S.option(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Null" :
          inlinedSchema = "S.null(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Array" :
          inlinedSchema = "S.array(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Object" :
          var items = literal.items;
          inlinedSchema = items.length !== 0 ? "S.object(s =>\n  {\n    " + items.map(function (item) {
                    return item.i + ": s.field(" + item.i + ", " + internalInline(item.t, undefined, undefined) + ")";
                  }).join(",\n    ") + ",\n  }\n)" : "S.object(_ => ())";
          break;
      case "Tuple" :
          var tupleSchemas = literal.items;
          var exit = 0;
          var len = tupleSchemas.length;
          if (len >= 4) {
            exit = 1;
          } else {
            switch (len) {
              case 0 :
                  exit = 1;
                  break;
              case 1 :
                  var i1 = tupleSchemas[0];
                  inlinedSchema = "S.tuple1(" + internalInline(i1.t, undefined, undefined) + ")";
                  break;
              case 2 :
                  var i1$1 = tupleSchemas[0];
                  var i2 = tupleSchemas[1];
                  inlinedSchema = "S.tuple2(" + internalInline(i1$1.t, undefined, undefined) + ", " + internalInline(i2.t, undefined, undefined) + ")";
                  break;
              case 3 :
                  var i1$2 = tupleSchemas[0];
                  var i2$1 = tupleSchemas[1];
                  var i3 = tupleSchemas[2];
                  inlinedSchema = "S.tuple3(" + internalInline(i1$2.t, undefined, undefined) + ", " + internalInline(i2$1.t, undefined, undefined) + ", " + internalInline(i3.t, undefined, undefined) + ")";
                  break;
              
            }
          }
          if (exit === 1) {
            inlinedSchema = "S.tuple(s => (" + tupleSchemas.map(function (item, idx) {
                    return "s.item(" + idx + ", " + internalInline(item.t, undefined, undefined) + ")";
                  }).join(", ") + "))";
          }
          break;
      case "Union" :
          var variantNamesCounter = {};
          inlinedSchema = "S.union([" + literal._0.map(function (s) {
                  var variantName = s.n();
                  var n = Js_dict.get(variantNamesCounter, variantName);
                  var numberOfVariantNames = n !== undefined ? n : 0;
                  variantNamesCounter[variantName] = numberOfVariantNames + 1;
                  var variantName$1 = numberOfVariantNames !== 0 ? variantName + (numberOfVariantNames + 1) : variantName;
                  var inlinedVariant = "#" + JSON.stringify(variantName$1);
                  return internalInline(s, inlinedVariant, undefined);
                }).join(", ") + "])";
          break;
      case "Dict" :
          inlinedSchema = "S.dict(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "JSON" :
          inlinedSchema = "S.json(~validate=" + literal.validated + ")";
          break;
      
    }
  }
  var $$default = schema.m[defaultMetadataId];
  var inlinedSchema$1;
  if ($$default !== undefined) {
    Js_dict.unsafeDeleteKey(metadataMap, defaultMetadataId);
    if ($$default.TAG === "Value") {
      var defaultValue = $$default._0;
      inlinedSchema$1 = inlinedSchema + ("->S.Option.getOr(%raw(\`" + (
          defaultValue === (void 0) ? "undefined" : JSON.stringify(defaultValue)
        ) + "\`))");
    } else {
      var any = $$default._0();
      inlinedSchema$1 = inlinedSchema + ("->S.Option.getOrWith(() => %raw(\`" + (
          any === (void 0) ? "undefined" : JSON.stringify(any)
        ) + "\`))");
    }
  } else {
    inlinedSchema$1 = inlinedSchema;
  }
  var message = deprecation(schema);
  var inlinedSchema$2 = message !== undefined ? (Js_dict.unsafeDeleteKey(metadataMap, deprecationMetadataId), inlinedSchema$1 + ("->S.deprecate(" + JSON.stringify(message) + ")")) : inlinedSchema$1;
  var message$1 = description(schema);
  var inlinedSchema$3 = message$1 !== undefined ? (Js_dict.unsafeDeleteKey(metadataMap, descriptionMetadataId), inlinedSchema$2 + ("->S.describe(" + (
          message$1 === (void 0) ? "undefined" : JSON.stringify(message$1)
        ) + ")")) : inlinedSchema$2;
  var match = schema.t;
  var inlinedSchema$4;
  inlinedSchema$4 = typeof match !== "object" || !(match.TAG === "Object" && match.unknownKeys !== "Strip") ? inlinedSchema$3 : inlinedSchema$3 + "->S.Object.strict";
  var match$1 = schema.t;
  var inlinedSchema$5;
  var exit$1 = 0;
  if (typeof match$1 !== "object") {
    switch (match$1) {
      case "String" :
          exit$1 = 1;
          break;
      case "Int" :
          var refinements$4 = refinements$2(schema);
          if (refinements$4.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$2);
            inlinedSchema$5 = inlinedSchema$4 + refinements$4.map(function (refinement) {
                    var match = refinement.kind;
                    if (typeof match !== "object") {
                      return "->S.port(~message=" + JSON.stringify(refinement.message) + ")";
                    } else if (match.TAG === "Min") {
                      return "->S.intMin(" + match.value + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    } else {
                      return "->S.intMax(" + match.value + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    }
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      case "Float" :
          var refinements$5 = refinements$3(schema);
          if (refinements$5.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$3);
            inlinedSchema$5 = inlinedSchema$4 + refinements$5.map(function (refinement) {
                    var match = refinement.kind;
                    if (match.TAG === "Min") {
                      var value = match.value;
                      return "->S.floatMin(" + (value.toString() + (
                                value % 1 === 0 ? "." : ""
                              )) + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    }
                    var value$1 = match.value;
                    return "->S.floatMax(" + (value$1.toString() + (
                              value$1 % 1 === 0 ? "." : ""
                            )) + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      default:
        inlinedSchema$5 = inlinedSchema$4;
    }
  } else {
    switch (match$1.TAG) {
      case "Literal" :
          if (match$1._0.kind === "String") {
            exit$1 = 1;
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      case "Array" :
          var refinements$6 = refinements(schema);
          if (refinements$6.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId);
            inlinedSchema$5 = inlinedSchema$4 + refinements$6.map(function (refinement) {
                    var match = refinement.kind;
                    switch (match.TAG) {
                      case "Min" :
                          return "->S.arrayMinLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      case "Max" :
                          return "->S.arrayMaxLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      case "Length" :
                          return "->S.arrayLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      
                    }
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      default:
        inlinedSchema$5 = inlinedSchema$4;
    }
  }
  if (exit$1 === 1) {
    var refinements$7 = refinements$1(schema);
    if (refinements$7.length !== 0) {
      Js_dict.unsafeDeleteKey(metadataMap, metadataId$1);
      inlinedSchema$5 = inlinedSchema$4 + refinements$7.map(function (refinement) {
              var match = refinement.kind;
              if (typeof match !== "object") {
                switch (match) {
                  case "Email" :
                      return "->S.email(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Uuid" :
                      return "->S.uuid(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Cuid" :
                      return "->S.cuid(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Url" :
                      return "->S.url(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Datetime" :
                      return "->S.datetime(~message=" + JSON.stringify(refinement.message) + ")";
                  
                }
              } else {
                switch (match.TAG) {
                  case "Min" :
                      return "->S.stringMinLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Max" :
                      return "->S.stringMaxLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Length" :
                      return "->S.stringLength(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Pattern" :
                      return "->S.pattern(%re(" + JSON.stringify(match.re.toString()) + "), ~message=" + JSON.stringify(refinement.message) + ")";
                  
                }
              }
            }).join("");
    } else {
      inlinedSchema$5 = inlinedSchema$4;
    }
  }
  var inlinedSchema$6 = Object.keys(metadataMap).length !== 0 ? "{\n  let s = " + inlinedSchema$5 + "\n  let _ = %raw(\`s.m = " + JSON.stringify(metadataMap) + "\`)\n  s\n}" : inlinedSchema$5;
  if (maybeVariant !== undefined) {
    return inlinedSchema$6 + ("->S.to(v => " + maybeVariant + "(v))");
  } else {
    return inlinedSchema$6;
  }
}

function inline$1(schema) {
  return internalInline(schema, undefined, undefined);
}

function tuple1(v0) {
  return factory$4(function (s) {
              return s.item(0, v0);
            });
}

function tuple2(v0, v1) {
  return factory$4(function (s) {
              return [
                      s.item(0, v0),
                      s.item(1, v1)
                    ];
            });
}

function tuple3(v0, v1, v2) {
  return factory$4(function (s) {
              return [
                      s.item(0, v0),
                      s.item(1, v1),
                      s.item(2, v2)
                    ];
            });
}

function intMin(schema, minValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue;
  return addRefinement(schema, metadataId$2, {
              kind: {
                TAG: "Min",
                value: minValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<" + ("e[" + (b.g.e.push(minValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function intMax(schema, maxValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue;
  return addRefinement(schema, metadataId$2, {
              kind: {
                TAG: "Max",
                value: maxValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ">" + ("e[" + (b.g.e.push(maxValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function port(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid port";
  return addRefinement(schema, metadataId$2, {
              kind: "Port",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<1||" + inputVar + ">65535){" + fail(b, message, path) + "}";
              }));
}

function floatMin(schema, minValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue;
  return addRefinement(schema, metadataId$3, {
              kind: {
                TAG: "Min",
                value: minValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<" + ("e[" + (b.g.e.push(minValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function floatMax(schema, maxValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue;
  return addRefinement(schema, metadataId$3, {
              kind: {
                TAG: "Max",
                value: maxValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ">" + ("e[" + (b.g.e.push(maxValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function arrayMinLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length + " or more items long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Min",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length<" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function arrayMaxLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length + " or fewer items long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Max",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length>" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function arrayLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be exactly " + length + " items long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Length",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length!==" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function stringMinLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length + " or more characters long";
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Min",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length<" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function stringMaxLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length + " or fewer characters long";
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Max",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length>" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function stringLength(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be exactly " + length + " characters long";
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Length",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length!==" + ("e[" + (b.g.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function email(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid email address";
  return addRefinement(schema, metadataId$1, {
              kind: "Email",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.g.e.push(emailRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function uuid(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid UUID";
  return addRefinement(schema, metadataId$1, {
              kind: "Uuid",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.g.e.push(uuidRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function cuid(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid CUID";
  return addRefinement(schema, metadataId$1, {
              kind: "Cuid",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.g.e.push(cuidRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function url(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid url";
  return addRefinement(schema, metadataId$1, {
              kind: "Url",
              message: message
            }, (function (b, inputVar, param, path) {
                return "try{new URL(" + inputVar + ")}catch(_){" + fail(b, message, path) + "}";
              }));
}

function pattern(schema, re, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid";
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Pattern",
                re: re
              },
              message: message
            }, (function (b, inputVar, param, path) {
                var reVal = val(b, "e[" + (b.g.e.push(re) - 1) + "]");
                var reVar = $$var(b, reVal);
                return reVar + ".lastIndex=0;if(!" + reVar + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function datetime(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid datetime string! Must be UTC";
  var refinement = {
    kind: "Datetime",
    message: message
  };
  var refinements = schema.m[metadataId$1];
  return transform$1(set$2(schema, metadataId$1, refinements !== undefined ? refinements.concat(refinement) : [refinement]), (function (s) {
                return {
                        p: (function (string) {
                            if (!datetimeRe.test(string)) {
                              s.fail(message, undefined);
                            }
                            return new Date(string);
                          }),
                        s: (function (date) {
                            return date.toISOString();
                          })
                      };
              }));
}

function trim(schema) {
  var transformer = function (string) {
    return string.trim();
  };
  return transform$1(schema, (function (param) {
                return {
                        p: transformer,
                        s: transformer
                      };
              }));
}

function unwrap(result) {
  if (result.TAG === "Ok") {
    return result._0;
  }
  throw result._0;
}

function js_union(values) {
  return factory$7(values.map(definitionToSchema));
}

function js_transform(schema, maybeParser, maybeSerializer) {
  return transform$1(schema, (function (s) {
                return {
                        p: maybeParser !== undefined ? (function (v) {
                              return maybeParser(v, s);
                            }) : undefined,
                        s: maybeSerializer !== undefined ? (function (v) {
                              return maybeSerializer(v, s);
                            }) : undefined
                      };
              }));
}

function js_refine(schema, refiner) {
  return refine(schema, (function (s) {
                return function (v) {
                  refiner(v, s);
                };
              }));
}

function noop$1(a) {
  return a;
}

function js_asyncParserRefine(schema, refine) {
  return transform$1(schema, (function (s) {
                return {
                        a: (function (v) {
                            return function () {
                              return refine(v, s).then(function () {
                                          return v;
                                        });
                            };
                          }),
                        s: noop$1
                      };
              }));
}

function js_optional(schema, maybeOr) {
  var schema$1 = factory(schema);
  if (maybeOr === undefined) {
    return schema$1;
  }
  var or = Caml_option.valFromOption(maybeOr);
  if (typeof or === "function") {
    return getOrWith(schema$1, or);
  } else {
    return getOr(schema$1, or);
  }
}

function js_tuple(definer) {
  if (typeof definer === "function") {
    return factory$4(definer);
  } else {
    return factory$4(function (s) {
                return definer.map(function (schema, idx) {
                            return s.item(idx, schema);
                          });
              });
  }
}

function js_custom(name, maybeParser, maybeSerializer, param) {
  return custom(name, (function (s) {
                return {
                        p: maybeParser !== undefined ? (function (v) {
                              return maybeParser(v, s);
                            }) : undefined,
                        s: maybeSerializer !== undefined ? (function (v) {
                              return maybeSerializer(v, s);
                            }) : undefined
                      };
              }));
}

function js_object(definer) {
  if (typeof definer === "function") {
    return factory$3(definer);
  } else {
    return factory$3(function (s) {
                var definition = {};
                var fieldNames = Object.keys(definer);
                for(var idx = 0 ,idx_finish = fieldNames.length; idx < idx_finish; ++idx){
                  var fieldName = fieldNames[idx];
                  var schema = definer[fieldName];
                  var schema$1 = schema && schema.serializeToJsonOrThrow ? schema : literal(schema);
                  definition[fieldName] = s.f(fieldName, schema$1);
                }
                return definition;
              });
  }
}

function js_merge(s1, s2) {
  var match = s1.t;
  if (typeof match === "object" && match.TAG === "Object") {
    var s1Items = match.items;
    var match$1 = s2.t;
    if (typeof match$1 === "object" && match$1.TAG === "Object") {
      var s2Items = match$1.items;
      var items = [];
      var fields = {};
      for(var idx = 0 ,idx_finish = s1Items.length; idx < idx_finish; ++idx){
        var item = s1Items[idx];
        items.push(item);
        fields[item.l] = item;
      }
      for(var idx$1 = 0 ,idx_finish$1 = s2Items.length; idx$1 < idx_finish$1; ++idx$1){
        var item$1 = s2Items[idx$1];
        if (fields[item$1.l]) {
          throw new Error("[rescript-schema] " + ("The field " + item$1.i + " is defined multiple times"));
        }
        items.push(item$1);
        fields[item$1.l] = item$1;
      }
      return makeSchema((function () {
                    return s1.n() + " & " + s2.n();
                  }), {
                  TAG: "Object",
                  items: items,
                  fields: fields,
                  unknownKeys: match$1.unknownKeys,
                  definition: (void 0)
                }, empty, (function (b, input, param, path) {
                    var s1Result = s1.b(b, input, s1, path);
                    var s2Result = s2.b(b, input, s2, path);
                    return val(b, "Object.assign(" + inline(b, s1Result) + ", " + inline(b, s2Result) + ")");
                  }), typeFilter$1, (function () {
                    return makeReverseSchema(primitiveName, "Unknown", empty, (function (b, param, param$1, path) {
                                  return invalidOperation(b, path, "The S.merge serializing is not supported yet");
                                }), undefined);
                  }));
    }
    
  }
  throw new Error("[rescript-schema] The merge supports only Object schemas");
}

function js_name(prim) {
  return prim.n();
}

var resetOperationsCache = ((schema) => {
  for (let key in schema) {
    if (+key) {
      delete schema[key];
    }
  }
});

function setGlobalConfig(override) {
  globalConfig.r = 0;
  var unknownKeys = override.defaultUnknownKeys;
  globalConfig.u = unknownKeys !== undefined ? unknownKeys : "Strip";
  var prevDisableNanNumberCheck = globalConfig.n;
  var disableNanNumberCheck = override.disableNanNumberCheck;
  globalConfig.n = disableNanNumberCheck !== undefined ? disableNanNumberCheck : false;
  if (prevDisableNanNumberCheck !== globalConfig.n) {
    return resetOperationsCache(schema$5);
  }
  
}

function js_unwrap(result) {
  if (result.success === true) {
    return result.value;
  }
  throw result.error;
}

var initialDefaultUnknownKeys = "Strip";

var initialDisableNanNumberProtection = false;

var B$1;

var isAsyncParse = isAsync;

var assertAnyWith = assertWith;

var assertOrRaiseWith = assertWith;

var parseWith = parseAnyWith;

var parseOrRaiseWith = parseAnyOrRaiseWith;

var parseAsyncWith = parseAnyAsyncWith;

var reverseConvertWith = serializeToUnknownOrRaiseWith;

var reverseConvertToJsonWith = serializeOrRaiseWith;

var reverseConvertToJsonStringWith = serializeToJsonStringOrRaiseWith;

var schema$7 = factory$8;

var object$1 = factory$3;

var never = schema;

var unknown = schema$1;

var string$1 = schema$2;

var bool = schema$3;

var $$int = schema$4;

var $$float = schema$5;

var bigint$1 = schema$6;

var $$null$1 = factory$1;

var option = factory;

var array$1 = factory$2;

var dict$1 = factory$5;

var variant = to;

var tuple = factory$4;

var union = factory$7;

var jsonString = factory$6;

exports.Obj = Obj;
exports.Stdlib = Stdlib;
exports.Path = Path;
exports.symbol = symbol;
exports.itemSymbol = itemSymbol;
exports.Raised = Raised;
exports.unsafeGetVariantPayload = unsafeGetVariantPayload;
exports.unsafeGetVarianTag = unsafeGetVarianTag;
exports.isSchemaObject = isSchemaObject;
exports.isSchema = isSchema;
exports.isLiteralSchema = isLiteralSchema;
exports.isPrimitiveSchema = isPrimitiveSchema;
exports.initialDefaultUnknownKeys = initialDefaultUnknownKeys;
exports.initialDisableNanNumberProtection = initialDisableNanNumberProtection;
exports.globalConfig = globalConfig;
exports.toJsResult = toJsResult;
exports.InternalError = InternalError;
exports.classify = classify;
exports.Operation = Operation;
exports.Builder = Builder;
exports.B = B$1;
exports.operationFn = operationFn;
exports.compile = compile$1;
exports.Reverse = Reverse;
exports.Literal = Literal;
exports.isAsync = isAsync;
exports.isAsyncParse = isAsyncParse;
exports.reverse = reverse;
exports.wrapExnToError = wrapExnToError;
exports.wrapExnToFailure = wrapExnToFailure;
exports.useSyncOperation = useSyncOperation;
exports.asyncPrepareOk = asyncPrepareOk;
exports.useAsyncOperation = useAsyncOperation;
exports.convertWith = convertWith;
exports.convertToJsonStringWith = convertToJsonStringWith;
exports.convertAnyWith = convertAnyWith;
exports.convertAnyToJsonWith = convertAnyToJsonWith;
exports.convertAnyToJsonStringWith = convertAnyToJsonStringWith;
exports.convertAnyAsyncWith = convertAnyAsyncWith;
exports.parseAnyOrRaiseWith = parseAnyOrRaiseWith;
exports.assertWith = assertWith;
exports.assertAnyWith = assertAnyWith;
exports.assertOrRaiseWith = assertOrRaiseWith;
exports.parseAnyWith = parseAnyWith;
exports.parseWith = parseWith;
exports.parseOrRaiseWith = parseOrRaiseWith;
exports.parseAnyAsyncWith = parseAnyAsyncWith;
exports.parseAsyncWith = parseAsyncWith;
exports.serializeOrRaiseWith = serializeOrRaiseWith;
exports.serializeWith = serializeWith;
exports.serializeToUnknownOrRaiseWith = serializeToUnknownOrRaiseWith;
exports.serializeToUnknownWith = serializeToUnknownWith;
exports.serializeToJsonStringOrRaiseWith = serializeToJsonStringOrRaiseWith;
exports.serializeToJsonStringWith = serializeToJsonStringWith;
exports.reverseConvertWith = reverseConvertWith;
exports.reverseConvertToJsonWith = reverseConvertToJsonWith;
exports.reverseConvertToJsonStringWith = reverseConvertToJsonStringWith;
exports.parseJsonStringWith = parseJsonStringWith;
exports.initialParseOrRaise = initialParseOrRaise;
exports.initialAssertOrRaise = initialAssertOrRaise;
exports.initialSerializeToUnknownOrRaise = initialSerializeToUnknownOrRaise;
exports.initialSerializeOrRaise = initialSerializeOrRaise;
exports.jsParse = jsParse;
exports.jsParseAsync = jsParseAsync;
exports.jsSerialize = jsSerialize;
exports.js_parseAsyncWith = js_parseAsyncWith;
exports.js_safe = js_safe;
exports.js_safeAsync = js_safeAsync;
exports.makeReverseSchema = makeReverseSchema;
exports.makeSchema = makeSchema;
exports.Metadata = Metadata;
exports.primitiveName = primitiveName;
exports.containerName = containerName;
exports.makePrimitiveSchema = makePrimitiveSchema;
exports.recursive = recursive;
exports.setName = setName;
exports.removeTypeValidation = removeTypeValidation;
exports.internalRefine = internalRefine;
exports.refine = refine;
exports.addRefinement = addRefinement;
exports.transform = transform$1;
exports.custom = custom;
exports.literal = literal;
exports.unit = unit;
exports.Definition = Definition;
exports.$$Option = $$Option$1;
exports.Null = Null;
exports.nullable = nullable;
exports.Never = Never;
exports.$$Array = $$Array$1;
exports.$$Object = $$Object$1;
exports.Tuple = Tuple;
exports.Dict = Dict$1;
exports.Unknown = Unknown;
exports.$$String = $$String;
exports.JsonString = JsonString;
exports.Bool = Bool;
exports.Int = Int$1;
exports.Float = Float$2;
exports.$$BigInt = $$BigInt$1;
exports.Union = Union;
exports.$$enum = $$enum;
exports.preprocess = preprocess;
exports.list = list;
exports.json = json;
exports.Catch = Catch;
exports.$$catch = $$catch;
exports.deprecationMetadataId = deprecationMetadataId;
exports.deprecate = deprecate;
exports.deprecation = deprecation;
exports.descriptionMetadataId = descriptionMetadataId;
exports.describe = describe;
exports.description = description;
exports.Schema = Schema;
exports.schema = schema$7;
exports.$$Error = $$Error$1;
exports.inline = inline$1;
exports.object = object$1;
exports.never = never;
exports.unknown = unknown;
exports.string = string$1;
exports.bool = bool;
exports.$$int = $$int;
exports.$$float = $$float;
exports.bigint = bigint$1;
exports.$$null = $$null$1;
exports.option = option;
exports.array = array$1;
exports.dict = dict$1;
exports.variant = variant;
exports.to = to;
exports.tuple = tuple;
exports.tuple1 = tuple1;
exports.tuple2 = tuple2;
exports.tuple3 = tuple3;
exports.union = union;
exports.jsonString = jsonString;
exports.intMin = intMin;
exports.intMax = intMax;
exports.port = port;
exports.floatMin = floatMin;
exports.floatMax = floatMax;
exports.arrayMinLength = arrayMinLength;
exports.arrayMaxLength = arrayMaxLength;
exports.arrayLength = arrayLength;
exports.stringMinLength = stringMinLength;
exports.stringMaxLength = stringMaxLength;
exports.stringLength = stringLength;
exports.email = email;
exports.uuid = uuid;
exports.cuid = cuid;
exports.url = url;
exports.pattern = pattern;
exports.datetime = datetime;
exports.trim = trim;
exports.unwrap = unwrap;
exports.js_union = js_union;
exports.js_transform = js_transform;
exports.js_refine = js_refine;
exports.noop = noop$1;
exports.js_asyncParserRefine = js_asyncParserRefine;
exports.js_optional = js_optional;
exports.js_tuple = js_tuple;
exports.js_custom = js_custom;
exports.js_object = js_object;
exports.js_merge = js_merge;
exports.js_name = js_name;
exports.resetOperationsCache = resetOperationsCache;
exports.setGlobalConfig = setGlobalConfig;
exports.js_unwrap = js_unwrap;
/* symbol Not a pure module */
