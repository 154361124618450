// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_UnverifiedJWT$Util = require("util/src/CustomScalars/CS_UnverifiedJWT.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"getBookingPaymentIntentInput":{"forNetAmountInPositiveCents":{"b":""},"againstBooking":{"c":"Util.CustomScalars.BookingId.Exn"}},"__root":{"input":{"r":"getBookingPaymentIntentInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"getBookingPaymentIntent_paymentIntent":{"c":"Util.CustomScalars.UnverifiedJWT.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.UnverifiedJWT.Exn": CS_UnverifiedJWT$Util.UnverifiedJWT.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"getBookingPaymentIntent_paymentIntent":{"c":"Util.CustomScalars.UnverifiedJWT.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.UnverifiedJWT.Exn": CS_UnverifiedJWT$Util.UnverifiedJWT.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function pmtMethod_decode($$enum) {
  if ($$enum === "FCC" || $$enum === "CHECK" || $$enum === "CREDIT_CARD" || $$enum === "WIRE" || $$enum === "RTP" || $$enum === "SAME_DAY_ACH" || $$enum === "P2P") {
    return $$enum;
  }
  
}

function pmtMethod_fromString(str) {
  return pmtMethod_decode(str);
}

function pmtProcessor_decode($$enum) {
  if ($$enum === "INCREASE_COM" || $$enum === "BRAINTREEPAYMENTS_COM") {
    return $$enum;
  }
  
}

function pmtProcessor_fromString(str) {
  return pmtProcessor_decode(str);
}

var Utils = {
  pmtMethod_decode: pmtMethod_decode,
  pmtMethod_fromString: pmtMethod_fromString,
  pmtProcessor_decode: pmtProcessor_decode,
  pmtProcessor_fromString: pmtProcessor_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetBookingPaymentIntentPayload",
    "kind": "LinkedField",
    "name": "getBookingPaymentIntent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentIntent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcessorPaymentIntentBookingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcessorPaymentIntentBookingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "de3b2939d26c7ffc183d9982df147da0",
    "id": null,
    "metadata": {},
    "name": "ProcessorPaymentIntentBookingMutation",
    "operationKind": "mutation",
    "text": "mutation ProcessorPaymentIntentBookingMutation(\n  $input: GetBookingPaymentIntentInput!\n) {\n  getBookingPaymentIntent(input: $input) {\n    paymentIntent\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
