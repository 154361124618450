// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var PaymentSuggestions$Thin = require("../../../utils/sales-flow/PaymentSuggestions.bs.js");
var BDBerthsPassengersQueryRefetch_graphql$Thin = require("../../../__generated__/BDBerthsPassengersQueryRefetch_graphql.bs.js");
var CurrentUserBookingSessionsQuery_graphql$Thin = require("../../../__generated__/CurrentUserBookingSessionsQuery_graphql.bs.js");
var CurrentUserBookingSessions_query_graphql$Thin = require("../../../__generated__/CurrentUserBookingSessions_query_graphql.bs.js");

var convertVariables = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CurrentUserBookingSessionsQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertVariables);

var Query_docGender_decode = CurrentUserBookingSessionsQuery_graphql$Thin.Utils.docGender_decode;

var Query_docGender_fromString = CurrentUserBookingSessionsQuery_graphql$Thin.Utils.docGender_fromString;

var Query = {
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = CurrentUserBookingSessions_query_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CurrentUserBookingSessions_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CurrentUserBookingSessions_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CurrentUserBookingSessions_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = BDBerthsPassengersQueryRefetch_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = BDBerthsPassengersQueryRefetch_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CurrentUserBookingSessions_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_docGender_decode = CurrentUserBookingSessions_query_graphql$Thin.Utils.docGender_decode;

var QueryFragment_docGender_fromString = CurrentUserBookingSessions_query_graphql$Thin.Utils.docGender_fromString;

var QueryFragment = {
  docGender_decode: QueryFragment_docGender_decode,
  docGender_fromString: QueryFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function $$fetch$1(sessionData, setError, voyageSlug) {
  setError(function (param) {
        
      });
  $$fetch(RelayEnv$Thin.environment, {
        voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
      }, (function (res) {
          if (res.TAG === "Ok") {
            var match = res._0.currentUserBookingSessions;
            if (match !== undefined) {
              var match$1 = match.nodes;
              if (match$1.length === 1) {
                var match$2 = match$1[0];
                var paymentPlan = match$2.paymentPlan;
                var paymentSuggestions = paymentPlan !== undefined ? PaymentSuggestions$Thin.getPaymentSuggestions({
                        TAG: "CurrentSession",
                        _0: paymentPlan
                      }) : undefined;
                return HopperState$Util.Observable.notify(sessionData, (function (m) {
                              return {
                                      paxes: m.paxes,
                                      bc: m.bc,
                                      pax1: m.pax1,
                                      paymentOption: m.paymentOption,
                                      sessionSaveIsMutating: m.sessionSaveIsMutating,
                                      signup: m.signup,
                                      url: m.url,
                                      paymentSuggestions: paymentSuggestions,
                                      quote: m.quote
                                    };
                            }));
              }
              
            }
            
          }
          setError(function (param) {
                return "An error occurred fetching payment suggestions. Please try again.";
              });
        }), undefined, undefined);
}

exports.Query = Query;
exports.QueryFragment = QueryFragment;
exports.$$fetch = $$fetch$1;
/* use Not a pure module */
