// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AccountPage$Thin = require("../pages/AccountPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var AccountRouteQuery_graphql$Thin = require("../__generated__/AccountRouteQuery_graphql.bs.js");

var convertVariables = AccountRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = AccountRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = AccountRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, AccountRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, AccountRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(AccountRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(AccountRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(AccountRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(AccountRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function AccountRoute(props) {
  var data = use(undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              mustBeLoggedIn: true,
              children: JsxRuntime.jsx(AccountPage$Thin.make, {
                    fragmentRefs: data.fragmentRefs
                  })
            });
}

var make = AccountRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
