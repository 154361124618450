// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NukaCarousel = require("nuka-carousel");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var YetAnotherReactLightbox = require("yet-another-react-lightbox");

function SubclassGallery(props) {
  var images = props.images;
  var match = React.useState(function () {
        return false;
      });
  var setLightboxOpen = match[1];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setIdx = match$1[1];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          sm: "grid"
                        },
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gridTemplateRows: "repeat(2, 1fr)",
                        gap: "1rem",
                        width: "100%",
                        height: "25rem"
                      },
                      children: Caml_option.some(Belt_Array.mapWithIndex(images, (function (idx, param) {
                                  if (idx > 3) {
                                    return null;
                                  }
                                  var tmp;
                                  switch (idx) {
                                    case 1 :
                                        tmp = 3;
                                        break;
                                    case 2 :
                                        tmp = images.length === 3 ? 3 : 4;
                                        break;
                                    case 3 :
                                        tmp = 4;
                                        break;
                                    default:
                                      tmp = 1;
                                  }
                                  var tmp$1;
                                  switch (idx) {
                                    case 1 :
                                        tmp$1 = images.length >= 4 ? "span 1" : "span 2";
                                        break;
                                    case 2 :
                                        tmp$1 = images.length === 3 ? "span 2" : "span 1";
                                        break;
                                    case 3 :
                                        tmp$1 = "span 1";
                                        break;
                                    default:
                                      tmp$1 = images.length === 1 ? "span 4" : "span 2";
                                  }
                                  return JsxRuntime.jsx(Stack, {
                                              component: "button",
                                              sx: {
                                                borderRadius: "8px",
                                                padding: "0px",
                                                border: "0px",
                                                backgroundImage: "url(" + param.src + ")",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                gridColumnStart: tmp,
                                                gridColumnEnd: tmp$1,
                                                gridRowStart: idx !== 2 ? (
                                                    idx !== 3 ? 1 : 2
                                                  ) : (
                                                    images.length === 3 ? 2 : 1
                                                  ),
                                                gridRowEnd: idx !== 0 && (idx !== 1 || images.length === 3) ? "span 1" : "span 2",
                                                "&:hover": {
                                                  cursor: "pointer",
                                                  backgroundColor: "rgba(0, 0, 0, 0.5)"
                                                }
                                              },
                                              onClick: (function (param) {
                                                  setIdx(function (param) {
                                                        return idx;
                                                      });
                                                  setLightboxOpen(function (param) {
                                                        return true;
                                                      });
                                                }),
                                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                        direction: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        sx: {
                                                          width: "100%",
                                                          height: "100%",
                                                          borderRadius: "8px",
                                                          transition: "background-color 0.2s",
                                                          backgroundColor: images.length > 4 && idx === 3 ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.0)",
                                                          "&:hover": {
                                                            backgroundColor: images.length > 4 && idx === 3 ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.2)"
                                                          }
                                                        },
                                                        children: Caml_option.some(images.length > 4 && idx === 3 ? JsxRuntime.jsx(Typography, {
                                                                    sx: {
                                                                      color: "white",
                                                                      fontSize: "1.5rem",
                                                                      fontWeight: "bold"
                                                                    },
                                                                    children: Caml_option.some("+" + String(images.length - 3 | 0))
                                                                  }) : null)
                                                      }))
                                            }, String(idx));
                                })))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          sm: "block"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(YetAnotherReactLightbox.Lightbox, {
                                open: match[0],
                                close: (function () {
                                    setLightboxOpen(function (param) {
                                          return false;
                                        });
                                  }),
                                index: match$1[0],
                                slides: images
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        width: "100%",
                        position: "relative",
                        display: {
                          xs: "block",
                          sm: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(NukaCarousel.Carousel, {
                                children: Caml_option.some(Belt_Array.mapWithIndex(images, (function (i, s) {
                                            return JsxRuntime.jsx("img", {
                                                        style: {
                                                          height: "100%",
                                                          width: "100%",
                                                          objectFit: "cover"
                                                        },
                                                        alt: s.alt,
                                                        src: s.src
                                                      }, String(i));
                                          }))),
                                showArrows: images.length > 1,
                                showDots: images.length > 1,
                                scrollDistance: "screen",
                                className: "subclass-carousel"
                              }))
                    })
              ]
            });
}

var make = SubclassGallery;

exports.make = make;
/* react Not a pure module */
