// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Iso = require("iso-3166-1");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("./CS_Make.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CountryCodesList = require("country-codes-list");

function findOne(cclProperty, string) {
  return Core__Option.flatMap(CountryCodesList.findOne(cclProperty, string), (function (obj) {
                return Core__Option.map(obj.countryCallingCode.split(" ")[0], (function (firstPartOfCountryCode) {
                              return {
                                      countryCallingCode: firstPartOfCountryCode,
                                      flag: obj.flag,
                                      countryCode: obj.countryCode
                                    };
                            }));
              }));
}

function augment(param) {
  var numeric = param.numeric;
  var alpha3 = param.alpha3;
  var alpha2 = param.alpha2;
  var country = param.country;
  return Core__Option.mapWithDefault(findOne("countryCode", alpha2), {
              country: country,
              alpha2: alpha2,
              alpha3: alpha3,
              numeric: numeric
            }, (function (param) {
                return {
                        country: country,
                        alpha2: alpha2,
                        alpha3: alpha3,
                        numeric: numeric,
                        emoji: param.flag,
                        callingCode: "+" + param.countryCallingCode
                      };
              }));
}

var Impl = {
  findOne: findOne,
  augment: augment
};

function whereAlpha3(s) {
  return Core__Option.map(Caml_option.nullable_to_opt(Iso.whereAlpha3(s)), augment);
}

function whereAlpha2(s) {
  return Core__Option.map(Caml_option.nullable_to_opt(Iso.whereAlpha2(s)), augment);
}

function findFromNumber(firstPartOfCountryCode, restOfNumber) {
  var foundShort = findOne("countryCallingCode", firstPartOfCountryCode);
  var foundLong = Core__Option.flatMap(foundShort, (function (v) {
          var first = v.countryCallingCode;
          var second = restOfNumber.substring(0, 3);
          return findOne("countryCallingCode", first + " " + second);
        }));
  var iso2CharCountry;
  if (foundLong !== undefined) {
    iso2CharCountry = foundLong.countryCode;
  } else {
    if (foundShort === undefined) {
      return ;
    }
    iso2CharCountry = foundShort.countryCode;
  }
  return whereAlpha2(iso2CharCountry === "CA" ? "US" : iso2CharCountry);
}

var Bindings = {
  Impl: Impl,
  whereAlpha3: whereAlpha3,
  whereAlpha2: whereAlpha2,
  findFromNumber: findFromNumber
};

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.stringLength(S$RescriptSchema.string, 3, undefined), (function (s) {
        return function (v) {
          if (Belt_Option.isSome(whereAlpha3(v))) {
            return ;
          } else {
            return s.fail("Must be ISO3166-1 Alpha3", undefined);
          }
        };
      }));

function meta(t) {
  return Belt_Option.getExn(whereAlpha3(t));
}

var include = CS_Make$Util.Stringable.MakeFromMetaStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String,
      meta: meta
    });

var Alpha3 = include;

exports.Bindings = Bindings;
exports.Alpha3 = Alpha3;
/* include Not a pure module */
