// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ResetPasswordPage$Thin = require("../pages/ResetPasswordPage.bs.js");

function ResetPasswordRoute(props) {
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              dash: false,
              children: JsxRuntime.jsx(ResetPasswordPage$Thin.make, {})
            });
}

var make = ResetPasswordRoute;

exports.make = make;
/* react/jsx-runtime Not a pure module */
