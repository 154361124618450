// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

var regex = new RegExp("^([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$");

var struct = S$RescriptSchema.refine(S$RescriptSchema.string, (function (s) {
        return function (value) {
          var match = regex.exec(value);
          if (match == null) {
            return s.fail("Must be a uuid", undefined);
          }
          
        };
      }));

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var FromStruct = {
  toPrimitive: toPrimitive,
  primitiveToEl: primitiveToEl,
  primitiveToJson: primitiveToJson,
  struct: struct,
  QueryVar: undefined
};

var ObjId = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var PlainUUID = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var $$String;

exports.$$String = $$String;
exports.regex = regex;
exports.struct = struct;
exports.FromStruct = FromStruct;
exports.ObjId = ObjId;
exports.PlainUUID = PlainUUID;
/* regex Not a pure module */
