// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../../context/AppConfig.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function PlaidConfirmationModal(props) {
  var onClose = props.onClose;
  var match = AppConfig$Thin.Context.use();
  var brandFamilyName = match.brandFamily.brandFamilyName;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Plaid Disclaimer",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsxs(Typography, {
                                        level: "body-md",
                                        children: [
                                          brandFamilyName + " uses ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: "Plaid",
                                                href: "https://plaid.com/",
                                                target: "_blank"
                                              }),
                                          " to connect to your bank. Read more in our ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: "FAQ",
                                                href: "https://jococruise.com/2025faq/#how-does-the-ach-transfer-process",
                                                target: "_blank"
                                              }),
                                          "."
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Typography, {
                                        level: "body-md",
                                        children: [
                                          brandFamilyName + "'s privacy policy can be found ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: "here",
                                                href: "https://jococruise.com/2025faq/#what-is-your-privacy-policy",
                                                target: "_blank"
                                              }),
                                          ". Plaid's can be found  ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: "here",
                                                href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                target: "_blank"
                                              }),
                                          "."
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Typography, {
                                        level: "body-md",
                                        children: [
                                          "By using the Plaid service, you are consenting to  ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: brandFamilyName + "'s privacy policy",
                                                href: "https://jococruise.com/2025faq/#what-is-your-privacy-policy",
                                                target: "_blank"
                                              }),
                                          "  and to ",
                                          JsxRuntime.jsx(Joy$Util.Link.make, {
                                                children: "Plaid's",
                                                href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                target: "_blank"
                                              }),
                                          "."
                                        ]
                                      })
                                ]
                              })),
                      sx: {
                        px: 2
                      }
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsxs(Stack, {
                              direction: {
                                xs: "column",
                                sm: "row"
                              },
                              justifyContent: "space-between",
                              spacing: 1,
                              sx: {
                                width: "100%"
                              },
                              children: [
                                JsxRuntime.jsx(Button, {
                                      onClick: onClose,
                                      variant: "outlined",
                                      color: "neutral",
                                      children: "Cancel"
                                    }),
                                JsxRuntime.jsx(Button, {
                                      onClick: props.onConfirm,
                                      children: "Agree & Connect"
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = PlaidConfirmationModal;

exports.make = make;
/* Joy-Util Not a pure module */
