// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CACard$Thin = require("../components/connected-accounts/CACard.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ConnectedAccountsState$Thin = require("../utils/accounts/ConnectedAccountsState.bs.js");
var ConnectedAccountsPageQuery_graphql$Thin = require("../__generated__/ConnectedAccountsPageQuery_graphql.bs.js");
var ConnectedAccountsPage_query_graphql$Thin = require("../__generated__/ConnectedAccountsPage_query_graphql.bs.js");

var convertFragment = ConnectedAccountsPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ConnectedAccountsPage_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = ConnectedAccountsPageQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = ConnectedAccountsPageQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_accountStatus_decode = ConnectedAccountsPage_query_graphql$Thin.Utils.accountStatus_decode;

var QueryFragment_accountStatus_fromString = ConnectedAccountsPage_query_graphql$Thin.Utils.accountStatus_fromString;

var QueryFragment_docGender_decode = ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode;

var QueryFragment_docGender_fromString = ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_fromString;

var QueryFragment_lifecycleStatus_decode = ConnectedAccountsPage_query_graphql$Thin.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = ConnectedAccountsPage_query_graphql$Thin.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  accountStatus_decode: QueryFragment_accountStatus_decode,
  accountStatus_fromString: QueryFragment_accountStatus_fromString,
  docGender_decode: QueryFragment_docGender_decode,
  docGender_fromString: QueryFragment_docGender_fromString,
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function ConnectedAccountsPage(props) {
  var fragmentRefs = props.fragmentRefs;
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(), "store-and-network", undefined);
  };
  var connectedAccountState = HopperState$Util.Observable.useStore(ConnectedAccountsState$Thin.blankModel, undefined);
  React.useEffect((function () {
          var match = query.connectedAccounts;
          if (match !== undefined) {
            var nodes = match.nodes;
            HopperState$Util.Observable.notify(connectedAccountState, (function (param) {
                    var ca = Belt_MapInt.fromArray(Belt_Array.mapWithIndex(nodes, (function (idx, node) {
                                var pax = ConnectedAccountsState$Thin.prefillPaxFromConnectedAccount(idx, node);
                                return [
                                        idx,
                                        pax
                                      ];
                              })));
                    return {
                            ca: ca
                          };
                  }));
          }
          
        }), [Belt_Option.getWithDefault(Belt_Option.flatMap(query.connectedAccounts, (function (v) {
                    return JSON.stringify(v);
                  })), "")]);
  var match$1 = query.connectedAccounts;
  var connectedAcctCards = match$1 !== undefined ? Belt_Array.mapWithIndex(match$1.nodes, (function (i, node) {
            return [
                    i,
                    ConnectedAccountsState$Thin.createCardDetails(node)
                  ];
          })) : [];
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    alignItems: "flex-start",
                    spacing: 2,
                    sx: {
                      width: "100%"
                    },
                    children: [
                      JsxRuntime.jsx(Typography, {
                            level: "h3",
                            component: "h1",
                            children: Caml_option.some(Txt$Thin.ConnectedAccounts.connectedAccountsHeading.value)
                          }),
                      JsxRuntime.jsx(Stack, {
                            spacing: 2,
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(Belt_Array.map(connectedAcctCards, (function (param) {
                                        var idx = param[0];
                                        return JsxRuntime.jsx(CACard$Thin.make, {
                                                    fragmentRefs: fragmentRefs,
                                                    idx: idx,
                                                    connectedAccountState: connectedAccountState,
                                                    refetch: refetch$1,
                                                    cardDetails: param[1]
                                                  }, idx);
                                      })))
                          })
                    ]
                  }),
              backgroundColor: "background.level1"
            });
}

var make = ConnectedAccountsPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
