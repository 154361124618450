// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

function makeRefetchVariables(currentDate) {
  return {
          currentDate: currentDate
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"currentDate":{"c":"Util.CustomScalars.SQLDate.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentDate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "currentDate",
    "variableName": "currentDate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyStartDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyEndDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numBerths",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "connectedPassengers",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPax",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBc",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookingsRouteQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UpcomingBookingsSection_query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "BookingHistorySection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookingsRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandFamilyVoyagesConnection",
        "kind": "LinkedField",
        "name": "brandFamilyVoyages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandFamilyVoyage",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voyageName",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageSetSlug",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "upcoming",
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "lifecycleStatus": "ACTIVE"
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "greaterThanOrEqualTo",
                    "variableName": "currentDate"
                  }
                ],
                "kind": "ObjectValue",
                "name": "voyStartDate"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "BookingListitemsConnection",
        "kind": "LinkedField",
        "name": "bookingListitems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingListitem",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "history",
        "args": [
          {
            "fields": [
              {
                "items": [
                  {
                    "kind": "Literal",
                    "name": "or.0",
                    "value": {
                      "lifecycleStatus": {
                        "notEqualTo": "ACTIVE"
                      }
                    }
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "lessThan",
                            "variableName": "currentDate"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "voyEndDate"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "or"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "BookingListitemsConnection",
        "kind": "LinkedField",
        "name": "bookingListitems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingListitem",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifecycleStatus",
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efa123b57de86441c5f7f1e141e60723",
    "id": null,
    "metadata": {},
    "name": "BookingsRouteQuery",
    "operationKind": "query",
    "text": "query BookingsRouteQuery(\n  $currentDate: Date!\n) {\n  ...UpcomingBookingsSection_query_421UAl\n  ...BookingHistorySection_query_421UAl\n}\n\nfragment BookingHistorySection_listitem on BookingListitem {\n  bookingId\n  voyageSlug\n  voyStartDate\n  voyEndDate\n  numBerths\n  lifecycleStatus\n  connectedPassengers\n  isPax\n  isBc\n}\n\nfragment BookingHistorySection_query_421UAl on Query {\n  brandFamilyVoyages {\n    nodes {\n      voyageName\n      voyageSlug\n      id\n    }\n  }\n  history: bookingListitems(filter: {or: [{lifecycleStatus: {notEqualTo: ACTIVE}}, {voyEndDate: {lessThan: $currentDate}}]}) {\n    nodes {\n      bookingId\n      ...BookingHistorySection_listitem\n      id\n    }\n  }\n}\n\nfragment UpcomingBookingsSection_listitem on BookingListitem {\n  bookingId\n  voyageSlug\n  voyStartDate\n  voyEndDate\n  numBerths\n  connectedPassengers\n  isPax\n  isBc\n}\n\nfragment UpcomingBookingsSection_query_421UAl on Query {\n  brandFamilyVoyages {\n    nodes {\n      voyageSlug\n      voyageName\n      voyStartDate\n      voyEndDate\n      imageSetSlug\n      id\n    }\n  }\n  upcoming: bookingListitems(condition: {lifecycleStatus: ACTIVE}, filter: {voyStartDate: {greaterThanOrEqualTo: $currentDate}}) {\n    nodes {\n      bookingId\n      ...UpcomingBookingsSection_listitem\n      id\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
