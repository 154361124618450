// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var $$Option = require("@mui/joy/Option").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var PhoneNumberInput$Thin = require("./PhoneNumberInput.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;

function CountryInput(props) {
  var __required = props.required;
  var __size = props.size;
  var __variant = props.variant;
  var __disabled = props.disabled;
  var disabled = __disabled !== undefined ? __disabled : false;
  var variant = __variant !== undefined ? __variant : "plain";
  var size = __size !== undefined ? __size : "md";
  var required = __required !== undefined ? __required : false;
  var match = AppConfig$Thin.Context.use();
  return JsxRuntime.jsx(Joy$Util.JoySelect.make, {
              children: Caml_option.some(Belt_Array.map(match.countries, (function (option) {
                          var emoji = option.emoji;
                          return JsxRuntime.jsxs($$Option, {
                                      value: option.alpha3,
                                      children: [
                                        emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                children: Caml_option.some(emoji)
                                              }) : null,
                                        JsxRuntime.jsxs(ListItemContent, {
                                              children: [
                                                option.country,
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-xs",
                                                      children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                    })
                                              ],
                                              sx: {
                                                fontSize: "sm"
                                              }
                                            })
                                      ],
                                      label: option.alpha3,
                                      sx: {
                                        maxWidth: "calc(100vw - 32px)",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                      }
                                    }, option.alpha3);
                        }))),
              disabled: disabled,
              onChange: props.onChange,
              placeholder: props.placeholder,
              required: required,
              size: size,
              slotProps: {
                listbox: {
                  sx: {
                    zIndex: "modal"
                  }
                }
              },
              value: props.value,
              variant: variant
            });
}

function CountryInput$PhoneInput(props) {
  var __required = props.required;
  var __error = props.error;
  var __disabled = props.disabled;
  var disabled = __disabled !== undefined ? __disabled : false;
  var error = __error !== undefined ? __error : false;
  var required = __required !== undefined ? __required : false;
  var countryOptions = Belt_Array.keepMap(AppConfig$Thin.Context.use().countries, (function (v) {
          var match = v.callingCode;
          if (match !== undefined) {
            return v;
          }
          
        }));
  return JsxRuntime.jsx(PhoneNumberInput$Thin.make, {
              countryOptions: countryOptions,
              countryCode: props.countryCode,
              phoneNumber: props.phoneNumber,
              onUpdateCountryCode: props.onUpdateCountryCode,
              onUpdatePhoneNumber: props.onUpdatePhoneNumber,
              disabled: disabled,
              error: error,
              required: required
            });
}

var PhoneInput = {
  make: CountryInput$PhoneInput
};

var make = CountryInput;

exports.make = make;
exports.PhoneInput = PhoneInput;
/* Joy-Util Not a pure module */
