// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;
var VisibilityOffOutlined = require("@mui/icons-material/VisibilityOffOutlined").default;

function PasswordInput(props) {
  var label = props.label;
  var helperText = props.helperText;
  var errorText = props.errorText;
  var __error = props.error;
  var __variant = props.variant;
  var __required = props.required;
  var __disabled = props.disabled;
  var __size = props.size;
  var __fullWidth = props.fullWidth;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var size = __size !== undefined ? __size : "md";
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var variant = __variant !== undefined ? __variant : "outlined";
  var error = __error !== undefined ? __error : false;
  var match = React.useState(function () {
        return "password";
      });
  var setInputType = match[1];
  var inputType = match[0];
  var onClick = function (param) {
    if (inputType === "password") {
      return setInputType(function (param) {
                  return "text";
                });
    } else {
      return setInputType(function (param) {
                  return "password";
                });
    }
  };
  var tmp = inputType === "text" ? JsxRuntime.jsx(IconButton, {
          onClick: onClick,
          children: Caml_option.some(JsxRuntime.jsx(VisibilityOffOutlined, {
                    sx: {
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }
                  }))
        }) : JsxRuntime.jsx(IconButton, {
          onClick: onClick,
          children: Caml_option.some(JsxRuntime.jsx(VisibilityOutlined, {
                    sx: {
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }
                  }))
        });
  var tmp$1;
  var exit = 0;
  if (error && errorText !== undefined) {
    tmp$1 = JsxRuntime.jsx(FormHelperText, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    color: "danger",
                    level: "body-sm",
                    children: Caml_option.some(errorText)
                  }))
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = helperText !== undefined ? JsxRuntime.jsx(FormHelperText, {
            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      children: Caml_option.some(helperText)
                    }))
          }) : JsxRuntime.jsx(JsxRuntime.Fragment, {});
  }
  return JsxRuntime.jsxs(FormControl, {
              error: error,
              children: [
                label !== undefined ? JsxRuntime.jsx(FormLabel, {
                        required: required,
                        children: Caml_option.some(label)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                JsxRuntime.jsx(Joy$Util.Input.make, {
                      type_: inputType,
                      size: size,
                      fullWidth: fullWidth,
                      placeholder: Belt_Option.getWithDefault(props.placeholder, ""),
                      disabled: disabled,
                      required: required,
                      variant: variant,
                      role: Belt_Option.getWithDefault(props.role, ""),
                      value: props.value,
                      onChange: props.onChange,
                      endDecorator: Caml_option.some(tmp)
                    }),
                tmp$1
              ]
            });
}

var make = PasswordInput;

exports.make = make;
/* react Not a pure module */
